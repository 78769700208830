import React, { useEffect } from 'react';
import cx from 'classnames';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { Button } from 'components/common/Button';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { InputText } from 'components/common/InputText';
import { Label } from 'components/common/Label';
import { RadioGroup } from 'components/common/RadioGroup';
import { Text } from 'components/common/Text';
import { FormProgressBar } from 'components/FormProgressBar';
import {
  FormProgressPoint,
  FormProgressPointsContainer,
} from 'components/FormProgressPoints/FormProgressPoints';
import { useExemptionsContext } from 'contexts/ExemptionsContext';
import { useDependantExemptionsContext } from 'contexts/DependantExemptionsContext';
import { useToastContext } from 'contexts/ToastContext';
import { validDateFuture } from 'utils/validation';
import { ReactComponent as Checkmark } from 'assets/icons/lawsat/checkmark.svg';
import { FormProgressBarProps } from 'components/FormProgressBar/FormProgressBar';

import styles from './PrescriptionExemptionExpire.module.scss';

interface PrescriptionExemptionExpireProps {
  dependantId?: string;
  heading: string;
  selectExemptionRoute: string;
  submitButton: {
    route: string;
    label: string;
  };
  progressBar?: FormProgressBarProps;
}

export const PrescriptionExemptionExpire = ({
  dependantId,
  heading,
  selectExemptionRoute,
  submitButton,
  progressBar,
}: PrescriptionExemptionExpireProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const history = useHistory();
  const { exemptionSelected, savePatientExemptionReason } =
    useExemptionsContext();
  const { dependantExemptionSelected, saveDependantExemptionReason } =
    useDependantExemptionsContext();

  const exemption = exemptionSelected || dependantExemptionSelected;

  useEffect(() => {
    !exemption && history.push(selectExemptionRoute);
  }, []);

  const schema = yup.object().shape({
    exemptionExp: yup
      .string()
      .required('date required')
      .test(
        'dateOfBirth',
        t('PrescriptionFlow.exemptionExpiry.formErrors.dateValidation'),
        function () {
          return validDateFuture(
            this.parent.dateDD,
            this.parent.dateMM,
            this.parent.dateYY,
          );
        },
      ),
  });

  const dateFieldClass = 'prescription-exemption__dateInput';

  const {
    control,
    handleSubmit,
    getValues,
    errors,
    setValue,
    watch,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dateChanged = watch(['dateDD', 'dateMM', 'dateYY']);

  useEffect(() => {
    const dateValues = getValues(['dateDD', 'dateMM', 'dateYY']);
    setValue(
      'exemptionExp',
      `${dateValues.dateYY}-${dateValues.dateMM}-${dateValues.dateDD}`,
      {
        shouldDirty: true,
        shouldValidate: formState.isSubmitted,
      },
    );
  }, [dateChanged]);

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('PrescriptionFlow.exemptionExpiry.formErrors.toastNoReason'),
    });
  };

  const onSubmit = () => {
    if (exemption) {
      const payload = {
        exemptionReasonId: exemption?.id,
        expiryDate: getValues('exemptionExp'),
        shouldNotify: getValues('renewal') === 'true',
      };
      if (dependantId) {
        saveDependantExemptionReason(dependantId, payload);
      } else {
        savePatientExemptionReason(payload);
      }
      history.push(submitButton.route);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <form
            data-testid="prescription-exemption-form"
            onSubmit={handleSubmit(onSubmit, invalid)}
          >
            {progressBar && (
              <div
                className={styles['prescription-exemption__progress-container']}
              >
                <FormProgressBar
                  numberOfStages={progressBar.numberOfStages}
                  currentStage={progressBar.currentStage}
                />
              </div>
            )}
            <div className={styles['prescription-exemption__form']}>
              <Heading
                tag="h2"
                size="lg"
                className={styles['prescription-exemption__title']}
              >
                {heading}
              </Heading>

              <FormProgressPointsContainer>
                <FormProgressPoint icon={Checkmark}>
                  <Heading
                    size="sm"
                    tag="h3"
                    className={styles['prescription-exemption__title']}
                  >
                    {exemption?.description}
                  </Heading>
                </FormProgressPoint>
                <FormProgressPoint>
                  <Label
                    size="lg"
                    className={cx({
                      [styles['prescription-exemption__text-validation']]:
                        errors.exemptionExp?.message.length > 0,
                    })}
                  >
                    {t(
                      'PrescriptionFlow.exemptionExpiry.formLabels.expireLabel',
                    )}
                  </Label>
                  {errors.exemptionExp?.message && (
                    <span
                      className={
                        styles['prescription-exemption__text-validation']
                      }
                    >
                      {errors.exemptionExp?.message}
                    </span>
                  )}
                  <Controller
                    control={control}
                    defaultValue=""
                    name="exemptionExp"
                    render={(props) => (
                      <>
                        <input
                          className={styles[`${dateFieldClass}`]}
                          {...props}
                          type="hidden"
                        />
                      </>
                    )}
                  />
                  <div className={styles['prescription-exemption__dateFields']}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="dateDD"
                      render={(props) => (
                        <>
                          <InputText
                            {...props}
                            maxLength={2}
                            onBlur={() =>
                              dateChanged.dateDD?.length === 1 &&
                              setValue('dateDD', `0${dateChanged.dateDD}`)
                            }
                            variant={
                              errors.exemptionExp?.message.length > 0
                                ? 'negative'
                                : 'accent'
                            }
                            className={styles[`${dateFieldClass}`]}
                            label={t(
                              'Account.contactDetails.formLabels.dateOfBirthDay',
                            )}
                          />
                        </>
                      )}
                    />
                    <Controller
                      control={control}
                      defaultValue=""
                      name="dateMM"
                      render={(props) => (
                        <>
                          <InputText
                            {...props}
                            maxLength={2}
                            variant={
                              errors.exemptionExp?.message.length > 0
                                ? 'negative'
                                : 'accent'
                            }
                            className={styles[`${dateFieldClass}`]}
                            label={t(
                              'Account.contactDetails.formLabels.dateOfBirthMonth',
                            )}
                            onBlur={() =>
                              dateChanged.dateMM?.length === 1 &&
                              setValue('dateMM', `0${dateChanged.dateMM}`)
                            }
                          />
                        </>
                      )}
                    />
                    <Controller
                      control={control}
                      defaultValue=""
                      name="dateYY"
                      render={(props) => (
                        <>
                          <InputText
                            maxLength={4}
                            variant={
                              errors.exemptionExp?.message.length > 0
                                ? 'negative'
                                : 'accent'
                            }
                            className={styles[`${dateFieldClass}`]}
                            label={t(
                              'Account.contactDetails.formLabels.dateOfBirthYear',
                            )}
                            {...props}
                          />
                        </>
                      )}
                    />
                  </div>
                </FormProgressPoint>
                <Controller
                  control={control}
                  defaultValue={'true'}
                  name="renewal"
                  render={(props) => (
                    <FormProgressPoint>
                      <Label id="renewalLabel">
                        {t(
                          'PrescriptionFlow.exemptionExpiry.formLabels.renewalLabel',
                        )}
                      </Label>
                      <RadioGroup
                        id="renewal"
                        labelBy="renewalLabel"
                        values={[
                          {
                            value: 'true',
                            label: t(
                              'PrescriptionFlow.exemptionExpiry.formLabels.renewTrue',
                            ),
                          },
                          {
                            value: 'false',
                            label: t(
                              'PrescriptionFlow.exemptionExpiry.formLabels.renewFalse',
                            ),
                          },
                        ]}
                        size="md"
                        orientation="horizontal"
                        {...props}
                      />
                      <Text
                        className={
                          styles['prescription-exemption__expire-reminder']
                        }
                      >
                        {t(
                          'PrescriptionFlow.exemptionExpiry.formLabels.renewalCaption',
                        )}
                      </Text>
                    </FormProgressPoint>
                  )}
                />
              </FormProgressPointsContainer>
            </div>
            <AppBarBottom fullWidth>
              <Button
                width="full"
                testId="submit-button"
                type="submit"
                appearance="solid"
                label={submitButton.label}
                isLoading={false}
              />
            </AppBarBottom>
          </form>
        </Col>
      </Row>
    </Container>
  );
};
