import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePrescriptionContext } from 'contexts/PrescriptionContext';
import { usePatientContext } from 'contexts/PatientContext';
import { useDependantContext } from 'contexts/DependantContext';
import { useModalContext } from 'contexts/ModalContext';
import { Container, Row, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { PrescriptionList } from 'components/PrescriptionList';
import { Icon } from 'components/common/Icon';
import { SwitchPatientModal } from 'components/SwitchPatientModal';
import { routes } from 'routes';
import { ReactComponent as AccountCreatedImage } from 'assets/illustrations/account-setup.svg';
import { ReactComponent as Pill } from 'assets/icons/lawsat/Pill.svg';

import styles from './PrescriptionLanding.module.scss';
const buttonStyle = styles['prescription-landing__button'];
const spacerStyle = styles['prescription-landing__hr'];

export const PrescriptionLanding = () => {
  const { dependantId } = useParams<{ dependantId: string }>();
  const { t } = useTranslation();
  const { open } = useModalContext();
  const { patientPrescriptions, getPatientPrescriptions } =
    usePrescriptionContext();
  const {
    dependantPrescriptions,
    dependant,
    getDependantPrescriptions,
    getDependant,
  } = useDependantContext();
  const { patient, getPatient, getDependants, dependants } =
    usePatientContext();

  useEffect(() => {
    getPatient();
    getDependants();
    getPatientPrescriptions();
    if (dependantId) {
      getDependant(dependantId);
      getDependantPrescriptions(dependantId);
    }
  }, [dependantId]);

  const addItemText = 'PrescriptionFlow.landing.button';

  const prescriptionList =
    (dependantId ? dependantPrescriptions : patientPrescriptions) ?? [];

  const openModal = () =>
    open(<SwitchPatientModal dependantId={dependantId} patient={patient} />);

  const showAddDependantButton = !dependantId && dependants?.length === 0;

  return (
    <>
      <Container className={styles['prescription-landing']}>
        <Row>
          <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <Heading
              tag="h2"
              size="xl"
              className={styles['prescription-landing__title']}
            >
              {dependantId
                ? t('PrescriptionFlow.landing.dependantTitle', {
                    dependant: dependant?.firstName,
                  })
                : t('PrescriptionFlow.landing.title')}
            </Heading>

            {prescriptionList.length === 0 && dependantId && (
              <>
                <div
                  className={styles['prescription-landing__no-orders-content']}
                >
                  <Icon
                    icon={Pill}
                    className={styles['prescription-landing__icon']}
                  />
                  <Text
                    size="lg"
                    className={styles['prescription-landing__no-orders-text']}
                  >
                    {t('PrescriptionFlow.landing.dependantText')}
                  </Text>
                </div>

                <hr className={spacerStyle} />

                <Button
                  width="full"
                  className={buttonStyle}
                  label={t(addItemText)}
                  link={{
                    to: `${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.ADD}`,
                  }}
                />

                <AddOrSwitchDependant
                  openModal={openModal}
                  showAddDependantButton={showAddDependantButton}
                />
              </>
            )}

            {prescriptionList.length === 0 && !dependantId && (
              <div className={styles['prescription-landing__content']}>
                <>
                  <AccountCreatedImage />
                  <Heading
                    tag="h3"
                    size="lg"
                    className={styles['prescription-landing__title']}
                  >
                    {t('PrescriptionFlow.landing.subTitle')}
                  </Heading>
                  <Text
                    size="lg"
                    className={styles['prescription-landing__text']}
                  >
                    {t('PrescriptionFlow.landing.text')}
                  </Text>

                  <hr className={spacerStyle} />

                  <Button
                    width="full"
                    className={buttonStyle}
                    label={t(addItemText)}
                    link={{
                      to: routes.PRESCRIPTION.ADD,
                    }}
                  />

                  <AddOrSwitchDependant
                    openModal={openModal}
                    showAddDependantButton={showAddDependantButton}
                  />
                </>
              </div>
            )}

            {prescriptionList.length > 0 && (
              <>
                <PrescriptionList list={prescriptionList} />

                <hr className={spacerStyle} />

                <Button
                  width="full"
                  className={buttonStyle}
                  label={t(addItemText)}
                  link={{
                    to: dependantId
                      ? `${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.ADD}`
                      : routes.PRESCRIPTION.ADD,
                  }}
                />

                <AddOrSwitchDependant
                  openModal={openModal}
                  showAddDependantButton={showAddDependantButton}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

const AddOrSwitchDependant = ({
  showAddDependantButton,
  openModal,
}: {
  showAddDependantButton: boolean;
  openModal: () => void;
}) => {
  const { t } = useTranslation();
  return showAddDependantButton ? (
    <div>
      <Button
        width="full"
        appearance="outline"
        className={buttonStyle}
        label={t('PrescriptionFlow.landing.dependantCTA')}
        link={{
          to: routes.DEPENDANT.ADD.BASE,
        }}
      />
    </div>
  ) : (
    <div>
      <Button
        width="full"
        appearance="outline"
        className={buttonStyle}
        label={t('PrescriptionFlow.landing.switchCTA')}
        onClick={() => openModal()}
      />
    </div>
  );
};
