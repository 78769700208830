import { PMedQuestionnaire, PmedFormResponse } from 'models/PMedQuestionnaire';
import { getIndexOfProductInBasket } from './HelperFunctions';
import { AppBasket } from 'models/Basket';

export const mergePmedForms = (
  formsToMerge: PmedFormResponse[],
  basket: AppBasket,
) => {
  const updatedForms: PmedFormResponse[] = [];
  formsToMerge &&
    formsToMerge.length > 0 &&
    formsToMerge.forEach((form) => {
      updatedForms.push(form);
    });
  return { ...basket, pmedForms: updatedForms };
};

export const removePmedFormFromBasket = (
  prevBasket: AppBasket,
  productId: string,
) => {
  const udpatedForms = [...prevBasket.pmedForms];
  const formIndex = udpatedForms.findIndex(
    (form) => form.productId.toString() === productId,
  );
  if (formIndex !== -1) {
    udpatedForms.splice(formIndex, 1);
  }
  return { ...prevBasket, pmedForms: udpatedForms };
};

export const savePmedFormToBasket = (
  prevBasket: AppBasket,
  productId: number,
  formAnswers: PMedQuestionnaire,
) => {
  const udpatedForms = [...prevBasket.pmedForms];
  const updatedShopProducts = [...prevBasket.shopProducts];
  const pmedFormIndex = udpatedForms.findIndex(
    (formResponse) => formResponse.productId === productId,
  );
  const shopProductIndex = getIndexOfProductInBasket(
    productId.toString(),
    updatedShopProducts,
  );
  if (pmedFormIndex === -1) {
    udpatedForms.push({ productId, formAnswers });
  } else {
    udpatedForms[pmedFormIndex] = {
      ...udpatedForms[pmedFormIndex],
      formAnswers,
    };
  }
  if (shopProductIndex !== -1) {
    updatedShopProducts[shopProductIndex].pMedQuestionComplete = true;
  }
  return {
    ...prevBasket,
    pmedForms: udpatedForms,
    shopProducts: updatedShopProducts,
  };
};
