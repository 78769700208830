import React, { Dispatch, SetStateAction } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Input } from 'reakit/Input';
import { Label } from 'components/common/Label';
import { Text } from 'components/common/Text';
import styles from './FileUpload.module.scss';

export interface FileUploadProps {
  inputLabel: string;
  uploadFormatHelperText?: string;
  generalHelperText?: string;
  setFileNames: Dispatch<SetStateAction<string[]>>;
  accept?: string;
  fileNames: string[];
  validationError?: string;
  onChange?: (value: File[] | null | undefined) => void;
  value?: string;
  editing?: boolean;
  className?: string;
  allowMultiSelect?: boolean;
}

const FileUploadRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FileUploadProps
> = (
  {
    accept,
    fileNames,
    setFileNames,
    validationError,
    onChange,
    value,
    inputLabel,
    uploadFormatHelperText,
    generalHelperText,
    className,
    allowMultiSelect = false,
  },
  ref,
) => {
  const { t } = useTranslation();

  const buttonText =
    fileNames?.length > 0 ? t('common.imageReplace') : t('common.imageAdd');

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event.target.files.length > 0) {
      onChange?.([...event.target.files].map((file) => file));
      setFileNames([...event.target.files].map((file) => file.name));
    }
  };

  return (
    <div className={cx(styles['file-upload'], className)}>
      <Label
        inputId="file"
        size="lg"
        className={cx(!!validationError && styles['file-upload__error'])}
      >
        {inputLabel}
      </Label>
      {!!validationError && (
        <span
          className={styles['file-upload__error']}
          data-testid="file-upload__error"
          role="alert"
        >
          {validationError}
        </span>
      )}

      {generalHelperText && (
        <span className={styles['file-upload__helper-text']}>
          {generalHelperText}
        </span>
      )}
      {uploadFormatHelperText && (
        <span className={styles['file-upload__helper-text']}>
          {uploadFormatHelperText}
        </span>
      )}
      <Input
        type="file"
        name="file"
        id="file"
        data-testid="file-input"
        accept={accept}
        className={styles['file-upload__input']}
        ref={ref}
        tabIndex={-1}
        onChange={handleOnChange}
        value={value}
        multiple={allowMultiSelect}
      />
      {fileNames.length > 0 && (
        <div className={styles['file-upload__file-name-container']}>
          {fileNames.map((fileName) => (
            <Text key={fileName}>{fileName}</Text>
          ))}
        </div>
      )}
      <div className={styles['file-upload__button-container']}>
        <div
          tabIndex={0}
          role="button"
          className={styles['file-upload__button']}
        >
          <label
            htmlFor="file"
            className={styles['file-upload__text']}
            data-testid="upload-button"
          >
            {buttonText}
          </label>
        </div>
      </div>
    </div>
  );
};

export const FileUpload = React.forwardRef(FileUploadRef);
