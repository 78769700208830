import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Button } from 'components/common/Button';
import { Container } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { PharmacyContactCard } from 'components/PharmacyContactCard';
import { PharmacyContactSummary } from 'models/Pharmacy/Pharmacy';

import styles from './PharmacyListSection.module.scss';

interface PharmacyListSectionProps {
  heading: string;
  pharmacies: PharmacyContactSummary[];
  cta?: { label: string; to: string };
  wrapper?: boolean;
}

export const PharmacyListSection = ({
  heading,
  pharmacies,
  cta,
  wrapper = false,
}: PharmacyListSectionProps) => {
  const { t } = useTranslation();
  const { logCtaClick, logSectionView } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.pharmacyListSection.sectionName'),
      });
    }
  }, [isInViewport]);

  return (
    <div ref={elementRef}>
      <Container
        className={clsx(styles['pharmacy-list-section__container'], {
          [styles['pharmacy-list-section__container--wrapper']]: wrapper,
        })}
      >
        <Heading
          tag="h1"
          size="xxl"
          className={clsx(styles['pharmacy-list-section__heading'], {
            [styles['pharmacy-list-section__heading--wrapper']]: wrapper,
          })}
        >
          {heading}
        </Heading>

        <div
          className={clsx(
            styles['pharmacy-list-section__pharmacies__container'],
            {
              [styles['pharmacy-list-section__pharmacies__container--wrapper']]:
                wrapper,
            },
          )}
        >
          {pharmacies.map((pharmacy) => (
            <PharmacyContactCard key={pharmacy.gphc} pharmacy={pharmacy} />
          ))}
        </div>

        {cta && (
          <div className={styles['pharmacy-list-section__pharmacies__cta']}>
            <Button
              label={cta.label}
              link={{ to: cta.to }}
              onClick={() =>
                logCtaClick({
                  label: cta.label,
                  pageName: pageName?.log,
                  section: t('amplitude.pharmacyListSection.section'),
                })
              }
            />
          </div>
        )}
      </Container>
    </div>
  );
};
