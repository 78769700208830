/* eslint-disable sonarjs/no-small-switch */
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useProductsContext } from 'contexts/ProductsContext';
import { useBasketContext } from 'contexts/BasketContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { Heading } from 'components/common/Heading';
import { Col, Container, Row } from 'components/common/grid';
import { Button } from 'components/common/Button';
import { FormProgressBar } from 'components/FormProgressBar';
import { LandingPageSpinner } from 'components/common/Spinner';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { PomForm as PomFormType } from 'models/PomQuestionnaires';
import { EczemaPomForm } from './EczemaPomForm';
import { PsoriasisPomForm } from './PsoriasisPomForm';
import { WeightLossPomForm } from './WeightLossPomForm';
import { ErectileDysfunctionPomForm } from './ErectileDysfunctionPomForm';
import { AcnePomForm } from './AcnePomForm';
import { RosaceaPomForm } from './RosaceaPomForm';

import styles from './PomForm.module.scss';

const getForm = (
  conditionId: number,
  onSubmit: (
    payload: PomFormType,
    isImageUploadRequired?: boolean,
  ) => Promise<void>,
  ActionButtons: () => JSX.Element,
) => {
  switch (conditionId) {
    case 1:
      return (
        <AcnePomForm onSubmit={onSubmit} actionButtons={<ActionButtons />} />
      );
    case 2:
      return (
        <EczemaPomForm onSubmit={onSubmit} actionButtons={<ActionButtons />} />
      );
    case 3:
      return (
        <RosaceaPomForm onSubmit={onSubmit} actionButtons={<ActionButtons />} />
      );
    case 4:
      return (
        <ErectileDysfunctionPomForm
          onSubmit={onSubmit}
          actionButtons={<ActionButtons />}
        />
      );
    case 14:
      return (
        <WeightLossPomForm
          onSubmit={onSubmit}
          actionButtons={<ActionButtons />}
        />
      );
    case 15:
      return (
        <PsoriasisPomForm
          onSubmit={onSubmit}
          actionButtons={<ActionButtons />}
        />
      );
    default:
      return <></>;
  }
};

interface PomFormProps {
  nextButton: { label: string; route: string };
  backButton: { label: string; route: string };
}

export const PomForm = ({ backButton, nextButton }: PomFormProps) => {
  const { productId } = useParams<{
    productId?: string;
  }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { product } = useProductsContext();
  const { getCondition, condition } = useConditionsContext();
  const {
    handleSavePomFormToBasket,
    handleUploadPomFormImages,
    loading: { pomImageUploadStatus },
  } = useBasketContext();
  const { logPageView } = useAmplitudeContext();

  useEffect(() => {
    if (productId) {
      product?.productConditionId && getCondition(product?.productConditionId);
    }
  }, [productId]);
  useEffect(() => {
    logPageView({
      pageName: t('amplitude.pomForm.pageName', {
        conditionName: condition?.name,
      }),
    });
  }, [condition]);

  if ((productId && !product) || !condition) {
    return <LandingPageSpinner />;
  }

  const ActionButtons = () => {
    return (
      <div className={styles['pomform__button-container']}>
        <Button
          appearance="outline"
          label={backButton.label}
          link={{ to: backButton.route }}
          disabled={pomImageUploadStatus === 'loading'}
        />
        <Button
          type="submit"
          appearance="solid"
          label={nextButton.label}
          isLoading={pomImageUploadStatus === 'loading'}
        />
      </div>
    );
  };

  const onSubmit = async (
    payload: PomFormType,
    isImageUploadRequired?: boolean,
  ) => {
    let imageGUIDs: string[] = [];
    let imageUploadSuccess = false;
    if (
      'imageFiles' in payload &&
      payload.imageFiles &&
      isImageUploadRequired
    ) {
      const { guids, success } = await handleUploadPomFormImages(
        payload.imageFiles,
        payload.conditionId,
      );
      imageGUIDs = guids;
      success && delete payload.imageFiles;
      imageUploadSuccess = success;
    } else if ('imageNames' in payload) {
      imageGUIDs = payload.imageNames;
    }

    if (imageUploadSuccess || !isImageUploadRequired) {
      const payloadForSaving = {
        ...payload,
        ...{
          imageNames: imageGUIDs,
        },
      };
      handleSavePomFormToBasket(condition.id, payloadForSaving);
      history.push(nextButton.route);
    }
  };

  return (
    <Container className={styles['pomform__container']}>
      <Row>
        <Col>
          <div className={styles['pomform__progress-container']}>
            <FormProgressBar numberOfStages={4} currentStage={3} />
          </div>
          <Heading className={styles['pomform__heading']} tag="h3">
            {t('pomForms.heading')}
          </Heading>
          {getForm(condition.id, onSubmit, ActionButtons)}
        </Col>
      </Row>
    </Container>
  );
};
