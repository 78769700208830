import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { Branch } from 'components/common/Branch';
import { Button } from 'components/common/Button';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { FormProgressBar } from 'components/FormProgressBar';
import { PharmacyList } from 'components/PharmacyList';
import { useDependantContext } from 'contexts/DependantContext';
import { useToastContext } from 'contexts/ToastContext';
import { useHistory, useLocation } from 'react-router';
import { FormProgressBarProps } from 'components/FormProgressBar/FormProgressBar';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import { routes } from 'routes';

import styles from './DependantSelectPharmacy.module.scss';

interface DependantSelectPharmacyProps {
  heading: string;
  submitButton: {
    label: string;
    route: string;
  };
  progressBar?: FormProgressBarProps;
}

export const DependantSelectPharmacy = ({
  submitButton,
  progressBar,
  heading,
}: DependantSelectPharmacyProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast, dismissToast } = useToastContext();
  const { pathname } = useLocation();

  const { dependant, dependantPharmacies, saveDependantPharmacy } =
    useDependantContext();

  const { control, handleSubmit, getValues, setValue } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        pharmacySelected: yup
          .string()
          .required(t('Dependant.selectPharmacy.formErrors.requiredPharmacy')),
      }),
    ),
  });

  useEffect(() => {
    if (dependantPharmacies.length > 0) {
      const selected = dependantPharmacies.find(
        (item) => item.selectedByPatient === true,
      );
      if (selected !== null) {
        setValue('pharmacySelected', selected?.id.toString());
      }
    }
  }, []);

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('Dependant.selectPharmacy.formErrors.toastNoPharmacy'),
      description: t(
        'Dependant.selectPharmacy.formErrors.toastNoPharmacyDescription',
      ),
    });
  };

  const onSubmit = async () => {
    dismissToast();
    saveDependantPharmacy(dependant?.id, {
      pharmacyId: parseInt(getValues('pharmacySelected')),
    });
    history.push(submitButton.route);
  };

  const breadcrumbs: Crumb[] = [
    { route: routes.ACCOUNT.BASE, text: t('NavBarLabels.account') },
    {
      route: `${routes.DEPENDANT.ACCOUNT.BASE}${dependant?.id}`,
      text: t('Account.dependantLanding.title', {
        dependantName: dependant?.firstName,
      }),
    },
    {
      route: `${routes.DEPENDANT.ACCOUNT.BASE}${dependant?.id}${routes.DEPENDANT.ACCOUNT.EDIT.PHARMACY}`,
      text: t('Account.pharmacies.titleWithDependant', {
        dependantName: dependant?.firstName,
      }),
    },
  ];

  return (
    <Container className={styles['dependant-select-pharmacy']}>
      {pathname.match(routes.DEPENDANT.ACCOUNT.EDIT.PHARMACY) && (
        <Breadcrumbs links={breadcrumbs} />
      )}
      <Row>
        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          {progressBar && (
            <div
              className={
                styles['dependant-select-pharmacy__progress-container']
              }
            >
              <FormProgressBar
                numberOfStages={progressBar?.numberOfStages}
                currentStage={progressBar?.currentStage}
              />
            </div>
          )}
          <Heading id="labelForPharmacyList">{heading}</Heading>
          <Text>{t('Dependant.selectPharmacy.intro')}</Text>
          <Branch
            Component={() => (
              <>
                {dependantPharmacies && (
                  <form
                    data-testid="dependant-select-pharmacy-form"
                    className={styles['dependant-select-pharmacy__form']}
                    onSubmit={handleSubmit(onSubmit, invalid)}
                  >
                    <Controller
                      control={control}
                      defaultValue=""
                      name="pharmacySelected"
                      isRequired
                      render={(props) => (
                        <>
                          <PharmacyList
                            labelBy="labelForPharmacyList"
                            id="pharmacySelected"
                            values={dependantPharmacies}
                            {...props}
                          />
                        </>
                      )}
                    />
                    <AppBarBottom fullWidth>
                      <Button
                        width="full"
                        className={
                          styles['dependant-select-pharmacy__submit-button']
                        }
                        testId="submit-button"
                        type="submit"
                        appearance="solid"
                        label={submitButton.label}
                      />
                    </AppBarBottom>
                  </form>
                )}
              </>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};
