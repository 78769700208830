import React from 'react';
import { ReactComponent as ShoppingBag } from 'assets/illustrations/orders.svg';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { Container } from 'components/common/grid';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';

import styles from './EmptyBasket.module.scss';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';

export const EmptyBasket = () => {
  const { t } = useTranslation();
  const { login, authStatus } = useAuthContext();
  const { logCtaClick } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const navigate = useHistory();

  const handleLogCtaClick = (label: string) => {
    logCtaClick({
      pageName: pageName?.log,
      label: label,
    });
  };

  return (
    <Container>
      <div className={styles['empty-basket-cta']}>
        <ShoppingBag className={styles['empty-basket-cta__illustration']} />
        <Heading size="xxl" className={styles['empty-basket-cta__heading']}>
          {t('BasketPage.emptyBasket.heading')}
        </Heading>
      </div>
      <div className={styles['empty-basket-cta__ctas']}>
        <Button
          label={t('BasketPage.emptyBasket.prescriptions-btn')}
          onClick={() => {
            handleLogCtaClick(t('BasketPage.emptyBasket.prescriptions-btn'));
            authStatus
              ? navigate.push(routes.PRESCRIPTION.BASE)
              : login({ redirectToUrl: '/prescription' });
          }}
        />
        <Button
          label={t('BasketPage.emptyBasket.shop-btn')}
          appearance="outline"
          onClick={() => {
            handleLogCtaClick(t('BasketPage.emptyBasket.shop-btn'));
            navigate.push(routes.SHOP.BASE);
          }}
        />
      </div>
    </Container>
  );
};
