import { ReactNode, useEffect, useRef } from 'react';
import { NavBar } from '../NavBar';
import { useLoginRedirect } from 'hooks/useLoginRedirect';
import { LandingPageSpinner } from 'components/common/Spinner';
import { useLocation } from 'react-router-dom';
import { Footer } from '../Footer';
import { LandingPageContextProvider } from 'contexts/LandingPageContext';

import styles from './AppLayout.module.scss';
import { routes } from 'routes';

interface AppLayoutProps {
  children: ReactNode;
  classNames?: {
    footer?: string;
  };
}

export const AppLayout = ({ children, classNames }: AppLayoutProps) => {
  const { loading } = useLoginRedirect();
  const { pathname } = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    !pathname.includes(routes.HELP.BASE) &&
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [pathname]);

  return (
    <LandingPageContextProvider>
      <div className={styles['page-layout']} ref={ref}>
        <div className={styles['page-layout__navbar']}>
          <NavBar />
        </div>
        <div className={styles['page-layout__content']}>
          {loading && <LandingPageSpinner />}
          {!loading && children}
        </div>
        <div className={styles['page-layout__footer']}>
          <Footer className={classNames?.footer} />
        </div>
      </div>
    </LandingPageContextProvider>
  );
};
