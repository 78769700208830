import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ExemptionPayload } from 'api/Exemption';
import {
  deleteDependantExemption,
  getDependantExemption,
  saveDependantExemption,
} from 'api/Dependant';
import { useToastContext } from 'contexts/ToastContext';
import { Exemption, ExemptionDetails } from 'models/Exemption';
import { BranchStatus } from 'components/common/Branch';
import { useApiError } from 'hooks/useApiError';
import { routes } from 'routes';
import axios from 'axios';
import { useBasketContext } from 'contexts/BasketContext';

export interface DependantExemptionsLoading {
  getDependantExemptionsStatus?: BranchStatus;
  saveDependantExemptionsStatus?: BranchStatus;
  deleteDependantExemptionsStatus?: BranchStatus;
}

export interface DependantExemptionsContextProps {
  dependantExemptionSelected: Exemption | null;
  setDependantExemptionSelected: (exemption: Exemption) => void;
  saveDependantExemptionReason: (
    dependantId: string,
    payload: ExemptionPayload,
  ) => void;
  deleteDependantExemptionReason: (dependantId: string) => void;
  getDependantExemptionDetails: (dependantId: string) => void;
  setDependantExemptionDetails: (
    exemptionDetails: ExemptionDetails | null,
  ) => void;
  dependantExemptionDetails: ExemptionDetails | null;
  loading: DependantExemptionsLoading;
  setLoading: (loading: DependantExemptionsLoading) => void;
}

export const DependantExemptionsContextDefaults: DependantExemptionsContextProps =
  {
    dependantExemptionSelected: null,
    setDependantExemptionSelected: () => null,
    saveDependantExemptionReason: () => null,
    deleteDependantExemptionReason: () => null,
    getDependantExemptionDetails: () => null,
    setDependantExemptionDetails: () => null,
    dependantExemptionDetails: null,
    loading: {},
    setLoading: () => null,
  };

export const DependantExemptionsContext =
  React.createContext<DependantExemptionsContextProps>(
    DependantExemptionsContextDefaults,
  );

export const useDependantExemptionsContext =
  (): DependantExemptionsContextProps =>
    React.useContext(DependantExemptionsContext);

interface PatientDepenantExemptionsProviderProps {
  children?: React.ReactNode;
}

export const DependantExemptionsProvider = ({
  children,
}: PatientDepenantExemptionsProviderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast } = useToastContext();
  const { handleApiError } = useApiError();
  const { handleToggleExemptionStatus } = useBasketContext();
  const [loading, setLoading] = React.useState<DependantExemptionsLoading>({});
  const [dependantExemptionSelected, setDependantExemptionSelected] =
    React.useState<Exemption | null>(null);
  const [dependantExemptionDetails, setDependantExemptionDetails] =
    React.useState<ExemptionDetails | null>(null);

  const errors = {
    title: t('common.error.genericTitle'),
    message: t('common.error.genericMessage'),
  };

  const errorWithToast = () => {
    setToast({
      status: 'error',
      title: errors.title,
      description: errors.message,
    });
  };

  const getDependantExemptionDetails = async (dependantId: string) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        getDependantExemptionsStatus: 'loading',
      }));
      const results = await getDependantExemption(dependantId);
      setDependantExemptionDetails(results);
      setLoading((prevState) => ({
        ...prevState,
        getDependantExemptionsStatus: 'finished',
      }));
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        setLoading((prevState) => ({
          ...prevState,
          getDependantExemptionsStatus: 'finished',
        }));
        setDependantExemptionDetails(null);
      } else {
        handleApiError(() => {
          setLoading((prevState) => ({
            ...prevState,
            getDependantExemptionsStatus: 'error',
          }));
          errorWithToast();
        }, error);
      }
    }
  };

  const saveDependantExemptionReason = async (
    dependantId: string,
    payload: ExemptionPayload,
  ) => {
    try {
      setLoading({ ...loading, saveDependantExemptionsStatus: 'loading' });
      await saveDependantExemption(dependantId, payload);
      handleToggleExemptionStatus(true, dependantId);
      setLoading({ ...loading, saveDependantExemptionsStatus: 'finished' });
      await getDependantExemptionDetails(dependantId);
    } catch (error) {
      handleApiError(() => {
        setLoading({ ...loading, saveDependantExemptionsStatus: 'error' });
        errorWithToast();
      }, error);
    }
  };
  const deleteDependantExemptionReason = async (dependantId: string) => {
    try {
      setLoading({ ...loading, deleteDependantExemptionsStatus: 'loading' });
      await deleteDependantExemption(dependantId);
      handleToggleExemptionStatus(false, dependantId);
      setLoading({ ...loading, deleteDependantExemptionsStatus: 'finished' });
      await getDependantExemptionDetails(dependantId);
      history.push(`${routes.DEPENDANT.ACCOUNT.BASE}${dependantId}`);
    } catch (error) {
      handleApiError(() => {
        setLoading({ ...loading, deleteDependantExemptionsStatus: 'error' });
        errorWithToast();
      }, error);
    }
  };

  return (
    <DependantExemptionsContext.Provider
      value={{
        dependantExemptionSelected,
        setDependantExemptionSelected,
        saveDependantExemptionReason,
        deleteDependantExemptionReason,
        dependantExemptionDetails,
        getDependantExemptionDetails,
        setDependantExemptionDetails,
        loading,
        setLoading,
      }}
    >
      {children}
    </DependantExemptionsContext.Provider>
  );
};
