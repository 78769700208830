import React from 'react';
import cx from 'classnames';
import { Label } from 'components/common/Label';
import styles from './DropDown.module.scss';

export type selectValues = {
  key?: string | number;
  id: string | number;
  name: string;
};

export interface DropDownProps {
  testId?: string;
  className?: string;
  label?: string;
  name?: string;
  values: selectValues[];
  onChange?: Function;
  selected?: string;
  validationError?: string;
  variant?: 'accent' | 'negative' | 'ui';
  setChangedVal?: Function;
  placeholder?: string;
}

export const DropDownRef: React.ForwardRefRenderFunction<
  HTMLSelectElement,
  DropDownProps
> = (
  {
    testId,
    label,
    name,
    className,
    onChange,
    values,
    selected,
    validationError,
    variant,
    setChangedVal,
    placeholder,
    ...props
  },
  ref,
) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(e.target.value.toString());
    }
    setChangedVal && setChangedVal(e.target.value?.toString());
  };

  return (
    <div
      className={cx(styles['drop-down'], {
        [`${className}`]: className,
        [styles[`drop-down--${variant}`]]: variant,
      })}
      data-testid={testId}
    >
      <div
        className={cx(styles['drop-down__text-group'], {
          [styles[`drop-down__text-group--${variant}`]]: variant,
          [`${className}`]: className,
        })}
        data-testid={!!testId ? `group-${name}` : null}
      >
        {label && (
          <Label
            size="lg"
            inputId={`drop-down__text-${name}`}
            className={styles['drop-down__text-label']}
          >
            {label}
          </Label>
        )}

        {!!validationError && (
          <span
            id={`error-${name}`}
            className={styles['drop-down__text-validation']}
            data-testid={!!testId ? `error-${name}` : null}
            role="alert"
          >
            {validationError}
          </span>
        )}
      </div>
      <select
        {...props}
        id={`select--${name}`}
        className={styles['drop-down__select']}
        onChange={(e) => {
          handleOnChange(e);
        }}
        value={selected}
        ref={ref}
      >
        <>
          {placeholder && (
            <option value="" disabled selected>
              {placeholder}
            </option>
          )}
          {values.map(({ name: optionName, id, key }) => {
            return (
              <option key={key ?? id} value={id.toString()}>
                {optionName}
              </option>
            );
          })}
        </>
      </select>
    </div>
  );
};

export const DropDown = React.forwardRef(DropDownRef);
