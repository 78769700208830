import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PharmacyListSection } from 'components/PharmacyListSection';
import { BannerSection } from 'components/Sections/Common/BannerSection';
import { PriceSection } from 'components/Sections/TravelVaccinations/PriceSection';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';

import { routes } from 'routes';
import translations from 'translations/en.json';
import styles from './PricingPage.module.scss';

const pageName = {
  log: 'travel-vax-pricing-page',
  display: 'Travel Vaccinations',
};

const travelVaxPharmacies = translations.travelVaccinations.pharmacies;

export const PricingPage = () => {
  const { t } = useTranslation();
  const { logPageView } = useAmplitudeContext();
  const { setPageName, resetPageName } = useLandingPageContext();

  useEffect(() => {
    logPageView({ pageName: pageName.log });
    setPageName(pageName);
    return () => resetPageName();
  }, []);

  return (
    <>
      <PriceSection />

      <BannerSection
        heading={t('travelVaccinations.pricingPage.banner.heading')}
        subHeading={t('travelVaccinations.pricingPage.banner.subHeading')}
        image={{
          asset: '/assets/images/pharmacy-purchase.png',
          alt: t('travelVaccinations.pricingPage.banner.imgAltText'),
        }}
        variant="condensed"
      />

      <div className={styles['spacer']} />

      <PharmacyListSection
        pharmacies={travelVaxPharmacies}
        heading={t('travelVaccinations.pricingPage.vaccinationCentres.heading')}
        cta={{
          to: routes.TRAVELVACCINATIONS.BOOK,
          label: t('travelVaccinations.bookCtaLabel'),
        }}
        wrapper
      />
    </>
  );
};
