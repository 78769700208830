import { CogClient } from 'api/utils';

const url = `gpsuggester?highlightPreTag=%3Cstrong%3E&highlightPostTag=%3C%2Fstrong%3E&search=`;

export type GPResult = {
  '@search.text': string;
  Id: string;
  Name: string | null;
  Address1: string | null;
  Address2: string | null;
  Address3: string | null;
  City: string | null;
  County: string | null;
  Postcode: string | null;
};

export type GPSuggestReturn = {
  value: GPResult[];
};

export const gpSuggest = async (querystring: string): Promise<GPResult[]> => {
  const response = await CogClient.get(`${url}${querystring}`);
  return response.data.value;
};
