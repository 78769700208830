import { ReactElement } from 'react';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import clsx from 'clsx';

import styles from './ContactDetailIconWithLabel.module.scss';

interface ContactDetailIconWithLabelProps {
  icon: ReactElement;
  label: string;
  size?: 'sm' | 'lg';
  type?: 'phone' | 'email' | 'address' | 'string';
  googleLink?: string;
}

export const ContactDetailIconWithLabel = ({
  size = 'sm',
  icon,
  label,
  type = 'string',
  googleLink,
}: ContactDetailIconWithLabelProps) => {
  const textStyle = clsx(styles['icon-with-label__text'], {
    [styles['icon-with-label__text--lg']]: size === 'lg',
  });

  let textContent;
  switch (type) {
    case 'address':
      textContent = googleLink ? (
        <a href={googleLink} target="_blank" rel="noopener noreferrer">
          {label}
        </a>
      ) : (
        label
      );
      break;

    case 'email':
      textContent = (
        <a
          href={`mailto:${label}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles['icon-with-label__text--wrap']}
        >
          {label}
        </a>
      );
      break;

    case 'phone':
      textContent = (
        <a
          href={`tel:+44${label.slice(1)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label}
        </a>
      );
      break;

    case 'string':
    default:
      textContent = label;
      break;
  }

  return (
    <div
      className={clsx(styles['icon-with-label__container'], {
        [styles['icon-with-label__container--lg']]: size === 'lg',
      })}
    >
      {icon}
      {size === 'lg' ? (
        <Heading size="sm" className={textStyle}>
          {textContent}
        </Heading>
      ) : (
        <Text size="md" className={textStyle}>
          {textContent}
        </Text>
      )}
    </div>
  );
};
