import React, { SVGProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import cx from 'classnames';
import { usePatientContext } from 'contexts/PatientContext';
import { useDependantContext } from 'contexts/DependantContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { Icon } from 'components/common/Icon';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Label } from 'components/common/Label';
import { DropDown, DropDownProps } from 'components/common/DropDown';
import { ReactComponent as ShoppingBagIcon } from 'assets/icons/lawsat/Shop.svg';
import { ReactComponent as PackageIcon } from 'assets/icons/lawsat/Package.svg';
import {
  DeliveryAddress,
  DeliveryOption as DeliveryOptionModel,
  DateTimeSlot,
} from 'models/DeliveryOption';
import {
  formatDeliveryOptionTitle,
  getEstimatedDeliveryTimeInfo,
} from 'utils/DeliveryOption';

import styles from './DeliveryOption.module.scss';

const MORNING_HOURS = '8am - 12pm';
const AFTERNOON_HOURS = '12pm - 6pm';

interface RoundCheckboxProps {
  id: string;
  checked?: boolean;
  onChange: () => void;
}

export const RoundCheckbox = ({
  id,
  checked = false,
  onChange,
}: RoundCheckboxProps) => {
  return (
    <Label inputId={id} className={styles['custom-checkbox']}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        className={styles['custom-checkbox__hidden']}
        onChange={onChange}
      />
      <span className={styles['custom-checkbox__checkmark']}></span>
    </Label>
  );
};

interface DeliveryOptionCardProps {
  icon: React.FC<SVGProps<SVGSVGElement>>;
  heading: string;
  infoText: string;
  cost: number | string;
  checkboxProps: RoundCheckboxProps;
  checked: boolean;
  dropdownHeading: string;
  dropdownProps: DropDownProps;
  showDropDown: boolean;
}

const DeliveryOptionCard = ({
  icon,
  heading,
  infoText,
  cost,
  checkboxProps,
  checked,
  dropdownProps,
  dropdownHeading,
  showDropDown,
}: DeliveryOptionCardProps) => (
  <>
    <div
      data-testid="delivery-card"
      className={cx(styles['delivery-option-card'], {
        [styles['delivery-option-card--checked']]: checked,
      })}
      onClick={() => checkboxProps.onChange()}
      onKeyDown={(e) =>
        (e.key === ' ' || e.key === 'Spacebar') && checkboxProps.onChange()
      }
      role="presentation"
    >
      <Icon icon={icon} className={styles['delivery-option-card__icon']} />
      <div className={styles['delivery-option-card__body']}>
        <Heading size="md">{heading}</Heading>
        <Text className={styles['delivery-option-card__info-text']}>
          {infoText}
        </Text>
        <Heading size="sm">{cost}</Heading>
      </div>
      <div className={styles['delivery-option-card__checkbox-container']}>
        {/* OnChange handled in parent div, remove here to avoid double calling */}
        <RoundCheckbox {...checkboxProps} onChange={() => ({})} />{' '}
      </div>
    </div>
    {showDropDown && checked && (
      <>
        <Heading size="sm">{dropdownHeading}</Heading>
        <DropDown className={styles['dropdown']} {...dropdownProps} />
      </>
    )}
  </>
);

interface DeliveryOptionProps {
  type?: 'shop' | 'prescription';
  checked: boolean;
  deliveryOption: DeliveryOptionModel;
  deliveryDataHandler: (
    orderType: 'shop' | 'prescription',
    data: DeliveryOptionModel | DeliveryAddress | DateTimeSlot,
    pharmacyId?: number,
  ) => void;
  mandatory?: boolean;
  dependantId?: string;
}

export const DeliveryOption = ({
  type = 'shop',
  checked,
  deliveryOption,
  deliveryDataHandler,
  dependantId,
  mandatory = false,
}: DeliveryOptionProps) => {
  const { t } = useTranslation();
  const { patientPharmacies, patientPharmacy, patient } = usePatientContext();
  const { dependantPharmacies, dependantPharmacy, dependant } =
    useDependantContext();
  const { logCtaClick } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const { option, cost, readyIn, availableDates } = deliveryOption;
  const [selectedPharmacyId, setSelectedPharmacyId] = useState<
    string | undefined
  >(
    dependantId && dependantPharmacy
      ? dependantPharmacy.id.toString()
      : patientPharmacy
      ? patientPharmacy?.id.toString()
      : undefined,
  );
  const [selectedDeliverySlotId, setSelectedDeliverySlotId] =
    useState<string>();

  const allPharmacies = dependantId ? dependantPharmacies : patientPharmacies;

  useEffect(() => {
    if (mandatory) {
      handleDeliveryOptionCheckbox();
      type === 'shop' &&
        option === 'Collection' &&
        selectedPharmacyId &&
        handlePharmacyChange(selectedPharmacyId);
    }
  }, []);

  const handleLogCtaClick = () => {
    logCtaClick({
      label: deliveryOption.option,
      pageName: pageName?.log,
    });
  };

  const handleDeliveryOptionCheckbox = () => {
    deliveryDataHandler(type, {
      ...deliveryOption,
      deliveryName: dependantId
        ? `${dependant?.firstName} ${dependant?.lastName}`
        : `${patient?.firstName} ${patient?.lastName}`,
    });
    handleLogCtaClick();
    type === 'shop' &&
      option === 'Collection' &&
      selectedPharmacyId &&
      handlePharmacyChange(selectedPharmacyId);
  };

  const handlePharmacyChange = (id: string) => {
    const pharmacy = allPharmacies.find(
      (pharmacy) => pharmacy.id.toString() === id,
    );
    if (pharmacy) {
      deliveryDataHandler(
        type,
        {
          line1: pharmacy.addressLine1,
          line2: pharmacy.addressLine2,
          line3: pharmacy.addressLine3,
          townCity: pharmacy.addressTownCity,
          county: pharmacy.addressCounty,
          postCode: pharmacy.addressPostCode,
        },
        pharmacy.id,
      );
    }
    setSelectedPharmacyId(id);
  };

  const handleDeliveryDayChange = (deliveryDateAndTime: string) => {
    const [deliveryDate, deliveryTimeSlot] = deliveryDateAndTime.split(' ');
    deliveryDataHandler(type, {
      deliveryDate,
      deliveryTimeSlot,
    });
    setSelectedDeliverySlotId(deliveryDateAndTime);
  };

  const pharmacyOptions = allPharmacies.map((pharmacy) => ({
    id: pharmacy.id,
    name: `${pharmacy.name}, ${pharmacy.addressLine1}, ${pharmacy.addressTownCity}, ${pharmacy.addressPostCode}`,
  }));

  const deliveryDayOptions = availableDates.reduce<
    { id: string; name: string }[]
  >((deliveryOptions, date) => {
    deliveryOptions.push(
      {
        id: `${date.substring(0, 19)} Morning`,
        name: `${format(new Date(date), 'EEEE, d MMMM')} (${MORNING_HOURS})`,
      },
      {
        id: `${date.substring(0, 19)} Afternoon`,
        name: `${format(new Date(date), 'EEEE, d MMMM')} (${AFTERNOON_HOURS})`,
      },
    );
    return deliveryOptions;
  }, []);

  if (option !== 'Collection') {
    return (
      <DeliveryOptionCard
        icon={PackageIcon}
        heading={formatDeliveryOptionTitle(option)}
        infoText={getEstimatedDeliveryTimeInfo(option)}
        cost={cost > 0 ? `£${cost}` : t('CheckoutPage.deliveryOption.costFree')}
        checkboxProps={{
          id: option,
          checked,
          onChange: handleDeliveryOptionCheckbox,
        }}
        checked={checked}
        dropdownHeading={t('CheckoutPage.deliveryOption.deliverDropdown')}
        dropdownProps={{
          values: deliveryDayOptions,
          selected: selectedDeliverySlotId,
          onChange: handleDeliveryDayChange,
          placeholder: !selectedDeliverySlotId
            ? t('CheckoutPage.deliveryOption.dropdownDefault')
            : undefined,
        }}
        showDropDown={type === 'prescription'}
      />
    );
  }

  return (
    <DeliveryOptionCard
      icon={ShoppingBagIcon}
      heading={t('CheckoutPage.deliveryOption.collectTitle', {
        pharmacy:
          type === 'shop'
            ? 'pharmacy'
            : dependantId
            ? dependantPharmacy?.name
            : patientPharmacy?.name,
      })}
      infoText={t('CheckoutPage.deliveryOption.estimatedWaitTimeCollection', {
        days: readyIn,
      })}
      cost={t('CheckoutPage.deliveryOption.costFree')}
      checkboxProps={{
        id: `${type}${option}`,
        checked,
        onChange: handleDeliveryOptionCheckbox,
      }}
      checked={checked}
      dropdownHeading={t('CheckoutPage.deliveryOption.collectDropdown')}
      dropdownProps={{
        values: pharmacyOptions,
        selected: selectedPharmacyId,
        onChange: handlePharmacyChange,
        placeholder: !selectedPharmacyId
          ? t('CheckoutPage.deliveryOption.noPharmacyPlaceholder')
          : undefined,
      }}
      showDropDown={type !== 'prescription'}
    />
  );
};
