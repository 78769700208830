/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useBasketContext } from 'contexts/BasketContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { Button } from 'components/common/Button';
import { IconButton } from 'components/common/IconButton';
import { ReactComponent as CartIcon } from 'assets/icons/lawsat/Cart.svg';
import { routes } from 'routes';
import cx from 'clsx';

import styles from './BasketIcon.module.scss';

interface BasketIconProps {
  type?: 'icon' | 'label';
  className?: string;
}

export const BasketIcon = ({ type = 'icon', className }: BasketIconProps) => {
  const { t } = useTranslation();
  const {
    basket: { shopProducts, prescriptionProducts },
    dependantBaskets,
  } = useBasketContext();
  const { logCtaClick } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const navigate = useHistory();

  const shopProductQuantity = shopProducts.reduce(
    (totalQty, item) => totalQty + item.quantity,
    0,
  );

  const prescriptionProductQuantity = prescriptionProducts.reduce(
    (totalQty, item) => totalQty + item.quantity,
    0,
  );

  const dependantBasketsQuantity = dependantBaskets.reduce(
    (totalQty, baskets) =>
      totalQty +
      baskets.basket.prescriptionProducts.reduce(
        (basketTotal, item) => basketTotal + item.quantity,
        0,
      ),
    0,
  );

  const quantity =
    shopProductQuantity +
    prescriptionProductQuantity +
    dependantBasketsQuantity;

  const handleLogCtaClick = () => {
    logCtaClick({
      label: t('NavBarLabels.basket'),
      pageName: pageName?.log,
      section: t('amplitude.navbar.section'),
    });
  };

  return (
    <div className={cx(styles['basket-icon'], className)}>
      {type === 'icon' ? (
        <>
          <IconButton
            icon={CartIcon}
            label={t('NavBarLabels.basket')}
            onClick={() => {
              handleLogCtaClick();
              navigate.push(routes.BASKET);
            }}
            className={styles['basket-icon__icon-button']}
          />
          {quantity > 0 && (
            <div className={styles['basket-icon__qty']}>
              {quantity <= 99 ? quantity : 99}
            </div>
          )}
        </>
      ) : (
        <Button
          appearance={'ghost'}
          variant={'ui'}
          icon={{ element: CartIcon }}
          label={t('NavBarLabels.basket')}
          onClick={() => {
            handleLogCtaClick();
            navigate.push(routes.BASKET);
          }}
        />
      )}
    </div>
  );
};
