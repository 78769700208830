import { Client } from 'api/utils';
import {
  PatientSCRInfo,
  Patient,
  SavePatientPayload,
  PatientForm,
} from 'models/Patient';
import dompurify from 'dompurify';
import { Address } from 'models/Address';
import { GP } from 'models/GP';
import { Pharmacy } from 'models/Pharmacy';
import { PrescriptionItem } from 'models/PrescriptionItem';

export type PharmacyPayload = {
  pharmacyId: number;
};

const sanitizer = dompurify.sanitize;
export const transformAddressPayload = (original: Address): Address => {
  return {
    line1: sanitizer(original.line1),
    line2:
      original.line2 && original.line2 !== ''
        ? sanitizer(original.line2)
        : null,
    line3:
      original.line3 && original.line3 !== ''
        ? sanitizer(original.line3)
        : null,
    townCity: sanitizer(original.townCity),
    postCode: sanitizer(original.postCode),
  };
};

export const transformPayload = (original: PatientForm): SavePatientPayload => {
  return {
    firstName: sanitizer(original.firstName),
    middleName: null,
    lastName: sanitizer(original.lastName),
    dateOfBirth: sanitizer(
      `${original.dateYY}-${original.dateMM}-${original.dateDD}`,
    ),
    contactNumber: sanitizer(
      `${original.contactNumberCountryCode} ${original.contactNumber.replace(
        /^0+/,
        '',
      )}`,
    ),
    contactMethod: sanitizer(original.contactMethod),
    sendMarketing: `${original.sendMarketing}` === 'true',
    nhsNumber:
      original.nhsNumber && original.nhsNumber !== ''
        ? sanitizer(original.nhsNumber)
        : null,
    address: {
      line1: sanitizer(original.addressLine1),
      line2:
        original.addressLine2 && original.addressLine2 !== ''
          ? sanitizer(original.addressLine2)
          : null,
      line3:
        original.addressLine3 && original.addressLine3 !== ''
          ? sanitizer(original.addressLine3)
          : null,
      postCode: sanitizer(original.addressPostCode),
      townCity: sanitizer(original.addressTownCity),
    },
  };
};

export const savePatient = async (form: PatientForm): Promise<void> => {
  return await Client.put(`Patient`, transformPayload(form));
};

export const getPatient = async (): Promise<Patient> => {
  const response = await Client.get(`Patient`);
  return response.data;
};

export const saveAddress = async (payload: Address): Promise<Address> => {
  const response = await Client.put(
    `Patient/address`,
    transformAddressPayload(payload),
  );
  return response.data;
};

export const getAddress = async (): Promise<Address> => {
  const response = await Client.get(`Patient/address`);
  return response.data;
};

export const savePatientGp = async (id: string): Promise<void> => {
  return await Client.put(`patient/gppractice`, {
    gpPracticeId: parseInt(id),
  });
};

export const getPatientGP = async (): Promise<GP> => {
  const response = await Client.get(`patient/gppractice`);
  return response.data;
};

export const savePatientPharmacy = async (
  payload: PharmacyPayload,
): Promise<void> => {
  return await Client.put(`Patient/pharmacy`, payload);
};

export const getPatientPharmacy = async (): Promise<Pharmacy> => {
  const response = await Client.get(`Patient/pharmacy`);
  return response.data;
};

export const getPatientPharmacies = async (): Promise<Pharmacy[]> => {
  const response = await Client.get(`Patient/pharmacies`);
  return response.data;
};

export const savePatientPrescription = async (
  payload: string,
): Promise<void> => {
  return await Client.post(`patient/prescriptionItems`, {
    medicinalProductId: payload,
  });
};

export const getPatientPrescriptionList = async (): Promise<
  PrescriptionItem[]
> => {
  const response = await Client.get(`patient/prescriptionItems`);
  return response.data;
};

export const getPatientPrescriptionItem = async (
  id: string,
): Promise<PrescriptionItem> => {
  const response = await Client.get(`patient/prescriptionItems/${id}`);
  return response.data;
};

export const deletePatientPrescriptionItem = async (
  id: string,
): Promise<PrescriptionItem> => {
  return await Client.delete(`patient/prescriptionItems/${id}`);
};

export const updatePatientPrescription = async (
  id: string,
  reminder: number,
): Promise<void> => {
  return await Client.put(`patient/prescriptionItems/${id}`, {
    ReminderFrequencyNumberOfDays: reminder,
  });
};

export const savePatientSCR = async (): Promise<void> => {
  return await Client.put(`patient/summaryCareRecordConsent`);
};

export const getPatientSCR = async (): Promise<PatientSCRInfo> => {
  const res = await Client.get(`patient/summaryCareRecordConsent`);
  return res.data;
};
