import React from 'react';
import cx from 'classnames';
import { ReactComponent as FallbackIcon } from 'assets/icons/lawsat/Photo.svg';
import styles from './ProductImage.module.scss';

interface ProductImageProps {
  imageFileName?: string;
  size?: number;
  className?: string;
}

export const ProductImage: React.FC<ProductImageProps> = ({
  imageFileName,
  className,
  size = 112,
}) => {
  const [error, setError] = React.useState(!imageFileName);

  const baseImageUrl = process.env.REACT_APP_IMG_URL || '';

  return (
    <div
      data-testid="product-image-container"
      className={cx(styles['product-image'], {
        [`${className}`]: className,
      })}
    >
      {error ? (
        <div
          data-testid="product-image-error"
          style={{
            maxWidth: `${size}px`,
          }}
          className={styles['product-image__error']}
        >
          <FallbackIcon />
        </div>
      ) : (
        <img
          data-testid="product-image"
          role="presentation"
          className={styles['product-image__image']}
          src={`${baseImageUrl}thumbnail/${imageFileName}`}
          srcSet={`${baseImageUrl}thumbnail/${imageFileName} ${size}w, ${baseImageUrl}thumbnail/retina/${imageFileName} ${
            size * 2
          }w`}
          alt="product"
          onLoad={() => setError(false)}
          onError={() => setError(true)}
        />
      )}
    </div>
  );
};
