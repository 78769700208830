import { CookiesPopup } from 'components/CookiesPopup';
import { CookiesProvider } from 'contexts/CookiesContext';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { AuthProvider } from 'contexts/AuthContext';
import { ToastProvider } from 'contexts/ToastContext';
import { AmplitudeProvider } from 'contexts/AmplitudeContext';
import { PatientProvider } from 'contexts/PatientContext';
import { PrescriptionProvider } from 'contexts/PrescriptionContext';
import { DependantProvider } from 'contexts/DependantContext';
import { ScrollProvider } from 'contexts/ScrollContext';
import { OrderHistoryProvider } from 'contexts/OrderHistoryContext';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { SignUp } from 'pages/SignUp';
import { Prescription } from 'pages/Prescription';
import { Order } from 'pages/Order';
import { Product } from 'pages/Product';
import { Account } from 'pages/Account';
import { Dependant } from 'pages/Dependant';
import { Shop } from 'pages/Shop/Shop';
import { Cookies } from 'pages/Cookies';
import { TermsAndConditions } from 'pages/TermsAndConditions';
import { PrivacyPolicy } from 'pages/PrivacyPolicy';
import { routes } from 'routes';
import { ProductsProvider } from 'contexts/ProductsContext';
import { Home } from 'pages/Home';
import { TravelVaccinations } from 'pages/TravelVaccinations';
import { Contact } from 'pages/Contact';
import { ConfigProvider } from 'contexts/ConfigContext';
import { ConditionsProvider } from 'contexts/ConditionsContext';
import { BasketProvider } from 'contexts/BasketContext';
import { AppLayout } from 'components/Layout/AppLayout/AppLayout';
import { Basket } from 'pages/Basket';
import { HelpCentre } from 'pages/HelpCentre';
import { PrescriptionOnboarding } from 'pages/Prescription/PrescriptionOnboarding';
import { DependantPrescriptionOnboarding } from 'pages/Dependant/DependantPrescriptionOnboarding';
import { ExemptionsProvider } from 'contexts/ExemptionsContext';
import { ModalProvider } from 'contexts/ModalContext';
import { DependantExemptionsProvider } from 'contexts/DependantExemptionsContext';
import { SearchResults } from 'pages/SearchResults';

import './App.scss';

export const App = () => {
  // To avoid problems with React.hydrate we need to prevent a first render by the privates routes.
  // By using a hasMounted flag we can force a render once the js bundle is ready.
  // Better explained here: https://www.joshwcomeau.com/react/the-perils-of-rehydration/

  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <Router>
      <ToastProvider>
        <AuthProvider>
          <AmplitudeProvider>
            <CookiesProvider>
              <PatientProvider>
                <ConditionsProvider>
                  <ProductsProvider>
                    <DependantProvider>
                      <ConfigProvider>
                        <BasketProvider>
                          <ExemptionsProvider>
                            <DependantExemptionsProvider>
                              <ScrollProvider>
                                <OrderHistoryProvider>
                                  <ErrorBoundary>
                                    <ModalProvider>
                                      <AppLayout>
                                        <Switch>
                                          {/* Account */}

                                          <PrivateRoute
                                            path={routes.SIGN_UP.BASE}
                                          >
                                            <SignUp />
                                          </PrivateRoute>

                                          <PrivateRoute
                                            path={routes.ACCOUNT.BASE}
                                          >
                                            <Account />
                                          </PrivateRoute>

                                          {/* Dependants */}

                                          <PrivateRoute
                                            path={`${routes.DEPENDANT.PRESCRIPTION.ONBOARDING.BASE}:dependantId`}
                                          >
                                            <DependantPrescriptionOnboarding />
                                          </PrivateRoute>

                                          <PrivateRoute
                                            path={`${routes.DEPENDANT.BASE}`}
                                          >
                                            <Dependant />
                                          </PrivateRoute>

                                          {/* Prescriptions */}

                                          <PrivateRoute
                                            path={
                                              routes.PRESCRIPTION.ONBOARDING
                                                .BASE
                                            }
                                          >
                                            <PrescriptionOnboarding />
                                          </PrivateRoute>

                                          <PrivateRoute
                                            path={routes.PRESCRIPTION.BASE}
                                          >
                                            <PrescriptionProvider>
                                              <Prescription />
                                            </PrescriptionProvider>
                                          </PrivateRoute>

                                          {/* Shop */}

                                          <Route path={routes.SHOP.BASE}>
                                            <Shop />
                                          </Route>

                                          <Route path={routes.BASKET}>
                                            <Basket />
                                          </Route>

                                          <Route
                                            path={`${routes.PRODUCT.BASE}/:productId`}
                                          >
                                            <Product />
                                          </Route>

                                          <Route path={routes.ORDER.BASE}>
                                            <Order />
                                          </Route>

                                          <Route
                                            path={`${routes.SEARCH_RESULTS}/:search/:page`}
                                          >
                                            <SearchResults />
                                          </Route>

                                          {/* Travel Vaccinations */}

                                          <Route
                                            path={`${routes.TRAVELVACCINATIONS.BASE}`}
                                          >
                                            <TravelVaccinations />
                                          </Route>

                                          {/* Policy Pages */}
                                          <Route path={routes.CONTACT}>
                                            <Contact />
                                          </Route>

                                          <Route path={`${routes.TERMS}`} exact>
                                            <TermsAndConditions />
                                          </Route>

                                          <Route
                                            path={`${routes.PRIVACY}`}
                                            exact
                                          >
                                            <PrivacyPolicy />
                                          </Route>

                                          <Route path={`${routes.HELP.BASE}`}>
                                            <HelpCentre />
                                          </Route>

                                          <Route
                                            path={`${routes.COOKIES}`}
                                            exact
                                          >
                                            <Cookies />
                                          </Route>

                                          {/* Home Page */}

                                          <Route path={routes.BASE}>
                                            <Home />
                                          </Route>

                                          <Redirect from="*" to={routes.BASE} />
                                        </Switch>
                                        <CookiesPopup />
                                      </AppLayout>
                                    </ModalProvider>
                                  </ErrorBoundary>
                                </OrderHistoryProvider>
                              </ScrollProvider>
                            </DependantExemptionsProvider>
                          </ExemptionsProvider>
                        </BasketProvider>
                      </ConfigProvider>
                    </DependantProvider>
                  </ProductsProvider>
                </ConditionsProvider>
              </PatientProvider>
            </CookiesProvider>
          </AmplitudeProvider>
        </AuthProvider>
      </ToastProvider>
    </Router>
  );
};
