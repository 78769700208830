import React from 'react';
import cx from 'classnames';
import styles from './StepsList.module.scss';

export type stepListVariant = 'ui' | 'accent';
interface StepsListProps {
  border?: boolean;
  type?: 'number' | 'letter';
  stepsList: string[];
  variant: stepListVariant;
  className?: string;
  testId?: string;
}

export const StepsList: React.FC<StepsListProps> = ({
  stepsList,
  border = true,
  variant = 'ui',
  className,
  testId,
}) => {
  return (
    <ol
      className={cx(styles['steps-list'], styles[`steps-list--${variant}`], {
        [`${className}`]: className,
        [styles[`steps-list--borderless`]]: !border,
      })}
      data-testid={testId}
    >
      {stepsList.map((step, index) => {
        return <li key={index}>{step}</li>;
      })}
    </ol>
  );
};
