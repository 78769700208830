import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { Button } from 'components/common/Button';
import { Heading } from 'components/common/Heading';
import { Label } from 'components/common/Label';
import { RadioGroup } from 'components/common/RadioGroup';
import { ValidationError } from 'components/common/ValidationError';
import { Textarea } from 'components/common/Textarea';
import { CheckBox } from 'components/common/CheckBox';
import { Text } from 'components/common/Text';
import { generalHealthQuestionnairePt2 } from 'formSchema/GeneralHealthQuestionnaire';
import { GeneralHealthFormPt2 as GeneralHealthFormPt2Type } from 'models/GeneralHealthQuestionnaire';
import { Product } from 'models/Product';
import { Condition } from 'models/Condition';
import { yupResolver } from '@hookform/resolvers';
import { routes } from 'routes';

import styles from '../GeneralHealthForm.module.scss';

const halfWidthInputStyles = 'general-health-form__input--half-width';
const textAreaStyles = 'general-health-form__input__textarea';

interface GeneralHealthFormPt2Props {
  nextButton: { label: string; route: string };
  backButton: { label: string; route: string };
  formDataHandler: (data: GeneralHealthFormPt2Type) => void;
  product?: Product | null;
  condition?: Condition | null;
  progressBar?: JSX.Element;
  formData?: GeneralHealthFormPt2Type;
}

export const GeneralHealthFormPt2 = ({
  nextButton,
  backButton,
  product,
  condition,
  formDataHandler,
  progressBar,
  formData,
}: GeneralHealthFormPt2Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { control, handleSubmit, errors, watch, reset } = useForm({
    resolver: yupResolver(generalHealthQuestionnairePt2()),
  });
  const { logPageView, logCtaClick } = useAmplitudeContext();

  const hasAllergies = watch('hasAllergies');
  const hasMedicalCondition = watch('hasMedicalCondition');
  const hasMentalHealthCondition = watch('hasMentalHealthCondition');
  const isTakingMedication = watch('isTakingMedication');

  useEffect(() => {
    if (product) {
      logPageView({
        pageName: t('amplitude.generalHealthPt2.productPageName', {
          productTitle: product?.title,
        }),
      });
    } else if (condition) {
      logPageView({
        pageName: t('amplitude.generalHealthPt2.conditionPageName', {
          conditionName: condition?.name,
        }),
      });
    }
  }, []);

  useEffect(() => {
    formData && reset({ ...formData });
  }, [formData]);

  const onSubmit = (payload: GeneralHealthFormPt2Type) => {
    formDataHandler(payload);
    history.push(nextButton.route);
  };

  const handleLogCtaClick = () => {
    logCtaClick({
      label: t('amplitude.generalHealthPt2.privacyPolicyLabel'),
      pageName: t('amplitude.generalHealthPt2.pageName'),
      section: t('amplitude.generalHealthPt2.privacyPolicySection'),
    });
  };

  const yes = t(
    'OrderFlow.generalHealthQuestionnaire.formLabels.commonOptions.yes',
  );
  const no = t(
    'OrderFlow.generalHealthQuestionnaire.formLabels.commonOptions.no',
  );

  return (
    <>
      <div className={styles['general-health-form__progress-container']}>
        {progressBar}
      </div>
      <Heading size="md" className={styles['general-health-form__title']}>
        {t('OrderFlow.generalHealthQuestionnaire.titlePt2')}
      </Heading>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="bloodPressure"
          control={control}
          defaultValue=""
          render={(props) => (
            <>
              <Label
                id="bloodPressureLabel"
                size="lg"
                error={!!errors.bloodPressure?.message}
              >
                {t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.bloodPressure.question',
                )}
              </Label>
              {errors.bloodPressure?.message && (
                <ValidationError id="bloodPressureValidationError">
                  {errors.bloodPressure.message}
                </ValidationError>
              )}
              <RadioGroup
                id="bloodPressure"
                labelBy="bloodPressureLabel"
                orientation="vertical"
                values={[
                  {
                    value: 'Normal',
                    label: t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.bloodPressure.options.normal',
                    ),
                  },
                  {
                    value: 'High',
                    label: t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.bloodPressure.options.high',
                    ),
                  },
                  {
                    value: 'Low',
                    label: t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.bloodPressure.options.low',
                    ),
                  },
                ]}
                className={styles[halfWidthInputStyles]}
                {...props}
              />
            </>
          )}
        />

        <Controller
          name="hasAllergies"
          control={control}
          defaultValue=""
          render={(props) => (
            <>
              <Label
                id="hasAllergiesLabel"
                size="lg"
                error={!!errors.hasAllergies?.message}
              >
                {t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.hasAllergies.question',
                )}
              </Label>
              {errors.hasAllergies?.message && (
                <ValidationError id="hasAllergiesValidationError">
                  {errors.hasAllergies.message}
                </ValidationError>
              )}
              <RadioGroup
                id="hasAllergies"
                labelBy="hasAllergiesLabel"
                values={[
                  {
                    value: 'Yes',
                    label: yes,
                  },
                  {
                    value: 'No',
                    label: no,
                  },
                ]}
                className={styles[halfWidthInputStyles]}
                {...props}
              />
            </>
          )}
        />

        {hasAllergies === 'Yes' && (
          <Controller
            name="allergyDetails"
            control={control}
            defaultValue=""
            render={(props, fieldState) => (
              <Textarea
                label={t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.allergyDetails',
                )}
                className={styles[textAreaStyles]}
                validationError={errors.allergyDetails?.message}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                {...props}
              />
            )}
          />
        )}

        <Controller
          name="hasMedicalCondition"
          control={control}
          defaultValue=""
          render={(props) => (
            <>
              <Label
                id="hasMedicalConditionLabel"
                size="lg"
                error={!!errors.hasMedicalCondition?.message}
              >
                {t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.hasMedicalCondition.question',
                )}
              </Label>
              {errors.hasMedicalCondition?.message && (
                <ValidationError id="hasMedicalConditionValidationError">
                  {errors.hasMedicalCondition.message}
                </ValidationError>
              )}
              <RadioGroup
                id="hasMedicalCondition"
                labelBy="hasMedicalConditionLabel"
                values={[
                  {
                    value: 'Yes',
                    label: yes,
                  },
                  {
                    value: 'No',
                    label: no,
                  },
                ]}
                className={styles[halfWidthInputStyles]}
                {...props}
              />
            </>
          )}
        />

        {hasMedicalCondition === 'Yes' && (
          <Controller
            name="medicalConditionDetail"
            control={control}
            defaultValue=""
            render={(props, fieldState) => (
              <Textarea
                label={t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.medicalConditionDetail',
                )}
                className={styles[textAreaStyles]}
                validationError={errors.medicalConditionDetail?.message}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                {...props}
              />
            )}
          />
        )}

        <Controller
          name="hasMentalHealthCondition"
          control={control}
          defaultValue=""
          render={(props) => (
            <>
              <Label
                id="hasMentalHealthConditionLabel"
                size="lg"
                error={!!errors.hasMentalHealthCondition?.message}
              >
                {t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.hasMentalHealthCondition.question',
                )}
              </Label>
              {errors.hasMentalHealthCondition?.message && (
                <ValidationError id="hasMentalHealthConditionValidationError">
                  {errors.hasMentalHealthCondition.message}
                </ValidationError>
              )}
              <RadioGroup
                id="hasMentalHealthCondition"
                labelBy="hasMentalHealthConditionLabel"
                values={[
                  {
                    value: 'Yes',
                    label: yes,
                  },
                  {
                    value: 'No',
                    label: no,
                  },
                ]}
                className={styles[halfWidthInputStyles]}
                {...props}
              />
            </>
          )}
        />

        {hasMentalHealthCondition === 'Yes' && (
          <Controller
            name="mentalHealthConditionDetail"
            control={control}
            defaultValue=""
            render={(props, fieldState) => (
              <Textarea
                label={t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.mentalHealthConditionDetail',
                )}
                className={styles[textAreaStyles]}
                validationError={errors.mentalHealthConditionDetail?.message}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                {...props}
              />
            )}
          />
        )}

        <Controller
          name="isTakingMedication"
          control={control}
          defaultValue=""
          render={(props) => (
            <>
              <Label
                id="isTakingMedicationLabel"
                size="lg"
                error={!!errors.isTakingMedication?.message}
              >
                {t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.isTakingMedication.question',
                )}
              </Label>
              {errors.isTakingMedication?.message && (
                <ValidationError id="isTakingMedicationValidationError">
                  {errors.isTakingMedication.message}
                </ValidationError>
              )}
              <Text className={styles['general-health-form__secondary-label']}>
                {t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.isTakingMedication.description',
                )}
              </Text>
              <RadioGroup
                id="isTakingMedication"
                labelBy="isTakingMedicationLabel"
                values={[
                  {
                    value: 'Yes',
                    label: yes,
                  },
                  {
                    value: 'No',
                    label: no,
                  },
                ]}
                className={styles[halfWidthInputStyles]}
                {...props}
              />
            </>
          )}
        />

        {isTakingMedication === 'Yes' && (
          <Controller
            name="takingMedicationDetail"
            control={control}
            defaultValue=""
            render={(props, fieldState) => (
              <Textarea
                label={t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.takingMedicationDetail',
                )}
                className={styles[textAreaStyles]}
                validationError={errors.takingMedicationDetail?.message}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                {...props}
              />
            )}
          />
        )}

        <Controller
          name="shareNotes"
          control={control}
          defaultValue=""
          render={(props) => (
            <Textarea
              rows={4}
              label={t(
                'OrderFlow.generalHealthQuestionnaire.formLabels.shareNotes.question',
              )}
              {...props}
            />
          )}
        />

        <Controller
          name="termsAndConditions"
          control={control}
          defaultValue={false}
          render={(props) => {
            const termsAndConditions: string[] = t(
              'OrderFlow.generalHealthQuestionnaire.formLabels.termsAndConditions.bulletPoints',
              { returnObjects: true },
            );
            return (
              <>
                <Label
                  id="termsAndConditionsLabel"
                  size="lg"
                  error={!!errors.termsAndConditions?.message}
                >
                  {t(
                    'OrderFlow.generalHealthQuestionnaire.formLabels.termsAndConditions.question',
                  )}
                </Label>
                {errors.termsAndConditions?.message && (
                  <ValidationError id="termsAndConditionsValidationError">
                    {errors.termsAndConditions.message}
                  </ValidationError>
                )}
                <ul className={styles['general-health-form__list']}>
                  <li className={styles['general-health-form__list-item']}>
                    {t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.termsAndConditions.policyLinkPt1',
                    )}
                    &nbsp;
                    <a
                      href={routes.PRIVACY}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        handleLogCtaClick();
                      }}
                    >
                      {t(
                        'OrderFlow.generalHealthQuestionnaire.formLabels.termsAndConditions.policyLinkPt2',
                      )}
                    </a>
                  </li>
                  {termsAndConditions.map((bulletPoint, i) => (
                    <li
                      key={i}
                      className={styles['general-health-form__list-item']}
                    >
                      {bulletPoint}
                    </li>
                  ))}
                </ul>
                <CheckBox
                  label={t(
                    'OrderFlow.generalHealthQuestionnaire.formLabels.termsAndConditions.checkbox',
                  )}
                  {...props}
                />
              </>
            );
          }}
        />

        <div className={styles['general-health-form__button-container']}>
          <Button
            appearance="outline"
            label={backButton.label}
            link={{ to: backButton.route }}
            className={styles['general-health-form__button']}
          />
          <Button
            label={nextButton.label}
            type="submit"
            className={styles['general-health-form__button']}
          />
        </div>
      </form>
    </>
  );
};
