import { format, utcToZonedTime } from 'date-fns-tz';
import { listValues } from 'components/common/SelectList';
import { GPResult } from 'api/GPSuggest';

/**
 * @name splitDate
 * @return {string[]}
 * @desc
 */
export const splitDate = (date: string): string[] => {
  date = date.split('T')[0];
  return date.split('-');
};

/**
 * @name getWeeks
 * @return {number}
 */
export const getWeeks = (days: number): number => {
  return Math.ceil(days / 7);
};

const timeZone = 'Europe/London';

export const formatDateString = (date: string): string => {
  return format(utcToZonedTime(new Date(date), timeZone), 'd MMMM yyyy');
};

export const formatDowMonthYear = (date: string): string => {
  return format(utcToZonedTime(new Date(date), timeZone), 'EEEE d MMMM');
};

export const decodeHTMLEntities = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
};

export const convertGPList = (originalList: GPResult[]): listValues[] => {
  return originalList.map((item) => {
    const listItem: listValues = {
      value: item.Id,
      label: `${item.Address1} ${item.Postcode}`,
    };
    if (item.Name) {
      listItem.title = item.Name;
    }
    return listItem;
  });
};

export const convertHeightFromImperial = (feet: number, inches: number) => {
  const CM_PER_FOOT = 30.48;
  const CM_PER_INCH = 2.54;
  return +(feet * CM_PER_FOOT + inches * CM_PER_INCH).toFixed(2);
};

export const convertHeightToImperial = (
  meters: number,
  centimeters: number,
) => {
  const FT_PER_M = 3.28084;
  const CM_PER_INCH = 2.54;
  const feet = Math.floor(meters * FT_PER_M);
  const remainingMeters = meters - feet / FT_PER_M;
  const totalInches = Math.round(
    (remainingMeters * 100 + centimeters) / CM_PER_INCH,
  );
  const inches = totalInches % 12;
  const additionalFeet = Math.floor(totalInches / 12);
  const updatedFeet = feet + additionalFeet;
  return {
    feet: updatedFeet,
    inches: inches,
  };
};

export const convertHeightFromMetric = (metres: number, cm: number) => {
  const CM_PER_METRE = 100;
  return +(metres * CM_PER_METRE + cm).toFixed(2);
};

export const convertWeightFromImperial = (stones: number, pounds: number) => {
  const KG_PER_STONE = 6.35029;
  const KG_PER_POUND = 0.453592142840941;
  return +(stones * KG_PER_STONE + pounds * KG_PER_POUND).toFixed(2);
};

export const convertWeightToImperial = (kgs: number) => {
  const STONES_PER_KEG = 0.157473;
  const stones = Math.floor(kgs * STONES_PER_KEG);
  const remainingKilograms = kgs - stones / STONES_PER_KEG;
  const LBS_PER_KG = 2.20462;
  const pounds = Math.round(remainingKilograms * LBS_PER_KG);

  return {
    stones,
    pounds,
  };
};

export interface EncodedImage {
  fileName: string;
  encodedImage: string;
  fileType: string;
}

export const encodeFileToBase64 = (file: File): Promise<EncodedImage> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (typeof fileReader.result === 'string') {
        resolve({
          encodedImage: fileReader.result,
          fileName: file.name,
          fileType: file.type,
        });
      } else {
        reject(new Error('FileReader result is not a string.'));
      }
    };

    fileReader.onerror = (error) => {
      reject(error);
    };

    fileReader.readAsDataURL(file);
  });
};

export const decodeBase64ToFile = ({
  encodedImage,
  fileName,
  fileType,
}: EncodedImage): File => {
  const base64Data = encodedImage.split(',')[1];
  const binaryString = atob(base64Data);
  const length = binaryString.length;
  const binaryArray = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    binaryArray[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([binaryArray], { type: 'application/octet-stream' });
  return new File([blob], fileName, { type: fileType });
};
