import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { AdviceSection } from 'components/Sections/TravelVaccinations/AdviceSection';
import {
  BookingProcessSection,
  getBookingProcessCards,
} from 'components/Sections/TravelVaccinations/BookingProcessSection';
import { PharmacyListSection } from 'components/PharmacyListSection';
import { FeaturesSection } from 'components/Sections/TravelVaccinations/FeaturesSection';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { PillLinkSection } from 'components/Sections/Common/PillLinkSection/PillLinkSection';
import { TravelVaccinationsSection } from 'components/Sections/Home/TravelVaccinationsSection';
import translations from 'translations/en.json';

import styles from './LandingPage.module.scss';

const pageName = {
  log: 'travel-vax-landing-page',
  display: 'Travel Vaccinations',
};

const travelVaxPharmacies = translations.travelVaccinations.pharmacies;

export const LandingPage = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { logPageView } = useAmplitudeContext();
  const { setPageName, resetPageName } = useLandingPageContext();

  useEffect(() => {
    logPageView({ pageName: pageName.log });
    setPageName(pageName);
    return () => resetPageName();
  }, []);

  const bookCtaLabel = t('travelVaccinations.bookCtaLabel');

  const popularVaccines = [
    'Hepatitis A',
    'Malaria',
    'Typhoid',
    'Yellow Fever',
    'Japanese Encephalitis',
    'Rabies',
    'Tick-Borne Encephalitis',
    'Cholera',
  ];

  return (
    <>
      <TravelVaccinationsSection
        ctaOne={{ label: bookCtaLabel, to: routes.TRAVELVACCINATIONS.BOOK }}
        ctaTwo={{
          label: t('travelVaccinations.seeOurPharmacies'),
          onClick: () =>
            ref.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            }),
        }}
        className={styles['landing-page__hero-section']}
      />

      <PillLinkSection
        heading={t('travelVaccinations.landingPage.ourVaccines.heading')}
        links={popularVaccines.map((vaccine) => ({
          label: vaccine,
          route: `${routes.TRAVELVACCINATIONS.INFO}/${vaccine
            .toLowerCase()
            .replace(' ', '-')}`,
        }))}
        className={styles['landing-page__pill-link-section']}
      />

      <BookingProcessSection
        cards={getBookingProcessCards(t, 'landingPage')}
        heading={t('travelVaccinations.landingPage.bookingProcess.heading')}
        cta={{
          label: bookCtaLabel,
          to: routes.TRAVELVACCINATIONS.BOOK,
        }}
        className={styles['landing-page__booking-process-section']}
      />

      <AdviceSection />

      <FeaturesSection />

      <div ref={ref}>
        <PharmacyListSection
          heading={t(
            'travelVaccinations.landingPage.vaccinationCentres.heading',
          )}
          pharmacies={travelVaxPharmacies}
          cta={{
            label: bookCtaLabel,
            to: routes.TRAVELVACCINATIONS.BOOK,
          }}
          wrapper
        />
      </div>
    </>
  );
};
