import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToastContext } from 'contexts/ToastContext';
import { Address } from 'models/Address';
import { searchPostCode } from 'api/PostCode';

export type AddressObject = {
  value: string;
  label: string;
  address: Address;
};
export interface PostCodeSearchContextProps {
  addresses: AddressObject[] | null;
  getAddresses: (postcode: string) => Promise<void>;
  clearAddresses: Function;
  loading: boolean;
  setLoading: Function;
  children?: React.ReactNode;
}

export const PostCodeSearchContextDefaults: PostCodeSearchContextProps = {
  addresses: null,
  getAddresses: () => Promise.resolve(),
  clearAddresses: Function,
  loading: false,
  setLoading: Function,
};

export const PostCodeSearchContext =
  React.createContext<PostCodeSearchContextProps>(
    PostCodeSearchContextDefaults,
  );

export const formatAddresArray = (
  addressArray: string[],
  id: number,
  postcode: string,
): AddressObject => {
  let addressString = '';
  addressArray.forEach((line) => {
    if (line !== '') {
      addressString = `${addressString} ${line}, `;
    }
  });
  return {
    value: id.toString(),
    label: addressString.replace(/[,\s]+$/, ''),
    address: {
      line1: addressArray[0],
      line2: addressArray[1],
      line3: addressArray[2],
      townCity: addressArray[3],
      postCode: postcode,
    },
  };
};

export const cleanAddressArray = (
  addressesArray: string[][],
  postcode: string,
): AddressObject[] => {
  const formattedAddresses: AddressObject[] = [];
  addressesArray.forEach((address: string[], index) => {
    formattedAddresses.push(formatAddresArray(address, index, postcode));
  });
  return formattedAddresses;
};

export const usePostCodeSearchContext = (): PostCodeSearchContextProps =>
  React.useContext<PostCodeSearchContextProps>(PostCodeSearchContext);

interface PostCodeSearchProviderProps {
  children?: React.ReactNode;
}

export const PostCodeSearchProvider = ({
  children,
}: PostCodeSearchProviderProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const errors = {
    title: t('common.error.genericTitle'),
    message: t('common.error.genericMessage'),
  };

  const [addresses, setAddresses] = React.useState<AddressObject[] | null>(
    null,
  );
  const [loading, setLoading] = React.useState(false);

  const getAddresses = async (postcode: string) => {
    try {
      const result = await searchPostCode(postcode);
      setAddresses(cleanAddressArray(result.addresses, postcode));
    } catch (error) {
      setToast({
        status: 'error',
        title: errors.title,
        description: errors.message,
      });
    }
    setLoading(false);
  };

  const clearAddresses = () => {
    setAddresses(() => null);
  };

  return (
    <PostCodeSearchContext.Provider
      value={{
        addresses,
        getAddresses,
        clearAddresses,
        loading,
        setLoading,
      }}
    >
      {children}
    </PostCodeSearchContext.Provider>
  );
};
