import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';

import styles from './TermsAndConditions.module.scss';

interface ArticleProps {
  title: string;
  textArray: string[];
}

const Article = ({ title, textArray }: ArticleProps) => {
  return (
    <>
      <Heading>{title}</Heading>
      {textArray.map((p, index) => {
        return (
          <li
            className={styles['terms-conditions__article-clause']}
            key={index}
          >
            <Text size="lg">{p}</Text>
          </li>
        );
      })}
    </>
  );
};

export const TermsAndConditions = () => {
  const { t } = useTranslation();
  const articleStyle = 'terms-conditions__article';

  return (
    <>
      <Container className={styles['terms-conditions']}>
        <Row>
          <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 8, offset: 2 }}>
            <ol className={styles[articleStyle]}>
              <Article
                title={t('TermsAndConditions.title')}
                textArray={t('TermsAndConditions.paragraphs', {
                  returnObjects: true,
                })}
              />
            </ol>

            <ol className={styles[articleStyle]}>
              <Article
                title={t('TermsAndConditions.conduct.title')}
                textArray={t('TermsAndConditions.conduct.paragraphs', {
                  returnObjects: true,
                })}
              />
            </ol>
            <ul className={'terms-conditions__article'}>
              {t<string, string[]>('TermsAndConditions.conduct.list', {
                returnObjects: true,
              }).map((i, index) => {
                return (
                  <li
                    className={styles['terms-conditions__article-list']}
                    key={index}
                  >
                    {t(i)}
                  </li>
                );
              })}
            </ul>

            <div className={styles[articleStyle]}>
              <Heading>{t('TermsAndConditions.service.title')}</Heading>
              <Text>{t('TermsAndConditions.service.p1')}</Text>
            </div>
            <div className={styles[articleStyle]}>
              <Heading>{t('TermsAndConditions.events.title')}</Heading>
              <Text>{t('TermsAndConditions.events.p1')}</Text>
            </div>
            <div className={styles[articleStyle]}>
              <Heading>{t('TermsAndConditions.copyright.title')}</Heading>
              <Text>{t('TermsAndConditions.copyright.p1')}</Text>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
