import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/common/IconButton';
import { Label } from 'components/common/Label';
import { ReactComponent as ChevronLeft } from 'assets/icons/lawsat/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';

import styles from './PaginationSelector.module.scss';

interface PaginationSelectorProps {
  currentPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  numberOfPages: number;
}

export const PaginationSelector = ({
  currentPage,
  setPage,
  numberOfPages,
}: PaginationSelectorProps) => {
  const { t } = useTranslation();
  const prevPageDisabled = currentPage === 1;
  const nextPageDisabled = currentPage === numberOfPages;

  const onClickBack = () => {
    setPage((prev) => Math.max(prev - 1, 1));
  };

  const onClickForward = () => {
    setPage((prev) => Math.min(prev + 1, numberOfPages));
  };

  return (
    <div className={styles['pagination-selector']}>
      <Label size="lg" className={styles['pagination-selector__page-counter']}>
        {t('searchResults.pageCountTracker', {
          currentPage: currentPage,
          numberOfPages: numberOfPages,
        })}
      </Label>
      <div className={styles['pagination-selector__selector-container']}>
        <IconButton
          icon={ChevronLeft}
          onClick={onClickBack}
          testId="back-button"
          label="Go back a page"
          className={styles['pagination-selector__button']}
          disabled={prevPageDisabled}
        />
        <span
          data-testid="current-page"
          className={styles['pagination-selector__page-number']}
        >
          {currentPage}
        </span>
        <IconButton
          icon={ChevronRight}
          onClick={onClickForward}
          testId="forward-button"
          label="Go forward a page"
          className={styles['pagination-selector__button']}
          disabled={nextPageDisabled}
        />
      </div>
    </div>
  );
};
