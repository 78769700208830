import React from 'react';
import { Link } from 'react-router-dom';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Icon } from 'components/common/Icon';

import styles from './InfoPanel.module.scss';
interface InfoPanelProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  text: string | React.ReactElement;
  link?: { to: string; text: string; newTab?: boolean };
}

export const InfoPanel: React.FC<InfoPanelProps> = ({
  title,
  text,
  icon,
  link,
}) => {
  const targetProps = link?.newTab
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : '';
  return (
    <div className={styles['info-panel']}>
      <Icon
        className={styles['info-panel__info-icon']}
        icon={icon}
        size="md"
      ></Icon>
      <Heading tag="h3" size="sm" className={styles['info-panel__info-title']}>
        {title}
      </Heading>
      <Text tag="div" size="md" className={styles['info-panel__text']}>
        {text}
      </Text>
      {link && (
        <Link
          className={styles['info-panel__link']}
          to={link.to}
          {...targetProps}
        >
          {link.text}
        </Link>
      )}
    </div>
  );
};
