import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { AccountCompleted } from 'containers/Account/AccountCompleted';
import { routes } from 'routes';
import { useTranslation } from 'react-i18next';
import { AccountPatientDetails } from 'containers/Account/AccountPatientDetails/AccountPatientDetails';
import { PostCodeSearchProvider } from 'contexts/PostCodeSearchContext';

export const SignUp = () => {
  const { t } = useTranslation();

  return (
    <Switch>
      {/* Step 2 */}
      <PrivateRoute path={routes.SIGN_UP.COMPLETED} exact>
        <AccountCompleted />
      </PrivateRoute>

      {/* Step 1 */}
      <PrivateRoute path={routes.SIGN_UP.BASE}>
        <PostCodeSearchProvider>
          <AccountPatientDetails
            heading={t('signUpFlow.contactDetails.title')}
            submitButton={{
              label: t('signUpFlow.contactDetails.submit'),
              route: routes.SIGN_UP.COMPLETED,
            }}
          />
        </PostCodeSearchProvider>
      </PrivateRoute>
    </Switch>
  );
};
