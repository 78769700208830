import { AcnePomForm } from './AcnePomForm';

export const acnePomFormMock: AcnePomForm = {
  conditionId: 1,
  affectedSkinAreas: { Back: true, Chest: false, Face: false, Other: true },
  confirmedDiagnosisOfAcneVulgarisByDoctor: 'Yes',
  affectedSkinAreasDetail: 'Affected areas',
  acneDuration: 'This is the first time',
  imageNames: ['image-guid'],
  imageFiles: [new File(['test'], 'test')],
  hasBeenReviewedInLast12Months: 'Yes',
  hasBeenReviewedInLast12MonthsDetail: 'Reviewed By Gp',
  isTakingAnyMedication: 'Yes',
  isTakingAnyMedicationDetail: 'Taking medication',
  medicationDisclaimerConfirm: true,
  userAcneSymptoms: {
    Whiteheads: false,
    Blackheads: true,
    'Inflamed spots on surface of skin': false,
    'Inflamed spots underneath surface of skin': false,
  },
};
