import React from 'react';
import { Icon } from 'components/common/Icon';
import styles from './FormProgressPoints.module.scss';

interface FormProgressPointProps {
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  children?: React.ReactNode;
}

export const FormProgressPointsContainer = ({
  children,
}: FormProgressPointProps) => {
  return <div className={styles['form-progress-points']}>{children} </div>;
};

export const FormProgressPoint = ({
  icon,
  children,
}: FormProgressPointProps) => {
  return (
    <div className={styles['form-progress-points__point']}>
      {icon && (
        <Icon
          className={styles['form-progress-points__icon']}
          icon={icon}
          size="sm"
        />
      )}
      {children}
    </div>
  );
};
