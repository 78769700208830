import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigContext } from 'contexts/ConfigContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Button } from 'components/common/Button';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { ReactComponent as Pill } from 'assets/icons/lawsat/Pill-Outline.svg';

import styles from './HeroSection.module.scss';

interface HeroSectionProps {
  heading: string;
  body?: string;
  ctaOne?: {
    label: string;
    onClick: (
      event?: React.MouseEvent<Element, MouseEvent>,
    ) => void | undefined;
  };
  ctaTwo?: {
    label: string;
    onClick: (
      event?: React.MouseEvent<Element, MouseEvent>,
    ) => void | undefined;
  };
}

export const HeroSection = ({
  heading,
  body,
  ctaOne,
  ctaTwo,
}: HeroSectionProps) => {
  const { t } = useTranslation();
  const { tagline, getPatientAppConfig } = useConfigContext();
  const { pageName } = useLandingPageContext();
  const { logCtaClick, logSectionView } = useAmplitudeContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    getPatientAppConfig();
  }, []);

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.heroSection.section'),
      });
    }
  }, [isInViewport]);

  const handleLogCtaClick = (label: string) => {
    logCtaClick({
      label: label,
      pageName: pageName?.log,
      section: t('amplitude.heroSection.section'),
    });
  };

  return (
    <Container className={styles['hero-section__container']}>
      <Row className={styles['hero-section__top-row']}>
        <Col
          xs={{ size: 12, offset: 0, order: 1 }}
          md={{ size: 7, offset: 0, order: 0 }}
          xl={{ size: 6, offset: 1 }}
          className={styles['hero-section__info-col']}
        >
          <div className={styles['hero-section__info']} ref={elementRef}>
            <Heading size={'xxxl'} className={styles['hero-section__heading']}>
              {heading}
            </Heading>
            {body && (
              <Text className={styles['hero-section__body']}>{body}</Text>
            )}
            {(ctaOne || ctaTwo) && (
              <div className={styles['hero-section__ctas']}>
                {ctaOne && (
                  <Button
                    label={ctaOne.label}
                    size={'sm'}
                    variant={'accent'}
                    onClick={() => {
                      handleLogCtaClick(ctaOne.label);
                      ctaOne.onClick();
                    }}
                  />
                )}
                {ctaTwo && (
                  <Button
                    label={ctaTwo.label}
                    size={'sm'}
                    variant={'accent'}
                    appearance={'outline'}
                    onClick={() => {
                      handleLogCtaClick(ctaTwo.label);
                      ctaTwo?.onClick();
                    }}
                  />
                )}
              </div>
            )}
            {tagline?.lineOne && (
              <div className={styles['hero-section__tagline__container']}>
                <Pill />
                <Heading
                  tag="h6"
                  size="xs"
                  className={styles['hero-section__tagline']}
                >
                  {tagline.lineOne}
                </Heading>
              </div>
            )}
            {tagline?.lineTwo && (
              <div className={styles['hero-section__tagline__container']}>
                <Pill />
                <Heading
                  tag="h6"
                  size="xs"
                  className={styles['hero-section__tagline']}
                >
                  {tagline.lineTwo}
                </Heading>
              </div>
            )}
          </div>
        </Col>
        <Col xs={12} md={5} xl={4}>
          <div className={styles['hero-section__image__container']}>
            <img
              src="/assets/images/homepage-hero.png"
              alt="A pharmacist carrying your treatments"
              className={styles['hero-section__image']}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
