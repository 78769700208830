/* eslint-disable sonarjs/no-duplicate-string */
import { BackendPomFormQuestion } from '../PomQuestionnaires';
import i18n from 'i18n';

export interface RosaceaPomForm {
  conditionId: 3;
  confirmedDiagnosisOfRosaceaByDoctor: string;
  rosaceaDuration: string;
  rosaceaSymptoms: string;
  hasOtherSkinConditions: string;
  hasOtherSkinConditionsDetail: string;
  hasVisionIssues: string;
  imageFiles: File[];
  imageNames: string[];
}

export const rosaceaPomFormQuestions: BackendPomFormQuestion[] = [
  {
    fieldName: 'confirmedDiagnosisOfRosaceaByDoctor',
    question: i18n.t('pomForms.rosacea.questions.1'),
    dataType: 'single select',
  },
  {
    fieldName: 'rosaceaDuration',
    question: i18n.t('pomForms.rosacea.questions.2'),
    dataType: 'single select',
  },
  {
    fieldName: 'rosaceaSymptoms',
    question: i18n.t('pomForms.rosacea.questions.3'),
    dataType: 'string',
  },
  {
    fieldName: 'hasOtherSkinConditions',
    question: i18n.t('pomForms.rosacea.questions.4'),
    dataType: 'single select',
  },
  {
    fieldName: 'hasOtherSkinConditionsDetail',
    question: i18n.t('pomForms.rosacea.questions.4b'),
    dataType: 'string',
  },
  {
    fieldName: 'hasVisionIssues',
    question: i18n.t('pomForms.rosacea.questions.5'),
    dataType: 'single select',
  },
  {
    fieldName: 'imageFiles',
    question: i18n.t('pomForms.rosacea.questions.6'),
    dataType: 'image',
  },
];

export const getRosaceaPayload = (formAnswers: RosaceaPomForm) => [
  {
    ...rosaceaPomFormQuestions[0],
    answer: formAnswers.confirmedDiagnosisOfRosaceaByDoctor,
  },
  {
    ...rosaceaPomFormQuestions[1],
    answer: formAnswers.rosaceaDuration,
  },
  {
    ...rosaceaPomFormQuestions[2],
    answer: formAnswers.rosaceaSymptoms,
  },
  {
    ...rosaceaPomFormQuestions[3],
    answer: formAnswers.hasOtherSkinConditions,
  },
  {
    ...rosaceaPomFormQuestions[4],
    answer: formAnswers.hasOtherSkinConditionsDetail ?? '',
  },
  {
    ...rosaceaPomFormQuestions[5],
    answer: formAnswers.hasVisionIssues,
  },
  {
    ...rosaceaPomFormQuestions[6],
    answer: 'images',
  },
];
