import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'assets/icons/lawsat/Bin.svg';
import { ReactComponent as TickIcon } from 'assets/icons/lawsat/checkmark.svg';
import { ProductImage } from 'components/Products/ProductImage';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { IconButton } from 'components/common/IconButton';
import { Heading } from 'components/common/Heading';
import { useWindowSize } from 'hooks/useWindowSize';
import type { BasketOrderline } from 'models/Basket';

import styles from './PMedTile.module.scss';

interface PMedTileProps {
  orderLine: BasketOrderline;
  onDelete: (orderLine: BasketOrderline) => void;
  onOpenForm: (orderLine: BasketOrderline) => void;
}

export const PMedTile: React.FC<PMedTileProps> = ({
  orderLine,
  onOpenForm,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className={styles['pmed-tile']}>
      <div className={styles['pmed-tile__header']}>
        <ProductImage
          className={styles['pmed-tile__image']}
          imageFileName={orderLine.imageFileName}
          size={56}
        />
        <Text size="lg">{orderLine.name}</Text>
      </div>
      <div className={styles['pmed-tile__controls']}>
        {width && width < 992 ? (
          <IconButton
            className={styles['pmed-tile__delete-button']}
            testId={`pmed-tile-delete-icon-button-${orderLine.id}`}
            variant="mid"
            icon={DeleteIcon}
            label={t('OrderFlow.pMedTile.remove')}
            onClick={() => onDelete(orderLine)}
          />
        ) : (
          <Button
            className={styles['pmed-tile__delete-button']}
            size="sm"
            appearance="flat"
            testId={`pmed-tile-delete-button-${orderLine.id}`}
            variant="ui"
            icon={{ element: DeleteIcon }}
            label={t('OrderFlow.pMedTile.remove')}
            onClick={() => onDelete(orderLine)}
          />
        )}
        {orderLine.pMedQuestionComplete ? (
          <div
            className={styles['pmed-tile__complete']}
            data-testid={`pmed-tile-form-complete-${orderLine.id}`}
          >
            <TickIcon
              className={styles['pmed-tile__complete-tick']}
              width="20px"
              height="20px"
            />
            <Heading className={styles['pmed-tile__complete-title']} tag="h6">
              {t('OrderFlow.pMedTile.completed')}
            </Heading>
          </div>
        ) : (
          <Button
            testId={`pmed-tile-open-form-button-${orderLine.id}`}
            className={styles['pmed-tile__form-button']}
            variant="accent"
            appearance="flat"
            label={t('OrderFlow.pMedTile.form')}
            size="sm"
            onClick={() => onOpenForm(orderLine)}
          />
        )}
      </div>
    </div>
  );
};
