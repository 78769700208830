import React from 'react';
import cx from 'classnames';
import { Container, Row, Col } from 'components/common/grid';
import styles from './AppBarBottom.module.scss';
export interface AppBarBottomProps {
  fixed?: boolean;
  className?: string;
  fullWidth?: boolean;
  stack?: boolean;
  align?: 'center' | 'right';
  children?: React.ReactNode;
}

export const AppBarBottom = ({
  fixed,
  className,
  fullWidth = false,
  stack = false,
  children,
  align = 'right',
}: AppBarBottomProps) => {
  const barClasses = cx(`${styles['app-bar-bottom']}`, {
    [styles[`app-bar-bottom--fixed`]]: fixed,
    [`${className}`]: className,
  });
  return (
    <div className={barClasses}>
      <Container className={styles['app-bar-bottom__container']}>
        <Row className={styles['app-bar-bottom__row']}>
          <Col
            className={styles['app-bar-bottom__col']}
            xs="12"
            md={fullWidth ? '12' : { size: 8, offset: 2 }}
            lg={fullWidth ? '12' : { size: 6, offset: 3 }}
          >
            <div
              className={cx(styles['app-bar-bottom__inner'], {
                [styles['app-bar-bottom__inner--align-center']]:
                  align === 'center',
                [styles['app-bar-bottom__stack']]: stack,
              })}
            >
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
