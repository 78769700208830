import { useDependantContext } from 'contexts/DependantContext';
import { useDependantExemptionsContext } from 'contexts/DependantExemptionsContext';
import { useExemptionsContext } from 'contexts/ExemptionsContext';
import { usePatientContext } from 'contexts/PatientContext';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { routes } from 'routes';

export const usePatientOnboardingComplete = () => {
  const { patientPharmacy, patientGp, patientSCRAccepted, getAllPatientInfo } =
    usePatientContext();
  const { getExemptionDetails } = useExemptionsContext();

  const [fetchComplete, setFetchComplete] = useState(false);
  const [checksComplete, setChecksComplete] = useState(false);
  const [onboardingComplete, setOnboardingComplete] = useState(false);

  const getData = async () => {
    setFetchComplete(() => false);
    await getAllPatientInfo();
    await getExemptionDetails();
    setFetchComplete(() => true);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (fetchComplete) {
      setChecksComplete(() => true);
      if (patientPharmacy && patientGp && patientSCRAccepted) {
        setOnboardingComplete(() => true);
      }
    }
  }, [fetchComplete, patientPharmacy, patientGp, patientSCRAccepted]);

  return { checksComplete, onboardingComplete };
};

export const useDependantPrescriptionOnboardingComplete = (
  dependantId: string,
) => {
  const history = useHistory();
  const {
    dependantPharmacy,
    dependantGp,
    dependantAddress,
    getAllDependantInfo,
    clearDependantContext,
  } = useDependantContext();

  const { getDependantExemptionDetails, setDependantExemptionDetails } =
    useDependantExemptionsContext();

  const [fetchComplete, setFetchComplete] = useState(false);
  const [checksComplete, setChecksComplete] = useState(false);
  const [onboardingComplete, setOnboardingComplete] = useState(false);

  const resetChecks = () => {
    setFetchComplete(false);
    setChecksComplete(false);
    setOnboardingComplete(false);
  };

  const getData = async () => {
    await getAllDependantInfo(dependantId);
    await getDependantExemptionDetails(dependantId);
    setFetchComplete(true);
  };

  useEffect(() => {
    if (dependantId) {
      resetChecks();
      clearDependantContext();
      setDependantExemptionDetails(null);
      getData();
    }
    return () => {
      resetChecks();
      clearDependantContext();
      setDependantExemptionDetails(null);
    };
  }, [dependantId]);

  useEffect(() => {
    if (fetchComplete) {
      setChecksComplete(true);
      if (!dependantAddress) {
        history.push(
          `${routes.DEPENDANT.ADD.BASE}${dependantId}${routes.DEPENDANT.ADD.ADDRESS}`,
        );
      } else if (!dependantPharmacy) {
        history.push(
          `${routes.DEPENDANT.ADD.BASE}${dependantId}${routes.DEPENDANT.ADD.PHARMACY}`,
        );
      } else if (dependantGp) {
        setOnboardingComplete(true);
      } else {
        setOnboardingComplete(false);
      }
    }
  }, [
    fetchComplete,
    dependantId,
    dependantPharmacy,
    dependantGp,
    dependantAddress,
  ]);

  if (!dependantId) {
    return null;
  } else {
    return { checksComplete, onboardingComplete };
  }
};
