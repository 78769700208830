/* eslint-disable sonarjs/no-duplicate-string */
import { BackendPomFormQuestion } from '../PomQuestionnaires';
import i18n from 'i18n';

export interface EczemaAffectedAreas {
  Elbows: boolean;
  'Back of knees': boolean;
  Hands: boolean;
  Torso: boolean;
  Other: boolean;
}

export const defaultEczemaAffectedAreas: EczemaAffectedAreas = {
  Elbows: false,
  'Back of knees': false,
  Hands: false,
  Torso: false,
  Other: false,
};

export interface EczemaPomForm {
  conditionId: 2;
  confirmedDiagnosisOfEczemaByDoctor: string;
  eczemaDuration: string;
  eczemaSymptoms: string;
  affectedSkinAreas: EczemaAffectedAreas;
  affectedSkinAreasDetail?: string;
  isExperiencingFlareUp: string;
  isExperiencingInfection: string;
  isTakingMedication: string;
  isTakingMedicationDetail?: string;
  isTakingMedicationFrequency?: string;
  hasBeenReviewedInLast12Months: string;
  hasBeenReviewedInLast12MonthsDetail?: string;
  imageFiles: File[];
  imageNames: string[];
}

export const eczemaPomFormQuestions: BackendPomFormQuestion[] = [
  {
    fieldName: 'confirmedDiagnosisOfEczemaByDoctor',
    question: i18n.t('pomForms.eczema.questions.1'),
    dataType: 'single select',
  },
  {
    fieldName: 'eczemaDuration',
    question: i18n.t('pomForms.eczema.questions.2'),
    dataType: 'single select',
  },
  {
    fieldName: 'eczemaSymptoms',
    question: i18n.t('pomForms.eczema.questions.3'),
    dataType: 'string',
  },
  {
    fieldName: 'affectedSkinAreas',
    question: i18n.t('pomForms.eczema.questions.4'),
    dataType: 'multi select',
  },
  {
    fieldName: 'affectedSkinAreasDetail',
    question: i18n.t('pomForms.eczema.questions.4b'),
    dataType: 'string',
  },
  {
    fieldName: 'isExperiencingFlareUp',
    question: i18n.t('pomForms.eczema.questions.5'),
    dataType: 'single select',
  },
  {
    fieldName: 'isExperiencingInfection',
    question: i18n.t('pomForms.eczema.questions.6'),
    dataType: 'single select',
  },
  {
    fieldName: 'isTakingMedication',
    question: i18n.t('pomForms.eczema.questions.7'),
    dataType: 'single select',
  },
  {
    fieldName: 'isTakingMedicationDetail',
    question: i18n.t('pomForms.eczema.questions.7b'),
    dataType: 'string',
  },
  {
    fieldName: 'isTakingMedicationFrequency',
    question: i18n.t('pomForms.eczema.questions.7c'),
    dataType: 'single select',
  },
  {
    fieldName: 'hasBeenReviewedInLast12Months',
    question: i18n.t('pomForms.eczema.questions.8'),
    dataType: 'single select',
  },
  {
    fieldName: 'hasBeenReviewedInLast12MonthsDetail',
    question: i18n.t('pomForms.eczema.questions.8b'),
    dataType: 'string',
  },
  {
    fieldName: 'imageFiles',
    question: i18n.t('pomForms.eczema.questions.9'),
    dataType: 'image',
  },
];

export const getEczemaPayload = (formAnswers: EczemaPomForm) => [
  {
    ...eczemaPomFormQuestions[0],
    answer: formAnswers.confirmedDiagnosisOfEczemaByDoctor,
  },
  {
    ...eczemaPomFormQuestions[1],
    answer: formAnswers.eczemaDuration,
  },
  {
    ...eczemaPomFormQuestions[2],
    answer: formAnswers.eczemaSymptoms,
  },
  {
    ...eczemaPomFormQuestions[3],
    answer: JSON.stringify(
      (
        Object.keys(formAnswers.affectedSkinAreas) as Array<
          keyof EczemaAffectedAreas
        >
      ).filter((key) => formAnswers.affectedSkinAreas[key]),
    ),
  },
  {
    ...eczemaPomFormQuestions[4],
    answer: formAnswers.affectedSkinAreasDetail ?? '',
  },
  {
    ...eczemaPomFormQuestions[5],
    answer: formAnswers.isExperiencingFlareUp,
  },
  {
    ...eczemaPomFormQuestions[6],
    answer: formAnswers.isExperiencingInfection,
  },
  {
    ...eczemaPomFormQuestions[7],
    answer: formAnswers.isTakingMedication,
  },
  {
    ...eczemaPomFormQuestions[8],
    answer: formAnswers.isTakingMedicationDetail ?? '',
  },
  {
    ...eczemaPomFormQuestions[9],
    answer: formAnswers.isTakingMedicationFrequency ?? 'n/a',
  },
  {
    ...eczemaPomFormQuestions[10],
    answer: String(formAnswers.hasBeenReviewedInLast12Months),
  },
  {
    ...eczemaPomFormQuestions[11],
    answer: formAnswers.hasBeenReviewedInLast12MonthsDetail ?? '',
  },
  {
    ...eczemaPomFormQuestions[12],
    answer: 'images',
  },
];
