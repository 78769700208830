import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePatientContext } from 'contexts/PatientContext';
import { useDependantContext } from 'contexts/DependantContext';
import { Container, Row, Col } from 'components/common/grid';
import { AppBarTop } from 'components/Layout/AppBarTop';
import { Card, CardType } from 'components/common/Card';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { ReactComponent as Plus } from 'assets/icons/lawsat/Plus.svg';
import { ReactComponent as PrescriptionCompleteImage } from 'assets/illustrations/payment-success.svg';

import { routes } from 'routes';
import styles from './PrescriptionCompleted.module.scss';

interface Props {
  isDependant: boolean;
}

export const PrescriptionCompleted: React.FC<Props> = ({ isDependant }) => {
  const { patient } = usePatientContext();
  const { dependant } = useDependantContext();
  const { t } = useTranslation();

  const subject = isDependant ? dependant : patient;
  const routePath = isDependant ? '/dependant' : '/prescription';
  const transPath = isDependant ? 'Dependant' : 'PrescriptionFlow';

  const ctaLink: CardType = {
    icon: Plus,
    title: t(`${transPath}.completed.linkList.title`),
    description: t(`${transPath}.completed.linkList.description`),
    link: isDependant
      ? `${routePath}/prescription/${dependant?.id}/add`
      : '/prescription/add',
  };

  return (
    <>
      <AppBarTop
        closeRoute={{
          to: routes.PRESCRIPTION.BASE,
          text: t(`${transPath}.completed.back`),
        }}
      />
      <Container className={styles['prescription-completed']}>
        <Row>
          <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <div className={styles['prescription-completed__content']}>
              <PrescriptionCompleteImage
                className={styles['prescription-completed__image']}
              />

              <Heading
                tag="h2"
                size="xl"
                className={styles['prescription-completed__title']}
              >
                {t(`${transPath}.completed.title`, {
                  name: subject?.firstName,
                })}
              </Heading>
              <Text
                size="lg"
                className={styles['prescription-completed__text']}
              >
                {t(`${transPath}.completed.intro`, {
                  name: subject?.firstName,
                })}
              </Text>
            </div>
            <Card
              item={ctaLink}
              className={styles['prescription-completed__list']}
            />
            <Button
              width="full"
              className={styles['prescription-completed__button']}
              label={t(`${transPath}.completed.cancel`, {
                name: subject?.firstName,
              })}
              link={{ to: routes.ACCOUNT.BASE }}
            ></Button>
            <Text
              size={isDependant ? 'sm' : 'lg'}
              className={styles['prescription-completed__info']}
            >
              {t(`${transPath}.completed.updateText`, {
                name: subject?.firstName,
              })}
            </Text>
          </Col>
        </Row>
      </Container>
    </>
  );
};
