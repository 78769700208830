import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDependantContext } from 'contexts/DependantContext';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { Container, Row, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { StepsList } from 'components/StepsList';
import { ReactComponent as Dependant } from 'assets/illustrations/dependant.svg';
import { routes } from 'routes';

import styles from './DependantAdd.module.scss';

export const DependantAdd = () => {
  const { t } = useTranslation();
  const { clearDependantContext } = useDependantContext();

  useEffect(() => {
    clearDependantContext();
  }, []);

  return (
    <Container className={styles['add-dependant']}>
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <Dependant className={styles['add-dependant__image']} />
          <Heading tag="h2" size="xl">
            {t('Dependant.add.title')}
          </Heading>
          <Text size="lg">{t('Dependant.add.description')}</Text>
          <StepsList
            variant="accent"
            stepsList={t('Dependant.add.getStartedSteps', {
              returnObjects: true,
            })}
          />
          <AppBarBottom fullWidth>
            <Button
              className={styles['add-dependant__get-started']}
              width="grow"
              variant="accent"
              appearance="solid"
              label={t('Dependant.add.dependantCTA')}
              link={{ to: routes.DEPENDANT.ADD.CONTACT_DETAILS }}
            />
          </AppBarBottom>
        </Col>
      </Row>
    </Container>
  );
};
