/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable  @typescript-eslint/no-empty-interface */
import { BackendPomFormQuestion } from '../PomQuestionnaires';
import i18n from 'i18n';

export interface ErectileDysfunctionPomForm {
  conditionId: 4;
  isDiagnosedByGp: string;
  hasTakenEdTreatmentBefore: string;
  hasTakenEdTreatmentBeforeDetail?: string;
  hasHighOrLowBloodPressure: string;
  applicableStatementsOne: string;
  applicableStatementsOneDetail?: string;
  sufferFromConditions: string;
  sufferFromConditionsDetail?: string;
  agreeToSeekHelp: boolean;
  applicableStatementsTwo: string;
  applicableStatementsTwoDetail?: string;
}

export const erectileDysfunctionPomFormQuestions: BackendPomFormQuestion[] = [
  {
    fieldName: 'isDiagnosedByGp',
    question: i18n.t('pomForms.erectileDysfunction.questions.1'),
    dataType: 'single select',
  },
  {
    fieldName: 'hasTakenEdTreatmentBefore',
    question: i18n.t('pomForms.erectileDysfunction.questions.2'),
    dataType: 'single select',
  },
  {
    fieldName: 'hasTakenEdTreatmentBeforeDetail',
    question: i18n.t('pomForms.erectileDysfunction.questions.3'),
    dataType: 'string',
  },
  {
    fieldName: 'hasHighOrLowBloodPressure',
    question: i18n.t('pomForms.erectileDysfunction.questions.4'),
    dataType: 'string',
  },
  {
    fieldName: 'applicableStatementsOne',
    question: i18n.t('pomForms.erectileDysfunction.questions.5'),
    dataType: 'single select',
  },
  {
    fieldName: 'applicableStatementsOneDetail',
    question: i18n.t('pomForms.erectileDysfunction.questions.6'),
    dataType: 'string',
  },
  {
    fieldName: 'sufferFromConditions',
    question: i18n.t('pomForms.erectileDysfunction.questions.7'),
    dataType: 'single select',
  },
  {
    fieldName: 'sufferFromConditionsDetail',
    question: i18n.t('pomForms.erectileDysfunction.questions.8'),
    dataType: 'string',
  },
  {
    fieldName: 'agreeToSeekHelp',
    question: i18n.t('pomForms.erectileDysfunction.questions.9'),
    dataType: 'single select',
  },
  {
    fieldName: 'applicableStatementsTwo',
    question: i18n.t('pomForms.erectileDysfunction.questions.10'),
    dataType: 'single select',
  },
  {
    fieldName: 'applicableStatementsTwoDetail',
    question: i18n.t('pomForms.erectileDysfunction.questions.11'),
    dataType: 'string',
  },
];

export const getErectileDysfunctionPayload = (
  formAnswers: ErectileDysfunctionPomForm,
) => [
  {
    ...erectileDysfunctionPomFormQuestions[0],
    answer: formAnswers.isDiagnosedByGp,
  },
  {
    ...erectileDysfunctionPomFormQuestions[1],
    answer: formAnswers.hasTakenEdTreatmentBefore,
  },
  {
    ...erectileDysfunctionPomFormQuestions[2],
    answer: formAnswers.hasTakenEdTreatmentBeforeDetail ?? '',
  },
  {
    ...erectileDysfunctionPomFormQuestions[3],
    answer: formAnswers.hasHighOrLowBloodPressure,
  },
  {
    ...erectileDysfunctionPomFormQuestions[4],
    answer: formAnswers.applicableStatementsOne,
  },
  {
    ...erectileDysfunctionPomFormQuestions[5],
    answer: formAnswers.applicableStatementsOneDetail ?? '',
  },
  {
    ...erectileDysfunctionPomFormQuestions[6],
    answer: formAnswers.sufferFromConditions,
  },
  {
    ...erectileDysfunctionPomFormQuestions[7],
    answer: formAnswers.sufferFromConditionsDetail ?? '',
  },
  {
    ...erectileDysfunctionPomFormQuestions[8],
    answer: String(formAnswers.agreeToSeekHelp),
  },
  {
    ...erectileDysfunctionPomFormQuestions[9],
    answer: formAnswers.applicableStatementsTwo,
  },
  {
    ...erectileDysfunctionPomFormQuestions[10],
    answer: formAnswers.applicableStatementsTwoDetail ?? '',
  },
];
