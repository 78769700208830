import React, { useState } from 'react';

interface PageName {
  log?: string;
  display?: string;
}

export interface LandingPageContextProps {
  pageName?: PageName;
  setPageName: React.Dispatch<React.SetStateAction<PageName | undefined>>;
  resetPageName: () => void;
}

export const LandingPageContextDefaults = {
  pageName: undefined,
  setPageName: () => ({}),
  resetPageName: () => ({}),
};

export const LandingPageContext = React.createContext<LandingPageContextProps>(
  LandingPageContextDefaults,
);

export const useLandingPageContext = (): LandingPageContextProps =>
  React.useContext(LandingPageContext);

interface LandingPageContextProviderProps {
  children?: React.ReactNode;
}

export const LandingPageContextProvider = ({
  children,
}: LandingPageContextProviderProps) => {
  const [pageName, setPageName] = useState<PageName>();
  const resetPageName = () => setPageName({ display: '', log: '' });
  return (
    <LandingPageContext.Provider
      value={{
        pageName,
        setPageName,
        resetPageName,
      }}
    >
      {children}
    </LandingPageContext.Provider>
  );
};
