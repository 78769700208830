import React from 'react';
import cx from 'classnames';
import styles from './Icon.module.scss';

type IconProps = {
  className?: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  srLabel?: string;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  testId?: string;
};

export const Icon: React.FC<IconProps> = ({
  /** props freetext classes */
  className,
  /** icon passed from parent import via CRA's internal SVGR */
  icon,
  /** a11y 'label' for accessibility */
  srLabel,
  /** icon size */
  size = 'md',
  /** testing id */
  testId,
}) => {
  const iconClasses = cx(
    styles.icon,
    {
      [styles[`icon--${size}`]]: size,
    },
    className,
  );
  const Component = icon;

  return (
    <span
      className={`icon ${iconClasses}`}
      data-testid={!!testId ? `container-${testId}` : null}
    >
      <Component
        className={styles['icon-svg']}
        data-testid={!!testId ? testId : null}
      />
      {srLabel && (
        <span
          className="sr-only"
          data-testid={!!testId ? `sr-${testId}` : null}
        >
          {srLabel}
        </span>
      )}
    </span>
  );
};
