import * as yup from 'yup';
import { validAfterMinDate } from 'utils/validation';
import i18n from 'i18next';

export const dependantContactDetailsSchema = (): yup.ObjectSchema => {
  const errorStrings = {
    min: `${i18n.t('Dependant.contactDetails.formErrors.min')}`,
    max: `${i18n.t('Dependant.contactDetails.formErrors.max')}`,
    date: `${i18n.t('Dependant.contactDetails.formErrors.date')}`,
    required: `${i18n.t('Dependant.contactDetails.formErrors.required')}`,
  };

  const sharedRules = yup
    .string()
    .matches(/^[A-Za-z].*$/, {
      message: i18n.t('Dependant.contactDetails.formErrors.firstCharacter'),
      excludeEmptyString: true,
    })
    .max(50, errorStrings.max);

  return yup.object().shape({
    firstName: sharedRules.required(
      `${errorStrings.required} ${i18n
        .t('Dependant.contactDetails.formLabels.firstName')
        .toLowerCase()}`,
    ),
    middleName: sharedRules,
    lastName: sharedRules.required(
      `${errorStrings.required}
         ${i18n
           .t('Dependant.contactDetails.formLabels.lastName')
           .toLowerCase()}`,
    ),
    nhsNumber: yup
      .string()
      .trim()
      .max(10, errorStrings.max)
      .matches(/\d{10}|^$/, i18n.t('Dependant.contactDetails.formErrors.nhs')),
    dateOfBirth: yup
      .string()
      .required('date required')
      .test('dateOfBirth', errorStrings.date, function () {
        return validAfterMinDate(
          this.parent.dateDD,
          this.parent.dateMM,
          this.parent.dateYY,
          '1900',
        );
      }),
  });
};
