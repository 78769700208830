import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Heading } from 'components/common/Heading';
import { Col, Container, Row } from 'components/common/grid';
import { Button } from 'components/common/Button';
import { Image } from 'components/common/Image';
import { Text } from 'components/common/Text';
import { useTranslation } from 'react-i18next';
import { FormProgressBar } from 'components/FormProgressBar';
import { useProductsContext } from 'contexts/ProductsContext';
import { LandingPageSpinner } from 'components/common/Spinner';
import { QuantitySelector } from 'components/Products/QuantitySelector';
import { useBasketContext } from 'contexts/BasketContext';

import { routes } from 'routes';
import styles from './PomConfirmationTreatmentFlow.module.scss';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';

export const PomConfirmationTreatmentFlow = () => {
  const { t } = useTranslation();
  const { product } = useProductsContext();
  const { handleAddTreatmentToBasket, basket } = useBasketContext();
  const [quantitySelectedToAdd, setQuantitySelectedToAdd] = useState(1);
  const { logPageView } = useAmplitudeContext();

  useEffect(() => {
    if (product) {
      logPageView({
        pageName: t('amplitude.pomConfirmationTreatmentFlow.pageName'),
      });
    }
  }, []);

  if (!product) {
    return <LandingPageSpinner />;
  }

  const basketOrderline = basket.shopProducts.find(
    (line) => Number(line.id) === product.id,
  );

  const quantityInBasket = basketOrderline?.quantity ?? 0;

  const basketIsAtMaxQuantity =
    !!basketOrderline?.orderLimit &&
    quantityInBasket >= basketOrderline.orderLimit;

  return (
    <Container className={styles['pom-confirmation__container']}>
      <Row>
        <Col>
          <div className={styles['pom-confirmation__progress-container']}>
            <FormProgressBar numberOfStages={4} currentStage={4} />
          </div>
          <Heading
            size="sm"
            className={styles['pom-confirmation__heading']}
            tag="h6"
          >
            {t('pomForms.confirmationPageTreatmentFlow.thanks')}
          </Heading>
          <Heading
            size="sm"
            className={styles['pom-confirmation__heading']}
            tag="h6"
          >
            {t('pomForms.confirmationPageTreatmentFlow.infoPt1')}
            <Link to={routes.ACCOUNT.BASE}>
              {t('pomForms.confirmationPageTreatmentFlow.infoPt2')}
            </Link>
            {t('pomForms.confirmationPageTreatmentFlow.infoPt3')}
          </Heading>
          <div className={styles['pom-confirmation__product-info-container']}>
            <Image
              imageFileName={product.imageFileName}
              alt={`product-image-${product.title}`}
            />
            <div>
              <Heading size="lg" tag="h3">
                {product.title}
              </Heading>
              <Text
                size="lg"
                className={styles['pom-confirmation__quantity-label']}
              >
                {t('pomForms.confirmationPageTreatmentFlow.quantity')}
              </Text>
              <QuantitySelector
                product={product}
                quantitySelectedToAdd={quantitySelectedToAdd}
                setQuantitySelectedToAdd={setQuantitySelectedToAdd}
              />
            </div>
          </div>
          <div className={styles['pom-confirmation__button-container']}>
            <Button
              appearance="outline"
              label={t('pomForms.confirmationPageTreatmentFlow.backButton')}
              link={{
                to: `${routes.SHOP.BASE}`,
              }}
            />
            <Button
              type="submit"
              appearance="solid"
              label={
                basketIsAtMaxQuantity
                  ? t('pomForms.confirmationPageTreatmentFlow.goToBasketButton')
                  : t(
                      'pomForms.confirmationPageTreatmentFlow.addToBasketButton',
                    )
              }
              onClick={() => {
                !basketIsAtMaxQuantity &&
                  handleAddTreatmentToBasket(product, quantitySelectedToAdd);
              }}
              link={{
                to: `${routes.BASKET}`,
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
