/* eslint-disable sonarjs/no-duplicate-string */
import * as yup from 'yup';
import i18n from 'i18next';
import { validAfterMinDate } from 'utils/validation';

export const generalHealthQuestionnairePt1 = (): yup.ObjectSchema => {
  const booleanRequiredError = i18n.t('common.formErrors.radioRequired');
  return yup.object().shape({
    isRegisteredWithGp: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(booleanRequiredError),
    gpPracticeId: yup
      .string()
      .when('isRegisteredWithGp', (isRegisteredWithGp, schema) => {
        if (isRegisteredWithGp === 'Yes') {
          return schema.required(booleanRequiredError);
        }
        return schema;
      }),
    shareInformationToGpGranted: yup
      .string()
      .when('isRegisteredWithGp', (isRegisteredWithGp, schema) => {
        if (isRegisteredWithGp === 'Yes') {
          return schema.required(booleanRequiredError);
        }
        return schema;
      }),
    gender: yup
      .string()
      .required(booleanRequiredError)
      .oneOf(['Male', 'Female'], booleanRequiredError),
    dateOfBirth: yup
      .string()
      .required(
        i18n.t('OrderFlow.generalHealthQuestionnaire.formValidationErrors.dob'),
      )
      .matches(
        /^\d{4}-(0?[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
        i18n.t('OrderFlow.generalHealthQuestionnaire.formValidationErrors.dob'),
      )
      .test('dateOfBirth', 'Please enter a valid date', function () {
        return validAfterMinDate(
          this.parent.dateDD,
          this.parent.dateMM,
          this.parent.dateYY,
          '1900',
        );
      }),
    height: yup
      .number()
      .typeError(
        i18n.t(
          'OrderFlow.generalHealthQuestionnaire.formValidationErrors.height',
        ),
      )
      .required(
        i18n.t(
          'OrderFlow.generalHealthQuestionnaire.formValidationErrors.height',
        ),
      )
      .test(
        'height',
        i18n.t(
          'OrderFlow.generalHealthQuestionnaire.formValidationErrors.height',
        ),
        (value) => !!value && value > 0,
      ),
    weight: yup
      .number()
      .typeError(
        i18n.t(
          'OrderFlow.generalHealthQuestionnaire.formValidationErrors.weight',
        ),
      )
      .required(
        i18n.t(
          'OrderFlow.generalHealthQuestionnaire.formValidationErrors.weight',
        ),
      )
      .test(
        'weight',
        i18n.t(
          'OrderFlow.generalHealthQuestionnaire.formValidationErrors.weight',
        ),
        (value) => !!value && value > 0,
      ),
  });
};

export const generalHealthQuestionnairePt2 = (): yup.ObjectSchema => {
  const requiredError = i18n.t('common.formErrors.required');
  const booleanRequiredError = i18n.t('common.formErrors.radioRequired');
  const maxLengthError = i18n.t('common.formErrors.maxLength', { max: 500 });
  return yup.object().shape({
    bloodPressure: yup
      .string()
      .oneOf(['Normal', 'High', 'Low'], booleanRequiredError)
      .required(),
    hasAllergies: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(),
    allergyDetails: yup
      .string()
      .typeError()
      .nullable()
      .when('hasAllergies', (hasAllergies, schema) => {
        if (hasAllergies === 'Yes') {
          return schema
            .required(requiredError)
            .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
            .max(500, maxLengthError)
            .trim();
        }
        return schema;
      }),
    hasMedicalCondition: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(),
    medicalConditionDetail: yup
      .string()
      .typeError()
      .nullable()
      .when('hasMedicalCondition', (hasMedicalCondition, schema) => {
        if (hasMedicalCondition === 'Yes') {
          return schema
            .required(requiredError)
            .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
            .max(500, maxLengthError)
            .trim();
        }
        return schema;
      }),
    hasMentalHealthCondition: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(),
    mentalHealthConditionDetail: yup
      .string()
      .typeError()
      .nullable()
      .when('hasMentalHealthCondition', (hasMentalHealthCondition, schema) => {
        if (hasMentalHealthCondition === 'Yes') {
          return schema
            .required(requiredError)
            .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
            .max(500, maxLengthError)
            .trim();
        }
        return schema;
      }),
    isTakingMedication: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(),
    takingMedicationDetail: yup
      .string()
      .typeError()
      .nullable()
      .when('isTakingMedication', (isTakingMedication, schema) => {
        if (isTakingMedication === 'Yes') {
          return schema
            .required(requiredError)
            .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
            .max(500, maxLengthError)
            .trim();
        }
        return schema;
      }),
    shareNotes: yup.string().optional(),
    termsAndConditions: yup
      .boolean()
      .oneOf([true], i18n.t('common.formErrors.termsAndConditions'))
      .required(),
  });
};
