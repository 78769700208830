import React, { useEffect } from 'react';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListNode, ListItemNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';
import {
  GetLexicalViewerThemeArgs,
  getLexicalViewerTheme,
} from './lexicalViewerTheme';

interface RichTextViewerProps {
  richText?: string;
  placeholder?: JSX.Element;
  themeProps?: GetLexicalViewerThemeArgs;
  amplitudeSectionName?: string | null;
}

export const RichTextViewer = ({
  richText,
  placeholder = <></>,
  themeProps = { textSize: 'lg' },
  amplitudeSectionName = null,
}: RichTextViewerProps) => {
  const { elementRef, isInViewport } = useIsInViewport({});
  const { logSectionView } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();

  const initialConfig = {
    namespace: 'RichTextViewer',
    theme: getLexicalViewerTheme(themeProps),
    editorState: richText,
    editable: false,
    onError: (error: Error) => {
      console.error(error);
    },
    nodes: [HeadingNode, ListNode, ListItemNode],
  };

  useEffect(() => {
    amplitudeSectionName &&
      logSectionView({
        pageName: pageName?.log,
        sectionName: amplitudeSectionName,
      });
  }, [isInViewport]);

  return (
    <div ref={elementRef}>
      <LexicalComposer initialConfig={initialConfig}>
        <RichTextPlugin
          contentEditable={<ContentEditable />}
          placeholder={placeholder}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ListPlugin />
      </LexicalComposer>
    </div>
  );
};
