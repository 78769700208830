import {
  BackendPomFormAnswer,
  PomForm,
  getPsoriasisPayload,
  getRosaceaPayload,
} from 'models/PomQuestionnaires';
import { getAcnePayload } from './Acne/AcnePomForm';
import { getEczemaPayload } from './Eczema';
import { getWeightLossPayload } from './WeightLoss';
import { getErectileDysfunctionPayload } from './ErectileDysfunction';

export const getPomFormAnswersPayload = (
  conditionId: number,
  formAnswers: PomForm,
): BackendPomFormAnswer[] => {
  if (conditionId === 1 && formAnswers.conditionId === 1) {
    return getAcnePayload(formAnswers);
  } else if (conditionId === 2 && formAnswers.conditionId === 2) {
    return getEczemaPayload(formAnswers);
  } else if (conditionId === 3 && formAnswers.conditionId === 3) {
    return getRosaceaPayload(formAnswers);
  } else if (conditionId === 4 && formAnswers.conditionId === 4) {
    return getErectileDysfunctionPayload(formAnswers);
  } else if (conditionId === 14 && formAnswers.conditionId === 14) {
    return getWeightLossPayload(formAnswers);
  } else if (conditionId === 15 && formAnswers.conditionId === 15) {
    return getPsoriasisPayload(formAnswers);
  }
  return [];
};
