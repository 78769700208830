/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { Switch, useParams } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { AccountLanding } from 'containers/Account/AccountLanding';
import { DependantContactDetails } from 'containers/Dependant/DependantContactDetails';
import { DependantSelectPharmacy } from 'containers/Dependant/DependantSelectPharmacy';
import { PrescriptionExemptionExpire } from 'containers/Prescription/PrescriptionExemptionExpire';
import { PrescriptionExemptionList } from 'containers/Prescription/PrescriptionExemptionList';
import { SelectGPLanding } from 'containers/Account/SelectGPLanding';
import { DependantAddressContainer } from 'containers/Dependant/DependantAddressContainer';
import { YourGP } from 'containers/Account/YourGP';
import { useDependantExemptionsContext } from 'contexts/DependantExemptionsContext';
import { SuggestProvider } from 'contexts/SuggestContext';
import { PostCodeSearchProvider } from 'contexts/PostCodeSearchContext';
import { useTranslation } from 'react-i18next';
import { useDependantContext } from 'contexts/DependantContext';
import { LandingPageSpinner } from 'components/common/Spinner';
import { routes } from 'routes';

export const DependantAccount = () => {
  const { dependantId } = useParams<{ dependantId?: string }>();
  const { t } = useTranslation();
  const [fetchComplete, setFetchComplete] = useState(false);

  const { dependant, getAllDependantInfo, clearDependantContext } =
    useDependantContext();

  const { dependantExemptionDetails, getDependantExemptionDetails } =
    useDependantExemptionsContext();

  const fetchData = async (dependantId: string) => {
    await getAllDependantInfo(dependantId);
    await getDependantExemptionDetails(dependantId);
    setFetchComplete(true);
  };

  useEffect(() => {
    dependantId && fetchData(dependantId);
    return clearDependantContext();
  }, [dependantId]);

  if (!fetchComplete) {
    return <LandingPageSpinner />;
  }

  const baseRoute = `${routes.DEPENDANT.ACCOUNT.BASE}${dependantId}`;
  return (
    <Switch>
      <PrivateRoute path={baseRoute} exact>
        <AccountLanding dependantId={dependantId} />
      </PrivateRoute>

      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.CONTACT_DETAILS}`}
        exact
      >
        <DependantContactDetails dependantId={dependantId} />
      </PrivateRoute>

      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.ADDRESS}`}
        exact
      >
        <PostCodeSearchProvider>
          <DependantAddressContainer
            heading={t('Dependant.address.editTitle', {
              dependant: dependant?.firstName,
            })}
            submitButton={{
              label: t('Dependant.address.formLabels.save'),
              route: baseRoute,
            }}
          />
        </PostCodeSearchProvider>
      </PrivateRoute>

      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.PHARMACY}`}
        exact
      >
        <DependantSelectPharmacy
          heading={t('Account.pharmacies.titleWithDependant', {
            dependantName: dependant?.firstName,
          })}
          submitButton={{
            route: baseRoute,
            label: t('Account.pharmacies.save'),
          }}
        />
      </PrivateRoute>

      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.VIEW_GP}`}
        exact
      >
        <YourGP
          dependantId={dependantId}
          heading={t('Account.yourGP.titleWithDependant')}
          selectGpRoute={`${baseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.SELECT_GP}`}
          submitButton={{
            route: baseRoute,
            label: t('Account.yourGP.doneButton'),
          }}
        />
      </PrivateRoute>

      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.SELECT_GP}`}
        exact
      >
        <SuggestProvider>
          <SelectGPLanding
            dependantId={dependantId}
            heading={t('Account.yourGP.changeDependantGPTitle')}
            submitButton={{
              route: baseRoute,
              label: t('Account.yourGP.saveNewGP'),
            }}
          />
        </SuggestProvider>
      </PrivateRoute>

      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.EXEMPTION}`}
        exact
      >
        <PrescriptionExemptionList
          dependantId={dependantId}
          heading={
            dependantExemptionDetails?.exemptionReasonId
              ? t('Account.exemption.titleWithDependant')
              : t('PrescriptionFlow.exemptionReason.titleWithDependant', {
                  dependantName: dependant?.firstName,
                })
          }
          exemptionExpireRoute={`${baseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.EXEMPTION_EXPIRE}`}
          exemptionNoExpireRoute={baseRoute}
          showRemove
        />
      </PrivateRoute>

      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.EXEMPTION_EXPIRE}`}
        exact
      >
        <PrescriptionExemptionExpire
          dependantId={dependantId}
          heading={t('PrescriptionFlow.exemptionExpiry.titleWithDependant', {
            dependantName: dependant?.firstName,
          })}
          selectExemptionRoute={`${baseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.EXEMPTION}`}
          submitButton={{
            label: t('Account.exemption.saveExemption'),
            route: baseRoute,
          }}
        />
      </PrivateRoute>
    </Switch>
  );
};
