import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';
import { LandingPage } from 'pages/Home/LandingPage';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { LandingPageSpinner } from 'components/common/Spinner';

export const Home = () => {
  const { getConditions } = useConditionsContext();
  const [fetchComplete, setFetchComplete] = useState(false);

  const fetchData = async () => {
    await getConditions();
    setFetchComplete(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!fetchComplete) {
    return <LandingPageSpinner />;
  }

  return (
    <Switch>
      <Route path={routes.BASE}>
        <LandingPage />
      </Route>
    </Switch>
  );
};
