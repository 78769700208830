import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useAuthContext } from 'contexts/AuthContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { useOnClickOutside } from 'hooks/useClickOutside';
import { ConditionGroup } from 'models/Condition/Condition';
import { Button } from 'components/common/Button';
import { isRouteActive } from 'components/Layout/NavBar';
import { ReactComponent as ChevronDown } from 'assets/icons/lawsat/ChevronDown.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';
import { routes } from 'routes';
import cx from 'classnames';

import styles from './DesktopMenuItems.module.scss';

const activeLinkStyle = styles['menu-items--active'];

export const DesktopMenuItems = () => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const { pathname } = useLocation();
  const { login, authStatus } = useAuthContext();
  const { conditionGroups, getConditionGroups } = useConditionsContext();
  const { pageName } = useLandingPageContext();
  const { logCtaClick } = useAmplitudeContext();

  const shopButtonRef = useRef(null);

  const [showConditionGroupsDropdown, setShowConditionGroupsDropdown] =
    useState(false);
  const [showConditionsDropdown, setShowConditionsDropdown] = useState(false);
  const [selectedConditionGroup, setSelectedConditionGroup] =
    useState<ConditionGroup | null>(null);

  let activeConditionGroupId: number | undefined;
  if (pathname.match(`${routes.SHOP.CONDITION}/`)) {
    const conditionId = pathname.split(`${routes.SHOP.CONDITION}/`)[1];
    activeConditionGroupId = conditionGroups?.filter(
      (group) =>
        group.conditions.filter(
          (condition) => condition.id == parseInt(conditionId),
        ).length > 0,
    )[0].id;
  }

  const handleOurTreatmentsClick = () => {
    handleLogCtaClick(t('NavBarLabels.shop'));
    setShowConditionGroupsDropdown(!showConditionGroupsDropdown);
    setShowConditionsDropdown(false);
  };

  const handleConditionGroupClick = (group: ConditionGroup) => {
    handleLogCtaClick(group.name);
    setShowConditionsDropdown(true);
    selectedConditionGroup === group
      ? setSelectedConditionGroup(null)
      : setSelectedConditionGroup(group);
  };

  const handleLogCtaClick = (label: string) => {
    logCtaClick({
      label: label,
      pageName: pageName?.log,
      section: t('amplitude.navbar.section'),
    });
  };

  const resetDropdown = useCallback(() => {
    setShowConditionGroupsDropdown(false);
    setShowConditionsDropdown(false);
    setSelectedConditionGroup(null);
  }, []);

  useOnClickOutside(shopButtonRef, resetDropdown);

  useEffect(() => {
    getConditionGroups();
  }, []);

  if (!conditionGroups) return null;

  const conditionGroupsToShow = conditionGroups?.filter(
    (condition) => condition.showInShop,
  );

  return (
    <div className={styles['menu-items']}>
      <div
        ref={shopButtonRef}
        className={styles['menu-items__shop-button-container']}
      >
        <Button
          label={t('NavBarLabels.shop')}
          testId="desktop-shop-button"
          appearance="ghost"
          variant="ui"
          onClick={() => handleOurTreatmentsClick()}
          className={cx(
            isRouteActive(pathname, routes.SHOP.BASE) && activeLinkStyle,
          )}
          icon={{ element: ChevronDown, alignment: 'right' }}
        />

        {showConditionGroupsDropdown && (
          <div
            className={cx(
              styles['menu-items__dropdown'],
              styles['menu-items__dropdown--condition-group'],
            )}
          >
            <Button
              label={t('DesktopMenuItems.allTreatments')}
              appearance="ghost"
              variant="ui"
              className={cx(
                isRouteActive(pathname, routes.SHOP.BASE, true) &&
                  activeLinkStyle,
                styles['menu-items__shop-button'],
              )}
              onClick={() => {
                resetDropdown();
                navigate.push(routes.SHOP.BASE);
              }}
            />
            {conditionGroupsToShow.map((group) => (
              <Button
                key={group.id}
                label={group.name}
                testId={`${group.name} Button`}
                appearance="ghost"
                variant="ui"
                size="sm"
                className={cx(
                  isRouteActive(
                    pathname,
                    `${routes.SHOP.CONDITIONGROUP}/${group.id}`,
                    true,
                  ) && activeLinkStyle,
                  activeConditionGroupId == group.id ? activeLinkStyle : null,
                )}
                icon={{ element: ChevronRight, alignment: 'right' }}
                onClick={() => handleConditionGroupClick(group)}
              />
            ))}

            {showConditionsDropdown && selectedConditionGroup && (
              <div
                className={cx(
                  styles['menu-items__dropdown'],
                  styles['menu-items__dropdown--condition'],
                )}
                style={{
                  top: `${
                    (conditionGroupsToShow.indexOf(selectedConditionGroup) +
                      1) *
                    56
                  }px`,
                }}
              >
                <Button
                  label={`All ${selectedConditionGroup.name}`}
                  appearance="ghost"
                  variant="ui"
                  size="sm"
                  width="full"
                  className={cx(
                    isRouteActive(
                      pathname,
                      `${routes.SHOP.CONDITIONGROUP}/${selectedConditionGroup.id}`,
                      true,
                    ) && activeLinkStyle,
                  )}
                  onClick={() => {
                    handleLogCtaClick(`All ${selectedConditionGroup.name}`);
                    resetDropdown();
                    navigate.push(
                      `${routes.SHOP.CONDITIONGROUP}/${selectedConditionGroup.id}`,
                    );
                  }}
                />
                {selectedConditionGroup?.conditions
                  .filter((condition) => condition.showInShop)
                  .filter(
                    (condition) =>
                      !condition.name.toLowerCase().includes('general'),
                  )
                  .map((condition) => (
                    <Button
                      key={condition.id}
                      label={condition.name}
                      appearance="ghost"
                      variant="ui"
                      size="sm"
                      className={cx(
                        isRouteActive(
                          pathname,
                          `${routes.SHOP.CONDITION}/${condition.id}`,
                          true,
                        ) && activeLinkStyle,
                      )}
                      onClick={() => {
                        handleLogCtaClick(condition.name);
                        resetDropdown();
                        navigate.push(
                          `${routes.SHOP.CONDITION}/${condition.id}`,
                        );
                      }}
                    />
                  ))}
              </div>
            )}
          </div>
        )}
      </div>

      <Button
        label={t('NavBarLabels.prescriptions')}
        testId={`desktop-prescriptions-button`}
        appearance="ghost"
        variant="ui"
        onClick={() => {
          handleLogCtaClick(t('NavBarLabels.prescriptions'));
          authStatus
            ? navigate.push(routes.PRESCRIPTION.BASE)
            : login({ redirectToUrl: routes.PRESCRIPTION.BASE });
        }}
        className={cx(
          isRouteActive(pathname, routes.PRESCRIPTION.BASE) && activeLinkStyle,
        )}
      />

      <Button
        label={t('NavBarLabels.travelVaccinations')}
        testId={`desktop-travel-vaccinations-button`}
        appearance="ghost"
        variant="ui"
        onClick={() => {
          handleLogCtaClick(t('NavBarLabels.travelVaccinations'));
          navigate.push(routes.TRAVELVACCINATIONS.BASE);
        }}
        className={cx(
          isRouteActive(pathname, routes.TRAVELVACCINATIONS.BASE) &&
            activeLinkStyle,
        )}
      />
    </div>
  );
};
