/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable  @typescript-eslint/no-empty-interface */
import { BackendPomFormQuestion } from '../PomQuestionnaires';
import i18n from 'i18n';

export interface WeightLossPomForm {
  conditionId: 14;
  weightLossGoals: string;
  isTakingInsulin: string;
  isDiagnosedWithDiabetes: string;
  hasEatingDisorder: string;
  hasEatingDisorderDetail?: string;
  hasPsychologicalCause: string;
  hasPsychologicalCauseDetail?: string;
  previouslyTakenMedication: string;
  previouslyTakenMedicationDetail?: string;
  currentlyTakingMedication: string;
  currentlyTakingMedicationDetail?: string;
  lifestyleDescription: string;
  hasTriedHealthyEating: string;
  hasKidneyLiverIssues: string;
  hasKidneyLiverIssuesDetail?: string;
  awareOfKeyStatements: boolean;
}

export const weightLossPomFormQuestions: BackendPomFormQuestion[] = [
  {
    fieldName: 'weightLossGoals',
    question: i18n.t('pomForms.weightLoss.questions.1'),
    dataType: 'string',
  },
  {
    fieldName: 'isTakingInsulin',
    question: i18n.t('pomForms.weightLoss.questions.2'),
    dataType: 'single select',
  },
  {
    fieldName: 'isDiagnosedWithDiabetes',
    question: i18n.t('pomForms.weightLoss.questions.3'),
    dataType: 'single select',
  },
  {
    fieldName: 'hasEatingDisorder',
    question: i18n.t('pomForms.weightLoss.questions.4'),
    dataType: 'single select',
  },
  {
    fieldName: 'hasEatingDisorderDetail',
    question: i18n.t('pomForms.weightLoss.questions.5'),
    dataType: 'string',
  },
  {
    fieldName: 'hasPsychologicalCause',
    question: i18n.t('pomForms.weightLoss.questions.6'),
    dataType: 'single select',
  },
  {
    fieldName: 'hasPsychologicalCauseDetail',
    question: i18n.t('pomForms.weightLoss.questions.7'),
    dataType: 'string',
  },
  {
    fieldName: 'previouslyTakenMedication',
    question: i18n.t('pomForms.weightLoss.questions.8'),
    dataType: 'single select',
  },
  {
    fieldName: 'previouslyTakenMedicationDetail',
    question: i18n.t('pomForms.weightLoss.questions.9'),
    dataType: 'string',
  },
  {
    fieldName: 'currentlyTakingMedication',
    question: i18n.t('pomForms.weightLoss.questions.10'),
    dataType: 'single select',
  },
  {
    fieldName: 'currentlyTakingMedicationDetail',
    question: i18n.t('pomForms.weightLoss.questions.11'),
    dataType: 'string',
  },
  {
    fieldName: 'lifestyleDescription',
    question: i18n.t('pomForms.weightLoss.questions.12'),
    dataType: 'string',
  },
  {
    fieldName: 'hasTriedHealthyEating',
    question: i18n.t('pomForms.weightLoss.questions.13'),
    dataType: 'single select',
  },
  {
    fieldName: 'hasKidneyLiverIssues',
    question: i18n.t('pomForms.weightLoss.questions.14'),
    dataType: 'single select',
  },
  {
    fieldName: 'hasKidneyLiverIssuesDetail',
    question: i18n.t('pomForms.weightLoss.questions.15'),
    dataType: 'string',
  },
  {
    fieldName: 'awareOfKeyStatements',
    question: i18n.t('pomForms.weightLoss.questions.16'),
    dataType: 'single select',
  },
];

export const getWeightLossPayload = (formAnswers: WeightLossPomForm) => [
  {
    ...weightLossPomFormQuestions[0],
    answer: formAnswers.weightLossGoals,
  },
  {
    ...weightLossPomFormQuestions[1],
    answer: formAnswers.isTakingInsulin,
  },
  {
    ...weightLossPomFormQuestions[2],
    answer: formAnswers.isDiagnosedWithDiabetes,
  },
  {
    ...weightLossPomFormQuestions[3],
    answer: formAnswers.hasEatingDisorder,
  },
  {
    ...weightLossPomFormQuestions[4],
    answer: formAnswers.hasEatingDisorderDetail ?? '',
  },
  {
    ...weightLossPomFormQuestions[5],
    answer: formAnswers.hasPsychologicalCause,
  },
  {
    ...weightLossPomFormQuestions[6],
    answer: formAnswers.hasPsychologicalCauseDetail ?? '',
  },
  {
    ...weightLossPomFormQuestions[7],
    answer: formAnswers.previouslyTakenMedication,
  },
  {
    ...weightLossPomFormQuestions[8],
    answer: formAnswers.previouslyTakenMedicationDetail ?? '',
  },
  {
    ...weightLossPomFormQuestions[9],
    answer: formAnswers.currentlyTakingMedication,
  },
  {
    ...weightLossPomFormQuestions[10],
    answer: formAnswers.currentlyTakingMedicationDetail ?? '',
  },
  {
    ...weightLossPomFormQuestions[11],
    answer: formAnswers.lifestyleDescription,
  },
  {
    ...weightLossPomFormQuestions[12],
    answer: formAnswers.hasTriedHealthyEating,
  },
  {
    ...weightLossPomFormQuestions[13],
    answer: formAnswers.hasKidneyLiverIssues,
  },
  {
    ...weightLossPomFormQuestions[14],
    answer: formAnswers.hasKidneyLiverIssuesDetail ?? '',
  },
  {
    ...weightLossPomFormQuestions[15],
    answer: String(formAnswers.awareOfKeyStatements),
  },
];
