/* eslint-disable sonarjs/no-duplicate-string */
import * as yup from 'yup';
import i18n from 'i18next';
import { rosaceaPomFormQuestions } from 'models/PomQuestionnaires';

export const RosaceaPomFormSchema = (
  shouldValidateImages: boolean,
): yup.ObjectSchema => {
  const requiredError = i18n.t('common.formErrors.required');
  const booleanRequiredError = i18n.t('common.formErrors.radioRequired');
  const maxLengthError = i18n.t('common.formErrors.maxLength', { max: 500 });

  return yup.object().shape(
    {
      [rosaceaPomFormQuestions[0].fieldName]: yup
        .string()
        .oneOf(['Yes', 'No'], booleanRequiredError)
        .required(booleanRequiredError),
      [rosaceaPomFormQuestions[1].fieldName]: yup
        .string()
        .oneOf(
          [
            'This is the first time',
            'Less than six months',
            'More than six months',
          ],
          booleanRequiredError,
        )
        .required(requiredError),
      [rosaceaPomFormQuestions[2].fieldName]: yup
        .string()
        .required(requiredError)
        .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
        .max(500, maxLengthError)
        .trim(),
      [rosaceaPomFormQuestions[3].fieldName]: yup
        .string()
        .oneOf(['Yes', 'No'], booleanRequiredError)
        .required(booleanRequiredError),
      [rosaceaPomFormQuestions[4].fieldName]: yup
        .string()
        .typeError()
        .nullable()
        .when(
          rosaceaPomFormQuestions[3].fieldName,
          (hasOtherSkinConditions, schema) => {
            if (hasOtherSkinConditions === 'Yes') {
              return schema
                .required(requiredError)
                .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
                .max(500, maxLengthError)
                .trim();
            }
            return schema;
          },
        ),
      [rosaceaPomFormQuestions[5].fieldName]: yup
        .string()
        .oneOf(['Yes', 'No'], booleanRequiredError)
        .required(booleanRequiredError),
      [rosaceaPomFormQuestions[6].fieldName]: shouldValidateImages
        ? yup
            .array()
            .required(i18n.t('common.formErrors.tooFewImages'))
            .min(1, i18n.t('common.formErrors.tooFewImages'))
            .max(2, i18n.t('common.formErrors.tooManyImages', { max: 2 }))
            .of(
              yup
                .mixed()
                .test('notBiggerThan5mb', 'File size exceeds 5MB', (file) =>
                  file ? file.size / Math.pow(1024, 2) <= 5 : true,
                ),
            )
        : yup.array(),
    },
    [['imageFiles', 'imageFiles']],
  );
};
