import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import cx from 'classnames';
import dompurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useDependantContext } from 'contexts/DependantContext';
import { useToastContext } from 'contexts/ToastContext';
import { Dependant, DependantForm, DependantDetail } from 'models/Dependant';
import { splitDate } from 'utils/transformers';
import { Container, Row, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Label } from 'components/common/Label';
import { InputText } from 'components/common/InputText';
import { Button } from 'components/common/Button';
import { FormProgressBar } from 'components/FormProgressBar';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { dependantContactDetailsSchema } from 'formSchema/DependantContactDetails';
import { routes } from 'routes';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';

import styles from './DependantContactDetails.module.scss';

const sanitizer = dompurify.sanitize;

export const transformPayload = (original: DependantForm): Dependant => {
  return {
    firstName: sanitizer(original.firstName),
    middleName: sanitizer(original.middleName),
    lastName: sanitizer(original.lastName),
    dateOfBirth: sanitizer(
      `${original.dateYY}-${original.dateMM}-${original.dateDD}`,
    ),
    nhsNumber: sanitizer(original.nhsNumber),
  };
};

const populateForm = (
  dependant: DependantDetail,
  populate: (name: string, value: unknown) => void,
) => {
  const date = splitDate(dependant.dateOfBirth);

  populate('firstName', dependant.firstName);
  populate('middleName', dependant.middleName);
  populate('lastName', dependant.lastName);
  populate('nhsNumber', dependant.nhsNumber);
  populate('dateDD', date[2]);
  populate('dateMM', date[1]);
  populate('dateYY', date[0]);
};

const dateFieldClass = 'dependant-contact-details__dateInput';
interface DependantContactDetailsProps {
  dependantId?: string;
}

export const DependantContactDetails = ({
  dependantId,
}: DependantContactDetailsProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setToast, dismissToast } = useToastContext();
  const { dependant, saveDependant, updateDependant, clearDependantContext } =
    useDependantContext();
  const { pathname } = useLocation();

  useEffect(() => {
    !dependantId && clearDependantContext();
  }, []);

  const {
    control,
    handleSubmit,
    getValues,
    errors,
    watch,
    setValue,
    formState,
  } = useForm({
    resolver: yupResolver(dependantContactDetailsSchema()),
  });

  const dateChanged = watch(['dateDD', 'dateMM', 'dateYY']);

  useEffect(() => {
    const dateValues = getValues(['dateDD', 'dateMM', 'dateYY']);
    setValue(
      'dateOfBirth',
      `${dateValues.dateDD}/${dateValues.dateMM}/${dateValues.dateYY}`,
      {
        shouldDirty: true,
        shouldValidate: formState.isSubmitted,
      },
    );
  }, [dateChanged]);

  useEffect(() => {
    if (dependantId && dependant) {
      populateForm(dependant, (name, value) => {
        setValue(name, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      });
    }
  }, [dependantId, dependant]);

  const onSubmit = async () => {
    dismissToast();
    const formValues = transformPayload(getValues() as DependantForm);
    if (dependantId) {
      await updateDependant(dependantId, formValues);
      history.push(`${routes.DEPENDANT.ACCOUNT.BASE}${dependantId}`);
    } else {
      const dependant = await saveDependant(formValues);
      history.push(
        `${routes.DEPENDANT.ADD.BASE}${dependant.id}${routes.DEPENDANT.ADD.ADDRESS}`,
      );
    }
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('Dependant.contactDetails.formErrors.validationErrorsTitle'),
      description: t(
        'Dependant.contactDetails.formErrors.validationErrorsDescription',
      ),
    });
  };

  const breadcrumbs: Crumb[] = [
    { route: routes.ACCOUNT.BASE, text: t('NavBarLabels.account') },
    {
      route: `${routes.DEPENDANT.ACCOUNT.BASE}${dependantId}`,
      text: t('Account.dependantLanding.title', {
        dependantName: dependant?.firstName,
      }),
    },
  ];

  return (
    <Container className={styles['dependant-contact-details']}>
      {pathname.match(routes.DEPENDANT.ACCOUNT.EDIT.CONTACT_DETAILS) && (
        <Breadcrumbs links={breadcrumbs} />
      )}
      <Row>
        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          {!dependantId && (
            <div
              className={
                styles['dependant-contact-details__progress-container']
              }
            >
              <FormProgressBar numberOfStages={3} currentStage={1} />
            </div>
          )}
          <Heading>{t('Dependant.contactDetails.title')}</Heading>
          <Text>{t('Dependant.contactDetails.intro')}</Text>
          <form
            className={styles['dependant-contact-details__form']}
            onSubmit={handleSubmit(onSubmit, invalid)}
          >
            <Controller
              control={control}
              defaultValue=""
              name="firstName"
              isRequired
              render={(props, fieldState) => (
                <>
                  <InputText
                    testId="formFirstName"
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.firstName?.message}
                    label={t('Dependant.contactDetails.formLabels.firstName')}
                    {...props}
                  />
                </>
              )}
            />
            <Controller
              control={control}
              defaultValue=""
              name="middleName"
              render={(props, fieldState) => (
                <>
                  <InputText
                    testId="formMiddleName"
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.middleName?.message}
                    label={t('Dependant.contactDetails.formLabels.middleName')}
                    {...props}
                  />
                </>
              )}
            />
            <Controller
              control={control}
              defaultValue=""
              name="lastName"
              render={(props, fieldState) => (
                <>
                  <InputText
                    testId="formLastName"
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.lastName?.message}
                    label={t('Dependant.contactDetails.formLabels.lastName')}
                    {...props}
                  />
                </>
              )}
            />
            <Label
              size="lg"
              className={cx({
                [styles['dependant-contact-details__text-validation']]:
                  errors.dateOfBirth?.message.length > 0,
              })}
            >
              {t('Dependant.contactDetails.formLabels.dateOfBirthFull')}
            </Label>
            {errors.dateOfBirth?.message && (
              <span
                className={styles['dependant-contact-details__text-validation']}
              >
                {errors.dateOfBirth?.message}
              </span>
            )}
            <Controller
              control={control}
              defaultValue=""
              name="dateOfBirth"
              render={(props) => (
                <>
                  <input
                    className={styles[`${dateFieldClass}`]}
                    {...props}
                    type="hidden"
                  />
                </>
              )}
            />
            <div className={styles['dependant-contact-details__dateFields']}>
              <Controller
                control={control}
                defaultValue=""
                name="dateDD"
                render={(props) => (
                  <>
                    <InputText
                      {...props}
                      testId="formDateDD"
                      maxLength={2}
                      variant={
                        errors.dateOfBirth?.message.length > 0
                          ? 'negative'
                          : 'accent'
                      }
                      className={styles[`${dateFieldClass}`]}
                      label={t(
                        'Dependant.contactDetails.formLabels.dateOfBirthDay',
                      )}
                      onBlur={() =>
                        dateChanged.dateDD.length === 1 &&
                        setValue('dateDD', `0${dateChanged.dateDD}`)
                      }
                    />
                  </>
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                name="dateMM"
                render={(props) => (
                  <>
                    <InputText
                      {...props}
                      testId="formDateMM"
                      maxLength={2}
                      variant={
                        errors.dateOfBirth?.message.length > 0
                          ? 'negative'
                          : 'accent'
                      }
                      className={styles[`${dateFieldClass}`]}
                      onBlur={() =>
                        dateChanged.dateMM.length === 1 &&
                        setValue('dateMM', `0${dateChanged.dateMM}`)
                      }
                      label={t(
                        'Dependant.contactDetails.formLabels.dateOfBirthMonth',
                      )}
                    />
                  </>
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                name="dateYY"
                render={(props) => (
                  <>
                    <InputText
                      maxLength={4}
                      variant={
                        errors.dateOfBirth?.message.length > 0
                          ? 'negative'
                          : 'accent'
                      }
                      className={styles[`${dateFieldClass}`]}
                      label={t(
                        'Dependant.contactDetails.formLabels.dateOfBirthYear',
                      )}
                      {...props}
                    />
                  </>
                )}
              />
            </div>
            <Controller
              control={control}
              defaultValue=""
              name="nhsNumber"
              render={(props, fieldState) => (
                <>
                  <InputText
                    testId="formNHS"
                    maxLength={10}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.nhsNumber?.message}
                    label={t('Dependant.contactDetails.formLabels.nhsNumber')}
                    caption={t('Dependant.contactDetails.formLabels.nhsReason')}
                    {...props}
                  />
                </>
              )}
            />
            <AppBarBottom fullWidth={true}>
              <Button
                width="full"
                className={styles['dependant-contact-details__submit-button']}
                testId="submit-button"
                type="submit"
                appearance="solid"
                label={
                  dependantId
                    ? t('Dependant.contactDetails.formLabels.editSubmit')
                    : t('Dependant.contactDetails.formLabels.submit')
                }
              ></Button>
            </AppBarBottom>{' '}
          </form>
        </Col>
      </Row>
    </Container>
  );
};
