import { Client } from 'api/utils';
import dompurify from 'dompurify';
import { Address } from 'models/Address';
import { Dependant, DependantDetail, DependantLite } from 'models/Dependant';
import { ExemptionPayload } from 'api/Exemption';
import { ExemptionDetails } from 'models/Exemption';
import { AxiosResponse } from 'axios';
import { GP } from 'models/GP';
import { Pharmacy } from 'models/Pharmacy';
import { PrescriptionItem } from 'models/PrescriptionItem';

export type PharmacyPayload = {
  pharmacyId: number;
};

const sanitizer = dompurify.sanitize;
export const transformAddressPayload = (original: Address): Address => {
  return {
    line1: sanitizer(original.line1),
    line2:
      original.line2 && original.line2 !== ''
        ? sanitizer(original.line2)
        : null,
    line3:
      original.line3 && original.line3 !== ''
        ? sanitizer(original.line3)
        : null,
    townCity: sanitizer(original.townCity),
    postCode: sanitizer(original.postCode),
  };
};

export const saveDependant = async (
  payload: Dependant,
): Promise<DependantDetail> => {
  const response = await Client.post(`patient/dependants`, payload);
  return response.data;
};

export const getDependants = async (): Promise<DependantLite[]> => {
  const response = await Client.get('patient/dependants');
  return response.data;
};

export const getDependant = async (id: string): Promise<DependantDetail> => {
  const response = await Client.get(`patient/dependants/${id}`);
  return response.data;
};

export const updateDependant = async (
  id: string,
  payload: Dependant,
): Promise<DependantDetail> => {
  const response = await Client.put(`patient/dependants/${id}`, payload);
  return response.data;
};

export const deleteDependant = async (id: string): Promise<void> => {
  return Client.delete(`patient/dependants/${id}`);
};

export const saveDependantAddress = async (
  id: string,
  payload: Address,
): Promise<Address> => {
  const response = await Client.put(
    `patient/dependants/${id}/address`,
    transformAddressPayload(payload),
  );
  return response.data;
};

export const getDependantAddress = async (id: string): Promise<Address> => {
  const response = await Client.get(`patient/dependants/${id}/address`);
  return response.data;
};

export const getDependantExemption = async (
  dependantId: string,
): Promise<ExemptionDetails> => {
  const response = await Client.get(
    `patient/dependants/${dependantId}/exemption`,
  );
  return response.data;
};

export const saveDependantExemption = async (
  dependantId: string,
  payload: ExemptionPayload,
): Promise<AxiosResponse> =>
  Client.put(`patient/dependants/${dependantId}/exemption`, payload);

export const deleteDependantExemption = (
  dependantId: string,
): Promise<AxiosResponse> =>
  Client.delete(`patient/dependants/${dependantId}/exemption`);

export const saveDependantGP = async (
  dependantId: string,
  gpId: string,
): Promise<AxiosResponse> => {
  return await Client.put(`patient/dependants/${dependantId}/gpPractice`, {
    gpPracticeId: parseInt(gpId),
  });
};

export const getDependantGP = async (dependantId: string): Promise<GP> => {
  const response = await Client.get(
    `patient/dependants/${dependantId}/gpPractice`,
  );
  return response.data;
};

export const saveDependantPharmacy = async (
  dependantId: string,
  payload: PharmacyPayload,
): Promise<void> => {
  return await Client.put(
    `Patient/dependants/${dependantId}/pharmacy`,
    payload,
  );
};

export const getDependantPharmacy = async (
  dependantId: string,
): Promise<Pharmacy> => {
  const response = await Client.get(
    `Patient/dependants/${dependantId}/pharmacy`,
  );
  return response.data;
};

export const getDependantPharmacies = async (
  dependantId: string,
): Promise<Pharmacy[]> => {
  const response = await Client.get(
    `Patient/dependants/${dependantId}/pharmacies`,
  );
  return response.data;
};

export const saveDependantPrescription = async (
  dependantId: string,
  payload: string,
): Promise<void> => {
  return Client.post(`patient/dependants/${dependantId}/prescriptionItems`, {
    medicinalProductId: payload,
  });
};

export const getDependantPrescriptionList = async (
  dependantId: string,
): Promise<PrescriptionItem[]> => {
  const response = await Client.get(
    `/patient/dependants/${dependantId}/prescriptionItems`,
  );
  return response.data;
};

export const getDependantPrescriptionItem = async (
  dependantId: string,
  itemId: string,
): Promise<PrescriptionItem> => {
  const response = await Client.get(
    `patient/dependants/${dependantId}/prescriptionItems/${itemId}`,
  );
  return response.data;
};

export const updateDependantPrescription = async (
  dependantId: string,
  itemId: string,
  reminder: number,
): Promise<void> => {
  return Client.put(
    `patient/dependants/${dependantId}/prescriptionItems/${itemId}`,
    {
      ReminderFrequencyNumberOfDays: reminder,
    },
  );
};

export const deleteDependantPrescriptionItem = async (
  dependantId: string,
  itemId: string,
): Promise<void> => {
  return Client.delete(
    `patient/dependants/${dependantId}/prescriptionItems/${itemId}`,
  );
};
