import { useEffect, useState } from 'react';
import cx from 'clsx';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBasketContext } from 'contexts/BasketContext';
import { useConfigContext } from 'contexts/ConfigContext';
import { useProductsContext } from 'contexts/ProductsContext';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { ShopUnavailable } from 'pages/Shop/ShopUnavailable';
import { Condition } from 'models/Condition';
import { ProductList } from 'components/Products/ProductList';
import { LandingPageSpinner } from 'components/common/Spinner';
import { PaginationSelector } from 'components/PaginationSelector';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { routes } from 'routes';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';

import styles from './SearchResults.module.scss';

const productNumberOfResultsPerPage = 6;
const conditionNumberOfResultsToShow = 100; // just give us all of them

const getNumberOfPages = (numberOfResults: number, resultsPerPage: number) =>
  Math.ceil(numberOfResults / resultsPerPage);

export const SearchResults = () => {
  const { search: searchTerm, page } = useParams<{
    search: string;
    page: string;
  }>();
  const productResultsCurrentPage = parseInt(page);
  const navigation = useHistory();
  const location = useLocation();
  const { refreshBasketTimeout } = useBasketContext();
  const { isShopEnabled, getPatientAppConfig } = useConfigContext();
  const {
    getProductsSearch,
    products,
    setProducts,
    setProductSearchResultsCount,
    productSearchResultsCount,
    loading: { productSearchStatus },
  } = useProductsContext();
  const { t } = useTranslation();
  const {
    getConditionsSearch,
    conditions,
    setConditions,
    loading: { conditionSearchStatus },
  } = useConditionsContext();

  const [paginationPage, setPaginationPage] = useState(
    productResultsCurrentPage,
  );
  const [productResultsPagesCount, setProductResultsPagesCount] = useState(
    productSearchResultsCount
      ? getNumberOfPages(
          productSearchResultsCount,
          productNumberOfResultsPerPage,
        )
      : 1,
  );

  const formattedSearchTerm = searchTerm.replace('%20', ' ');

  useEffect(() => {
    setProductResultsPagesCount(
      productSearchResultsCount
        ? getNumberOfPages(
            productSearchResultsCount,
            productNumberOfResultsPerPage,
          )
        : 1,
    );
  }, [productSearchResultsCount]);

  useEffect(() => {
    setPaginationPage(productResultsCurrentPage);
  }, [searchTerm]);

  useEffect(() => {
    getProductsSearch(
      searchTerm,
      productNumberOfResultsPerPage,
      productNumberOfResultsPerPage * (productResultsCurrentPage - 1),
    );
    getConditionsSearch(searchTerm, conditionNumberOfResultsToShow, 0); // no pagination for conditions

    return () => {
      setProducts([]);
      setConditions([]);
      setProductSearchResultsCount(undefined);
    };
  }, [searchTerm, productResultsCurrentPage]);

  useEffect(() => {
    navigation.push(`${routes.SEARCH_RESULTS}/${searchTerm}/${paginationPage}`);
  }, [paginationPage]);

  useEffect(() => {
    refreshBasketTimeout();
    getPatientAppConfig();
  }, [location]);

  if (!isShopEnabled) {
    return <ShopUnavailable />;
  }

  if (
    productSearchStatus !== 'finished' ||
    conditionSearchStatus !== 'finished'
  ) {
    return <LandingPageSpinner />;
  }

  const hasProducts = products.length > 0;
  const hasConditions = conditions && conditions.length > 0;
  const hasNoResults = !(hasProducts || hasConditions);

  return (
    <div
      className={cx(
        styles['search-results'],
        hasNoResults && styles['search-results--no-results'],
      )}
    >
      {hasNoResults ? (
        <NoResults searchTerm={formattedSearchTerm} />
      ) : (
        <>
          {/* Products */}
          <Heading
            tag="h2"
            size="lg"
            className={styles['search-results__section-heading']}
          >
            {t('searchResults.treatmentsHeading', {
              searchTerm: formattedSearchTerm,
            })}
          </Heading>

          {hasProducts ? (
            <>
              <ProductList
                products={products}
                baseUrl={routes.PRODUCT.BASE}
                className={cx(
                  styles['search-results__products__list-overrides'],
                )}
              />
              {productResultsPagesCount > 1 && (
                <PaginationSelector
                  currentPage={productResultsCurrentPage}
                  setPage={setPaginationPage}
                  numberOfPages={productResultsPagesCount}
                />
              )}
            </>
          ) : (
            <ul>
              <li>{t('searchResults.noTreatmentsMessage')}</li>
            </ul>
          )}

          {/* Conditions */}
          <Heading
            tag="h2"
            size="lg"
            className={styles['search-results__section-heading']}
          >
            {t('searchResults.conditionsHeading', {
              searchTerm: formattedSearchTerm,
            })}
          </Heading>

          {hasConditions ? (
            <div className={styles['search-results__conditions']}>
              {conditions.map((condition) => (
                <ConditionCard condition={condition} key={condition.id} />
              ))}
            </div>
          ) : (
            <ul>
              <li>{t('searchResults.noConditionsMessage')}</li>
            </ul>
          )}
        </>
      )}
    </div>
  );
};

interface NoResultsProps {
  searchTerm: string;
}

const NoResults = ({ searchTerm }: NoResultsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading tag="h2" size="md">
        {t('searchResults.noResultsHeading', { searchTerm })}
      </Heading>
      <ul>
        <li>{t('searchResults.noResultsString1')}</li>
        <li>
          <span>{t('searchResults.noResultsString2')}</span>
          &nbsp;
          <a href={routes.SHOP.BASE} target="_blank" rel="noopener noreferrer">
            {t('searchResults.noResultsString3')}
          </a>
          &nbsp;
          <span>{t('searchResults.noResultsString4')}</span>
        </li>
      </ul>
    </>
  );
};

interface ConditionCardProps {
  condition: Condition;
}
const ConditionCard = ({ condition }: ConditionCardProps) => {
  return (
    <Link
      to={`${routes.SHOP.CONDITION}/${condition.id}`}
      className={styles['condition-card']}
    >
      <div className={styles['condition-card__contents']}>
        <Heading tag="h4">{condition.name}</Heading>
        <Text className={styles['condition-card__tagline']}>
          {condition.tagline}
        </Text>
      </div>
      <ChevronRight />
    </Link>
  );
};
