/* eslint-disable sonarjs/no-duplicate-string */
import { Switch } from 'react-router-dom';
import { useExemptionsContext } from 'contexts/ExemptionsContext';
import { SuggestProvider } from 'contexts/SuggestContext';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { PrescriptionNextStep } from 'containers/Prescription/PrescriptionNextStep';
import { PrescriptionExemption } from 'containers/Prescription/PrescriptionExemption';
import { PrescriptionExemptionList } from 'containers/Prescription/PrescriptionExemptionList';
import { PrescriptionExemptionExpire } from 'containers/Prescription/PrescriptionExemptionExpire';
import { PrescriptionSCR } from 'containers/Prescription/PrescriptionSCR';
import { PrescriptionSCRDetail } from 'containers/Prescription/PrescriptionSCRDetail';
import { AccountSelectPharmacy } from 'containers/Account/AccountSelectPharmacy';
import { SelectGPLanding } from 'containers/Account/SelectGPLanding';
import { usePatientContext } from 'contexts/PatientContext';
import { YourGP } from 'containers/Account/YourGP';
import { useTranslation } from 'react-i18next';
import { LandingPageSpinner } from 'components/common/Spinner';
import { usePatientOnboardingComplete } from 'hooks/useOnboardingComplete';
import { AccountPatientDetails } from 'containers/Account/AccountPatientDetails';
import { PostCodeSearchProvider } from 'contexts/PostCodeSearchContext';
import { routes } from 'routes';

export const PrescriptionOnboarding = () => {
  const { t } = useTranslation();
  const { patientPharmacies } = usePatientContext();
  const { checksComplete } = usePatientOnboardingComplete();
  const { exemptionDetails } = useExemptionsContext();

  if (!checksComplete) {
    return <LandingPageSpinner />;
  }

  return (
    <Switch>
      {/* Onboarding Step 0 */}
      <PrivateRoute path={routes.PRESCRIPTION.ONBOARDING.NEXT} exact>
        <PrescriptionNextStep />
      </PrivateRoute>

      {/* Onboarding Step 1 */}
      <PrivateRoute path={routes.PRESCRIPTION.ONBOARDING.REVIEW_CONTACT} exact>
        <PostCodeSearchProvider>
          <AccountPatientDetails
            heading={t('PrescriptionFlow.onboarding.contactDetails.title')}
            subText={t('Account.contactDetails.intro')}
            submitButton={{
              label: t('PrescriptionFlow.onboarding.contactDetails.submit'),
              route: routes.PRESCRIPTION.ONBOARDING.SELECT_PHARMACY,
            }}
            progressBar={{ currentStage: 1, numberOfStages: 5 }}
            showNhsNumberField
          />
        </PostCodeSearchProvider>
      </PrivateRoute>

      {/* Onboarding Step 2 */}
      <PrivateRoute path={routes.PRESCRIPTION.ONBOARDING.SELECT_PHARMACY} exact>
        <AccountSelectPharmacy
          heading={
            patientPharmacies.find((item) => item.selectedByPatient === true)
              ? t('Account.selectPharmacy.reviewTitle')
              : t('Account.selectPharmacy.title')
          }
          bodyText={t('Account.selectPharmacy.intro')}
          submitButton={{
            label: t('Account.selectPharmacy.formLabels.continue'),
            route: routes.PRESCRIPTION.ONBOARDING.GP,
          }}
          progressBar={{ numberOfStages: 5, currentStage: 2 }}
        />
      </PrivateRoute>

      {/* Onboarding Step 3 */}
      <PrivateRoute path={routes.PRESCRIPTION.ONBOARDING.SELECT_GP} exact>
        <SuggestProvider>
          <SelectGPLanding
            heading={t('PrescriptionFlow.gp.title')}
            submitButton={{
              route: routes.PRESCRIPTION.ONBOARDING.EXEMPTION,
              label: t('PrescriptionFlow.exemptionReason.submitButton'),
            }}
            progressBar={{ numberOfStages: 5, currentStage: 3 }}
          />
        </SuggestProvider>
      </PrivateRoute>

      <PrivateRoute path={routes.PRESCRIPTION.ONBOARDING.GP} exact>
        <YourGP
          heading={t('Account.yourGP.reviewTitle')}
          selectGpRoute={routes.PRESCRIPTION.ONBOARDING.SELECT_GP}
          submitButton={{
            route: routes.PRESCRIPTION.ONBOARDING.EXEMPTION,
            label: t('Account.yourGP.continueButton'),
          }}
          progressBar={{ currentStage: 3, numberOfStages: 5 }}
        />
      </PrivateRoute>

      {/* Onboarding Step 4 */}
      <PrivateRoute path={routes.PRESCRIPTION.ONBOARDING.EXEMPTION} exact>
        <PrescriptionExemption
          heading={t('PrescriptionFlow.exemption.title')}
          secondaryHeading={t('PrescriptionFlow.exemptionCert.title')}
          progressBar={{ numberOfStages: 5, currentStage: 4 }}
          skipButton={{
            route: routes.PRESCRIPTION.ONBOARDING.SCR,
            label: t('PrescriptionFlow.exemptionCert.noButton'),
          }}
          yesButton={{
            label: t('PrescriptionFlow.exemption.yesButton'),
            route: routes.PRESCRIPTION.ONBOARDING.EXEMPTION_SELECT,
          }}
        />
      </PrivateRoute>

      <PrivateRoute
        path={routes.PRESCRIPTION.ONBOARDING.EXEMPTION_SELECT}
        exact
      >
        <PrescriptionExemptionList
          heading={
            exemptionDetails?.exemptionReasonId
              ? t('PrescriptionFlow.onboarding.exemptionList.reviewTitle')
              : t('PrescriptionFlow.exemptionReason.title')
          }
          progressBar={{ currentStage: 4, numberOfStages: 5 }}
          exemptionExpireRoute={routes.PRESCRIPTION.ONBOARDING.EXEMPTION_EXP}
          exemptionNoExpireRoute={routes.PRESCRIPTION.ONBOARDING.SCR}
        />
      </PrivateRoute>

      <PrivateRoute path={routes.PRESCRIPTION.ONBOARDING.EXEMPTION_EXP} exact>
        <PrescriptionExemptionExpire
          heading={t('PrescriptionFlow.exemptionExpiry.title')}
          selectExemptionRoute={routes.PRESCRIPTION.ONBOARDING.EXEMPTION_SELECT}
          submitButton={{
            label: t('PrescriptionFlow.exemptionReason.submitButton'),
            route: routes.PRESCRIPTION.ONBOARDING.SCR,
          }}
          progressBar={{ numberOfStages: 5, currentStage: 4 }}
        />
      </PrivateRoute>

      {/* Onboarding Step 5 */}
      <PrivateRoute path={routes.PRESCRIPTION.ONBOARDING.SCR} exact>
        <PrescriptionSCR />
      </PrivateRoute>

      <PrivateRoute path={routes.PRESCRIPTION.ONBOARDING.SCR_DEC} exact>
        <PrescriptionSCRDetail />
      </PrivateRoute>
    </Switch>
  );
};
