import { useEffect } from 'react';
import { HeroSection } from 'components/Sections/Common/HeroSection';
import { HereToHelpSection } from 'components/Sections/Common/HereToHelpSection';
import { TravelVaccinationsSection } from 'components/Sections/Home/TravelVaccinationsSection';
import { useAuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { PillLinkSection } from 'components/Sections/Common/PillLinkSection/PillLinkSection';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { StartConsultationSection } from 'components/Sections/Common/StartConsultationSection';
import { HowItWorksSection } from 'components/Sections/Common/HowItWorksSection';

import styles from './LandingPage.module.scss';

const pageName = { log: 'home-landing-page' };

export const LandingPage = () => {
  const { t } = useTranslation();
  const { conditions } = useConditionsContext();
  const { login, authStatus } = useAuthContext();
  const { logPageView } = useAmplitudeContext();
  const { setPageName, resetPageName } = useLandingPageContext();
  const navigate = useHistory();

  useEffect(() => {
    logPageView({ pageName: pageName.log });
    setPageName(pageName);
    return () => resetPageName();
  }, []);

  const treatmentsToShow = conditions?.filter(
    (condition) =>
      !condition.name.toLowerCase().includes('general') && condition.showInShop,
  );

  const validConditionsForConsultation =
    (
      conditions?.filter(
        (condition) =>
          condition.showInShop &&
          condition.products.some(
            (product) => product.isPom && product.inStock,
          ),
      ) ?? []
    ).length > 0;

  return (
    <>
      <HeroSection
        heading={t('LandingPage.hero.title')}
        body={t('LandingPage.hero.body')}
        ctaOne={{
          label: t('LandingPage.hero.button'),
          onClick: () => {
            authStatus
              ? navigate.push(routes.PRESCRIPTION.BASE)
              : login({ redirectToUrl: '/prescription' });
          },
        }}
        ctaTwo={{
          label: t('LandingPage.hero.shopButton'),
          onClick: () => {
            navigate.push(routes.SHOP.BASE);
          },
        }}
      />

      {treatmentsToShow && treatmentsToShow.length > 0 && (
        <PillLinkSection
          heading={t('LandingPage.ourTreatments.heading')}
          links={treatmentsToShow.map((condition) => ({
            label: condition.name,
            route: `${routes.SHOP.CONDITION}/${condition.id}`,
          }))}
          className={
            !validConditionsForConsultation
              ? styles[
                  'landing-page__section-overrides__pill-link-section--padded'
                ]
              : styles['landing-page__section-overrides__pill-link-section']
          }
        />
      )}

      {validConditionsForConsultation && (
        <StartConsultationSection
          ctaLink={`${routes.SHOP.BASE}${routes.POM.SELECT}`}
        />
      )}

      <HowItWorksSection mode="both" />

      <TravelVaccinationsSection
        ctaOne={{
          label: t('LandingPage.travelVaccinations.ctaOne'),
          to: routes.TRAVELVACCINATIONS.BOOK,
        }}
        ctaTwo={{
          label: t('LandingPage.travelVaccinations.ctaTwo'),
          to: routes.TRAVELVACCINATIONS.BASE,
        }}
      />

      <HereToHelpSection
        className={
          styles['landing-page__section-overrides__here-to-help-section']
        }
      />
    </>
  );
};
