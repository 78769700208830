import React from 'react';
import classNames from 'classnames';

export type supportedTags = 'div' | 'section' | 'ul' | 'ol' | 'li';
interface RowProps {
  tag?: supportedTags;
  className?: string;
  testId?: string;
  children?: React.ReactNode;
}

export const Row = ({
  tag = 'div',
  className,
  children,
  testId,
  ...attributes
}: RowProps) => {
  const Component = tag;
  const classes = classNames(className, 'row');

  return (
    <Component
      {...attributes}
      className={classes}
      data-testid={testId ? testId : null}
    >
      {children}
    </Component>
  );
};
