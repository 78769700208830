import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Button as ReakitUIButton } from 'reakit/Button';
import { Spinner } from '../Spinner';
import { Icon } from '../Icon';
import styles from './Button.module.scss';

export type ButtonAppearance =
  | 'solid'
  | 'outline'
  | 'flat'
  | 'minimal'
  | 'ghost';
export type ButtonVariant = 'ui' | 'accent' | 'negative';
export type ButtonSize = 'sm' | 'md' | 'lg';
export type ButtonRadius = 'square' | 'rounded' | 'pill';
export type ButtonWidth = 'full' | 'grow';
type ButtonType = 'button' | 'reset' | 'submit';

export interface ButtonProps {
  type?: ButtonType;
  appearance?: ButtonAppearance;
  variant?: ButtonVariant;
  size?: ButtonSize;
  width?: ButtonWidth;
  radius?: ButtonRadius;
  disabled?: boolean;
  isLoading?: boolean;
  label: string;
  testId?: string;
  className?: string;
  link?: { to: string; target?: string; rel?: string };
  onClick?: (event: React.MouseEvent) => void;
  icon?: {
    element: React.FC<React.SVGProps<SVGSVGElement>>;
    alignment?: 'right' | 'left';
  };
}

export const Button: React.FC<ButtonProps> = ({
  type,
  appearance = 'solid',
  variant = 'accent',
  size = 'md',
  width,
  radius = 'rounded',
  disabled = false,
  isLoading,
  label,
  testId,
  className,
  link,
  onClick,
  icon,
}) => {
  const iconAlignment = icon?.alignment ?? 'left';

  const buttonClasses = cx(
    `${styles['btn']}`,
    [`${styles[`btn--${appearance}--${variant}`]}`],
    {
      [`${styles[`btn--${size}`]}`]: size,
      [`${styles[`btn--${width}`]}`]: width,
      [`${styles[`btn--${radius}`]}`]: radius,
      [`${styles[`btn--disabled`]}`]: disabled || isLoading,
      [`${styles[`btn--icon--${iconAlignment}`]}`]: icon,
      [`${className}`]: className,
    },
  );

  const getSpinnerAppearance = () => {
    if (appearance === 'solid' || appearance === 'flat') {
      return 'light';
    }

    return 'dark';
  };

  const getIcon = () =>
    icon && (
      <Icon
        className={styles[`btn__icon--${iconAlignment}`]}
        testId={testId && `${testId}-${iconAlignment}`}
        icon={icon.element}
        size={size}
      />
    );

  const button = (
    <ReakitUIButton
      className={buttonClasses}
      type={type || 'button'}
      disabled={disabled || isLoading}
      onClick={onClick}
      data-testid={testId}
    >
      {isLoading ? (
        <Spinner
          size={size}
          variant={variant}
          appearance={getSpinnerAppearance()}
        />
      ) : null}
      {iconAlignment === 'left' && getIcon()}
      {label && (
        <span
          className={cx(`${styles['btn__text']}`, {
            [`${styles[`btn__text--loading`]}`]: isLoading,
          })}
          data-testid={testId && `${testId}-label`}
        >
          {label}
        </span>
      )}
      {iconAlignment === 'right' && getIcon()}
    </ReakitUIButton>
  );

  return (
    <>
      {link ? (
        <Link
          data-testid={testId}
          className={buttonClasses}
          to={{ pathname: link.to }}
          target={link.target}
          rel={link.rel}
          onClick={onClick ?? undefined}
        >
          {iconAlignment === 'left' && getIcon()}

          <span
            className={cx(`${styles['btn__text']}`)}
            data-testid={testId && `${testId}-label`}
          >
            {label}
          </span>
          {iconAlignment === 'right' && getIcon()}
        </Link>
      ) : (
        button
      )}
    </>
  );
};
