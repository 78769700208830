import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useApiError } from 'hooks/useApiError';
import { BranchStatus } from 'components/common/Branch';
import { Dependant, DependantDetail } from 'models/Dependant/Dependant';
import { PrescriptionItem } from 'models/PrescriptionItem';
import { Address } from 'models/Address';
import { useToastContext } from 'contexts/ToastContext';
import {
  getDependant as getDependantAPI,
  saveDependant as saveDependantAPI,
  updateDependant as updateDependantAPI,
  deleteDependant as deleteDependantAPI,
  getDependantGP as getDependantGPAPI,
  saveDependantGP as saveDependantGPAPI,
  getDependantPharmacies as getDependantPharmaciesAPI,
  getDependantPharmacy as getDependantPharmacyAPI,
  saveDependantPharmacy as saveDependantPharmacyAPI,
  saveDependantAddress as saveDependantAddressAPI,
  getDependantAddress as getDependantAddressAPI,
  getDependantPrescriptionItem as getDependantPrescriptionItemAPI,
  getDependantPrescriptionList as getDependantPrescriptionListAPI,
  saveDependantPrescription as saveDependantPrescriptionAPI,
  updateDependantPrescription as updateDependantPrescriptionAPI,
  deleteDependantPrescriptionItem as deleteDependantPrescriptionItemAPI,
} from 'api/Dependant';
import { Pharmacy } from 'models/Pharmacy';
import { routes } from 'routes';
import { GP } from 'models/GP';

export interface DependantContextLoading {
  dependantPrescriptionStatus?: BranchStatus;
  dependantDetailStatus?: BranchStatus;
  dependantGPStatus?: BranchStatus;
  dependantPrescriptionDetailStatus?: BranchStatus;
  updateDependantPrescriptionReminderStatus?: BranchStatus;
  removeDependantStatus?: BranchStatus;
  removeDependantPrescriptionStatus?: BranchStatus;
}

export interface DependantContextProps {
  dependant: DependantDetail | null;
  dependantAddress: Address | null;
  saveDependant: Function;
  getDependant: Function;
  getDependantGP: Function;
  updateDependant: Function;
  setDependant: Function;
  getDependantPharmacy: Function;
  getDependantPharmacies: Function;
  setDependantPharmacies: Function;
  saveDependantPharmacy: Function;
  dependantPharmacies: Pharmacy[];
  dependantPharmacy: Pharmacy | null;
  saveDependantAddress: Function;
  getDependantAddress: Function;
  saveDependantGP: Function;
  loading: BranchStatus;
  setLoading: Function;
  redirectUrl: null | string;
  setRedirect: Function;
  setDependantAddress: Function;
  dependantPrescriptions: PrescriptionItem[];
  getDependantPrescriptions: Function;
  addDependantPrescription: Function;
  updateDependantPrescriptionReminder: Function;
  getDependantPrescription: Function;
  dependantPrescription: PrescriptionItem | null;
  contextLoading: DependantContextLoading;
  removeDependant: Function;
  removeDependantPrescriptionItem: Function;
  setDependantPrescription: Function;
  dependantGp: GP | null;
  setDependantGp: Function;
  setDependantPharmacy: Function;
  getAllDependantInfo: (dependantId: string) => void;
  clearDependantContext: () => void;
  children?: React.ReactNode;
}

export const DependantContextDefaults: DependantContextProps = {
  dependant: null,
  saveDependant: Function,
  getDependant: Function,
  getDependantGP: Function,
  updateDependant: Function,
  setDependant: Function,
  getDependantPharmacy: Function,
  getDependantPharmacies: Function,
  setDependantPharmacies: Function,
  saveDependantPharmacy: Function,
  dependantPharmacies: [],
  dependantPharmacy: null,
  saveDependantAddress: Function,
  getDependantAddress: Function,
  saveDependantGP: Function,
  loading: 'finished',
  setLoading: Function,
  redirectUrl: null,
  setRedirect: Function,
  dependantAddress: null,
  setDependantAddress: Function,
  dependantPrescriptions: [],
  getDependantPrescriptions: Function,
  addDependantPrescription: Function,
  updateDependantPrescriptionReminder: Function,
  getDependantPrescription: Function,
  dependantPrescription: null,
  contextLoading: {},
  removeDependant: Function,
  removeDependantPrescriptionItem: Function,
  setDependantPrescription: Function,
  dependantGp: null,
  setDependantGp: Function,
  setDependantPharmacy: Function,
  getAllDependantInfo: () => ({}),
  clearDependantContext: () => ({}),
};

export const DependantContext = React.createContext<DependantContextProps>(
  DependantContextDefaults,
);

export const useDependantContext = (): DependantContextProps =>
  React.useContext(DependantContext);

interface DependantProviderProps {
  children?: React.ReactNode;
}

export const DependantProvider = ({ children }: DependantProviderProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const { handleApiError } = useApiError();
  const history = useHistory();
  const [dependant, setDependant] = React.useState<DependantDetail | null>(
    null,
  );
  const [dependantAddress, setDependantAddress] =
    React.useState<Address | null>(null);
  const [dependantPharmacies, setDependantPharmacies] = React.useState<
    Pharmacy[]
  >([]);
  const [dependantPharmacy, setDependantPharmacy] =
    React.useState<Pharmacy | null>(null);
  const [redirectUrl, setRedirect] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<BranchStatus>('idle');
  const [dependantPrescriptions, setDependantPrescriptionList] = React.useState<
    PrescriptionItem[]
  >([]);
  const [dependantPrescription, setDependantPrescription] =
    React.useState<PrescriptionItem | null>(null);
  const [contextLoading, setContextLoading] =
    React.useState<DependantContextLoading>({
      dependantDetailStatus: 'idle',
    });
  const [dependantGp, setDependantGp] = React.useState<GP | null>(null);

  const errors = {
    title: t('common.error.genericTitle'),
    message: t('common.error.genericMessage'),
  };

  const errorWithToast = () => {
    setToast({
      status: 'error',
      title: errors.title,
      description: errors.message,
    });
    setLoading('error');
  };

  const saveDependant = async (DependantPayload: Dependant) => {
    try {
      setLoading('loading');
      const result = await saveDependantAPI(DependantPayload);
      setDependant(result);
      setLoading('finished');
      return result;
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
        errorWithToast();
      }, error);
    }
  };

  const getDependant = async (id: string) => {
    try {
      setContextLoading((prevState) => ({
        ...prevState,
        dependantDetailStatus: 'loading',
      }));
      const result = await getDependantAPI(id);
      setDependant(result);
      setContextLoading((prevState) => ({
        ...prevState,
        dependantDetailStatus: 'finished',
      }));
    } catch (error) {
      handleApiError(() => {
        setContextLoading((prevState) => ({
          ...prevState,
          dependantDetailStatus: 'error',
        }));

        errorWithToast();
      }, error);
    }
  };

  const updateDependant = async (id: string, DependantPayload: Dependant) => {
    try {
      setLoading('loading');
      const result = await updateDependantAPI(id, DependantPayload);
      setDependant(result);
      history.push(routes.DEPENDANT.BASE);
      setLoading('finished');
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
        errorWithToast();
      }, error);
    }
  };

  const getDependantPharmacies = async (id: string) => {
    try {
      const result = await getDependantPharmaciesAPI(id);
      setDependantPharmacies(result);
      setLoading('finished');
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
      }, error);
    }
  };

  const getDependantPharmacy = async (id: string) => {
    try {
      const result = await getDependantPharmacyAPI(id);
      setDependantPharmacy(result);
      setLoading('finished');
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
      }, error);
    }
  };

  const saveDependantPharmacy = async (
    id: string,
    payload: { pharmacyId: number },
  ) => {
    try {
      await saveDependantPharmacyAPI(id, payload);
      getDependantPharmacies(id);
      getDependantPharmacy(id);
      setLoading('finished');
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
        errorWithToast();
      }, error);
    }
  };

  const saveDependantAddress = async (id: string, payload: Address) => {
    try {
      setLoading('loading');
      const result = await saveDependantAddressAPI(id, payload);
      setDependantAddress(result);
      setLoading('finished');
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
        errorWithToast();
      }, error);
    }
  };

  const getDependantAddress = async (id: string) => {
    try {
      setLoading('loading');
      const result = await getDependantAddressAPI(id);
      setDependantAddress(result);
      setLoading('finished');
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
      }, error);
    }
  };

  const getDependantGP = async (dependantId: string) => {
    try {
      setContextLoading((prevState) => ({
        ...prevState,
        dependantGPStatus: 'loading',
      }));
      const result = await getDependantGPAPI(dependantId);
      setDependantGp(result);
      setContextLoading((prevState) => ({
        ...prevState,
        dependantGPStatus: 'finished',
      }));
    } catch (error) {
      handleApiError(() => {
        setContextLoading((prevState) => ({
          ...prevState,
          dependantGPStatus: 'error',
        }));
      }, error);
    }
  };

  const saveDependantGP = async (dependantId: string, payload: string) => {
    try {
      setLoading('loading');
      await saveDependantGPAPI(dependantId, payload);
      getDependantGP(dependantId);
      setLoading('finished');
    } catch (error) {
      setLoading('error');
      handleApiError(() => {
        errorWithToast();
      }, error);
    }
  };

  const addDependantPrescription = async (
    dependantId: string,
    payload: string,
  ) => {
    try {
      setLoading('loading');
      await saveDependantPrescriptionAPI(dependantId, payload);
      setLoading('finished');
      history.push(`${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}`);
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
        errorWithToast();
      }, error);
    }
  };

  const getDependantPrescriptions = async (dependantId: string) => {
    try {
      setContextLoading((prevState) => ({
        ...prevState,
        dependantPrescriptionStatus: 'loading',
      }));
      const result = await getDependantPrescriptionListAPI(dependantId);
      setDependantPrescriptionList(result);
      result.length > 0
        ? setContextLoading((prevState) => ({
            ...prevState,
            dependantPrescriptionStatus: 'finished',
          }))
        : setContextLoading((prevState) => ({
            ...prevState,
            dependantPrescriptionStatus: 'empty',
          }));
    } catch (error) {
      handleApiError(() => {
        errorWithToast();
      }, error);
      setContextLoading((prevState) => ({
        ...prevState,
        dependantPrescriptionStatus: 'error',
      }));
    }
  };

  const updateDependantPrescriptionReminder = async (
    dependantId: string,
    itemId: string,
    days: number,
  ) => {
    try {
      setContextLoading((prevState) => ({
        ...prevState,
        updateDependantPrescriptionReminderStatus: 'loading',
      }));
      await updateDependantPrescriptionAPI(dependantId, itemId, days);
      setContextLoading((prevState) => ({
        ...prevState,
        updateDependantPrescriptionReminderStatus: 'finished',
      }));
      history.push(`${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}`);
    } catch (error) {
      handleApiError(() => {
        setContextLoading((prevState) => ({
          ...prevState,
          updateDependantPrescriptionReminderStatus: 'error',
        }));
        errorWithToast();
      }, error);
    }
  };

  const getDependantPrescription = async (
    dependantId: string,
    itemId: string,
  ) => {
    try {
      setContextLoading((prevState) => ({
        ...prevState,
        dependantPrescriptionDetailStatus: 'loading',
      }));
      const result = await getDependantPrescriptionItemAPI(dependantId, itemId);
      setDependantPrescription(result);
      setContextLoading((prevState) => ({
        ...prevState,
        dependantPrescriptionDetailStatus: 'finished',
      }));
    } catch (error) {
      handleApiError(() => {
        setContextLoading((prevState) => ({
          ...prevState,
          dependantPrescriptionDetailStatus: 'error',
        }));
        errorWithToast();
      }, error);
    }
  };

  const removeDependantPrescriptionItem = async (
    dependantId: string,
    itemId: string,
  ) => {
    try {
      setContextLoading((prevState) => ({
        ...prevState,
        removeDependantPrescriptionStatus: 'loading',
      }));
      await deleteDependantPrescriptionItemAPI(dependantId, itemId);
      setContextLoading((prevState) => ({
        ...prevState,
        removeDependantPrescriptionStatus: 'finished',
      }));
      history.push(`${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}`);
    } catch (error) {
      handleApiError(() => {
        setContextLoading((prevState) => ({
          ...prevState,
          removeDependantPrescriptionStatus: 'error',
        }));
        errorWithToast();
      }, error);
    }
  };

  const removeDependant = async (dependantId: string) => {
    try {
      setContextLoading((prevState) => ({
        ...prevState,
        removeDependantStatus: 'loading',
      }));
      await deleteDependantAPI(dependantId);
      setContextLoading((prevState) => ({
        ...prevState,
        removeDependantStatus: 'finished',
      }));
      history.push(routes.ACCOUNT.BASE);
    } catch (error) {
      handleApiError(() => {
        errorWithToast();
      }, error);
      setContextLoading((prevState) => ({
        ...prevState,
        removeDependantStatus: 'error',
      }));
    }
  };

  const getAllDependantInfo = async (dependantId: string) => {
    await getDependant(dependantId);
    await getDependantAddress(dependantId);
    await getDependantGP(dependantId);
    await getDependantPharmacies(dependantId);
    await getDependantPharmacy(dependantId);
  };

  const clearDependantContext = () => {
    setDependant(null);
    setDependantAddress(null);
    setDependantGp(null);
    setDependantPharmacies([]);
    setDependantPharmacy(null);
    setDependantPrescription(null);
    setDependantPrescriptionList([]);
  };

  const value = useMemo(
    () => ({
      dependant,
      setDependant,
      saveDependant,
      getDependant,
      getDependantGP,
      updateDependant,
      getDependantPharmacy,
      getDependantPharmacies,
      saveDependantPharmacy,
      dependantPharmacies,
      dependantPharmacy,
      saveDependantAddress,
      getDependantAddress,
      saveDependantGP,
      loading,
      setLoading,
      redirectUrl,
      setRedirect,
      dependantAddress,
      setDependantAddress,
      dependantPrescriptions,
      getDependantPrescriptions,
      addDependantPrescription,
      updateDependantPrescriptionReminder,
      getDependantPrescription,
      dependantPrescription,
      contextLoading,
      removeDependant,
      removeDependantPrescriptionItem,
      setDependantPrescription,
      dependantGp,
      setDependantGp,
      setDependantPharmacy,
      setDependantPharmacies,
      clearDependantContext,
      getAllDependantInfo,
    }),
    [
      dependant,
      dependantPharmacies,
      dependantPharmacy,
      dependantAddress,
      redirectUrl,
      dependantGp,
      dependantPrescription,
      loading,
      contextLoading,
    ],
  );

  return (
    <DependantContext.Provider value={value}>
      {children}
    </DependantContext.Provider>
  );
};
