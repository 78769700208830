import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';

import strings from './en.json';
import styles from '../VaccinationDescriptions.module.scss';

export const MalariaDescription = () => {
  return (
    <div>
      <Heading tag="h3">{strings.About.title}</Heading>
      <Text>{strings.About.description}</Text>
      <ul>
        {strings.About.aboutList.map((symptom) => (
          <li className={styles['vaccination__list']} key={symptom}>
            {symptom}
          </li>
        ))}
      </ul>
      <br />
      <Heading tag="h3">{strings.CommonSymptoms.title}</Heading>
      <Text>{strings.CommonSymptoms.descriptionI}</Text>
      <ul>
        {strings.CommonSymptoms.atRiskList.map((group) => (
          <li className={styles['vaccination__list']} key={group}>
            {group}
          </li>
        ))}
      </ul>
      <br />
      <Text>{strings.CommonSymptoms.descriptionII}</Text>
      <br />
      <Heading tag="h3">{strings.Prevention.title}</Heading>
      <Text>{strings.Prevention.description}</Text>
      <ul>
        {strings.Prevention.preventionList.map((prevention) => (
          <li className={styles['vaccination__list']} key={prevention}>
            {prevention}
          </li>
        ))}
      </ul>
      <br />
      <Heading tag="h3">{strings.Medication.title}</Heading>
      <Text>{strings.Medication.description}</Text>
      <br />
      <Heading tag="h3">{strings.MedicationLength.title}</Heading>
      <Text>{strings.MedicationLength.description}</Text>
    </div>
  );
};
