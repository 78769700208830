import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  getPatient as getPatientAPI,
  savePatient as savePatientAPI,
  getAddress as getAddressAPI,
  saveAddress as saveAddressAPI,
  getPatientGP as getPatientGpAPI,
  savePatientGp as savePatientGpAPI,
  getPatientPharmacies as getPatientPharmaciesAPI,
  getPatientPharmacy as getPatientPharmacyAPI,
  savePatientPharmacy as savePatientPharmacyAPI,
  savePatientSCR as savePatientScrAPI,
  getPatientSCR as getPatientScrAPI,
} from 'api/Patient';
import { getDependants as getDependantsAPI } from 'api/Dependant';
import { BranchStatus } from 'components/common/Branch';
import { useToastContext } from 'contexts/ToastContext';
import { useApiError } from 'hooks/useApiError';
import { Patient, PatientForm } from 'models/Patient';
import { Address } from 'models/Address';
import { Pharmacy } from 'models/Pharmacy';
import { GP } from 'models/GP';
import { useAuthContext } from 'contexts/AuthContext';
import { Toggle, defaultToggle } from 'models/Toggle';
import { DependantLite } from 'models/Dependant';
import { GeneralHealthForm } from 'models/GeneralHealthQuestionnaire';
import {
  getLatestGeneralHealthForm as getLatestGeneralHealthFormAPI,
  saveGeneralHealthForm as saveGeneralHealthFormAPI,
} from 'api/GeneralHealthForm/GeneralHealthForm';

export interface PatientLoadingStatus {
  getPatient: BranchStatus;
  getPatientPharmacyStatus: BranchStatus;
  getPatientGp: BranchStatus;
  getDependantsStatus?: BranchStatus;
}

export interface PatientContextProps {
  patient: Patient | null;
  patientAddress: Address | null;
  patientPharmacy: Pharmacy | null;
  patientToggle: Toggle<boolean | undefined>;
  getPatient: Function;
  setPatient: Function;
  getPatientAddress: Function;
  setPatientAddress: Function;
  savePatient: (patientForm: PatientForm) => Promise<boolean>;
  saveAddress: Function;
  savePatientSCRAccepted: Function;
  getPatientPharmacies: Function;
  getPatientPharmacy: Function;
  patientPharmacies: Pharmacy[];
  savePatientPharmacy: Function;
  getPatientSCRInfo: () => void;
  patientSCRAccepted: boolean | undefined;
  savePatientGp: Function;
  getPatientGp: Function;
  loadingStatuses: PatientLoadingStatus;
  patientGp: GP | null;
  setPatientGp: Function;
  dependants: DependantLite[] | null;
  getDependants: Function;
  setDependants: Function;
  patientGeneralHealthForm: GeneralHealthForm | null;
  getPatientGeneralHealthForm: () => void;
  savePatientGeneralHealthForm: (form: GeneralHealthForm) => void;
  getAllPatientInfo: () => void;
  children?: ReactNode;
}

export const PatientContextDefaults: PatientContextProps = {
  patient: null,
  dependants: null,
  getDependants: Function,
  setDependants: Function,
  patientToggle: defaultToggle,
  patientAddress: null,
  patientPharmacy: null,
  setPatient: Function,
  setPatientAddress: Function,
  getPatient: Function,
  getPatientAddress: Function,
  savePatient: async () => false,
  saveAddress: Function,
  savePatientSCRAccepted: Function,
  getPatientSCRInfo: () => ({}),
  patientSCRAccepted: false,
  getPatientPharmacies: Function,
  patientPharmacies: [],
  getPatientPharmacy: Function,
  savePatientPharmacy: Function,
  savePatientGp: Function,
  getPatientGp: Function,
  loadingStatuses: {
    getPatient: 'idle',
    getPatientPharmacyStatus: 'idle',
    getPatientGp: 'idle',
  },
  patientGp: null,
  patientGeneralHealthForm: null,
  getAllPatientInfo: () => ({}),
  getPatientGeneralHealthForm: async () => ({}),
  savePatientGeneralHealthForm: () => ({}),
  setPatientGp: Function,
};

export const PatientContext = createContext<PatientContextProps>(
  PatientContextDefaults,
);

export const usePatientContext = (): PatientContextProps =>
  useContext(PatientContext);

interface PatientProviderProps {
  children?: ReactNode;
}

export const PatientProvider = ({ children }: PatientProviderProps) => {
  const { t } = useTranslation();
  const { authStatus } = useAuthContext();
  const { setToast } = useToastContext();
  const { handleApiError } = useApiError();
  const [patient, setPatient] = useState<Patient | null>(null);
  const [patientToggle, setPatientToggle] =
    useState<Toggle<boolean | undefined>>(defaultToggle);
  const [dependants, setDependants] = React.useState<DependantLite[] | null>(
    [],
  );
  const [patientAddress, setPatientAddress] = useState<Address | null>(null);
  const [patientPharmacies, setPatientPharmacies] = useState<Pharmacy[]>([]);
  const [patientPharmacy, setPatientPharmacy] = useState<Pharmacy | null>(null);
  const [patientGp, setPatientGp] = useState<GP | null>(null);
  const [patientSCRAccepted, setPatientSCRAccepted] = useState<boolean>();
  const [patientGeneralHealthForm, setPatientGeneralHealthForm] =
    useState<GeneralHealthForm | null>(null);
  const [loadingStatuses, setLoadingStatuses] = useState<PatientLoadingStatus>({
    getPatient: 'loading',
    getPatientPharmacyStatus: 'loading',
    getPatientGp: 'loading',
  });

  useEffect(() => {
    setPatientToggle((prevState) => ({
      current: !!patient,
      previous: prevState?.current,
    }));
  }, [patient]);

  useEffect(() => {
    if (!authStatus && patient) {
      setPatient(null);
    } else if (authStatus && !patient) {
      getPatient();
    }
  }, [authStatus]);

  const errors = {
    title: t('common.error.genericTitle'),
    message: t('common.error.genericMessage'),
  };

  const errorWithToast = () => {
    setToast({
      status: 'error',
      title: errors.title,
      description: errors.message,
    });
  };

  const getPatient = async () => {
    try {
      setLoadingStatuses((prevState) => ({
        ...prevState,
        getPatient: 'loading',
      }));
      const result = await getPatientAPI();
      setPatient({ ...patient, ...result });
      setLoadingStatuses((prevState) => ({
        ...prevState,
        getPatient: 'finished',
      }));
    } catch (error) {
      handleApiError(() => {
        setLoadingStatuses((prevState) => ({
          ...prevState,
          getPatient: 'error',
        }));
      }, error);
    }
  };

  const getDependants = async () => {
    try {
      setLoadingStatuses((prevState) => ({
        ...prevState,
        getDependantsStatus: 'loading',
      }));

      const result = await getDependantsAPI();
      setDependants(result);
      setLoadingStatuses((prevState) => ({
        ...prevState,
        getDependantsStatus: 'finished',
      }));
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        handleApiError(() => {
          setLoadingStatuses((prevState) => ({
            ...prevState,
            getDependantsStatus: 'error',
          }));
          errorWithToast();
        }, error);
      }
    }
  };

  const getPatientAddress = async () => {
    try {
      const result = await getAddressAPI();
      setPatientAddress(result);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        handleApiError(() => {
          errorWithToast();
        }, error);
      }
    }
  };

  const getPatientGp = async () => {
    try {
      setLoadingStatuses((prevState) => ({
        ...prevState,
        getPatientGp: 'loading',
      }));
      const result = await getPatientGpAPI();
      setPatientGp(result);
      setLoadingStatuses((prevState) => ({
        ...prevState,
        getPatientGp: 'finished',
      }));
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        handleApiError(() => {
          setLoadingStatuses((prevState) => ({
            ...prevState,
            getPatientGp: 'error',
          }));
          errorWithToast();
        }, error);
      }
    }
  };

  const getPatientPharmacies = async () => {
    try {
      const result = await getPatientPharmaciesAPI();
      setPatientPharmacies(result);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        handleApiError(() => {
          errorWithToast();
        }, error);
      }
    }
  };

  const getPatientPharmacy = async () => {
    try {
      setLoadingStatuses({
        ...loadingStatuses,
        getPatientPharmacyStatus: 'loading',
      });
      const result = await getPatientPharmacyAPI();
      setPatientPharmacy(result);
      setLoadingStatuses({
        ...loadingStatuses,
        getPatientPharmacyStatus: 'finished',
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        handleApiError(() => {
          errorWithToast();
          setLoadingStatuses({
            ...loadingStatuses,
            getPatientPharmacyStatus: 'error',
          });
        }, error);
      }
    }
  };

  const savePatientPharmacy = async (payload: { pharmacyId: number }) => {
    try {
      await savePatientPharmacyAPI(payload);
      getPatientPharmacy();
      getPatientPharmacies();
    } catch (error) {
      handleApiError(() => {
        errorWithToast();
      }, error);
    }
  };

  const savePatientGp = async (payload: string) => {
    try {
      await savePatientGpAPI(payload);
      getPatientGp();
    } catch (error) {
      handleApiError(() => {
        errorWithToast();
      }, error);
    }
  };

  const savePatient = async (patientForm: PatientForm) => {
    try {
      await savePatientAPI(patientForm);
      getPatient();
      getPatientAddress();
      return true;
    } catch (error) {
      handleApiError(() => {
        errorWithToast();
      }, error);
      return false;
    }
  };

  const saveAddress = async (patientAddressPayload: Address) => {
    try {
      const result = await saveAddressAPI(patientAddressPayload);
      setPatientAddress(result);
    } catch (error) {
      handleApiError(() => {
        errorWithToast();
      }, error);
    }
  };

  const getPatientSCRInfo = async () => {
    try {
      const result = await getPatientScrAPI();
      setPatientSCRAccepted(
        () => result.dateOfSummaryCareRecordConsent !== null,
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        handleApiError(() => {
          errorWithToast();
        }, error);
      }
    }
  };

  const savePatientSCRAccepted = async () => {
    try {
      await savePatientScrAPI();
      getPatientSCRInfo();
    } catch (error) {
      handleApiError(() => {
        errorWithToast();
      }, error);
    }
  };

  const getPatientGeneralHealthForm = async () => {
    try {
      const result = await getLatestGeneralHealthFormAPI();
      setPatientGeneralHealthForm(result);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        handleApiError(() => {
          errorWithToast();
        }, error);
      }
    }
  };

  const savePatientGeneralHealthForm = async (form: GeneralHealthForm) => {
    try {
      const result = await saveGeneralHealthFormAPI(form);
      setPatientGeneralHealthForm(result);
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        handleApiError(() => {
          errorWithToast();
        }, error);
      }
    }
  };
  const getAllPatientInfo = async () => {
    await getPatient();
    await getPatientAddress();
    await getDependants();
    await getPatientPharmacy();
    await getPatientPharmacies();
    await getPatientGp();
    await getPatientSCRInfo();
  };

  const value = useMemo(
    () => ({
      patient,
      patientToggle,
      patientAddress,
      patientPharmacy,
      getPatient,
      getPatientAddress,
      setPatient,
      setPatientAddress,
      savePatient,
      saveAddress,
      patientSCRAccepted,
      savePatientSCRAccepted,
      getPatientSCRInfo,
      getPatientPharmacies,
      getPatientPharmacy,
      patientPharmacies,
      savePatientPharmacy,
      savePatientGp,
      getPatientGp,
      loadingStatuses,
      patientGp,
      setPatientGp,
      dependants,
      getDependants,
      setDependants,
      getAllPatientInfo,
      savePatientGeneralHealthForm,
      getPatientGeneralHealthForm,
      patientGeneralHealthForm,
    }),
    [
      patient,
      patientToggle,
      patientAddress,
      patientPharmacy,
      patientPharmacies,
      loadingStatuses,
      patientGp,
      patientSCRAccepted,
      dependants,
      patientGeneralHealthForm,
    ],
  );
  return (
    <PatientContext.Provider value={value}>{children}</PatientContext.Provider>
  );
};
