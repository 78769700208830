/* eslint-disable sonarjs/no-duplicate-string */
import { BackendPomFormQuestion } from '../PomQuestionnaires';
import i18n from 'i18n';
export interface AcneTypesOfSymptoms {
  Whiteheads: boolean;
  Blackheads: boolean;
  'Inflamed spots on surface of skin': boolean;
  'Inflamed spots underneath surface of skin': boolean;
}

export const defaultAcneTypesOfSymptoms: AcneTypesOfSymptoms = {
  Whiteheads: false,
  Blackheads: false,
  'Inflamed spots on surface of skin': false,
  'Inflamed spots underneath surface of skin': false,
};

export interface AcneAffectedAreas {
  Face: boolean;
  Back: boolean;
  Chest: boolean;
  Other: boolean;
}

export const defaultAcneAffectedAreas: AcneAffectedAreas = {
  Face: false,
  Back: false,
  Chest: false,
  Other: false,
};

export interface AcnePomForm {
  conditionId: 1;
  confirmedDiagnosisOfAcneVulgarisByDoctor: string;
  acneDuration: string;
  userAcneSymptoms: AcneTypesOfSymptoms;
  affectedSkinAreas: AcneAffectedAreas;
  affectedSkinAreasDetail: string;
  isTakingAnyMedication: string;
  isTakingAnyMedicationDetail?: string;
  hasBeenReviewedInLast12Months: string;
  hasBeenReviewedInLast12MonthsDetail?: string;
  imageFiles?: File[];
  imageNames: string[];
  medicationDisclaimerConfirm: boolean;
}

export const acnePomFormQuestions: BackendPomFormQuestion[] = [
  {
    fieldName: 'confirmedDiagnosisOfAcneVulgarisByDoctor',
    question: i18n.t('pomForms.acne.questions.1'),
    dataType: 'single select',
  },
  {
    fieldName: 'acneDuration',
    question: i18n.t('pomForms.acne.questions.2'),
    dataType: 'single select',
  },
  {
    fieldName: 'userAcneSymptoms',
    question: i18n.t('pomForms.acne.questions.3'),
    dataType: 'multi select',
  },
  {
    fieldName: 'affectedSkinAreas',
    question: i18n.t('pomForms.acne.questions.4'),
    dataType: 'multi select',
  },
  {
    fieldName: 'affectedSkinAreasDetail',
    question: i18n.t('pomForms.acne.questions.4b'),
    dataType: 'string',
  },
  {
    fieldName: 'isTakingAnyMedication',
    question: i18n.t('pomForms.acne.questions.5'),
    dataType: 'single select',
  },
  {
    fieldName: 'isTakingAnyMedicationDetail',
    question: i18n.t('pomForms.acne.questions.5b'),
    dataType: 'string',
  },
  {
    fieldName: 'hasBeenReviewedInLast12Months',
    question: i18n.t('pomForms.acne.questions.6'),
    dataType: 'single select',
  },
  {
    fieldName: 'hasBeenReviewedInLast12MonthsDetail',
    question: i18n.t('pomForms.acne.questions.6b'),
    dataType: 'string',
  },
  {
    fieldName: 'imageFiles',
    question: i18n.t('pomForms.acne.questions.7'),
    dataType: 'image',
  },
  {
    fieldName: 'medicationDisclaimerConfirm',
    question: i18n.t('pomForms.acne.questions.8'),
    dataType: 'single select',
  },
];

export const getAcnePayload = (formAnswers: AcnePomForm) => [
  {
    ...acnePomFormQuestions[0],
    answer: formAnswers.confirmedDiagnosisOfAcneVulgarisByDoctor,
  },
  {
    ...acnePomFormQuestions[1],
    answer: formAnswers.acneDuration,
  },
  {
    ...acnePomFormQuestions[2],
    answer: JSON.stringify(
      (
        Object.keys(formAnswers.userAcneSymptoms) as Array<
          keyof AcneTypesOfSymptoms
        >
      ).filter((key) => formAnswers.userAcneSymptoms[key]),
    ),
  },
  {
    ...acnePomFormQuestions[3],
    answer: JSON.stringify(
      (
        Object.keys(formAnswers.affectedSkinAreas) as Array<
          keyof AcneAffectedAreas
        >
      ).filter((key) => formAnswers.affectedSkinAreas[key]),
    ),
  },
  {
    ...acnePomFormQuestions[4],
    answer: formAnswers.affectedSkinAreasDetail ?? '',
  },
  {
    ...acnePomFormQuestions[5],
    answer: formAnswers.isTakingAnyMedication,
  },
  {
    ...acnePomFormQuestions[6],
    answer: formAnswers.isTakingAnyMedicationDetail ?? '',
  },
  {
    ...acnePomFormQuestions[7],
    answer: formAnswers.hasBeenReviewedInLast12Months,
  },
  {
    ...acnePomFormQuestions[8],
    answer: formAnswers.hasBeenReviewedInLast12MonthsDetail ?? '',
  },
  {
    ...acnePomFormQuestions[9],
    answer: 'images',
  },
  {
    ...acnePomFormQuestions[10],
    answer: String(formAnswers.medicationDisclaimerConfirm),
  },
];
