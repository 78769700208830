/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { usePatientContext } from 'contexts/PatientContext';
import { useToastContext } from 'contexts/ToastContext';
import { Patient, PatientForm } from 'models/Patient';
import { splitDate } from 'utils/transformers';
import { Container, Row, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Label } from 'components/common/Label';
import { InputText } from 'components/common/InputText';
import { RadioGroup } from 'components/common/RadioGroup';
import { CheckBox } from 'components/common/CheckBox';
import { Button } from 'components/common/Button';
import { CountryPhoneCodeSelector } from 'components/CountryPhoneCodeSelector';
import { FormProgressBar } from 'components/FormProgressBar';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { useHistory, useLocation } from 'react-router';
import { FormProgressBarProps } from 'components/FormProgressBar/FormProgressBar';
import { accountPatientDetailsSchema } from 'formSchema/AccountPatientDetails';
import { usePostCodeSearchContext } from 'contexts/PostCodeSearchContext';
import { SelectList } from 'components/common/SelectList';
import { Address } from 'models/Address';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import { routes } from 'routes';

import styles from './AccountPatientDetails.module.scss';

const defaultFormFillOptions = {
  shouldValidate: true,
  shouldDirty: true,
};

type SetValueType = (
  name: string,
  value: string | boolean,
  config?:
    | Partial<{
        shouldValidate: boolean;
        shouldDirty: boolean;
      }>
    | undefined,
) => void;

const populatePatientInfo = (patient: Patient, setValue: SetValueType) => {
  setValue('firstName', patient.firstName, defaultFormFillOptions);
  setValue('lastName', patient.lastName, defaultFormFillOptions);
  setValue(
    'contactNumberCountryCode',
    patient.contactNumber.split(' ')[0],
    defaultFormFillOptions,
  );
  setValue(
    'contactNumber',
    patient.contactNumber.split(' ')[1] || '',
    defaultFormFillOptions,
  );
  setValue('dateDD', splitDate(patient.dateOfBirth)[2], defaultFormFillOptions);
  setValue('dateMM', splitDate(patient.dateOfBirth)[1], defaultFormFillOptions);
  setValue('dateYY', splitDate(patient.dateOfBirth)[0], defaultFormFillOptions);
  patient.nhsNumber &&
    setValue('nhsNumber', patient.nhsNumber, defaultFormFillOptions);
  setValue('contactMethod', patient.contactMethod.toLowerCase(), {
    shouldDirty: true,
  });
  setValue('sendMarketing', patient.sendMarketing, { shouldDirty: true });
};

const populateAddressInfo = (address: Address, setValue: SetValueType) => {
  setValue('addressLine1', address.line1, defaultFormFillOptions);
  setValue('addressLine2', address.line2 ?? '', defaultFormFillOptions);
  setValue('addressLine3', address.line3 ?? '', defaultFormFillOptions);
  setValue('addressTownCity', address.townCity, defaultFormFillOptions);
  setValue('addressPostCode', address.postCode, defaultFormFillOptions);
};

interface AccountPatientDetailsProps {
  heading?: string;
  subText?: string;
  submitButton: {
    route: string;
    label: string;
  };
  progressBar?: FormProgressBarProps;
  showNhsNumberField?: boolean;
}

export const AccountPatientDetails = ({
  submitButton,
  progressBar,
  heading,
  subText,
  showNhsNumberField = false,
}: AccountPatientDetailsProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const { setToast, dismissToast } = useToastContext();
  const { addresses, clearAddresses, getAddresses } =
    usePostCodeSearchContext();
  const {
    savePatient,
    patient,
    patientAddress,
    getPatientAddress,
    getPatient,
  } = usePatientContext();
  const [addressMode, setAddressMode] = useState<'search' | 'manual'>('search');
  const [postcode, setPostcode] = useState('');
  const [invalidPostcode, setInvalidPostcode] = useState(false);

  const {
    control,
    handleSubmit,
    getValues,
    errors,
    watch,
    setValue,
    formState,
  } = useForm({
    resolver: yupResolver(accountPatientDetailsSchema(showNhsNumberField)),
  });

  const addressWatch = watch('addressSelected');
  const dateWatch = watch(['dateDD', 'dateMM', 'dateYY']);

  useEffect(() => {
    getPatient();
    setValue('contactNumberCountryCode', '+44', defaultFormFillOptions);
  }, []);

  useEffect(() => {
    if (patient) {
      getPatientAddress();
      populatePatientInfo(patient, setValue);
    }
  }, [patient]);

  useEffect(() => {
    if (patientAddress) {
      setAddressMode('manual');
      populateAddressInfo(patientAddress, setValue);
    }
  }, [patientAddress]);

  useEffect(() => {
    const dateValues = getValues(['dateDD', 'dateMM', 'dateYY']);
    setValue(
      'dateOfBirth',
      `${dateValues.dateDD}/${dateValues.dateMM}/${dateValues.dateYY}`,
      {
        shouldDirty: true,
        shouldValidate: formState.isSubmitted,
      },
    );
  }, [dateWatch]);

  useEffect(() => {
    if (addressWatch) {
      const addressPayload = addresses?.find(
        (address) => address.value === addressWatch,
      );

      if (addressPayload?.address) {
        const options = {
          shouldDirty: true,
          shouldValidate: formState.isSubmitted,
        };
        setValue('addressLine1', addressPayload.address.line1, options);
        setValue('addressLine2', addressPayload.address.line2, options);
        setValue('addressLine3', addressPayload.address.line3, options);
        setValue('addressTownCity', addressPayload.address.townCity, options);
        setValue(
          'addressPostCode',
          addressPayload.address.postCode.toUpperCase(),
          options,
        );
      }
    }
  }, [addressWatch]);

  const isPostcodeValid = (postcodeToCheck: string): boolean => {
    const ukPostcodeRegex = /^[A-Za-z]{1,2}\d{1,2}[A-Za-z]?\s?\d[A-Za-z]{2}$/;
    return ukPostcodeRegex.test(postcodeToCheck);
  };

  const handlePostcodeSearch = () => {
    if (isPostcodeValid(postcode)) {
      setInvalidPostcode(false);
      clearAddresses();
      getAddresses(postcode);
    } else {
      setInvalidPostcode(true);
    }
  };

  const handlePostcodeInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPostcode(e.target.value);
  };

  const resetAddressFields = () => {
    const options = {
      shouldDirty: true,
    };
    setPostcode('');
    setInvalidPostcode(false);
    setValue('addressLine1', '', options);
    setValue('addressLine2', '', options);
    setValue('addressLine3', '', options);
    setValue('addressTownCity', '', options);
    setValue('addressPostCode', '', options);
  };

  const onSubmit = async (data: PatientForm) => {
    dismissToast();
    const success = await savePatient(data);
    success && history.push(submitButton.route);
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('Account.contactDetails.formErrors.validationErrorsTitle'),
      description: t(
        'Account.contactDetails.formErrors.validationErrorsDescription',
      ),
    });
  };

  const countryPhoneCode = watch('contactNumberCountryCode');
  const dateFieldClass = 'account-patient-details__dateInput';

  const addressErrors =
    !!errors.addressLine1 ||
    !!errors.addressTownCity ||
    !!errors.addressPostCode;

  const breadcrumbs: Crumb[] = [
    { route: routes.ACCOUNT.BASE, text: t('NavBarLabels.account') },
    {
      route: routes.ACCOUNT.EDIT.CONTACT_DETAILS,
      text: t('Account.landing.editContactDetails'),
    },
  ];

  return (
    <Container className={styles['account-patient-details']}>
      {pathname.match(routes.ACCOUNT.EDIT.CONTACT_DETAILS) && (
        <Breadcrumbs links={breadcrumbs} />
      )}
      <Row>
        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          {progressBar && (
            <div
              className={styles['account-patient-details__progress-container']}
            >
              <FormProgressBar
                numberOfStages={progressBar.numberOfStages}
                currentStage={progressBar.currentStage}
              />
            </div>
          )}

          {heading && <Heading>{heading}</Heading>}
          {subText && <Text>{subText}</Text>}

          <form
            className={styles['account-patient-details__form']}
            onSubmit={handleSubmit(onSubmit, invalid)}
          >
            <Controller
              control={control}
              defaultValue=""
              name="firstName"
              isRequired
              render={(props, fieldState) => (
                <InputText
                  testId="formFirstName"
                  variant={fieldState.invalid ? 'negative' : 'accent'}
                  validationError={errors.firstName?.message}
                  label={t('Account.contactDetails.formLabels.firstName')}
                  {...props}
                />
              )}
            />

            <Controller
              control={control}
              defaultValue=""
              name="lastName"
              render={(props, fieldState) => (
                <InputText
                  testId="formLastName"
                  variant={fieldState.invalid ? 'negative' : 'accent'}
                  validationError={errors.lastName?.message}
                  label={t('Account.contactDetails.formLabels.lastName')}
                  {...props}
                />
              )}
            />

            <Text>{t('Account.contactDetails.formLabels.phoneInfo')}</Text>

            <div
              className={
                styles['account-patient-details__contact-number__container']
              }
            >
              <Controller
                control={control}
                name="contactNumberCountryCode"
                defaultValue="+44"
                render={(props, fieldState) => (
                  <CountryPhoneCodeSelector
                    testId="formContactCountryCode"
                    label={t(
                      'Account.contactDetails.formLabels.contactNumberCountryCode',
                    )}
                    selected={countryPhoneCode}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.contactNumberCountryCode?.message}
                    className={
                      styles[
                        'account-patient-details__contact-number__country-code'
                      ]
                    }
                    {...props}
                  />
                )}
              />

              <Controller
                control={control}
                defaultValue=""
                name="contactNumber"
                render={(props, fieldState) => (
                  <InputText
                    testId="formContact"
                    maxLength={13}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.contactNumber?.message}
                    label={t('Account.contactDetails.formLabels.contactNumber')}
                    className={
                      styles['account-patient-details__contact-number__number']
                    }
                    {...props}
                  />
                )}
              />
            </div>

            <Label
              size="lg"
              className={cx({
                [styles['account-patient-details__text-validation']]:
                  errors.dateOfBirth?.message.length > 0,
              })}
            >
              {t('Account.contactDetails.formLabels.dateOfBirthFull')}
            </Label>
            {errors.dateOfBirth?.message && (
              <span
                className={styles['account-patient-details__text-validation']}
              >
                {errors.dateOfBirth?.message}
              </span>
            )}

            <Controller
              control={control}
              defaultValue=""
              name="dateOfBirth"
              render={(props) => (
                <input
                  className={styles[`${dateFieldClass}`]}
                  {...props}
                  type="hidden"
                />
              )}
            />

            <div className={styles['account-patient-details__dateFields']}>
              <Controller
                control={control}
                defaultValue=""
                name="dateDD"
                render={(props) => (
                  <InputText
                    {...props}
                    testId="formDateDD"
                    maxLength={2}
                    variant={
                      errors.dateOfBirth?.message.length > 0
                        ? 'negative'
                        : 'accent'
                    }
                    className={styles[`${dateFieldClass}`]}
                    label={t(
                      'Account.contactDetails.formLabels.dateOfBirthDay',
                    )}
                    onBlur={() =>
                      dateWatch.dateDD.length === 1 &&
                      setValue('dateDD', `0${dateWatch.dateDD}`)
                    }
                  />
                )}
              />

              <Controller
                control={control}
                defaultValue=""
                name="dateMM"
                render={(props) => (
                  <InputText
                    {...props}
                    testId="formDateMM"
                    maxLength={2}
                    variant={
                      errors.dateOfBirth?.message.length > 0
                        ? 'negative'
                        : 'accent'
                    }
                    className={styles[`${dateFieldClass}`]}
                    onBlur={() =>
                      dateWatch.dateMM.length === 1 &&
                      setValue('dateMM', `0${dateWatch.dateMM}`)
                    }
                    label={t(
                      'Account.contactDetails.formLabels.dateOfBirthMonth',
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                defaultValue=""
                name="dateYY"
                render={(props) => (
                  <InputText
                    maxLength={4}
                    variant={
                      errors.dateOfBirth?.message.length > 0
                        ? 'negative'
                        : 'accent'
                    }
                    className={styles[`${dateFieldClass}`]}
                    label={t(
                      'Account.contactDetails.formLabels.dateOfBirthYear',
                    )}
                    {...props}
                  />
                )}
              />
            </div>

            {/* Manual Address Entry */}
            <div
              className={cx(
                styles['account-patient-details__address-search'],
                addressMode !== 'manual' &&
                  styles['account-patient-details__address-search--hidden'],
              )}
            >
              <Controller
                control={control}
                defaultValue=""
                name="addressLine1"
                isRequired
                render={(props, fieldState) => (
                  <InputText
                    testId="addressFormLine1"
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.addressLine1?.message}
                    label={t('Account.contactDetails.formLabels.addressLine1')}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                name="addressLine2"
                isRequired
                render={(props, fieldState) => (
                  <InputText
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.addressLine2?.message}
                    label={t('Account.contactDetails.formLabels.addressLine2')}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                name="addressLine3"
                isRequired
                render={(props, fieldState) => (
                  <InputText
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.line3?.message}
                    label={t('Account.contactDetails.formLabels.addressLine3')}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                name="addressTownCity"
                isRequired
                render={(props, fieldState) => (
                  <InputText
                    testId="addressFormTownCity"
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.addressTownCity?.message}
                    label={t(
                      'Account.contactDetails.formLabels.addressTownCity',
                    )}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                name="addressPostCode"
                isRequired
                render={(props, fieldState) => (
                  <InputText
                    testId="addressFormPostCode"
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.addressPostCode?.message}
                    label={t(
                      'Account.contactDetails.formLabels.addressPostCode',
                    )}
                    {...props}
                  />
                )}
              />
              <Button
                testId="switch-button-search"
                appearance="ghost"
                className={
                  styles['account-patient-details__switch-address-mode-button']
                }
                label={t('Account.contactDetails.formLabels.searchSwitch')}
                onClick={() => {
                  resetAddressFields();
                  setAddressMode('search');
                }}
              ></Button>
            </div>

            {/* Postcode Search Address Entry */}
            <div
              className={cx(
                styles['account-patient-details__address-search'],
                addressMode !== 'search' &&
                  styles['account-patient-details__address-search--hidden'],
              )}
            >
              <div
                className={
                  styles['account-patient-details__postcode-search__container']
                }
              >
                <InputText
                  label={t('Account.contactDetails.formLabels.address')}
                  name="postcodeSearchInput"
                  className={
                    styles['account-patient-details__postcode-search__input']
                  }
                  variant={
                    invalidPostcode || addressErrors ? 'negative' : 'accent'
                  }
                  validationError={
                    invalidPostcode
                      ? t('Account.contactDetails.formErrors.postCode')
                      : addressErrors
                      ? t('Account.contactDetails.formErrors.requiredAddress')
                      : undefined
                  }
                  placeholder={t(
                    'Account.contactDetails.formLabels.postcodePlaceholder',
                  )}
                  onChange={(e) => {
                    setInvalidPostcode(false);
                    handlePostcodeInputChange(e);
                  }}
                  value={postcode}
                />
                <Button
                  size="lg"
                  className={
                    styles[
                      'account-patient-details__postcode-search__search-button'
                    ]
                  }
                  data-testid="postcode-search-button"
                  appearance="flat"
                  width="full"
                  label={t(
                    'Account.contactDetails.formLabels.searchForPostcode',
                  )}
                  onClick={() => handlePostcodeSearch()}
                />
              </div>
              {addresses && (
                <Controller
                  control={control}
                  defaultValue=""
                  name="addressSelected"
                  isRequired
                  render={(props) => (
                    <SelectList
                      id="addressSelected"
                      className={
                        styles['account-patient-details__address-search__list']
                      }
                      listValues={
                        addressWatch ? [addresses[addressWatch]] : addresses
                      }
                      {...props}
                    />
                  )}
                />
              )}
              {addresses?.length === 0 && (
                <Text
                  className={
                    styles[
                      'account-patient-details__address-search__no-results'
                    ]
                  }
                >
                  {t('Account.contactDetails.formErrors.noAddresses')}
                </Text>
              )}
              <Button
                testId="switch-button-edit"
                appearance="ghost"
                className={
                  styles['account-patient-details__switch-address-mode-button']
                }
                label={t('Account.contactDetails.formLabels.manualSwitch')}
                onClick={() => {
                  clearAddresses();
                  setAddressMode('manual');
                }}
              />
            </div>

            <Controller
              control={control}
              defaultValue=""
              name="nhsNumber"
              render={(props, fieldState) => (
                <InputText
                  testId="formNHS"
                  maxLength={10}
                  variant={fieldState.invalid ? 'negative' : 'accent'}
                  validationError={errors.nhsNumber?.message}
                  label={t('Account.contactDetails.formLabels.nhsNumber')}
                  caption={t('Account.contactDetails.formLabels.nhsReason')}
                  className={cx(
                    !showNhsNumberField &&
                      styles['account-patient-details__nhs-number--hidden'],
                  )}
                  {...props}
                />
              )}
            />

            <Controller
              control={control}
              defaultValue="email"
              name="contactMethod"
              render={(props) => (
                <>
                  <Label id="contactMethodLabel" size="lg">
                    {t('Account.contactDetails.formLabels.updates')}
                  </Label>
                  <RadioGroup
                    id="contactMethod"
                    labelBy="contactMethodLabel"
                    values={[
                      {
                        value: 'email',
                        label: t('Account.contactDetails.formLabels.email'),
                      },
                      {
                        value: 'sms',
                        label: t('Account.contactDetails.formLabels.sms'),
                      },
                    ]}
                    size="md"
                    orientation="vertical"
                    {...props}
                  />
                </>
              )}
            />

            <Controller
              control={control}
              defaultValue={false}
              name="sendMarketing"
              render={(props) => (
                <>
                  <Label size="lg">
                    {t('Account.contactDetails.formLabels.optInTitle')}
                  </Label>
                  <CheckBox
                    label={t('Account.contactDetails.formLabels.optInCheckBox')}
                    size="md"
                    orientation="vertical"
                    {...props}
                  />
                </>
              )}
            />

            <AppBarBottom fullWidth={true}>
              <Button
                width="full"
                className={styles['account-patient-details__submit-button']}
                testId="submit-button"
                type="submit"
                appearance="solid"
                label={submitButton.label}
              ></Button>
            </AppBarBottom>
          </form>
        </Col>
      </Row>
    </Container>
  );
};
