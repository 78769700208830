import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppBarTop } from 'components/Layout/AppBarTop';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { RadioGroup } from 'components/common/RadioGroup';
import { Label } from 'components/common/Label';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { Button } from 'components/common/Button';
import { CheckBox } from 'components/common/CheckBox';
import { InputText } from 'components/common/InputText';
import { ValidationError } from 'components/common/ValidationError';
import { Textarea } from 'components/common/Textarea';
import { pMedQuestionnaireSchema } from 'formSchema/PMedQuestionnaire';
import { PMedQuestionnaire as PMedQuestionnaireType } from 'models/PMedQuestionnaire';
import { useBasketContext } from 'contexts/BasketContext';

import { routes } from 'routes';
import styles from './PMedQuestionnaire.module.scss';

export const PMedQuestionnaire = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { basket, handleSavePmedFormToBasket } = useBasketContext();
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(pMedQuestionnaireSchema()),
  });
  const [productName, setProductName] = useState('');
  const [formAnswers, setFormAnswers] = useState<
    PMedQuestionnaireType | undefined
  >();

  useEffect(() => {
    setProductName(
      basket.shopProducts.find((line) => line.id === id)?.name ?? '',
    );
    setFormAnswers(
      basket.pmedForms.find((form) => form.productId === parseInt(id))
        ?.formAnswers,
    );
  }, [basket.shopProducts]);

  useEffect(() => {
    if (formAnswers) {
      reset({
        ...formAnswers,
        isUserPregnant: formAnswers.isUserPregnant.toString(),
        isUserBreastFeeding: formAnswers.isUserBreastFeeding.toString(),
      });
    }
  }, [formAnswers]);

  const onSubmit = async (payload: PMedQuestionnaireType) => {
    const productId = basket.shopProducts.find((line) => line.id === id)?.id;
    productId && handleSavePmedFormToBasket(parseInt(productId), payload);
    history.goBack();
  };

  return (
    <div className={styles['pmed-questionnaire']}>
      <Container>
        <AppBarTop
          title={t('OrderFlow.related.title')}
          confirmClose={{
            route: routes.SHOP.BASE,
            CTA: t('OrderFlow.confirmCloseModalCTA'),
            text: t('OrderFlow.confirmCloseModalTitle'),
          }}
          backRoute={{
            to: routes.ORDER.PMED,
            text: t('OrderFlow.ProductConditions.back'),
          }}
        />
        <Row>
          <Col sm="12" md={{ size: '6', offset: '3' }}>
            <Heading className={styles['pmed-questionnaire__title']} tag="h3">
              {productName}
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="whoWillUseIt"
                control={control}
                render={(props) => (
                  <>
                    <Label
                      id="whoWillUseItLabel"
                      error={!!errors.whoWillUseIt?.message}
                      size="lg"
                    >
                      {t('OrderFlow.pMedQuestionnaire.formLabels.whoWillUseIt')}
                    </Label>
                    {errors.whoWillUseIt?.message && (
                      <ValidationError id="whoWillUseIt">
                        {errors.whoWillUseIt.message}
                      </ValidationError>
                    )}
                    <RadioGroup
                      id="whoWillUseIt"
                      labelBy="whoWillUseItLabel"
                      values={[
                        {
                          value: 'Myself',
                          label: t(
                            'OrderFlow.pMedQuestionnaire.formLabels.myself',
                          ),
                        },
                        {
                          value: 'SomeoneElse',
                          label: t(
                            'OrderFlow.pMedQuestionnaire.formLabels.someoneElse',
                          ),
                        },
                      ]}
                      orientation="vertical"
                      {...props}
                    />
                  </>
                )}
              />
              <Controller
                name="isUserPregnant"
                control={control}
                defaultValue={null}
                render={(props) => (
                  <>
                    <Label
                      id="isUserPregnantLabel"
                      error={!!errors.isUserPregnant?.message}
                      size="lg"
                    >
                      {t(
                        'OrderFlow.pMedQuestionnaire.formLabels.isUserPregnant',
                      )}
                    </Label>
                    {errors.isUserPregnant?.message && (
                      <ValidationError id="isUserPregnant">
                        {errors.isUserPregnant.message}
                      </ValidationError>
                    )}
                    <RadioGroup
                      id="isUserPregnant"
                      labelBy="isUserPregnantLabel"
                      values={[
                        {
                          value: 'true',
                          label: t(
                            'OrderFlow.pMedQuestionnaire.formLabels.yes',
                          ),
                        },
                        {
                          value: 'false',
                          label: t('OrderFlow.pMedQuestionnaire.formLabels.no'),
                        },
                      ]}
                      orientation="vertical"
                      {...props}
                    />
                  </>
                )}
              />
              <Controller
                name="isUserBreastFeeding"
                control={control}
                defaultValue={null}
                render={(props) => (
                  <>
                    <Label
                      id="isUserBreastFeedingLabel"
                      error={!!errors.isUserBreastFeeding?.message}
                      size="lg"
                    >
                      {t(
                        'OrderFlow.pMedQuestionnaire.formLabels.isUserBreastFeeding',
                      )}
                    </Label>
                    {errors.isUserBreastFeeding?.message && (
                      <ValidationError id="isUserBreastFeeding">
                        {errors.isUserBreastFeeding.message}
                      </ValidationError>
                    )}
                    <RadioGroup
                      id="isUserBreastFeeding"
                      labelBy="isUserBreastFeedingLabel"
                      values={[
                        {
                          value: 'true',
                          label: t(
                            'OrderFlow.pMedQuestionnaire.formLabels.yes',
                          ),
                        },
                        {
                          value: 'false',
                          label: t('OrderFlow.pMedQuestionnaire.formLabels.no'),
                        },
                      ]}
                      orientation="vertical"
                      {...props}
                    />
                  </>
                )}
              />
              <Controller
                name="userAgeInYears"
                control={control}
                defaultValue={''}
                render={(props, fieldState) => (
                  <InputText
                    label={t(
                      'OrderFlow.pMedQuestionnaire.formLabels.userAgeInYears',
                    )}
                    validationError={errors.userAgeInYears?.message}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    {...props}
                  />
                )}
              />
              <Controller
                name="conditionsSymptomsUsedFor"
                control={control}
                defaultValue={''}
                render={(props, fieldState) => (
                  <InputText
                    label={t(
                      'OrderFlow.pMedQuestionnaire.formLabels.conditionsSymptomsUsedFor',
                    )}
                    validationError={errors.conditionsSymptomsUsedFor?.message}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    {...props}
                  />
                )}
              />
              <Controller
                name="userSymptomsDuration"
                control={control}
                defaultValue={null}
                render={(props) => (
                  <>
                    <Label
                      id="userSymptomsDurationLabel"
                      error={!!errors.userSymptomsDuration?.message}
                      size="lg"
                    >
                      {t(
                        'OrderFlow.pMedQuestionnaire.formLabels.userSymptomsDuration',
                      )}
                    </Label>
                    {errors.userSymptomsDuration?.message && (
                      <ValidationError id="userSymptomsDuration">
                        {errors.userSymptomsDuration.message}
                      </ValidationError>
                    )}
                    <RadioGroup
                      id="userSymptomsDuration"
                      labelBy="userSymptomsDurationLabel"
                      values={[
                        {
                          value: 'LessThanAWeek',
                          label: t(
                            'OrderFlow.pMedQuestionnaire.formLabels.lessThanAWeek',
                          ),
                        },
                        {
                          value: 'OneToThreeWeeks',
                          label: t(
                            'OrderFlow.pMedQuestionnaire.formLabels.1To3Weeks',
                          ),
                        },
                        {
                          value: 'OverThreeWeeks',
                          label: t(
                            'OrderFlow.pMedQuestionnaire.formLabels.over3Weeks',
                          ),
                        },
                      ]}
                      orientation="vertical"
                      {...props}
                    />
                  </>
                )}
              />
              <Controller
                name="userExistingConditions"
                control={control}
                defaultValue={''}
                render={(props, fieldState) => (
                  <InputText
                    label={t(
                      'OrderFlow.pMedQuestionnaire.formLabels.userExistingConditions',
                    )}
                    validationError={errors.userExistingConditions?.message}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    {...props}
                  />
                )}
              />
              <Controller
                name="userOtherMedications"
                control={control}
                defaultValue={''}
                render={(props, fieldState) => (
                  <InputText
                    label={t(
                      'OrderFlow.pMedQuestionnaire.formLabels.userOtherMedications',
                    )}
                    validationError={errors.userOtherMedications?.message}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    {...props}
                  />
                )}
              />
              <Controller
                name="additionalComments"
                control={control}
                defaultValue={''}
                render={(props, fieldState) => (
                  <Textarea
                    label={t(
                      'OrderFlow.pMedQuestionnaire.formLabels.additionalComments',
                    )}
                    validationError={errors.additionalComments?.message}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    {...props}
                  />
                )}
              />
              <Controller
                name="termsConditionsConfirm"
                control={control}
                defaultValue={null}
                render={(props) => (
                  <>
                    <Label
                      inputId="termsConditionsConfirm"
                      error={!!errors.termsConditionsConfirm?.message}
                      size="lg"
                    >
                      {t(
                        'OrderFlow.pMedQuestionnaire.formLabels.termsConditionsConfirm',
                      )}
                    </Label>
                    {errors.termsConditionsConfirm?.message && (
                      <ValidationError id="termsConditionsConfirm">
                        {errors.termsConditionsConfirm.message}
                      </ValidationError>
                    )}
                    <CheckBox
                      label={t(
                        'OrderFlow.pMedQuestionnaire.formLabels.confirm',
                      )}
                      {...props}
                    />
                    <Link
                      className={styles['pmed-questionnaire__link']}
                      to={routes.TERMS}
                      target="_blank"
                    >
                      {t(
                        'OrderFlow.pMedQuestionnaire.formLabels.termsAndConditionsLink',
                      )}
                    </Link>
                  </>
                )}
              />
              <AppBarBottom fullWidth>
                <Button
                  className={styles['pmed-questionnaire__submit-button']}
                  testId="pmed-questionnaire-submit-button"
                  type="submit"
                  appearance="solid"
                  width="full"
                  label={t(
                    'OrderFlow.pMedQuestionnaire.formLabels.submitButton',
                  )}
                />
              </AppBarBottom>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
