import React from 'react';
import classNames from 'classnames';

export type supportedTags = 'div' | 'section' | 'ul' | 'ol';
interface ContainerProps {
  tag?: supportedTags;
  fluid?: boolean;
  className?: string;
  testId?: string;
  children?: React.ReactNode;
}

export const Container = ({
  tag = 'div',
  fluid,
  className,
  children,
  testId,
  ...attributes
}: ContainerProps) => {
  const Component = tag;
  const classes = classNames(className, {
    container: !fluid,
    'container-fluid': fluid,
  });

  return (
    <Component
      {...attributes}
      className={classes}
      data-testid={testId ? testId : null}
    >
      {children}
    </Component>
  );
};
