import cx from 'classnames';
import { useEffect } from 'react';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { useLandingPageContext } from 'contexts/LandingPageContext/LandingPageContext';
import { Image } from 'components/common/Image';
import { ColProps } from 'components/common/grid/Col/Col';

import styles from './BannerSection.module.scss';
import { Text } from 'components/common/Text';

type Variants = 'condensed' | 'no-overflow';

interface BannerSectionProps {
  heading: string;
  subHeading?: string;
  bodyText?: string;
  image?: { src?: string; asset?: string; alt?: string };
  variant?: Variants;
  ctas?: {
    ctaOne?: { label: string; to?: string; onClick?: Function };
    ctaTwo?: { label: string; to?: string; onClick?: Function };
  };
  colProps?: {
    textCol?: ColProps;
    imageCol?: ColProps;
  };
}

export const BannerSection = ({
  heading,
  subHeading,
  bodyText,
  image,
  variant,
  ctas,
  colProps,
}: BannerSectionProps) => {
  const { logCtaClick, logSectionView } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  const ctaOne = ctas?.ctaOne;
  const ctaTwo = ctas?.ctaTwo;

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: 'banner',
      });
    }
  }, [isInViewport]);

  const condensedStyle = variant === 'condensed';
  const noOverflow = variant === 'no-overflow';

  const sectionStyle = cx(
    styles['banner-section'],
    noOverflow && styles['banner-section--no-overflow'],
    condensedStyle && styles['banner-section--condensed'],
    !image && styles['banner-section--no-image'],
  );

  const textColStyle = cx(
    styles['banner-section__text-column'],
    condensedStyle && styles['banner-section__text-column--condensed'],
    !image && styles['banner-section__text-column--no-image'],
  );

  const imageColStyle = cx(
    styles['banner-section__image-column'],
    noOverflow && styles['banner-section__image-column--no-overflow'],
    condensedStyle && styles['banner-section__image-column--condensed'],
    condensedStyle &&
      !subHeading &&
      styles['banner-section__image-column--condensed--no-text'],
  );
  return (
    <div className={sectionStyle} ref={elementRef} data-testid="section">
      <Container
        className={cx(styles['banner-section__container'], {
          [styles['banner-section__container--condensed']]: condensedStyle,
        })}
      >
        <Row>
          {(image?.src || image?.asset) && (
            <Col
              className={imageColStyle}
              xs={12}
              lg={{ size: 6, order: 1, offset: 0 }}
              testId="image-col"
              {...colProps?.imageCol}
            >
              {image.src && <Image imageFileName={image.src} alt={image.alt} />}
              {!image.src && image.asset && (
                <img src={image.asset} alt={image.alt} />
              )}
            </Col>
          )}
          <Col
            className={textColStyle}
            xs={12}
            lg={image ? { size: 6, offset: 0 } : { size: 12, offset: 0 }}
            testId="text-col"
            {...colProps?.textCol}
          >
            <Heading tag="h2">{heading}</Heading>
            {subHeading && (
              <Heading tag="h3" size="sm">
                {subHeading}
              </Heading>
            )}
            {bodyText && <Text size="md">{bodyText}</Text>}
            {(ctaOne || ctaTwo) && (
              <div className={styles['banner-section__ctas-container']}>
                {ctaOne && (
                  <Button
                    label={ctaOne.label}
                    link={ctaOne.to ? { to: ctaOne.to } : undefined}
                    variant="accent"
                    appearance="minimal"
                    size="lg"
                    width="grow"
                    className={styles['banner-section__cta-overrides']}
                    onClick={() => {
                      logCtaClick({
                        label: ctaOne.label,
                        pageName: pageName?.log,
                        section: 'Banner',
                      });
                      ctaOne.onClick?.();
                    }}
                  />
                )}
                {ctaTwo && (
                  <Button
                    label={ctaTwo.label}
                    link={ctaTwo.to ? { to: ctaTwo.to } : undefined}
                    variant="accent"
                    size="lg"
                    width="grow"
                    className={cx(
                      styles['banner-section__cta-overrides'],
                      styles['banner-section__cta-overrides--ctaTwo'],
                    )}
                    onClick={() => {
                      logCtaClick({
                        label: ctaTwo.label,
                        pageName: pageName?.log,
                        section: 'Banner',
                      });
                      ctaTwo.onClick?.();
                    }}
                  />
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
