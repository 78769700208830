import { useEffect, useRef } from 'react';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { Crumb } from 'components/common/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { ProductTileSection } from 'components/ProductTileSection';
import { ConditionDescriptionSection } from 'components/Sections/Shop/ConditionDescriptionSection';
import { useBasketContext } from 'contexts/BasketContext';
import { ConditionBanner } from 'components/Sections/Shop/ConditionBanner/ConditionBanner';

import { routes } from 'routes';

export const ConditionPage = () => {
  const { t } = useTranslation();
  const { logPageView } = useAmplitudeContext();
  const { basket } = useBasketContext();
  const { setPageName, resetPageName } = useLandingPageContext();
  const { condition } = useConditionsContext();
  const descriptionRef = useRef<HTMLDivElement>(null);

  const pageName = {
    log: t('amplitude.conditionPage.pageName', {
      conditionName: condition?.name,
    }),
    display: `${condition?.name}`,
  };

  useEffect(() => {
    if (condition?.name) {
      logPageView({ pageName: pageName.log });
      setPageName(pageName);
    }
    return () => resetPageName();
  }, [condition]);

  if (!condition) {
    return null;
  }

  const breadcrumbs: Crumb[] = [
    { route: routes.SHOP.BASE, text: t('NavBarLabels.shop') },
    {
      route: `${routes.SHOP.CONDITIONGROUP}/${condition.conditionGroupId}`,
      text: condition.conditionGroupName ?? '',
    },
    {
      route: `${routes.SHOP.CONDITION}/${condition.id}`,
      text: condition.name ?? '',
    },
  ];

  const hasPomProducts =
    condition.products.filter((product) => product.isPom).length > 0;

  const hasPomProductsInStock =
    hasPomProducts &&
    condition.products.filter((product) => product.isPom && product.inStock)
      .length > 0;

  const isPomFormInBasket =
    basket.pomForms.findIndex((form) => form.conditionId === condition.id) !==
    -1;

  return (
    <>
      <ConditionBanner
        breadcrumbs={breadcrumbs}
        startConsultationCta={{
          showCta: hasPomProducts,
          startConsulationLink: `${routes.SHOP.CONDITION}/${condition.id}${routes.GENERAL_HEALTH.PT1}`,
          showReview: isPomFormInBasket,
          disableButton: !hasPomProductsInStock,
        }}
        condition={condition}
        descriptionRef={descriptionRef}
      />

      {condition.products && condition.products.length > 0 && (
        <ProductTileSection products={condition.products} showCardsWithShadow />
      )}

      {condition.description && (
        <>
          <div ref={descriptionRef} />
          <ConditionDescriptionSection description={condition.description} />
        </>
      )}
    </>
  );
};
