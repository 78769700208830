import { Switch } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { AccountSelectPharmacy } from 'containers/Account/AccountSelectPharmacy';
import { SelectGPLanding } from 'containers/Account/SelectGPLanding';
import { YourGP } from 'containers/Account/YourGP';
import { PrescriptionExemptionExpire } from 'containers/Prescription/PrescriptionExemptionExpire';
import { PrescriptionExemptionList } from 'containers/Prescription/PrescriptionExemptionList';
import { useExemptionsContext } from 'contexts/ExemptionsContext';
import { SuggestProvider } from 'contexts/SuggestContext/SuggestContext';
import { DependantAccount } from 'pages/Dependant/DependantAccount';
import { useTranslation } from 'react-i18next';
import { AccountPatientDetails } from 'containers/Account/AccountPatientDetails';
import { PostCodeSearchProvider } from 'contexts/PostCodeSearchContext';
import { routes } from 'routes';

export const AccountEdit = () => {
  const { t } = useTranslation();

  const { exemptionDetails } = useExemptionsContext();

  return (
    <Switch>
      <PrivateRoute path={routes.ACCOUNT.EDIT.CONTACT_DETAILS} exact>
        <PostCodeSearchProvider>
          <AccountPatientDetails
            heading={t('Account.contactDetails.editTitle')}
            subText={t('Account.contactDetails.intro')}
            submitButton={{
              label: t('Account.contactDetails.formLabels.editSubmit'),
              route: routes.ACCOUNT.BASE,
            }}
            showNhsNumberField
          />
        </PostCodeSearchProvider>
      </PrivateRoute>

      <PrivateRoute path={routes.ACCOUNT.EDIT.PHARMACY} exact>
        <AccountSelectPharmacy
          heading={t('Account.pharmacies.title')}
          bodyText={t('Account.pharmacies.content')}
          submitButton={{
            label: t('Account.pharmacies.save'),
            route: routes.ACCOUNT.BASE,
          }}
        />
      </PrivateRoute>

      <PrivateRoute path={routes.ACCOUNT.EDIT.VIEW_GP} exact>
        <YourGP
          heading={t('Account.yourGP.title')}
          selectGpRoute={routes.ACCOUNT.EDIT.SELECT_GP}
          submitButton={{
            route: routes.ACCOUNT.BASE,
            label: t('Account.yourGP.doneButton'),
          }}
        />
      </PrivateRoute>

      <PrivateRoute path={routes.ACCOUNT.EDIT.SELECT_GP} exact>
        <SuggestProvider>
          <SelectGPLanding
            heading={t('Account.yourGP.changeYourGPTitle')}
            submitButton={{
              label: t('Account.yourGP.saveNewGP'),
              route: routes.ACCOUNT.BASE,
            }}
          />
        </SuggestProvider>
      </PrivateRoute>

      <PrivateRoute path={routes.ACCOUNT.EDIT.EXEMPTION} exact>
        <PrescriptionExemptionList
          exemptionExpireRoute={routes.ACCOUNT.EDIT.EXEMPTION_EXPIRE}
          exemptionNoExpireRoute={routes.ACCOUNT.BASE}
          heading={
            exemptionDetails?.exemptionReasonId
              ? t('Account.exemption.title')
              : t('PrescriptionFlow.exemptionReason.title')
          }
          showRemove
        />
      </PrivateRoute>

      <PrivateRoute path={routes.ACCOUNT.EDIT.EXEMPTION_EXPIRE} exact>
        <PrescriptionExemptionExpire
          heading={t('PrescriptionFlow.exemptionExpiry.title')}
          selectExemptionRoute={routes.ACCOUNT.EDIT.EXEMPTION}
          submitButton={{
            label: t('Account.exemption.saveExemption'),
            route: routes.ACCOUNT.BASE,
          }}
        />
      </PrivateRoute>

      <PrivateRoute path={`${routes.DEPENDANT.ACCOUNT.BASE}:dependantId`}>
        <DependantAccount />
      </PrivateRoute>
    </Switch>
  );
};
