import React from 'react';
import cx from 'classnames';

import styles from './FormProgressBar.module.scss';

export interface FormProgressBarProps {
  numberOfStages: number;
  currentStage: number;
  variant?: 'accent' | 'ui';
}

export const FormProgressBar: React.FC<FormProgressBarProps> = ({
  numberOfStages,
  currentStage,
  variant = 'ui',
}) => {
  const createElements = (n: number) => {
    const elements = [];
    for (let i = 0; i < n; i++) {
      elements.push(
        <li
          key={i}
          className={cx(styles['form-progress-bar__indicator'], {
            [styles['form-progress-bar__indicator--active']]:
              i + 1 === currentStage,
            [styles['form-progress-bar__indicator--done']]:
              i + 1 < currentStage,
          })}
        >
          {i + 1}
        </li>,
      );
    }
    return elements;
  };
  return (
    <div
      className={cx(
        styles['form-progress-bar'],
        styles[`form-progress-bar--${variant}`],
      )}
    >
      <span className="sr-only">
        step {currentStage} of {numberOfStages}
      </span>
      <ul>{createElements(numberOfStages)}</ul>
    </div>
  );
};
