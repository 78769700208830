import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { HelpArticleType } from 'models/HelpArticle';
import { Button } from 'components/common/Button';
import { ReactComponent as LinkToIcon } from 'assets/icons/LinkTo.svg';
import styles from './HelpArticle.module.scss';

interface HelpArticleProps {
  article: HelpArticleType;
}

export const HelpArticle: React.FC<HelpArticleProps> = ({ article }) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid={article.id}
      id={article.id}
      className={styles['help-article__article']}
    >
      <Heading
        tag="h5"
        size="sm"
        className={styles['help-article__article-title']}
      >
        {t(article.title)}
      </Heading>
      {article.paragraphs.map((p) => {
        return (
          <Text
            key={p.paragraph}
            size="lg"
            className={styles['help-article__text']}
          >
            {t(p.paragraph, p.options)}
          </Text>
        );
      })}
      {article.link && (
        <Button
          key={article.link.url}
          label={t(article.link.label)}
          link={{
            to: t(article.link.url),
            rel: 'noopener noreferrer',
            target: '_blank',
          }}
          icon={{ element: LinkToIcon, alignment: 'right' }}
          className={styles['help-article__link']}
        />
      )}
    </div>
  );
};
