import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { sanitize } from 'dompurify';
import { getWeeks, formatDateString } from 'utils/transformers';
import { usePrescriptionContext } from 'contexts/PrescriptionContext';
import { useModalContext } from 'contexts/ModalContext';
import { useDependantContext } from 'contexts/DependantContext';
import { Container, Row, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { IconInfoSpan } from 'components/common/IconInfoSpan';
import { ReactComponent as Bell } from 'assets/icons/lawsat/Bell.svg';
import { ReactComponent as Clock } from 'assets/icons/lawsat/Clock.svg';
import { Button } from 'components/common/Button';
import { routes } from 'routes';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';

import styles from './PrescriptionDetail.module.scss';

interface PrescriptionDetailProps {
  isDependant: boolean;
}

export const PrescriptionDetail = ({
  isDependant,
}: PrescriptionDetailProps) => {
  const [duration, setDuration] = React.useState<string>('not set');
  const { t } = useTranslation();
  const { id, dependantId } = useParams<{ id: string; dependantId: string }>();
  const {
    prescriptionDetail,
    getPatientPrescriptionDetail,
    removePatientPrescription,
    getPatientPrescriptions,
    setPrescriptionDetail,
  } = usePrescriptionContext();
  const {
    getDependantPrescription,
    dependantPrescription,
    removeDependantPrescriptionItem,
    getDependantPrescriptions,
    setDependantPrescription,
  } = useDependantContext();
  const { open, close } = useModalContext();

  const loadData = async () => {
    if (isDependant) {
      getDependantPrescription(Number(dependantId), id);
    } else {
      await getPatientPrescriptionDetail(id);
    }
  };

  const resetPrescriptionDetail = () => {
    if (isDependant) {
      setDependantPrescription(null);
    } else {
      setPrescriptionDetail(null);
    }
  };

  React.useEffect(() => {
    loadData();

    return () => resetPrescriptionDetail();
  }, []);

  const itemDetail = dependantId ? dependantPrescription : prescriptionDetail;

  React.useEffect(() => {
    let string = t('PrescriptionFlow.detail.noReminder');

    if (itemDetail?.reminderFrequencyNumberOfDays) {
      string =
        getWeeks(itemDetail?.reminderFrequencyNumberOfDays) <= 2
          ? t('PrescriptionFlow.List.weekWithCount', {
              count: getWeeks(itemDetail?.reminderFrequencyNumberOfDays),
            })
          : t('PrescriptionFlow.List.monthWithCount', {
              count: getWeeks(itemDetail?.reminderFrequencyNumberOfDays) / 4,
            });
    }
    setDuration(string);
  }, [itemDetail]);

  const breadcrumbs: Crumb[] = [
    {
      route: isDependant
        ? `${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}`
        : routes.PRESCRIPTION.BASE,
      text: `Back to ${t('NavBarLabels.prescriptions')}`,
    },
  ];

  const handleClick = async () => {
    if (isDependant) {
      await removeDependantPrescriptionItem(
        dependantId,
        itemDetail?.medicinalProductId,
      );
      await getDependantPrescriptions(dependantId);
    } else {
      await removePatientPrescription(itemDetail?.medicinalProductId);
      await getPatientPrescriptions();
    }
  };

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      {itemDetail && (
        <Container className={styles['prescription-detail']}>
          <Row>
            <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
              <Heading
                tag="h3"
                size="lg"
                className={styles['prescription-detail__title']}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(itemDetail?.medicinalProductName),
                  }}
                />
              </Heading>
              <div className={styles['prescription-detail__card']}>
                <Heading tag="h3" size="sm">
                  {t('PrescriptionFlow.detail.reorderTitle')}
                </Heading>

                <IconInfoSpan icon={Bell} text={duration} />
                {!itemDetail.reminderFrequencyNumberOfDays && (
                  <Button
                    className={styles['prescription-detail__card-button']}
                    label={t('PrescriptionFlow.detail.reminderButton')}
                    appearance="flat"
                    link={{
                      to: isDependant
                        ? `${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.REMINDER}/${itemDetail?.medicinalProductId}`
                        : `${routes.PRESCRIPTION.REMINDER}/${itemDetail?.medicinalProductId}`,
                    }}
                  />
                )}
              </div>
              <div className={styles['prescription-detail__card']}>
                <Heading tag="h3" size="sm">
                  {t('PrescriptionFlow.detail.lastOrderTitle')}
                </Heading>
                <IconInfoSpan
                  icon={Clock}
                  text={
                    itemDetail?.lastOrderedDateTime
                      ? t('PrescriptionFlow.List.lastOrder', {
                          time: formatDateString(
                            itemDetail?.lastOrderedDateTime,
                          ),
                        })
                      : t('PrescriptionFlow.List.noOrders')
                  }
                />
              </div>
              <hr className={styles['prescription-detail__hr']} />
              <Button
                testId="prescription-delete-button"
                className={styles['prescription-detail__delete']}
                label={t('PrescriptionFlow.detail.deleteButton')}
                variant="negative"
                appearance="solid"
                onClick={() =>
                  open(
                    <>
                      <Heading tag="h3" size="lg">
                        {t('PrescriptionFlow.detail.modalTitle')}
                      </Heading>
                      <Text
                        size="lg"
                        className={styles['prescription-detail__modal-text']}
                      >
                        {t('PrescriptionFlow.detail.modalText')}
                      </Text>
                      <Button
                        testId="prescription-delete-confirm"
                        className={styles['prescription-detail__modal-button']}
                        label={t('PrescriptionFlow.detail.deleteButton')}
                        variant="negative"
                        appearance="solid"
                        onClick={async () => {
                          handleClick();
                          close();
                        }}
                      />
                      <Button
                        testId="prescription-modal-close"
                        className={styles['prescription-detail__modal-button']}
                        label={t('PrescriptionFlow.detail.modalCancel')}
                        variant="ui"
                        appearance="flat"
                        width="full"
                        onClick={() => {
                          close();
                        }}
                      />
                    </>,
                  )
                }
              />
              <Text
                size="sm"
                className={styles['prescription-detail__info-text']}
              >
                <Trans i18nKey="PrescriptionFlow.detail.deleteInfo"></Trans>
              </Text>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
