import React from 'react';
import * as msal from '@azure/msal-browser';
import { msalConfig, silentRequest } from 'api/utils/AzureConfig';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Client } from 'api/utils';
import { clearSessionStorage, useAuthContext } from 'contexts/AuthContext';
import { useToastContext } from 'contexts/ToastContext';
import { routes } from 'routes';

export const AxiosInterceptors = () => {
  const pc = new msal.PublicClientApplication(msalConfig);
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast } = useToastContext();
  const { authStatus, setAuthFlags } = useAuthContext();

  React.useEffect(() => {
    Client.interceptors.response.use(
      (response) => response,
      (err) => {
        if (authStatus && err?.response?.status === 401) {
          setAuthFlags(false);
          clearSessionStorage();
          history.push(routes.BASE);
          setToast({
            status: 'error',
            title: t('common.error.authErrorMessage'),
          });
        }
        return Promise.reject(err);
      },
    );

    Client.interceptors.request.use(async (config) => {
      try {
        if (!config?.params?.bypassAuth) {
          const currentAccounts = pc.getAllAccounts();
          currentAccounts
            ? (silentRequest.account = currentAccounts[0])
            : console.error('Account not found');

          const tokenRequest = await pc.acquireTokenSilent(silentRequest);
          tokenRequest.accessToken &&
            (config.headers.Authorization = `Bearer ${tokenRequest.accessToken}`);
        }
      } catch (e) {
        setAuthFlags(false);
        clearSessionStorage();
        history.push(routes.BASE);
        setToast({
          status: 'error',
          title: t('common.error.authErrorMessage'),
        });
      }
      return config;
    });
  }, []);

  return <></>;
};
