import React, { useState } from 'react';
import { useDependantContext } from 'contexts/DependantContext';
import { Container, Row, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { FormProgressBar } from 'components/FormProgressBar';
import { DependantAddress } from 'containers/Dependant/DependantAddress';
import { DependantAddressSearch } from 'containers/Dependant/DependantAddressSearch';
import { FormProgressBarProps } from 'components/FormProgressBar/FormProgressBar';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import { routes } from 'routes';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import styles from './DependantAddressContainer.module.scss';

interface DependantAddressContainerProps {
  heading: string;
  progressBar?: FormProgressBarProps;
  submitButton: {
    route: string;
    label: string;
  };
}

export const DependantAddressContainer = ({
  heading,
  submitButton,
  progressBar,
}: DependantAddressContainerProps) => {
  const { dependantAddress, dependant } = useDependantContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [addressPageState, setAddressPageState] = useState<'search' | 'manual'>(
    dependantAddress ? 'manual' : 'search',
  );

  const breadcrumbs: Crumb[] = [
    { route: routes.ACCOUNT.BASE, text: t('NavBarLabels.account') },
    {
      route: `${routes.DEPENDANT.ACCOUNT.BASE}${dependant?.id}`,
      text: t('Account.dependantLanding.title', {
        dependantName: dependant?.firstName,
      }),
    },
  ];

  return (
    <Container>
      {pathname.match(routes.DEPENDANT.ACCOUNT.EDIT.ADDRESS) && (
        <Breadcrumbs links={breadcrumbs} />
      )}
      <Row>
        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          {progressBar && (
            <div
              className={
                styles['dependant-address-container__progress-container']
              }
            >
              <FormProgressBar
                numberOfStages={progressBar.numberOfStages}
                currentStage={progressBar.currentStage}
              />
            </div>
          )}
          <Heading>{heading}</Heading>
          {addressPageState === 'manual' && (
            <DependantAddress
              setAddressPageState={setAddressPageState}
              submitButton={submitButton}
            />
          )}
          {addressPageState === 'search' && (
            <DependantAddressSearch
              setAddressPageState={setAddressPageState}
              submitButton={submitButton}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};
