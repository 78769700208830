import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useApiError } from 'hooks/useApiError';
import { useToastContext } from 'contexts/ToastContext';
import { BranchStatus } from 'components/common/Branch';
import { PrescriptionItem } from 'models/PrescriptionItem';
import {
  getPatientPrescriptionList,
  savePatientPrescription,
  getPatientPrescriptionItem,
  deletePatientPrescriptionItem,
  updatePatientPrescription,
} from 'api/Patient';
import { routes } from 'routes';
export interface PrescriptionContextProps {
  getPatientPrescriptions: Function;
  addPatientPrescription: Function;
  updatePatientPrescriptionReminder: Function;
  removePatientPrescription: Function;
  getPatientPrescriptionDetail: Function;
  setPatientPrescriptions: Function;
  patientPrescriptions: PrescriptionItem[] | null;
  prescriptionDetail: PrescriptionItem | null;
  loading: BranchStatus;
  setPrescriptionDetail: Function;
  children?: React.ReactNode;
}

export const PrescriptionContextDefaults: PrescriptionContextProps = {
  getPatientPrescriptions: Function,
  addPatientPrescription: Function,
  updatePatientPrescriptionReminder: Function,
  removePatientPrescription: Function,
  getPatientPrescriptionDetail: Function,
  setPatientPrescriptions: Function,
  patientPrescriptions: null,
  prescriptionDetail: null,
  loading: 'idle',
  setPrescriptionDetail: Function,
};

export const PrescriptionContext =
  React.createContext<PrescriptionContextProps>(PrescriptionContextDefaults);

export const usePrescriptionContext = (): PrescriptionContextProps =>
  React.useContext(PrescriptionContext);

interface PrescriptionProviderProps {
  children?: React.ReactNode;
}

export const PrescriptionProvider = ({
  children,
}: PrescriptionProviderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast } = useToastContext();
  const { handleApiError } = useApiError();
  const [loading, setLoading] = React.useState<BranchStatus>('idle');

  const errors = {
    title: t('common.error.genericTitle'),
    message: t('common.error.genericMessage'),
  };

  const errorWithToast = () => {
    setToast({
      status: 'error',
      title: errors.title,
      description: errors.message,
    });
    setLoading('error');
  };

  const [patientPrescriptions, setPatientPrescriptions] = React.useState<
    PrescriptionItem[] | null
  >(null);
  const [prescriptionDetail, setPrescriptionDetail] =
    React.useState<PrescriptionItem | null>(null);

  const getPatientPrescriptions = async () => {
    try {
      setLoading('loading');
      const results = await getPatientPrescriptionList();
      setPatientPrescriptions(results);
      setLoading('finished');
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
        errorWithToast();
      }, error);
    }
  };

  const addPatientPrescription = async (payload: string) => {
    try {
      setLoading('loading');
      await savePatientPrescription(payload);
      setLoading('finished');
      history.push(routes.PRESCRIPTION.BASE);
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
        errorWithToast();
      }, error);
    }
  };

  const updatePatientPrescriptionReminder = async (
    id: string,
    days: number,
  ) => {
    try {
      setLoading('loading');
      await updatePatientPrescription(id, days);
      await getPatientPrescriptions();
      setLoading('finished');
      history.push(routes.PRESCRIPTION.BASE);
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
        errorWithToast();
      }, error);
    }
  };

  const removePatientPrescription = async (id: string) => {
    try {
      setLoading('loading');
      await deletePatientPrescriptionItem(id);
      setLoading('finished');
      history.push(routes.PRESCRIPTION.BASE);
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
        errorWithToast();
      }, error);
    }
  };

  const getPatientPrescriptionDetail = async (id: string) => {
    try {
      setLoading('loading');
      const result = await getPatientPrescriptionItem(id);
      setPrescriptionDetail(result);
      setLoading('finished');
    } catch (error) {
      handleApiError(() => {
        setLoading('error');
        errorWithToast();
      }, error);
    }
  };

  return (
    <PrescriptionContext.Provider
      value={{
        getPatientPrescriptions,
        addPatientPrescription,
        updatePatientPrescriptionReminder,
        removePatientPrescription,
        patientPrescriptions,
        setPatientPrescriptions,
        getPatientPrescriptionDetail,
        prescriptionDetail,
        loading,
        setPrescriptionDetail,
      }}
    >
      {children}
    </PrescriptionContext.Provider>
  );
};
