import { MutableRefObject, useEffect, useRef, useState } from 'react';

interface IntersectionObserverEntryType {
  isIntersecting: boolean;
}

export interface UseIsInViewportProps {
  mode?: 'single' | 'continuous'; // mode allows for either continuous toggling, or a 'latched' toggle
}

export interface UseIsInViewportReturnType {
  elementRef: MutableRefObject<null>;
  isInViewport: boolean;
}

export const useIsInViewport = ({
  mode = 'single',
}: UseIsInViewportProps): UseIsInViewportReturnType => {
  const [isInViewport, setIsInViewport] = useState(false);
  const elementRef = useRef(null);
  const observer = new IntersectionObserver(
    ([entry]: IntersectionObserverEntryType[]) => {
      if (mode === 'continuous' || (mode === 'single' && !isInViewport)) {
        setIsInViewport(entry.isIntersecting);
      }
    },
    { threshold: 0.5 }, // at least half the element must be in view to trigger
  );

  useEffect(() => {
    const { current } = elementRef;
    if (!current) return;

    observer.observe(current);

    return () => {
      observer.disconnect();
    };
  }, [elementRef, observer]);

  return { elementRef, isInViewport };
};
