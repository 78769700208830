import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { AppBarTop } from 'components/Layout/AppBarTop';
import { Card, CardType } from 'components/common/Card';
import { Branch } from 'components/common/Branch';
import { Button } from 'components/common/Button';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { useModalContext } from 'contexts/ModalContext';
import { useAuthContext } from 'contexts/AuthContext';
import { useExemptionsContext } from 'contexts/ExemptionsContext';
import { useDependantContext } from 'contexts/DependantContext';
import { useDependantExemptionsContext } from 'contexts/DependantExemptionsContext';
import { usePatientContext } from 'contexts/PatientContext';
import { ReactComponent as File } from 'assets/icons/lawsat/File.svg';
import { ReactComponent as Pill } from 'assets/icons/lawsat/Pill-Outline.svg';
import { ReactComponent as Shop } from 'assets/icons/lawsat/Shop.svg';
import { ReactComponent as User } from 'assets/icons/lawsat/User.svg';
import { ReactComponent as Box } from 'assets/icons/lawsat/Box.svg';
import { ReactComponent as Home } from 'assets/icons/lawsat/Home.svg';
import { routes } from 'routes';

import styles from './AccountLanding.module.scss';

interface AccountLandingProps {
  dependantId?: string;
}

export const AccountLanding = ({ dependantId }: AccountLandingProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { logout } = useAuthContext();
  const { open, close } = useModalContext();
  const { exemptionDetails } = useExemptionsContext();
  const { dependantExemptionDetails } = useDependantExemptionsContext();
  const { patient, dependants, patientAddress, patientGp, patientPharmacy } =
    usePatientContext();

  const {
    dependant,
    dependantAddress,
    dependantPharmacy,
    dependantGp,
    removeDependant,
  } = useDependantContext();

  if (patient && !patientAddress) {
    history.push(routes.SIGN_UP.BASE);
  }

  const translationPath = dependantId
    ? 'Account.dependantLanding'
    : 'Account.landing';

  const dependantBaseRoute = `${routes.DEPENDANT.ACCOUNT.BASE}${dependantId}`;

  const menuItems: CardType[] = [
    {
      title: t(`${translationPath}.editContactDetails`),
      icon: User,
      link: dependantId
        ? `${dependantBaseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.CONTACT_DETAILS}`
        : routes.ACCOUNT.EDIT.CONTACT_DETAILS,
    },
    {
      title: dependantId
        ? dependantPharmacy
          ? t(`${translationPath}.editPharmacy`)
          : t(`${translationPath}.addPharmacy`)
        : patientPharmacy
        ? t(`${translationPath}.editPharmacy`)
        : t(`${translationPath}.addPharmacy`),
      icon: Shop,
      link: dependantId
        ? `${dependantBaseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.PHARMACY}`
        : routes.ACCOUNT.EDIT.PHARMACY,
    },
    {
      title: dependantId
        ? dependantGp
          ? t(`${translationPath}.editGP`)
          : t(`${translationPath}.addGP`)
        : patientGp
        ? t(`${translationPath}.editGP`)
        : t(`${translationPath}.addGP`),
      icon: Pill,
      link: dependantId
        ? `${dependantBaseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.VIEW_GP}`
        : routes.ACCOUNT.EDIT.VIEW_GP,
    },
  ];

  dependantId
    ? menuItems.splice(1, 0, {
        title: dependantAddress
          ? t(`${translationPath}.editAddress`)
          : t(`${translationPath}.addAddress`),
        icon: Home,
        link: `${dependantBaseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.ADDRESS}`,
      })
    : menuItems.unshift({
        title: t('Account.landing.orders'),
        icon: Box,
        link: routes.ACCOUNT.ORDERS,
      });

  if (
    (dependantId && dependantGp && dependantPharmacy) ||
    (!dependantId && patientGp && patientPharmacy)
  ) {
    menuItems.push({
      title: dependantId
        ? dependantExemptionDetails
          ? t(`${translationPath}.editExemption`)
          : t(`${translationPath}.addExemption`)
        : exemptionDetails
        ? t(`${translationPath}.editExemption`)
        : t(`${translationPath}.addExemption`),
      icon: File,
      link: dependantId
        ? `${dependantBaseRoute}${routes.DEPENDANT.ACCOUNT.EDIT.EXEMPTION}`
        : routes.ACCOUNT.EDIT.EXEMPTION,
    });
  }

  return (
    <Container className={styles['account-landing']}>
      {dependantId && (
        <AppBarTop
          backRoute={{
            to: routes.ACCOUNT.BASE,
            text: t('Account.dependantLanding.back'),
          }}
        />
      )}
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <div>
            <Heading
              tag="h2"
              size="xl"
              className={styles['account-landing__title']}
            >
              {t(`${translationPath}.title`, {
                dependantName: dependant?.firstName,
              })}
            </Heading>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <Branch
            Component={() => (
              <>
                {menuItems.map((item, index) => (
                  <Card
                    key={index}
                    item={item}
                    className={styles['account-landing__card--with-icon']}
                  />
                ))}
              </>
            )}
          />
          <Branch
            Component={() => (
              <>
                {!dependantId && dependants && dependants.length !== 0 ? (
                  <>
                    <Heading
                      size={'xs'}
                      className={styles['account-landing__dependants-title']}
                    >
                      {t('Account.landing.dependantsTitle')}
                    </Heading>
                    {dependants.map((dependant) => (
                      <Card
                        key={dependant.id}
                        item={{
                          title: `${dependant?.firstName} ${dependant?.lastName}`,
                          link: `${routes.DEPENDANT.ACCOUNT.BASE}${dependant.id}`,
                        }}
                        className={styles['account-landing__card']}
                      />
                    ))}
                  </>
                ) : null}
              </>
            )}
          />

          <AppBarBottom
            fullWidth
            className={styles['account-landing__actions']}
          >
            {!dependantId && (
              <Button
                width="full"
                testId="logout-button"
                appearance="solid"
                label={t('Account.landing.signOut')}
                onClick={() => logout()}
                className={styles['account-landing__actions__logout']}
              />
            )}
            {dependantId && (
              <Button
                width="full"
                testId="remove-dependant-button"
                appearance="solid"
                variant="negative"
                label={t('Account.dependantLanding.removeDependant')}
                onClick={() =>
                  open(
                    <>
                      <Heading
                        tag="h3"
                        size="lg"
                        className={styles['account-landing__modal-title']}
                      >
                        {t('Account.landing.deleteDependant.title')}
                      </Heading>
                      <Text
                        size="md"
                        className={styles['account-landing__modal-body']}
                      >
                        {t('Account.landing.deleteDependant.body')}
                      </Text>
                      <Button
                        label={t('Account.landing.deleteDependant.confirm')}
                        variant="negative"
                        appearance="solid"
                        className={styles['account-landing__modal-button']}
                        onClick={async () => {
                          removeDependant(dependantId);
                          close();
                        }}
                      />
                      <Button
                        className={styles['account-landing__modal-button']}
                        label={t('Account.landing.deleteDependant.cancel')}
                        variant="ui"
                        appearance="flat"
                        width="full"
                        onClick={() => {
                          close();
                        }}
                      />
                    </>,
                  )
                }
              />
            )}
          </AppBarBottom>
        </Col>
      </Row>
    </Container>
  );
};
