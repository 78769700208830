import React from 'react';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';

import styles from './TravelVaccinationsInfoCard.module.scss';

export interface TravelVaccinationsInfoCardProps {
  img: { src: string; alt: string };
  heading: string;
  bodyText: string;
  stepNumber?: string;
}

export const TravelVaccinationsInfoCard = ({
  img: { alt, src },
  bodyText,
  heading,
  stepNumber,
}: TravelVaccinationsInfoCardProps) => {
  return (
    <div className={styles['info-card__container']}>
      <div className={styles['info-card__img-container']}>
        <img src={src} alt={alt} data-testid="info-card-img"></img>
        {stepNumber && (
          <div className={styles['info-card__step-number']}>
            <Heading tag="h1" testId="info-card-step-number">
              {stepNumber}
            </Heading>
          </div>
        )}
      </div>
      <div className={styles['info-card__text-container']}>
        <Heading
          tag="h3"
          testId="info-card-heading"
          className={styles['info-card__heading']}
        >
          {heading}
        </Heading>
        <Text
          size="md"
          testId="info-card-body-text"
          className={styles['info-card__body-text']}
        >
          {bodyText}
        </Text>
      </div>
    </div>
  );
};
