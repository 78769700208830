import Axios from 'axios';

export const Client = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const ExtClient = Axios.create();

export const CogClient = Axios.create({
  baseURL: process.env.REACT_APP_FUNC_URL,
});
