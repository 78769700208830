import { Switch, Route } from 'react-router-dom';
import { LandingPage } from 'pages/Shop/LandingPage';
import { ConditionGroupPage } from './ConditionGroupPage';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { useBasketContext } from 'contexts/BasketContext';
import { useConfigContext } from 'contexts/ConfigContext';
import { ShopUnavailable } from './ShopUnavailable';
import { Condition } from './Condition';
import { PomIntermediaryPage } from 'containers/POM/PomIntermediaryPage';
import { routes } from 'routes';

export const Shop = () => {
  const location = useLocation();
  const { refreshBasketTimeout } = useBasketContext();
  const { isShopEnabled, getPatientAppConfig } = useConfigContext();

  useEffect(() => {
    refreshBasketTimeout();
    getPatientAppConfig();
  }, [location]);

  if (!isShopEnabled) {
    return <ShopUnavailable />;
  }

  return (
    <Switch>
      {/* All conditions POM select */}
      <Route path={`${routes.SHOP.BASE}${routes.POM.SELECT}`} exact>
        <PomIntermediaryPage />
      </Route>

      {/* Condition Groups */}
      <Route path={`${routes.SHOP.CONDITIONGROUP}/:conditionGroupId`} exact>
        <ConditionGroupPage />
      </Route>
      <Route
        path={`${routes.SHOP.CONDITIONGROUP}/:conditionGroupId${routes.POM.SELECT}`}
        exact
      >
        <PomIntermediaryPage />
      </Route>

      {/* Conditions */}
      <Route path={`${routes.SHOP.CONDITION}/:conditionId`}>
        <Condition />
      </Route>

      {/* Shop home page */}
      <Route path={routes.SHOP.BASE}>
        <LandingPage />
      </Route>
    </Switch>
  );
};
