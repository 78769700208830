import React, { useEffect } from 'react';
import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import { ProductDetail } from 'pages/Product/ProductDetail';
import { useBasketContext } from 'contexts/BasketContext';
import { useConfigContext } from 'contexts/ConfigContext';
import { ShopUnavailable } from 'pages/Shop/ShopUnavailable';
import { PomForm } from 'containers/POM/PomForm';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { useProductsContext } from 'contexts/ProductsContext';
import { LandingPageSpinner } from 'components/common/Spinner';
import { GeneralHealthForm } from 'containers/GeneralHealthForm';
import { PomConfirmationTreatmentFlow } from 'containers/POM/PomConfirmationTreatmentFlow';
import { PrivateRoute } from 'components/common/PrivateRoute';

import { routes } from 'routes';
import { useTranslation } from 'react-i18next';

export const Product = () => {
  const { productId } = useParams<{
    productId: string;
  }>();
  const { t } = useTranslation();
  const location = useLocation();
  const { refreshBasketTimeout } = useBasketContext();
  const { isShopEnabled, getPatientAppConfig } = useConfigContext();
  const { getConditions, conditions, getConditionGroups, conditionGroups } =
    useConditionsContext();
  const { getProduct, product, setProduct } = useProductsContext();

  useEffect(() => {
    if (productId) {
      getPatientAppConfig();
      getConditions();
      getConditionGroups();
      getProduct(productId);
    }
    return () => {
      setProduct(null);
    };
  }, []);

  useEffect(() => {
    refreshBasketTimeout();
    getProduct(productId);
  }, [location]);

  if (!isShopEnabled) {
    return <ShopUnavailable />;
  }

  if (!conditions || !product || !conditionGroups) {
    return <LandingPageSpinner />;
  }

  return (
    <Switch>
      <PrivateRoute
        path={`${routes.PRODUCT.BASE}/:productId${routes.GENERAL_HEALTH.BASE}`}
      >
        <GeneralHealthForm />
      </PrivateRoute>
      <PrivateRoute
        path={`${routes.PRODUCT.BASE}/:productId${routes.POM.BASE}`}
        exact
      >
        <PomForm
          backButton={{
            label: t('pomForms.back'),
            route: `${routes.PRODUCT.BASE}/${productId}${routes.GENERAL_HEALTH.PT2}`,
          }}
          nextButton={{
            label: t('pomForms.submitTreatment'),
            route: `${routes.PRODUCT.BASE}/${productId}${routes.POM.CONFIRMATION.BASE}`,
          }}
        />
      </PrivateRoute>
      <PrivateRoute
        path={`${routes.PRODUCT.BASE}/:productId${routes.POM.CONFIRMATION.BASE}`}
        exact
      >
        <PomConfirmationTreatmentFlow />
      </PrivateRoute>
      <Route path={`${routes.PRODUCT.BASE}/:productId`} exact>
        <ProductDetail />
      </Route>
    </Switch>
  );
};
