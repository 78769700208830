import { AxiosResponse } from 'axios';
import { Client } from 'api/utils';
import { Exemption as ExemptionType, ExemptionDetails } from 'models/Exemption';

export type ExemptionPayload = {
  exemptionReasonId: number | null;
  expiryDate: string | null;
  shouldNotify: boolean | null;
};

export const getExemptionsList = async (): Promise<ExemptionType[]> => {
  const response = await Client.get(`exemptionReasons`);
  return response.data;
};

export const getPatientExemption = async (): Promise<ExemptionDetails> => {
  const response = await Client.get(`/patient/exemption`);
  return response.data;
};

export const savePatientExemption = async (
  payload: ExemptionPayload,
): Promise<AxiosResponse> => await Client.put(`/patient/exemption`, payload);

export const deletePatientExemption = (): Promise<AxiosResponse> =>
  Client.delete('/patient/exemption');
