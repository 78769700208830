import React from 'react';
import cx from 'classnames';
import styles from './Heading.module.scss';

interface HeadingProps {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  testId?: string;
  id?: string;
  children?: React.ReactNode;
}

export const Heading = ({
  tag = 'h3',
  className,
  size,
  testId,
  id,
  children,
}: HeadingProps) => {
  const Component = tag;

  // if no size is set, select default size based on heading type
  if (!size) {
    switch (tag) {
      case 'h1':
        size = 'xxl';
        break;
      case 'h2':
        size = 'xl';
        break;
      case 'h3':
        size = 'lg';
        break;
      case 'h5':
        size = 'sm';
        break;
      case 'h6':
        size = 'xs';
        break;
      default:
        size = 'md';
        break;
    }
  }

  const headingClasses = cx(
    `${styles['heading']}`,
    `${styles[`heading-${size}`]}`,
    { [`${className}`]: className },
  );

  return (
    <Component className={headingClasses} data-testid={testId} id={id}>
      {children}
    </Component>
  );
};
