import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { usePatientContext } from 'contexts/PatientContext';
import { useToastContext } from 'contexts/ToastContext';
import { Container, Row, Col } from 'components/common/grid';
import { FormProgressBar } from 'components/FormProgressBar';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { PharmacyList } from 'components/PharmacyList';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { FormProgressBarProps } from 'components/FormProgressBar/FormProgressBar';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import { routes } from 'routes';

import styles from './AccountSelectPharmacy.module.scss';

interface AccountSelectPharmacyProps {
  heading: string;
  bodyText: string;
  submitButton: {
    route: string;
    label: string;
  };
  progressBar?: FormProgressBarProps;
}

export const AccountSelectPharmacy = ({
  heading,
  bodyText,
  submitButton,
  progressBar,
}: AccountSelectPharmacyProps) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { setToast, dismissToast } = useToastContext();
  const { patientPharmacies, savePatientPharmacy } = usePatientContext();

  useEffect(() => {
    const selected = patientPharmacies.find(
      (item) => item.selectedByPatient === true,
    );

    if (selected) {
      setValue('pharmacySelected', selected?.id.toString());
    }
  }, [patientPharmacies]);

  const schema = yup.object().shape({
    pharmacySelected: yup
      .string()
      .required(t('Account.contactDetails.formErrors.requiredAddress')),
  });

  const { control, handleSubmit, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('Account.selectPharmacy.formErrors.toastNoPharmacy'),
      description: t(
        'Account.selectPharmacy.formErrors.toastNoPharmacyDescription',
      ),
    });
  };

  const onSubmit = async () => {
    dismissToast();
    await savePatientPharmacy({
      pharmacyId: parseInt(getValues('pharmacySelected')),
    });
    history.push(submitButton.route);
  };

  const breadcrumbs: Crumb[] = [
    { route: routes.ACCOUNT.BASE, text: t('NavBarLabels.account') },
    {
      route: routes.ACCOUNT.EDIT.PHARMACY,
      text: t('Account.landing.editPharmacy'),
    },
  ];

  return (
    <Container className={styles['account-select-pharmacy']}>
      {pathname.match(routes.ACCOUNT.EDIT.PHARMACY) && (
        <Breadcrumbs links={breadcrumbs} />
      )}
      <Row>
        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          {progressBar && (
            <div
              className={styles['account-select-pharmacy__progress-container']}
            >
              <FormProgressBar
                numberOfStages={progressBar.numberOfStages}
                currentStage={progressBar.currentStage}
              />
            </div>
          )}
          <Heading id="labelForPharmacyList">{heading}</Heading>
          <Text>{bodyText}</Text>
          {patientPharmacies && (
            <form
              data-testid="account-select-pharmacy-form"
              className={styles['account-select-pharmacy__form']}
              onSubmit={handleSubmit(onSubmit, invalid)}
            >
              <Controller
                control={control}
                defaultValue=""
                name="pharmacySelected"
                isRequired
                render={(props) => (
                  <>
                    <PharmacyList
                      labelBy="labelForPharmacyList"
                      id="pharmacySelected"
                      values={patientPharmacies}
                      {...props}
                    />
                  </>
                )}
              />
              <AppBarBottom fullWidth>
                <Button
                  width="full"
                  className={styles['account-select-pharmacy__submit-button']}
                  testId="submit-button"
                  type="submit"
                  appearance="solid"
                  label={submitButton.label}
                />
              </AppBarBottom>
            </form>
          )}
        </Col>
      </Row>
    </Container>
  );
};
