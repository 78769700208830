import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useToastContext } from 'contexts/ToastContext';
import { usePostCodeSearchContext } from 'contexts/PostCodeSearchContext';
import { InputText } from 'components/common/InputText';
import { Button } from 'components/common/Button';
import { ReactComponent as SearchIcon } from 'assets/icons/lawsat/Search.svg';

import styles from './PostCodeSearch.module.scss';

export const PostCodeSearch = () => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const { getAddresses, clearAddresses } = usePostCodeSearchContext();

  const schema = yup.object().shape({
    postCode: yup
      .string()
      .required(t('Account.address.formErrors.requiredPostCode'))
      .matches(
        /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i,
        t('Account.address.formErrors.invalidPostCode'),
      ),
  });

  const { control, handleSubmit, getValues, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const valid = async () => {
    await getAddresses(getValues('postCode'));
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('Account.address.formErrors.toastPostcode'),
    });
  };

  return (
    <form
      data-testid="account-address-form"
      className={styles['post-code-search__form']}
      onSubmit={handleSubmit(valid, invalid)}
      onChange={() => clearAddresses()}
    >
      <Controller
        control={control}
        defaultValue=""
        name="postCode"
        isRequired
        render={(props, fieldState) => (
          <>
            <InputText
              icon={SearchIcon}
              className={styles['post-code-search__input']}
              labelClassName={styles['post-code-search__label']}
              variant={fieldState.invalid ? 'negative' : 'accent'}
              validationError={errors.postCode?.message}
              placeholder={t('Account.address.formLabels.searchPlaceholder')}
              label={t('Account.address.formLabels.search')}
              {...props}
            />
          </>
        )}
      />
      <div>
        <Button
          size="lg"
          className={styles['post-code-search__search-button']}
          data-testid="submit-button"
          type="submit"
          appearance="flat"
          label={t('Account.address.formLabels.searchButton')}
          isLoading={false}
        ></Button>
      </div>
    </form>
  );
};
