import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { useModalContext } from 'contexts/ModalContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { PomConfirmLoginModal } from 'components/ConfirmLoginModal';
import { Button } from 'components/common/Button';
import { ReactComponent as QuestionAnswer } from 'assets/icons/lawsat/QuestionAnswer.svg';
import { ReactComponent as Cart } from 'assets/icons/lawsat/Cart.svg';
import { ReactComponent as UserCheckmark } from 'assets/icons/lawsat/UserCheckmark.svg';

import styles from './StartConsultationCTA.module.scss';
import { useLandingPageContext } from 'contexts/LandingPageContext';

const infoMsgStyle = 'start-consultation-cta__info-msg';
const infoMsgIconStyle = 'start-consultation-cta__info-msg__icon';

interface StartConsultationCTAProps {
  cta: { link: string; label: string; disableButton?: boolean };
  requireLogin?: boolean;
}

export const StartConsultationCTA = ({
  cta,
  requireLogin = true,
}: StartConsultationCTAProps) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const { authStatus, login } = useAuthContext();
  const { open: openModal } = useModalContext();
  const { logCtaClick } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();

  const handleCtaClick = () => {
    logCtaClick({
      label: t('amplitude.startConsultationCTA.label'),
      pageName: pageName?.log,
      section: t('amplitude.startConsultationCTA.section'),
    });
    if (!requireLogin || authStatus) {
      navigate.push(cta.link);
    } else {
      openModal(
        <PomConfirmLoginModal
          onLogin={() =>
            login(
              { redirectToUrl: cta.link },
              { label: 'Continue to consultation' },
            )
          }
        />,
      );
    }
  };

  return (
    <div className={styles['start-consultation-cta']}>
      <div className={styles['start-consultation-cta__section-container']}>
        <Heading>{t('shop.startConsultation.heading')}</Heading>
        <Text>{t('shop.startConsultation.intro')}</Text>
      </div>
      <div className={styles['start-consultation-cta__section-container']}>
        <Text size="lg" className={styles[infoMsgStyle]}>
          <QuestionAnswer className={styles[infoMsgIconStyle]} />
          {t('shop.startConsultation.stepOne')}
        </Text>
        <Text size="lg" className={styles[infoMsgStyle]}>
          <Cart className={styles[infoMsgIconStyle]} />
          {t('shop.startConsultation.stepTwo')}
        </Text>
        <Text size="lg" className={styles[infoMsgStyle]}>
          <UserCheckmark className={styles[infoMsgIconStyle]} />
          {t('shop.startConsultation.stepThree')}
        </Text>
      </div>
      <Button
        label={cta.label}
        onClick={handleCtaClick}
        disabled={cta.disableButton ?? false}
        variant={cta.disableButton ? 'ui' : 'accent'}
        appearance={cta.disableButton ? 'flat' : 'solid'}
      />
    </div>
  );
};
