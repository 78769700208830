/* eslint-disable sonarjs/no-duplicate-string */
import { Switch, Redirect } from 'react-router-dom';
import { SuggestProvider } from 'contexts/SuggestContext';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { PrescriptionCompleted } from 'containers/Prescription/PrescriptionCompleted';
import { PrescriptionLanding } from 'containers/Prescription/PrescriptionLanding';
import { PrescriptionAdd } from 'containers/Prescription/PrescriptionAdd';
import { PrescriptionDetail } from 'containers/Prescription/PrescriptionDetail';
import { PrescriptionReminder } from 'containers/Prescription/PrescriptionReminder';
import { LandingPageSpinner } from 'components/common/Spinner';
import { usePatientOnboardingComplete } from 'hooks/useOnboardingComplete';
import { routes } from 'routes';

export const Prescription = () => {
  const { checksComplete, onboardingComplete } = usePatientOnboardingComplete();

  if (!checksComplete) {
    return <LandingPageSpinner />;
  }

  return (
    <Switch>
      {/* Bounce to onboarding if not complete */}
      {!onboardingComplete && (
        <Redirect to={routes.PRESCRIPTION.ONBOARDING.NEXT} />
      )}

      <PrivateRoute path={routes.PRESCRIPTION.COMPLETED} exact>
        <PrescriptionCompleted isDependant={false} />
      </PrivateRoute>

      <PrivateRoute path={routes.PRESCRIPTION.ADD} exact>
        <SuggestProvider>
          <PrescriptionAdd />
        </SuggestProvider>
      </PrivateRoute>

      <PrivateRoute path={`${routes.PRESCRIPTION.DETAIL}/:id`} exact>
        <PrescriptionDetail isDependant={false} />
      </PrivateRoute>

      <PrivateRoute path={`${routes.PRESCRIPTION.REMINDER}/:id`} exact>
        <PrescriptionReminder />
      </PrivateRoute>

      <PrivateRoute path={routes.PRESCRIPTION.BASE}>
        <PrescriptionLanding />
      </PrivateRoute>
    </Switch>
  );
};
