import React from 'react';

import {
  DropDown,
  DropDownProps,
  selectValues,
} from 'components/common/DropDown';
import { CountryPhoneCodes } from './countryPhoneCodes';

type CountryPhoneCodeSelectorProps = Omit<DropDownProps, 'values'>;

const CountryPhoneCodeSelectorRef: React.ForwardRefRenderFunction<
  HTMLSelectElement,
  CountryPhoneCodeSelectorProps
> = ({ ...props }, ref) => {
  const values: selectValues[] = CountryPhoneCodes.map((country) => ({
    key: country.code,
    id: country.dial_code,
    name: `(${country.dial_code}) ${country.name}`,
  }));

  return <DropDown values={values} ref={ref} {...props} />;
};

export const CountryPhoneCodeSelector = React.forwardRef(
  CountryPhoneCodeSelectorRef,
);
