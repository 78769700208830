import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { Button } from 'components/common/Button';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { GPCard } from 'components/GPCard';
import { useDependantContext } from 'contexts/DependantContext';
import { usePatientContext } from 'contexts/PatientContext';
import { FormProgressBar } from 'components/FormProgressBar';
import { FormProgressBarProps } from 'components/FormProgressBar/FormProgressBar';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import { routes } from 'routes';

import styles from './YourGP.module.scss';

interface YourGPProps {
  heading: string;
  selectGpRoute: string;
  submitButton: {
    route: string;
    label: string;
  };
  dependantId?: string;
  progressBar?: FormProgressBarProps;
}

export const YourGP = ({
  dependantId,
  selectGpRoute,
  submitButton,
  progressBar,
  heading,
}: YourGPProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { patientGp } = usePatientContext();
  const { dependantGp, dependant } = useDependantContext();
  const { pathname } = useLocation();

  useEffect(() => {
    if ((dependantId && !dependantGp) || (!dependantId && !patientGp)) {
      history.push(selectGpRoute);
    }
  }, []);

  const gp = dependantId ? dependantGp : patientGp;

  const breadcrumbs: Crumb[] = dependantId
    ? [
        {
          route: routes.DEPENDANT.ACCOUNT.BASE,
          text: t('NavBarLabels.account'),
        },
        {
          route: `${routes.DEPENDANT.ACCOUNT.BASE}${dependant?.id}`,
          text: t('Account.dependantLanding.title', {
            dependantName: dependant?.firstName,
          }),
        },
        {
          route: `${routes.DEPENDANT.ACCOUNT.BASE}${dependant?.id}${routes.DEPENDANT.ACCOUNT.EDIT.VIEW_GP}`,
          text: t('Account.dependantLanding.editGP'),
        },
      ]
    : [
        { route: routes.ACCOUNT.BASE, text: t('NavBarLabels.account') },
        {
          route: routes.ACCOUNT.EDIT.VIEW_GP,
          text: t('Account.landing.editGP'),
        },
      ];

  return (
    <Container className={styles['your-gp']}>
      {(pathname.match(routes.ACCOUNT.EDIT.VIEW_GP) ||
        pathname.match(routes.DEPENDANT.ACCOUNT.EDIT.VIEW_GP)) && (
        <Breadcrumbs links={breadcrumbs} />
      )}
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          {progressBar && (
            <div className={styles['your-gp__progress-container']}>
              <FormProgressBar numberOfStages={5} currentStage={3} />
            </div>
          )}
          <Heading tag="h2" size="lg" className={styles['your-gp__title']}>
            {heading}
          </Heading>
          {gp && (
            <GPCard
              gp={gp}
              buttonText={
                dependantId
                  ? t('Account.yourGP.changeTheirGP')
                  : t('Account.yourGP.changeYourGP')
              }
              link={selectGpRoute}
            />
          )}
          <AppBarBottom fullWidth>
            <Button
              width="full"
              testId="done-button"
              appearance="solid"
              label={submitButton.label}
              onClick={() => history.push(submitButton.route)}
            />
          </AppBarBottom>
        </Col>
      </Row>
    </Container>
  );
};
