import React from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
}

export interface ErrorBoundaryProps {
  errorComponent?: React.ReactNode;
  children?: React.ReactNode;
}

// NOTE: You can swap this for whatever you want. Ideally import a DefaultErrorComponent equivalent
const DefaultErrorComponent = () => <h1>Something went wrong.</h1>;

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render(): React.ReactNode {
    const { errorComponent = <DefaultErrorComponent />, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return errorComponent;
    }

    return children;
  }
}
