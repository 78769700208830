import { Heading } from 'components/common/Heading';
import { ReactComponent as MapPin } from 'assets/icons/lawsat/MapPin.svg';
import { ReactComponent as Phone } from 'assets/icons/lawsat/Phone.svg';
import { ReactComponent as Tick } from 'assets/icons/lawsat/checkmark.svg';
import { ContactDetailIconWithLabel } from 'components/ContactDetailIconWithLabel';

import styles from './PharmacyContactCard.module.scss';

interface PharmacyContactCardProps {
  pharmacy: {
    name: string;
    address: string;
    phoneNumber: string;
    gphc: string;
    googleLink: string;
  };
}

export const PharmacyContactCard = ({ pharmacy }: PharmacyContactCardProps) => {
  const iconStyle = styles['pharmacy-contact-card__icons'];
  return (
    <div className={styles['pharmacy-contact-card']}>
      <Heading size="sm">{pharmacy.name}</Heading>
      <address className={styles['pharmacy-contact-card__details']}>
        <div className={styles['pharmacy-contact-card__details__address']}>
          <ContactDetailIconWithLabel
            icon={<MapPin className={iconStyle} />}
            label={pharmacy.address}
            type="address"
            googleLink={pharmacy.googleLink}
          />
        </div>
        <ContactDetailIconWithLabel
          icon={<Phone className={iconStyle} />}
          type="phone"
          label={pharmacy.phoneNumber}
        />
        <ContactDetailIconWithLabel
          icon={<Tick className={iconStyle} />}
          label={pharmacy.gphc}
        />
      </address>
    </div>
  );
};
