import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Button } from 'components/common/Button';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import cx from 'clsx';

import styles from './TravelVaccinationsSection.module.scss';

interface TravelVaccinationsSectionProps {
  ctaOne?: { label: string; to?: string; onClick?: Function };
  ctaTwo?: { label: string; to?: string; onClick?: Function };
  className?: string;
}

export const TravelVaccinationsSection = ({
  ctaOne,
  ctaTwo,
  className,
}: TravelVaccinationsSectionProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logSectionView, logCtaClick } = useAmplitudeContext();
  const { elementRef, isInViewport } = useIsInViewport({ mode: 'single' });

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.travelVaccinationsSection.section'),
      });
    }
  }, [isInViewport]);

  const handleLogCtaClick = (label: string) => {
    logCtaClick({
      label: label,
      pageName: pageName?.log,
      section: t('amplitude.travelVaccinationsSection.section'),
    });
  };

  return (
    <div ref={elementRef}>
      <Container
        className={cx(
          styles['travel-vaccinations-section__container'],
          className,
        )}
      >
        <Row className={styles['travel-vaccinations-section__row']}>
          <Col
            xs={12}
            md={6}
            className={styles['travel-vaccinations-section__image-col']}
          >
            <img
              src="/assets/images/travel-vax-hero.png"
              alt="A lady with a passport going on holiday"
              className={styles['travel-vaccinations-section__image']}
            />
          </Col>
          <Col
            xs={12}
            md={6}
            lg={{ size: 5, offset: 1 }}
            className={styles['travel-vaccinations-section__content-col']}
          >
            <Heading size={'xl'}>
              {t('LandingPage.travelVaccinations.title')}
            </Heading>
            <Text
              size="lg"
              className={styles['travel-vaccinations-section__subheading']}
            >
              {t('LandingPage.travelVaccinations.subtitle')}
            </Text>
            {(ctaOne || ctaTwo) && (
              <div
                className={
                  styles['travel-vaccinations-section__ctas-container']
                }
              >
                {ctaOne && (
                  <Button
                    label={ctaOne.label ?? ''}
                    link={ctaOne.to ? { to: ctaOne.to } : undefined}
                    testId={'cta-one'}
                    size={'md'}
                    variant={'accent'}
                    onClick={() => {
                      handleLogCtaClick(ctaOne.label);
                      ctaOne?.onClick?.();
                    }}
                  />
                )}
                {ctaTwo && (
                  <Button
                    label={ctaTwo.label ?? ''}
                    link={ctaTwo.to ? { to: ctaTwo.to } : undefined}
                    testId={'cta-tctaTwo'}
                    size={'md'}
                    variant={'accent'}
                    appearance={'outline'}
                    onClick={() => {
                      handleLogCtaClick(ctaTwo.label);
                      ctaTwo?.onClick?.();
                    }}
                  />
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
