import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useApiError } from 'hooks/useApiError';
import { useToastContext } from 'contexts/ToastContext';
import { getConfig as getConfigAPI } from 'api/Config';
import { Toggle, defaultToggle } from 'models/Toggle';

export interface ConfigContextProps {
  tagline: {
    lineOne?: string;
    lineTwo?: string;
  };
  isShopEnabled: boolean;
  isStandardDeliveryEnabled: boolean;
  isExpressDeliveryEnabled: boolean;
  freeDeliveryThreshold: number;
  standardPrescriptionPrice: number;
  threeMonthPrePayment: number;
  twelveMonthPrePayment: number;
  shopEnabledToggle: Toggle<boolean | undefined>;
  getPatientAppConfig: () => Promise<void>;
}

export const ConfigContextDefaults: ConfigContextProps = {
  tagline: {
    lineOne: '',
    lineTwo: '',
  },
  isShopEnabled: false,
  isStandardDeliveryEnabled: false,
  isExpressDeliveryEnabled: false,
  freeDeliveryThreshold: 20,
  standardPrescriptionPrice: 9.65,
  threeMonthPrePayment: 31.25,
  twelveMonthPrePayment: 111.6,
  shopEnabledToggle: defaultToggle,
  getPatientAppConfig: () => Promise.resolve(),
};

export const ConfigContextValues = (): ConfigContextProps => {
  const { handleApiError } = useApiError();
  const { showDefaultErrorToast } = useToastContext();
  const [tagline, setTagline] = useState<{
    lineOne?: string;
    lineTwo?: string;
  }>({});
  const [freeDeliveryThreshold, setFreeDeliveryThreshold] = useState<number>(
    ConfigContextDefaults.freeDeliveryThreshold,
  );
  const [isShopEnabled, setIsShopEnabled] = useState<boolean>(true);
  const [isStandardDeliveryEnabled, setIsStandardDeliveryEnabled] =
    useState<boolean>(false);
  const [isExpressDeliveryEnabled, setIsExpressDeliveryEnabled] =
    useState<boolean>(false);
  const [standardPrescriptionPrice, setStandardPrescriptionPrice] =
    useState<number>(ConfigContextDefaults.standardPrescriptionPrice);
  const [threeMonthPrePayment, setThreeMonthPrePayment] = useState<number>(
    ConfigContextDefaults.threeMonthPrePayment,
  );
  const [twelveMonthPrePayment, setTwelveMonthPrePayment] = useState<number>(
    ConfigContextDefaults.twelveMonthPrePayment,
  );

  const [shopEnabledToggle, setShopEnabledToggle] = React.useState<
    Toggle<boolean | undefined>
  >({
    current: true,
    previous: undefined,
  });

  useEffect(() => {
    setShopEnabledToggle((prevState) => ({
      current: isShopEnabled,
      previous: prevState?.current,
    }));
  }, [isShopEnabled]);

  const getPatientAppConfig = async () => {
    try {
      const config = await getConfigAPI();
      setIsShopEnabled(
        config?.find((configItem) => configItem.key === 'ShopEnabled')
          ?.value === 'true',
      );
      setTagline({
        lineOne: config?.find((configItem) => configItem.key === 'Tagline')
          ?.value,
        lineTwo: config?.find(
          (configItem) => configItem.key === 'TaglineSecondLine',
        )?.value,
      });
      setFreeDeliveryThreshold(
        parseFloat(
          config?.find(
            (configItem) => configItem.key === 'FreeStandardDeliveryThreshold',
          )?.value ?? '',
        ),
      );
      setIsStandardDeliveryEnabled(
        config?.find(
          (configItem) => configItem.key === 'StandardDeliveryEnabled',
        )?.value === 'true',
      );
      setIsExpressDeliveryEnabled(
        config?.find(
          (configItem) => configItem.key === 'ExpressDeliveryEnabled',
        )?.value === 'true',
      );
      setStandardPrescriptionPrice(
        parseFloat(
          config?.find(
            (configItem) => configItem.key === 'StandardPrescriptionPrice',
          )?.value ?? '',
        ),
      );
      setThreeMonthPrePayment(
        parseFloat(
          config?.find(
            (configItem) => configItem.key === 'ThreeMonthPrePayment',
          )?.value ?? '',
        ),
      );
      setTwelveMonthPrePayment(
        parseFloat(
          config?.find(
            (configItem) => configItem.key === 'TwelveMonthPrePayment',
          )?.value ?? '',
        ),
      );
    } catch (error) {
      setIsShopEnabled(false);
      handleApiError(() => {
        showDefaultErrorToast();
      }, error);
    }
  };

  return {
    tagline,
    isShopEnabled,
    freeDeliveryThreshold,
    shopEnabledToggle,
    isStandardDeliveryEnabled,
    isExpressDeliveryEnabled,
    getPatientAppConfig,
    standardPrescriptionPrice,
    threeMonthPrePayment,
    twelveMonthPrePayment,
  };
};

export const ConfigContext = createContext<ConfigContextProps | undefined>(
  undefined,
);

export const useConfigContext = (): ConfigContextProps => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error('useConfigContext must be used within a ConfigProvider');
  }

  return context;
};
interface ConfigProviderProps {
  children?: ReactNode;
}

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  return (
    <ConfigContext.Provider value={ConfigContextValues()}>
      {children}
    </ConfigContext.Provider>
  );
};
