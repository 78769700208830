import React from 'react';

export interface WindowSizeType {
  width?: number;
  height?: number;
}

export const useWindowSize = (): WindowSizeType => {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = React.useState<WindowSizeType>({
    width: undefined,
    height: undefined,
  });
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};
