import { BasketOrderline, DependantBasket } from 'models/Basket';
import { PrescriptionItem } from 'models/PrescriptionItem';
import { Product } from 'models/Product';

export const getBasketOrderlineSubtotal = (products: BasketOrderline[]) =>
  products.reduce(
    (runningTotal, item) =>
      runningTotal +
      (item.isUsedAsContraceptive
        ? item.priceWhenUsedAsContraceptive ?? item.price
        : item.price) *
        item.quantity,
    0,
  );

export const getDependantBasketIndex = (
  dependantId: string,
  baskets: DependantBasket[],
) => baskets?.findIndex((basket) => basket.dependantId === dependantId);

export const getIndexOfProductInBasket = (
  productId: string,
  collection: BasketOrderline[],
) => collection.findIndex((product) => product.id === productId);

export const getOrderlineFromShopProduct = (
  product: Product,
  quantity?: number,
): BasketOrderline => ({
  name: product.title,
  price: product.price,
  quantity: quantity ?? 1,
  isPMed: product.isPMed ?? false,
  isPom: product.isPom ?? false,
  isPrescriptionItem: false,
  pMedQuestionComplete: false,
  id: product.id.toString(),
  imageFileName: product.imageFileName,
  orderLimit: product.orderLimit,
});

export const getOrderlineFromPrescriptionItem = (
  prescriptionItem: PrescriptionItem,
) => ({
  name: prescriptionItem.medicinalProductName,
  id: prescriptionItem.medicinalProductId,
  price: prescriptionItem.price ?? 0,
  priceWhenUsedAsContraceptive: prescriptionItem.priceWhenUsedAsContraceptive,
  quantity: 1,
  isPMed: false,
  isPrescriptionItem: true,
  pMedQuestionComplete: false,
  isUsedAsContraceptive: false,
  canBeUsedAsContraceptive:
    prescriptionItem.medicinalProductCanBeUsedAsContraceptive,
});
