import cx from 'classnames';
import { Link } from 'react-router-dom';
import { ProductSummary } from 'models/Product/Product';
import { ProductImage } from 'components/Products/ProductImage';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { ReactComponent as Chevron } from 'assets/icons/lawsat/ChevronRight.svg';
import { useTranslation } from 'react-i18next';

import styles from './ProductTile.module.scss';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';

interface ProductTileProps {
  product: ProductSummary;
  productLinkBaseUrl: string;
  withShadow?: boolean;
}

export const ProductTile = ({
  product,
  productLinkBaseUrl,
  withShadow,
}: ProductTileProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logCtaClick } = useAmplitudeContext();

  return (
    <Link
      className={cx(styles['product-tile'], {
        [styles['product-tile--with-shadow']]: withShadow,
      })}
      to={`${productLinkBaseUrl}/${product.id}`}
      onClick={() => {
        logCtaClick({
          label: product.title,
          pageName: pageName?.log,
          section: t('amplitude.productTile.section'),
        });
      }}
    >
      {!product.inStock && (
        <div className={styles['product-tile__out-of-stock']}>
          <Text size="xs">{t('OrderFlow.ProductDetail.outOfStock')}</Text>
        </div>
      )}
      <ProductImage
        className={styles['product-tile__image']}
        imageFileName={product.imageFileName}
      />
      <Heading className={styles['product-tile__title']} size="xs">
        {product.title}
      </Heading>
      <div className={styles['product-tile__footer']}>
        <Text className={styles['product-tile__price']}>
          £{product.price.toFixed(2)}
        </Text>
        <div className={styles['product-tile__chevron']}>
          <Chevron />
        </div>
      </div>
    </Link>
  );
};
