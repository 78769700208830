import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { Heading } from 'components/common/Heading';
import { Col, Container, Row } from 'components/common/grid';
import { Button } from 'components/common/Button';
import { ProductList } from 'components/Products/ProductList';
import { FormProgressBar } from 'components/FormProgressBar';
import { LandingPageSpinner } from 'components/common/Spinner';
import { routes } from 'routes';

import styles from './PomConfirmationConditionFlow.module.scss';

export const PomConfirmationConditionFlow = () => {
  const { t } = useTranslation();
  const { condition } = useConditionsContext();
  const { logPageView } = useAmplitudeContext();

  useEffect(() => {
    logPageView({
      pageName: t('amplitude.pomConfirmationConditionFlow.pageName', {
        conditionName: condition?.name,
      }),
    });
  }, []);

  if (!condition) {
    return <LandingPageSpinner />;
  }

  const conditionPomProducts = condition.products.filter(
    (product) => product.isPom,
  );

  return (
    <Container className={styles['pom-confirmation__container']}>
      <Row>
        <Col>
          <div className={styles['pom-confirmation__progress-container']}>
            <FormProgressBar numberOfStages={4} currentStage={4} />
          </div>
          <Heading
            size="sm"
            className={styles['pom-confirmation__heading']}
            tag="h6"
          >
            {t('pomForms.confirmationPageConditionFlow.thanks', {
              condition: condition.name,
            })}
          </Heading>
          <Heading
            size="sm"
            className={styles['pom-confirmation__heading']}
            tag="h6"
          >
            {t('pomForms.confirmationPageConditionFlow.info')}
          </Heading>

          {conditionPomProducts.length > 0 && (
            <ProductList
              products={conditionPomProducts}
              baseUrl={`${routes.SHOP.CONDITION}/${condition.id}${routes.POM.CONFIRMATION.PRODUCT}`}
              className={styles['pom-confirmation__products-section']}
            />
          )}

          <div className={styles['pom-confirmation__button-container']}>
            <Button
              appearance="outline"
              label={t('pomForms.confirmationPageConditionFlow.backButton', {
                condition: condition.name,
              })}
              link={{
                to: `${routes.SHOP.CONDITION}/${condition.id}`,
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
