import React from 'react';
import { useDialogState, DialogStateReturn } from 'reakit/Dialog';
import { Dialog, DialogBackdrop } from 'components/common/Modal';

export interface ModalContextProps {
  dialog: Partial<DialogStateReturn>;
  open: Function;
  close: Function;
}

export const ModalContextDefaults: ModalContextProps = {
  dialog: {},
  open: Function,
  close: Function,
};

export const ModalContext = React.createContext(ModalContextDefaults);

export const useModalContext = (): ModalContextProps =>
  React.useContext(ModalContext);

interface ModelProviderProps {
  children?: React.ReactNode;
}

export const ModalProvider = ({
  children,
  ...initialState
}: ModelProviderProps) => {
  const dialog = useDialogState(initialState);
  const [content, setContent] = React.useState<React.ReactNode>('');

  const open = (someContent: React.ReactNode) => {
    dialog.setVisible(true);
    setContent(someContent);
  };

  const close = () => {
    dialog.setVisible(false);
    setContent('');
  };

  return (
    <ModalContext.Provider
      value={{
        dialog: React.useMemo(() => dialog, Object.values(dialog)),
        open,
        close,
      }}
    >
      {children}
      <Dialog>{content}</Dialog>
      <DialogBackdrop />
    </ModalContext.Provider>
  );
};
