import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'contexts/ModalContext';
import { useDependantContext } from 'contexts/DependantContext';
import { Patient } from 'models/Patient';
import { routes } from 'routes';
import { Heading } from 'components/common/Heading';
import { Icon } from 'components/common/Icon';
import { Button } from 'components/common/Button';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';
import { usePatientContext } from 'contexts/PatientContext';
import styles from './SwitchPatientModal.module.scss';

interface SwitchPatientModalProps {
  dependantId: string;
  patient: Patient | null;
}

export const SwitchPatientModal: React.FC<SwitchPatientModalProps> = ({
  dependantId,
  patient,
}) => {
  const { close } = useModalContext();
  const { t } = useTranslation();
  const { dependants } = usePatientContext();
  const { setDependant } = useDependantContext();

  const filteredDependants = dependants?.filter(({ id }) => {
    return id !== Number(dependantId);
  });

  const mainUserName = `${patient?.firstName} ${patient?.lastName}`;

  return (
    <div className={styles['switch-patient-modal']}>
      <Heading size="lg" tag="h3">
        {t('PrescriptionFlow.switchDependant.title')}
      </Heading>
      <div className={styles['switch-patient-modal__list-item']}>
        <Link
          className={styles['switch-patient-modal__link']}
          to={`${routes.PRESCRIPTION.BASE}`}
          onClick={() => {
            setDependant(null);
            close();
          }}
        >
          <Heading size="sm" tag="h3">
            {t('PrescriptionFlow.switchDependant.youOption', {
              userName: mainUserName,
            })}
          </Heading>
          <Icon
            className={styles['switch-patient-modal__arrow']}
            icon={ChevronRight}
          />
        </Link>
      </div>
      {filteredDependants &&
        filteredDependants.map(({ id, firstName, lastName }) => {
          return (
            <div
              key={id}
              className={styles['switch-patient-modal__list-item']}
              data-testid="modal-dependant-list"
            >
              <Link
                key={id}
                className={styles['switch-patient-modal__link']}
                to={`${routes.DEPENDANT.PRESCRIPTION.BASE}${id}`}
                onClick={() => {
                  setDependant(null);
                  close();
                }}
              >
                <Heading size="sm" tag="h3">
                  {`${firstName} ${lastName}`}
                </Heading>
                <Icon
                  className={styles['switch-patient-modal__arrow']}
                  icon={ChevronRight}
                />
              </Link>
            </div>
          );
        })}

      <Button
        className={styles['switch-patient-modal__button']}
        label={t('PrescriptionFlow.switchDependant.add')}
        width={'full'}
        link={{ to: routes.DEPENDANT.ADD.BASE }}
        onClick={() => close()}
      />
    </div>
  );
};
