import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLandingPageContext } from 'contexts/LandingPageContext/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { ReactComponent as CheckMark } from 'assets/icons/CircleCheckmark.svg';
import { routes } from 'routes';

import styles from './FeaturesSection.module.scss';

interface FeatureProps {
  heading: string;
  subHeading: string;
}

const Feature = ({ heading, subHeading }: FeatureProps) => {
  return (
    <div className={styles['feature__container']}>
      <div className={styles['feature__icon']}>
        <CheckMark />
      </div>
      <div className={styles['feature__text-col']}>
        <Heading tag="h5">{heading}</Heading>
        <Text size="lg">{subHeading}</Text>
      </div>
    </div>
  );
};

export const FeaturesSection = () => {
  const { t } = useTranslation();
  const { logCtaClick, logSectionView } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.featuresSection.sectionName'),
      });
    }
  }, [isInViewport]);

  const features: FeatureProps[] = [
    {
      heading: t('travelVaccinations.landingPage.features.featureOne.heading'),
      subHeading: t(
        'travelVaccinations.landingPage.features.featureOne.subHeading',
      ),
    },
    {
      heading: t('travelVaccinations.landingPage.features.featureTwo.heading'),
      subHeading: t(
        'travelVaccinations.landingPage.features.featureTwo.subHeading',
      ),
    },
    {
      heading: t(
        'travelVaccinations.landingPage.features.featureThree.heading',
      ),
      subHeading: t(
        'travelVaccinations.landingPage.features.featureThree.subHeading',
      ),
    },
    {
      heading: t('travelVaccinations.landingPage.features.featureFour.heading'),
      subHeading: t(
        'travelVaccinations.landingPage.features.featureFour.subHeading',
      ),
    },
  ];

  return (
    <div className={styles['features-section']} ref={elementRef}>
      <Col className={styles['features-section__col']}>
        {features.map((feature) => (
          <Feature {...feature} key={feature.heading} />
        ))}
        <div className={styles['features-section__button-container']}>
          <Button
            label={t('travelVaccinations.bookCtaLabel')}
            link={{ to: routes.TRAVELVACCINATIONS.BOOK }}
            variant="accent"
            appearance="solid"
            className={styles['features-section__cta-overrides']}
            onClick={() =>
              logCtaClick({
                label: t('travelVaccinations.bookCtaLabel'),
                pageName: pageName?.log,
                section: t('amplitude.featuresSection.section'),
              })
            }
          />

          <Button
            label={t('travelVaccinations.landingPage.features.priceCtaLabel')}
            link={{ to: routes.TRAVELVACCINATIONS.PRICING }}
            variant="accent"
            appearance="outline"
            className={styles['features-section__cta-overrides']}
            onClick={() =>
              logCtaClick({
                label: t(
                  'travelVaccinations.landingPage.features.priceCtaLabel',
                ),
                pageName: pageName?.log,
                section: t('amplitude.featuresSection.section'),
              })
            }
          />
        </div>
      </Col>
    </div>
  );
};
