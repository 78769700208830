import { Route, Switch, useLocation } from 'react-router-dom';
import { PaymentProvider } from 'contexts/PaymentContext';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { OrderPMed } from 'containers/Order/OrderPMed';
import { PMedQuestionnaire } from 'containers/PMedQuestionnaire';
import { OrderFail } from 'containers/Order/OrderFail';
import { useBasketContext } from 'contexts/BasketContext';
import { useEffect } from 'react';
import { OrderSuccess } from 'containers/Order/OrderSuccess';
import { routes } from 'routes';
import { Checkout } from 'pages/Checkout';

export const Order = () => {
  const location = useLocation();
  const { refreshBasketTimeout } = useBasketContext();

  useEffect(() => {
    refreshBasketTimeout();
  }, [location]);

  return (
    <Switch>
      <PrivateRoute path={routes.ORDER.PMED} exact>
        <OrderPMed />
      </PrivateRoute>
      <PrivateRoute path={`${routes.ORDER.PMED}/:id`} exact>
        <PMedQuestionnaire />
      </PrivateRoute>
      <PaymentProvider>
        <PrivateRoute path={`${routes.ORDER.CHECKOUT}/:dependantId`} exact>
          <Checkout />
        </PrivateRoute>
        <PrivateRoute path={routes.ORDER.CHECKOUT} exact>
          <Checkout />
        </PrivateRoute>
        <Route path={routes.ORDER.FAIL} exact>
          <OrderFail />
        </Route>
        <Route path={routes.ORDER.SUCCESS} exact>
          <OrderSuccess />
        </Route>
      </PaymentProvider>
    </Switch>
  );
};
