import { useCookiesContext } from 'contexts/CookiesContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/common/Button';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { routes } from 'routes';
import styles from './Cookies.module.scss';

interface ArticleListProps {
  textArray: string[];
}

const ArticleList = ({ textArray }: ArticleListProps) => {
  return (
    <ul className={styles['cookies__article']}>
      {textArray.map((i, index) => {
        return (
          <li className={styles['cookies__article-list']} key={index}>
            <Text>{i}</Text>
          </li>
        );
      })}
    </ul>
  );
};

export const Cookies = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { approveCookies } = useCookiesContext();

  const titleStyles = 'cookies__title';

  const onAccept = () => {
    history.push(routes.BASE);
    approveCookies();
  };

  return (
    <>
      <Container className={styles['cookies']}>
        <Row>
          <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 8, offset: 2 }}>
            <Heading className={styles[titleStyles]}>
              {t('Cookies.title')}
            </Heading>
            <Heading className={styles[titleStyles]} size="md">
              {t('Cookies.subtitle')}
            </Heading>
            <ArticleList
              textArray={t('Cookies.paragraphs', {
                returnObjects: true,
              })}
            />
            <Text>{t('Cookies.footer')}</Text>
            <div className={styles['cookies__actions']}>
              <Button
                testId={'accept-button'}
                label={t('Cookies.acceptButton')}
                onClick={onAccept}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
