import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { routes } from 'routes';

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { authStatus, setAuthFlags } = useAuthContext();

  React.useEffect(() => {
    authStatus ? setAuthFlags(true) : setAuthFlags(false);
  }, []);

  return (
    <Route
      {...rest}
      render={() =>
        authStatus ? (
          <>{children} </>
        ) : (
          <Redirect
            to={{
              pathname: routes.BASE,
            }}
          />
        )
      }
    />
  );
};
