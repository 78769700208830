import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useToastContext } from 'contexts/ToastContext';
import { usePatientContext } from 'contexts/PatientContext';
import { Container, Row, Col } from 'components/common/grid';
import { FormProgressBar } from 'components/FormProgressBar';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { CheckBox } from 'components/common/CheckBox';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { InfoPanel } from 'components/InfoPanel';
import { ReactComponent as CircleInfo } from 'assets/icons/lawsat/CircleInfo.svg';
import { routes } from 'routes';
import { useHistory } from 'react-router';

import styles from './PrescriptionSCR.module.scss';

export const PrescriptionSCR = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast } = useToastContext();
  const { savePatientSCRAccepted } = usePatientContext();
  const schema = yup.object().shape({
    acceptSCR: yup.boolean().oneOf([true]),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('PrescriptionFlow.scr.formErrors.toastTitle'),
    });
  };

  const onSubmit = async () => {
    savePatientSCRAccepted();
    history.push(routes.PRESCRIPTION.COMPLETED);
  };

  return (
    <Container className={styles['prescription-scr']}>
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <div className={styles['prescription-scr__progress-container']}>
            <FormProgressBar numberOfStages={5} currentStage={5} />
          </div>
          <Heading
            tag="h2"
            size="xl"
            className={styles['prescription-scr__title']}
          >
            {t('PrescriptionFlow.scr.title')}
          </Heading>
          <div>
            <form
              data-testid="prescription-scr-form"
              className={styles['prescription-scr__form']}
              onSubmit={handleSubmit(onSubmit, invalid)}
            >
              <Controller
                control={control}
                defaultValue={false}
                name="acceptSCR"
                isRequired
                render={(props) => (
                  <>
                    <CheckBox
                      testId="checkbox-button"
                      label={t('PrescriptionFlow.scr.consentLabel')}
                      className={styles['prescription-scr__checkbox']}
                      {...props}
                    />
                  </>
                )}
              />
              <InfoPanel
                title={t('PrescriptionFlow.scr.infoTitle')}
                icon={CircleInfo}
                text={t('PrescriptionFlow.scr.infoText')}
                link={{
                  to: '/prescription/onboarding/scr/declaration',
                  text: t('PrescriptionFlow.scr.infoLink'),
                  newTab: false,
                }}
              />
              <AppBarBottom fullWidth>
                <Button
                  width="full"
                  className={styles['prescription-exemption__submit-button']}
                  testId="submit-button"
                  type="submit"
                  appearance="solid"
                  label={t('PrescriptionFlow.scr.submitButton')}
                ></Button>
              </AppBarBottom>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
