import React from 'react';
import cx from 'clsx';
import { useTranslation } from 'react-i18next';
import { OrderHistory } from 'models/OrderHistory';
import { OrderLine } from 'models/Order';
import { Heading } from 'components/common/Heading';
import { Label } from 'components/common/Label';
import { Text } from 'components/common/Text';
import { OrderStatusTile } from 'components/OrderStatusTile';
import { ReactComponent as ShopIcon } from 'assets/icons/lawsat/Shop.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/lawsat/Time.svg';
import { ReactComponent as TruckIcon } from 'assets/icons/lawsat/Truck.svg';
import { ReactComponent as PillIcon } from 'assets/icons/lawsat/Pill-Outline.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/lawsat/Home.svg';
import { formatDateString, formatDowMonthYear } from 'utils/transformers';

import styles from './OrderDetail.module.scss';

export interface OrderHeaderProps {
  order: OrderHistory;
}

const OrderHeader = ({ order }: OrderHeaderProps) => {
  const { t } = useTranslation();
  const date = order.orderReceivedDateTime
    ? formatDateString(order.orderReceivedDateTime)
    : '';

  const title = order.containsPrescriptionItems
    ? t('Orders.prescriptionTitle', {
        date,
      })
    : t('Orders.shopOrderTitle', {
        date,
      });

  return (
    <Heading size="lg" tag="h3" className={styles['order-detail__main-title']}>
      {title}
    </Heading>
  );
};

interface IconRowProps {
  icon?: React.FunctionComponent;
  isOrder?: boolean;
  children?: React.ReactNode;
  cancelled?: boolean;
}

const IconRow = ({
  icon,
  children,
  isOrder,
  cancelled = false,
}: IconRowProps) => {
  const Icon = icon;
  const styleSwitch = isOrder ? 'order-' : '';

  return (
    <div className={styles['order-detail__row']}>
      <div
        className={cx(
          styles[`order-detail__${styleSwitch}icon-column`],
          cancelled &&
            isOrder &&
            styles[`order-detail__order-icon-column--cancelled`],
        )}
      >
        {Icon && <Icon />}
      </div>
      <div className={styles[`order-detail__${styleSwitch}content-column`]}>
        {children}
      </div>
    </div>
  );
};

interface IconLabelRowProps {
  icon?: React.FunctionComponent;
  labelText: string;
  labelValue: string;
  text?: string;
}

const IconLabelRow = ({
  icon,
  labelText,
  labelValue,
  text,
}: IconLabelRowProps) => {
  return (
    <IconRow icon={icon}>
      <Label size="md" className={styles['order-detail__label']}>
        {labelText}
      </Label>{' '}
      <Text tag="span">{labelValue}</Text>
      {text && (
        <Text tag="p" className={styles['order-detail__content']}>
          {text}
        </Text>
      )}
    </IconRow>
  );
};

interface OrderLineRowProps {
  orderLine: OrderLine;
}

const OrderLineRow = ({ orderLine }: OrderLineRowProps) => {
  const { t } = useTranslation();
  const { isPom, pomStatus, quantity, name, price, isPrescriptionItem } =
    orderLine;
  const isRejectedPom = isPom && pomStatus === 'Rejected';
  let icon;
  if (isPrescriptionItem) {
    icon = PillIcon;
  } else {
    const count = () => {
      return (
        <Label
          size="md"
          className={cx(
            styles['order-detail__quantity'],
            isRejectedPom && styles['order-detail__quantity--cancelled'],
          )}
        >
          {quantity}
        </Label>
      );
    };
    icon = count;
  }

  return (
    <IconRow icon={icon} isOrder={true} cancelled={isRejectedPom}>
      <div className={styles['order-detail__row']}>
        <div
          className={cx(
            isRejectedPom &&
              styles['order-detail__item-name-container--cancelled'],
          )}
        >
          <span
            className={cx(
              styles['order-detail__item-name'],
              isRejectedPom && styles['order-detail__item-name--cancelled'],
            )}
          >
            {name}
          </span>
          {isRejectedPom && (
            <>
              <span className={styles['order-detail__item-name__spacer-dot']}>
                &middot;
              </span>
              <span>{t('OrderDetails.rejected')}</span>
            </>
          )}
        </div>
        <div
          className={cx(
            styles['order-detail__item-price'],
            pomStatus === 'Rejected' &&
              styles['order-detail__item-price--cancelled'],
          )}
        >
          £{price.toFixed(2)}
        </div>
      </div>
    </IconRow>
  );
};

const extractDetails = (order: OrderHistory) => {
  const { deliveryOption, deliveryAddress } = order;
  const collecting = deliveryOption === 'Collection';

  const address = [
    deliveryAddress.line1,
    deliveryAddress.line2,
    deliveryAddress.line3,
    deliveryAddress.county,
    deliveryAddress.townCity,
    deliveryAddress.postCode,
  ]
    .filter((line) => !!line)
    .join(', ');

  return {
    address,
    collecting,
  };
};

export interface OrderDetailProps {
  order: OrderHistory;
}

export const OrderDetail = ({ order }: OrderDetailProps) => {
  const { t } = useTranslation();
  const costRowClasses = styles['order-detail__cost-row'];
  const labelClasses = styles['order-detail__label'];
  const { deliveryName, orderLines, containsPrescriptionItems } = order;
  const { address, collecting } = extractDetails(order);

  return (
    <div className={styles['order-detail']}>
      <OrderHeader order={order} />
      {order.orderStatus && (
        <>
          <Heading
            size="xs"
            tag="h6"
            className={styles['order-detail__status-title']}
          >
            {t('OrderDetails.statusTitle')}
          </Heading>

          <OrderStatusTile
            orderStatus={order.orderStatus}
            deliveryOption={order.deliveryOption}
            hasPrescriptions={containsPrescriptionItems}
          />
        </>
      )}

      <Heading
        size="xs"
        tag="h6"
        className={styles['order-detail__details-title']}
      >
        {t('OrderDetails.orderDetailsTitle')}
      </Heading>

      {orderLines?.map((orderLine) => (
        <OrderLineRow orderLine={orderLine} key={orderLine.id} />
      ))}

      <IconRow>
        <div className={costRowClasses}>
          <Label size="md" className={labelClasses}>
            {t('OrderDetails.subTotal')}
          </Label>
          <div>£{order.subTotal.toFixed(2)}</div>
        </div>
        <div className={costRowClasses}>
          <Label size="md" className={labelClasses}>
            {t('OrderDetails.delivery')}
          </Label>
          £{order.deliveryCost.toFixed(2)}
        </div>
        {order.refunded > 0 && (
          <div className={costRowClasses}>
            <Label size="md" className={labelClasses}>
              {t('OrderDetails.refunded')}
            </Label>
            £{order.refunded.toFixed(2)}
          </div>
        )}
        <div className={styles['order-detail__total-cost-row']}>
          <Label size="md">{t('OrderDetails.orderTotal')}</Label>
          <Heading
            size="lg"
            tag="h3"
            className={styles['order-detail__total-cost-amount']}
          >
            £{order.totalCost.toFixed(2)}
          </Heading>
        </div>
      </IconRow>

      {collecting && (
        <>
          {deliveryName && (
            <IconLabelRow
              icon={ShopIcon}
              labelText={t('OrderDetails.collectFrom')}
              labelValue={deliveryName}
              text={address}
            />
          )}
          {order.orderStatus !== 'Cancelled' && order.deliveryDate && (
            <IconLabelRow
              icon={TimeIcon}
              labelText={t('OrderDetails.deliveryReady')}
              labelValue={formatDowMonthYear(order.deliveryDate)}
            />
          )}
        </>
      )}

      {!collecting && (
        <>
          {deliveryName && (
            <IconLabelRow
              icon={HomeIcon}
              labelText={t('OrderDetails.deliverTo')}
              labelValue={deliveryName}
              text={address}
            />
          )}
          {order.orderStatus !== 'Cancelled' && order.deliveryDate && (
            <IconLabelRow
              icon={TruckIcon}
              labelText={t('OrderDetails.arrives')}
              labelValue={formatDowMonthYear(order.deliveryDate)}
            />
          )}

          {order.orderStatus !== 'Cancelled' && order.deliveryTimeSlot && (
            <IconLabelRow
              icon={TimeIcon}
              labelText={t('OrderDetails.deliverBetween')}
              labelValue={t(`OrderDetails.deliver${order.deliveryTimeSlot}`)}
            />
          )}
        </>
      )}
    </div>
  );
};
