import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Container, Col } from 'components/common/grid';
import { RichTextViewer } from 'components/RichTextViewer';
import cx from 'classnames';

import styles from './ConditionDescriptionSection.module.scss';

interface ConditionDescriptionSectionProps {
  description?: string;
  classname?: string;
}

export const ConditionDescriptionSection = ({
  description,
  classname,
}: ConditionDescriptionSectionProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logSectionView } = useAmplitudeContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.conditionDescriptionSection.section'),
      });
    }
  }, [isInViewport]);

  if (!description) return null;

  return (
    <div ref={elementRef}>
      <Container
        className={cx(styles['condition-description-section'], classname)}
      >
        <Col>
          <RichTextViewer richText={description} />
        </Col>
      </Container>
    </div>
  );
};
