import React from 'react';
import cx from 'classnames';
import styles from './Label.module.scss';

interface LabelProps {
  id?: string;
  tag?: 'p' | 'span' | 'label';
  className?: string;
  inputId?: string;
  size?: 'sm' | 'md' | 'lg';
  testId?: string;
  error?: boolean;
  children?: React.ReactNode;
}

export const Label = ({
  id,
  tag,
  className,
  inputId,
  size = 'md',
  testId,
  error = false,
  children,
}: LabelProps) => {
  const Component = tag ? tag : 'label';
  const labelClasses = cx(`${styles['label']}`, `${styles[`label-${size}`]}`, {
    [`${className}`]: className,
    [styles['error']]: error,
  });

  return (
    <Component
      id={id}
      htmlFor={inputId}
      className={labelClasses}
      data-testid={testId}
    >
      {children}
    </Component>
  );
};
