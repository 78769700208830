import React from 'react';
import cx from 'classnames';
import styles from './Text.module.scss';

interface TextProps {
  tag?: 'p' | 'span' | 'li' | 'label' | 'blockquote' | 'div';
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  testId?: string;
  id?: string;
  truncate?: boolean;
  children?: React.ReactNode;
}

export const Text = ({
  tag = 'p',
  className,
  size = 'md',
  testId,
  id,
  truncate = false,
  children,
}: TextProps) => {
  const Component = tag;

  const textClasses = cx(`${styles['text']}`, `${styles[`text-${size}`]}`, {
    [`${className}`]: className,
    truncate,
  });

  return (
    <Component className={textClasses} data-testid={testId} id={id}>
      {children}
    </Component>
  );
};
