import React from 'react';
import { Container, Col, Row } from 'components/common/grid';
import { PaymentResult } from 'components/PaymentResult';
import styles from './OrderFail.module.scss';

export const OrderFail = () => {
  return (
    <div className={styles['order-fail']}>
      <Container>
        <Row>
          <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <PaymentResult.Fail />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
