interface OrderSummary {
  orderGroupId: number;
  dependantId: string | undefined;
}

export const useRetainOrderInfo = () => {
  const clearRetainedOrderInfo = (): void => {
    window.sessionStorage.removeItem('RetainedOrderInfo');
  };

  const saveRetainedOrderInfo = (summary: OrderSummary) => {
    window.sessionStorage.setItem('RetainedOrderInfo', JSON.stringify(summary));
  };

  const getRetainedOrderInfo = () => {
    const storedRetainedOrderInfo =
      window.sessionStorage.getItem('RetainedOrderInfo');
    if (storedRetainedOrderInfo) {
      const parsedRetainedOrderInfo: OrderSummary = JSON.parse(
        storedRetainedOrderInfo,
      );
      return parsedRetainedOrderInfo;
    }
  };

  return {
    clearRetainedOrderInfo,
    saveRetainedOrderInfo,
    getRetainedOrderInfo,
  };
};
