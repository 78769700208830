import React, { useState } from 'react';
import cx from 'classnames';
import { ReactComponent as FallbackIcon } from 'assets/icons/lawsat/Photo.svg';

import styles from './Image.module.scss';

interface ImageProps {
  imageFileName?: string;
  className?: string;
  alt?: string;
}

export const Image = ({ imageFileName, className, alt }: ImageProps) => {
  const [error, setError] = useState(!imageFileName);

  const imageUrlMobile = `${process.env.REACT_APP_IMG_URL}mobile/retina/${imageFileName}`;
  const imageUrlDesktop = `${process.env.REACT_APP_IMG_URL}desktop/retina/${imageFileName}`;

  return (
    <>
      {error || !imageFileName ? (
        <div data-testid="fallback-img" className={styles['image-error']}>
          <FallbackIcon role="presentation" />
        </div>
      ) : (
        <img
          data-testid="image-element"
          alt={alt}
          role="presentation"
          className={cx(styles['image'], className)}
          src={imageUrlMobile}
          srcSet={`${imageUrlMobile} 200w, ${imageUrlDesktop} 456w`}
          sizes="(max-width: 1024px) 200px, 456px"
          onLoad={() => setError(false)}
          onError={() => setError(true)}
        />
      )}
    </>
  );
};
