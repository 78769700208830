import React from 'react';
import cx from 'classnames';
import { Text } from 'components/common/Text';
import { BasketOrderline } from 'models/Basket';

import styles from './OrderLineSummary.module.scss';

interface OrderSummaryLineProps {
  item: BasketOrderline;
}

export const OrderLineSummary = ({ item }: OrderSummaryLineProps) => {
  const { name, quantity, price, isPrescriptionItem, isUsedAsContraceptive } =
    item;
  return (
    <div
      className={cx(
        styles['order-summary-line'],
        isPrescriptionItem && styles['order-summary-line--prescription-only'],
      )}
    >
      <Text className={styles['order-summary-line__col1']}>{name}</Text>
      {!isPrescriptionItem && (
        <Text className={styles['order-summary-line__col2']}>{quantity}</Text>
      )}

      <Text className={styles['order-summary-line__col3']}>
        £{(isUsedAsContraceptive ? 0 : price * (quantity ?? 1)).toFixed(2)}
      </Text>
    </div>
  );
};
