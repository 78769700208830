/* eslint-disable sonarjs/no-duplicate-string */
import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { routes } from 'routes';
import { useAuthContext } from 'contexts/AuthContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { Button } from 'components/common/Button';
import { BasketIcon } from 'components/Basket/BasketIcon';
import { ReactComponent as BustIcon } from 'assets/icons/lawsat/User.svg';
import { ReactComponent as PlaneIcon } from 'assets/icons/lawsat/Plane.svg';
import { ReactComponent as BagIcon } from 'assets/icons/lawsat/MedicalBag.svg';
import { ReactComponent as PillIcon } from 'assets/icons/lawsat/Pill.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/lawsat/ChevronLeft.svg';
import { isRouteActive } from 'components/Layout/NavBar';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { ConditionGroup } from 'models/Condition/Condition';

import styles from './MobileMenuItems.module.scss';

const activeLinkStyle = styles['menu-items--active'];
const buttonWithChevronStyle = styles['menu-items__button-with-chevron'];

interface MobileMenuItemsProps {
  isMenuOpen: boolean;
  closeMenu: () => void;
}

export const MobileMenuItems = ({
  isMenuOpen,
  closeMenu,
}: MobileMenuItemsProps) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const { pathname } = useLocation();
  const { login, authStatus } = useAuthContext();
  const { conditionGroups, getConditionGroups } = useConditionsContext();
  const { pageName } = useLandingPageContext();
  const { logCtaClick } = useAmplitudeContext();

  const [showBaseMenu, setShowBaseMenu] = useState(true);
  const [showConditionGroupsOverlay, setShowConditionGroupsOverlay] =
    useState(false);
  const [showConditionsOverlay, setShowConditionsOverlay] = useState(false);
  const [selectedConditionGroup, setSelectedConditionGroup] =
    useState<ConditionGroup | null>(null);

  let activeConditionGroupId: number | undefined;
  if (pathname.match(`${routes.SHOP.CONDITION}/`)) {
    const conditionId = pathname.split(`${routes.SHOP.CONDITION}/`)[1];
    activeConditionGroupId = conditionGroups?.filter(
      (group) =>
        group.conditions.filter(
          (condition) => condition.id == parseInt(conditionId),
        ).length > 0,
    )[0].id;
  }

  const handleLogCtaClick = (label: string) => {
    logCtaClick({
      label: label,
      pageName: pageName?.log,
      section: t('amplitude.navbar.section'),
    });
  };

  const closeAllOverlays = useCallback(() => {
    setShowBaseMenu(true);
    setShowConditionGroupsOverlay(false);
    setShowConditionsOverlay(false);
  }, []);

  useEffect(() => {
    getConditionGroups();
  }, []);

  useEffect(() => {
    !isMenuOpen && closeAllOverlays();
  }, [isMenuOpen]);

  if (!conditionGroups) return null;

  const conditionGroupsToShow = conditionGroups?.filter(
    (condition) => condition.showInShop,
  );

  return (
    <div className={styles['menu-items']}>
      {showBaseMenu && (
        <div className={styles['menu-items__button-container']}>
          <div className={buttonWithChevronStyle}>
            <Button
              label={t('NavBarLabels.shop')}
              testId="mobile-shop-button"
              appearance="ghost"
              variant="ui"
              onClick={() => {
                setShowBaseMenu(false);
                setShowConditionGroupsOverlay(true);
              }}
              className={cx(
                isRouteActive(pathname, routes.SHOP.BASE) && activeLinkStyle,
              )}
              icon={{ element: BagIcon }}
            />
            <ChevronRight />
          </div>

          <Button
            label={t('NavBarLabels.prescriptions')}
            testId="mobile-prescriptions-button"
            appearance="ghost"
            variant="ui"
            onClick={() => {
              handleLogCtaClick(t('NavBarLabels.prescriptions'));
              authStatus
                ? navigate.push(routes.PRESCRIPTION.BASE)
                : login({ redirectToUrl: routes.PRESCRIPTION.BASE });
            }}
            className={cx(
              isRouteActive(pathname, routes.PRESCRIPTION.BASE) &&
                activeLinkStyle,
            )}
            icon={{ element: PillIcon }}
          />

          <Button
            label={t('NavBarLabels.travelVaccinations')}
            testId="mobile-travel-vaccinations-button"
            appearance="ghost"
            variant="ui"
            onClick={() => {
              handleLogCtaClick(t('NavBarLabels.travelVaccinations'));
              navigate.push(routes.TRAVELVACCINATIONS.BASE);
            }}
            className={cx(
              isRouteActive(pathname, routes.TRAVELVACCINATIONS.BASE) &&
                activeLinkStyle,
            )}
            icon={{ element: PlaneIcon }}
          />

          <div className={styles['menu-items__divider']} />

          <Button
            label={t('NavBarLabels.account')}
            testId="mobile-account-button"
            appearance="ghost"
            variant="ui"
            onClick={() => {
              handleLogCtaClick(t('NavBarLabels.account'));
              authStatus
                ? navigate.push(routes.ACCOUNT.BASE)
                : login({ redirectToUrl: routes.ACCOUNT.BASE });
            }}
            className={cx(
              isRouteActive(pathname, routes.ACCOUNT.BASE) && activeLinkStyle,
            )}
            icon={{ element: BustIcon, alignment: 'left' }}
          />

          <BasketIcon
            type="label"
            className={cx(
              isRouteActive(pathname, routes.BASKET) && activeLinkStyle,
            )}
          />
        </div>
      )}

      {showConditionGroupsOverlay && (
        <div>
          <Button
            label={t('MobileMenuItems.backToMenu')}
            appearance="ghost"
            variant="ui"
            size="sm"
            width="full"
            className={styles['menu-items__back-button']}
            onClick={() => {
              setShowBaseMenu(true);
              setShowConditionGroupsOverlay(false);
            }}
            icon={{ element: ChevronLeft, alignment: 'left' }}
          />
          <Button
            label={t('NavBarLabels.shop')}
            testId={`mobile-shop-button`}
            appearance="ghost"
            variant="ui"
            width="full"
            className={cx(
              isRouteActive(pathname, routes.SHOP.BASE) && activeLinkStyle,
              styles['menu-items__shop-button'],
            )}
            onClick={() => {
              handleLogCtaClick(t('NavBarLabels.shop'));
              navigate.push(routes.SHOP.BASE);
            }}
          />
          {conditionGroupsToShow.map((group) => (
            <Button
              key={group.id}
              label={group.name}
              appearance="ghost"
              variant="ui"
              size="sm"
              width="full"
              className={cx(
                isRouteActive(
                  pathname,
                  `${routes.SHOP.CONDITIONGROUP}/${group.id}`,
                  true,
                ) && activeLinkStyle,
                activeConditionGroupId == group.id ? activeLinkStyle : null,
              )}
              icon={{ element: ChevronRight, alignment: 'right' }}
              onClick={() => {
                handleLogCtaClick(group.name);
                setSelectedConditionGroup(group);
                setShowConditionGroupsOverlay(false);
                setShowConditionsOverlay(true);
              }}
            />
          ))}
        </div>
      )}
      {showConditionsOverlay && selectedConditionGroup && (
        <div>
          <Button
            label={t('MobileMenuItems.shopByCondition')}
            appearance="ghost"
            variant="ui"
            size="sm"
            width="full"
            className={styles['menu-items__back-button--condition']}
            onClick={() => {
              setShowConditionGroupsOverlay(true);
              setShowConditionsOverlay(false);
            }}
            icon={{ element: ChevronLeft, alignment: 'left' }}
          />
          <Button
            label={`All ${selectedConditionGroup.name}`}
            appearance="ghost"
            variant="ui"
            size="sm"
            width="full"
            className={cx(
              isRouteActive(
                pathname,
                `${routes.SHOP.CONDITIONGROUP}/${selectedConditionGroup.id}`,
                true,
              ) && activeLinkStyle,
            )}
            onClick={() => {
              handleLogCtaClick(`All ${selectedConditionGroup.name}`);
              closeAllOverlays();
              closeMenu();
              navigate.push(
                `${routes.SHOP.CONDITIONGROUP}/${selectedConditionGroup.id}`,
              );
            }}
          />
          {selectedConditionGroup?.conditions
            .filter((condition) => condition.showInShop)
            .filter(
              (condition) => !condition.name.toLowerCase().includes('general'),
            )
            .map((condition) => (
              <Button
                key={condition.id}
                label={condition.name}
                appearance="ghost"
                variant="ui"
                size="sm"
                width="full"
                className={cx(
                  isRouteActive(
                    pathname,
                    `${routes.SHOP.CONDITION}/${condition.id}`,
                    true,
                  ) && activeLinkStyle,
                )}
                onClick={() => {
                  handleLogCtaClick(condition.name);
                  closeAllOverlays();
                  closeMenu();
                  navigate.push(`${routes.SHOP.CONDITION}/${condition.id}`);
                }}
              />
            ))}
        </div>
      )}
    </div>
  );
};
