import React from 'react';
import cx from 'classnames';
import { Icon } from 'components/common/Icon';
import { Text } from 'components/common/Text';
import styles from './IconInfoSpan.module.scss';

interface IconInfoSpanProps {
  className?: string;
  testId?: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  text: string | undefined | null;
  size?: 'sm' | 'md' | 'lg';
}

export const IconInfoSpan: React.FC<IconInfoSpanProps> = ({
  icon,
  text,
  className,
  testId,
  size = 'md',
}) => {
  const calcIconSize = (): 'xs' | 'sm' | 'md' => {
    switch (size) {
      case 'sm':
        return 'xs';
      case 'lg':
        return 'md';
      default:
        return 'sm';
    }
  };
  return (
    <Text
      testId={testId}
      size={size}
      className={cx(styles['icon-info-span'], { [`${className}`]: className })}
    >
      <Icon
        testId={`${testId}_icon`}
        icon={icon}
        size={calcIconSize()}
        className={styles['icon-info-span__info-icon']}
      ></Icon>
      {text}
    </Text>
  );
};
