export const routes = {
  BASE: '/',
  CONTACT: '/contact',
  DEFAULT_AUTH_ROUTE: '/dashboard',
  LOGIN: '/login',
  TERMS: '/terms-conditions',
  PRIVACY: '/privacy-policy',
  COOKIES: '/cookies',
  BASKET: '/basket',
  TRAVELVACCINATIONS: {
    BASE: '/travel-vaccinations',
    BOOK: '/travel-vaccinations/book',
    PRICING: '/travel-vaccinations/pricing',
    INFO: '/travel-vaccinations/info',
  },
  SIGN_UP: {
    BASE: '/sign-up/',
    COMPLETED: '/sign-up/completed',
  },
  PRESCRIPTION: {
    BASE: '/prescription/',
    ADD: '/prescription/add',
    DETAIL: '/prescription/detail',
    REMINDER: '/prescription/reminder',
    COMPLETED: '/prescription/completed',
    EXEMPTION: '/prescription/exemption',
    EXEMPTION_SELECT: '/prescription/exemption/select',
    EXEMPTION_EXP: '/prescription/exemption/expire',
    ONBOARDING: {
      BASE: '/prescription/onboarding',
      NEXT: '/prescription/onboarding/next-steps',
      REVIEW_CONTACT: '/prescription/onboarding/review-contact',
      SELECT_PHARMACY: '/prescription/onboarding/pharmacy',
      GP: '/prescription/onboarding/gp',
      SELECT_GP: '/prescription/onboarding/select-gp',
      EXEMPTION: '/prescription/onboarding/exemption',
      EXEMPTION_SELECT: '/prescription/onboarding/exemption/select',
      EXEMPTION_EXP: '/prescription/onboarding/exemption/expire',
      SCR: '/prescription/onboarding/scr',
      SCR_DEC: '/prescription/onboarding/scr/declaration',
    },
  },
  DEPENDANT: {
    BASE: '/dependant/',
    ADD: {
      BASE: '/dependant/add/',
      CONTACT_DETAILS: '/dependant/add/contact-details',
      DETAIL: '/dependant/add/detail',
      ADDRESS: '/address',
      PHARMACY: '/select-pharmacy',
      ADDED: '/added',
    },
    PRESCRIPTION: {
      BASE: '/dependant/prescription/',
      ADD: '/add',
      DETAIL: '/detail',
      REMINDER: '/reminder',
      COMPLETED: '/completed',
      EXEMPTION_SELECT: '/exemption/select',
      EXEMPTION_EXP: '/exemption/expire',
      EXEMPTION: '/exemption',
      ONBOARDING: {
        BASE: '/dependant/prescription/onboarding/',
        NEXT: '/next-steps',
      },
    },
    ACCOUNT: {
      BASE: '/dependant/account/',
      EDIT: {
        CONTACT_DETAILS: '/edit/contact-details',
        ADDRESS: '/edit/address',
        PHARMACY: '/edit/pharmacy',
        VIEW_GP: '/edit/gp',
        SELECT_GP: '/edit/gp/select',
        EXEMPTION: '/edit/exemption',
        EXEMPTION_EXPIRE: '/edit/exemption-expire',
      },
    },
  },
  PRODUCT: {
    BASE: '/product',
  },
  POM: {
    BASE: '/pom',
    SELECT: '/pom/select-condition',
    CONFIRMATION: {
      BASE: '/pom/confirmation',
      PRODUCT: '/pom/confirmation/product',
    },
  },
  GENERAL_HEALTH: {
    BASE: '/general-health',
    PT1: '/general-health/pt1',
    PT2: '/general-health/pt2',
  },
  HELP: {
    BASE: '/help',
  },
  ACCOUNT: {
    BASE: '/account',
    ORDERS: '/account/orders',
    EDIT: {
      BASE: '/account/edit',
      CONTACT_DETAILS: '/account/edit/contact-details',
      PHARMACY: '/account/edit/pharmacy',
      VIEW_GP: '/account/edit/gp',
      SELECT_GP: '/account/edit/gp/select',
      EXEMPTION: '/account/edit/exemption',
      EXEMPTION_EXPIRE: '/account/edit/exemption-expire',
    },
  },
  ORDER: {
    BASE: '/order',
    PMED: '/order/pmed',
    DELIVERY: '/order/delivery',
    CHECKOUT: '/order/checkout',
    SUCCESS: '/order/success',
    FAIL: '/order/fail',
  },
  SHOP: {
    BASE: '/treatments',
    CONDITION: '/treatments/condition',
    CONDITIONGROUP: '/treatments/condition-group',
  },
  SEARCH_RESULTS: '/search-results',
  SELECT_GP: '/select-your-gp',
  LOADING: '/loading',
  LOGOUT: '/logout',
  FACEBOOK: 'https://www.facebook.com/lawsatpharmltd/',
};
