import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PharmacyListSection } from 'components/PharmacyListSection';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import translations from 'translations/en.json';

const pageName = { log: 'contact-page', display: 'Contact Us' };

const pharmacies = translations.contact.pharmacies;

export const Contact = () => {
  const { t } = useTranslation();
  const { logPageView } = useAmplitudeContext();
  const { setPageName } = useLandingPageContext();

  useEffect(() => {
    logPageView({ pageName: pageName.log });
    setPageName(pageName);
  }, []);

  return (
    <PharmacyListSection
      pharmacies={pharmacies}
      heading={t('contact.contactPage.ourPharmacies')}
    />
  );
};
