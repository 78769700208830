import React from 'react';
import { Pharmacy } from 'models/Pharmacy';
import { IconInfoSpan } from 'components/common/IconInfoSpan';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { formatPharmacyAddress } from 'utils/Address';
import { ReactComponent as PhoneIcon } from 'assets/icons/lawsat/Phone.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/lawsat/Email.svg';

import styles from './GetInTouch.module.scss';

interface GetInTouchProps {
  pharmacy: Pharmacy;
}

export const GetInTouch: React.FC<GetInTouchProps> = ({ pharmacy }) => {
  const { name, phoneNumber, emailAddress } = pharmacy;

  return (
    <div className={styles['get-in-touch']}>
      <Heading size="sm" tag="h3" className={styles['get-in-touch__title']}>
        {name}
      </Heading>
      <Text size={'xs'} className={styles['get-in-touch__address']}>
        {formatPharmacyAddress(pharmacy)}
      </Text>
      <div className={styles['get-in-touch__info-container']}>
        {phoneNumber && (
          <IconInfoSpan
            testId={'pharmacy-phone'}
            size={'md'}
            className={styles['get-in-touch__info']}
            icon={PhoneIcon}
            text={phoneNumber}
          />
        )}
        {emailAddress && (
          <IconInfoSpan
            testId={'pharmacy-email'}
            size={'md'}
            className={styles['get-in-touch__info']}
            icon={EmailIcon}
            text={emailAddress}
          />
        )}
      </div>
    </div>
  );
};
