import React from 'react';
import { useCheckboxState, Checkbox } from 'reakit/Checkbox';
import cx from 'classnames';
import { Label } from 'components/common/Label';
import styles from './Toggle.module.scss';

export interface ToggleProps {
  className?: string;
  disabled?: boolean;
  label: string;
  onChange?: (value: string) => void;
  orientation?: 'horizontal' | 'vertical';
  testId?: string;
  value?: boolean;
}

const ToggleRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  ToggleProps
> = (
  { className, disabled = false, label, onChange, testId, value = true },
  ref,
) => {
  const checkbox = useCheckboxState({ state: value });

  React.useEffect(() => {
    checkbox.setState(value);
  }, [value]);

  const classes = cx(`${styles['input__toggle-container']}`, {
    [`${styles['disabled']}`]: disabled,
    [`${className}`]: className,
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.checked.toString());
  };

  return (
    <div className={classes} data-testid={testId}>
      <Label
        testId={testId ? `${testId}-label` : undefined}
        className={cx(styles['input__toggle-label'])}
      >
        <Checkbox
          data-testid={testId ? `${testId}-checkbox` : undefined}
          {...checkbox}
          onChange={handleOnChange}
          className={styles['input__toggle']}
          ref={ref}
        />
        <span
          className={styles['input__toggle-indicator']}
          data-testid={testId ? `${testId}-indicator` : undefined}
        />
        <span className={styles['input__toggle-text']}>{label}</span>
        <span className={styles['input__faux-focus']}></span>
      </Label>
    </div>
  );
};

export const Toggle = React.forwardRef(ToggleRef);
