import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';
import { HelpCentreArticles } from './HelpCentreArticles';
import { HelpCentreLanding } from './LandingPage';
import { useConfigContext } from 'contexts/ConfigContext';
import { LandingPageSpinner } from 'components/common/Spinner';

export const HelpCentre = () => {
  const { getPatientAppConfig } = useConfigContext();
  const [fetchComplete, setFetchComplete] = useState(false);

  const getData = async () => {
    await getPatientAppConfig();
    setFetchComplete(() => true);
  };

  useEffect(() => {
    getData();
  }, []);

  if (!fetchComplete) {
    return <LandingPageSpinner />;
  }

  return (
    <Switch>
      <Route path={`${routes.HELP.BASE}/:articleId`} exact>
        <HelpCentreArticles />
      </Route>
      <Route path={routes.HELP.BASE}>
        <HelpCentreLanding />
      </Route>
    </Switch>
  );
};
