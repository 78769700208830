import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useApiError } from 'hooks/useApiError';
import {
  ExemptionPayload,
  getExemptionsList,
  savePatientExemption,
} from 'api/Exemption';
import {
  deletePatientExemption,
  getPatientExemption,
} from 'api/Exemption/Exemption';
import { useToastContext } from 'contexts/ToastContext';
import { Exemption as ExemptionType } from 'models/Exemption';
import { ExemptionDetails } from 'models/Exemption/Exemption';
import { BranchStatus } from 'components/common/Branch';
import { routes } from 'routes';
import { useBasketContext } from 'contexts/BasketContext';

export interface ExemptionsContextProps {
  exemptionList: ExemptionType[];
  exemptionSelected: ExemptionType | null;
  getExemptionList: Function;
  exemptionDetails: ExemptionDetails | null;
  getExemptionDetails: Function;
  setExemptionDetails: Function;
  setExemptionSelected: Function;
  savePatientExemptionReason: Function;
  deletePatientExemptionReason: Function;
  loading: ExemptionContextLoading;
  setLoading: Function;
}

export interface ExemptionContextLoading {
  exemptionListStatus?: BranchStatus;
  exemptionDetailsStatus?: BranchStatus;
  saveExemptionStatus?: BranchStatus;
  deleteExemptionStatus?: BranchStatus;
}

export const ExemptionsContextDefaults: ExemptionsContextProps = {
  exemptionList: [],
  exemptionSelected: null,
  setExemptionSelected: Function,
  getExemptionList: Function,
  exemptionDetails: null,
  getExemptionDetails: Function,
  setExemptionDetails: Function,
  savePatientExemptionReason: Function,
  deletePatientExemptionReason: Function,
  loading: {},
  setLoading: Function,
};

export const ExemptionsContext = React.createContext<ExemptionsContextProps>(
  ExemptionsContextDefaults,
);

export const useExemptionsContext = (): ExemptionsContextProps =>
  React.useContext(ExemptionsContext);

interface ExemptionsProviderProps {
  children?: React.ReactNode;
}

export const ExemptionsProvider = ({ children }: ExemptionsProviderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast } = useToastContext();
  const { handleToggleExemptionStatus } = useBasketContext();
  const { handleApiError } = useApiError();
  const [loading, setLoading] = React.useState<ExemptionContextLoading>({});
  const [exemptionList, setExemptionList] = React.useState<ExemptionType[]>([]);
  const [exemptionSelected, setExemptionSelected] = React.useState(null);
  const [exemptionDetails, setExemptionDetails] =
    React.useState<ExemptionDetails | null>(null);

  const errors = {
    title: t('common.error.genericTitle'),
    message: t('common.error.genericMessage'),
  };

  const errorWithToast = () => {
    setToast({
      status: 'error',
      title: errors.title,
      description: errors.message,
    });
  };

  const getExemptionList = async () => {
    try {
      setLoading({ ...loading, exemptionListStatus: 'loading' });
      const results = await getExemptionsList();
      setExemptionList(results);
      setLoading({ ...loading, exemptionListStatus: 'finished' });
    } catch (error) {
      handleApiError(() => {
        setLoading({ ...loading, exemptionListStatus: 'error' });
        errorWithToast();
      }, error);
    }
  };

  const getExemptionDetails = async () => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        exemptionDetailsStatus: 'loading',
      }));
      const results = await getPatientExemption();
      setExemptionDetails(results);
      setLoading((prevState) => ({
        ...prevState,
        exemptionDetailsStatus: 'finished',
      }));
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        setExemptionDetails(null);
        setLoading((prevState) => ({
          ...prevState,
          exemptionDetailsStatus: 'finished',
        }));
      } else {
        handleApiError(() => {
          setLoading((prevState) => ({
            ...prevState,
            exemptionDetailsStatus: 'error',
          }));
          errorWithToast();
        }, error);
      }
    }
  };

  const savePatientExemptionReason = async (payload: ExemptionPayload) => {
    try {
      setLoading({ ...loading, saveExemptionStatus: 'loading' });
      await savePatientExemption(payload);
      getExemptionDetails();
      handleToggleExemptionStatus(true);
      setLoading({ ...loading, saveExemptionStatus: 'finished' });
    } catch (error) {
      handleApiError(() => {
        errorWithToast();
        setLoading({ ...loading, saveExemptionStatus: 'error' });
      }, error);
    }
  };

  const deletePatientExemptionReason = async () => {
    try {
      setLoading({ ...loading, deleteExemptionStatus: 'loading' });
      await deletePatientExemption();
      getExemptionDetails();
      handleToggleExemptionStatus(false);
      setLoading({ ...loading, deleteExemptionStatus: 'finished' });
      history.push(routes.ACCOUNT.BASE);
    } catch (error) {
      handleApiError(() => {
        errorWithToast();
        setLoading({ ...loading, deleteExemptionStatus: 'error' });
      }, error);
    }
  };

  return (
    <ExemptionsContext.Provider
      value={{
        exemptionList,
        exemptionSelected,
        getExemptionList,
        setExemptionSelected,
        exemptionDetails,
        getExemptionDetails,
        setExemptionDetails,
        savePatientExemptionReason,
        deletePatientExemptionReason,
        loading,
        setLoading,
      }}
    >
      {children}
    </ExemptionsContext.Provider>
  );
};
