import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { useBasketContext } from 'contexts/BasketContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { usePatientContext } from 'contexts/PatientContext';
import { DependantBasket } from 'models/Basket';
import { EmptyBasket } from 'components/Basket/EmptyBasket';
import { Button } from 'components/common/Button';
import { Heading } from 'components/common/Heading';
import { BasketItemCard } from 'components/Basket/BasketItemCard';
import { Container, Row, Col } from 'components/common/grid';
import { Text } from 'components/common/Text';
import { DropDown } from 'components/common/DropDown';
import { ReactComponent as CircleAlert } from 'assets/icons/lawsat/CircleAlert.svg';
import { ReactComponent as CircleInfo } from 'assets/icons/lawsat/CircleInfo.svg';
import { routes } from 'routes';

import styles from './Basket.module.scss';
import { useLandingPageContext } from 'contexts/LandingPageContext';

const headingStyle = styles['basket__section - heading'];
const infoStyle = styles['basket__section-info'];
const infoIconStyle = styles['basket__section-info-icon'];

export const Basket = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { login, authStatus } = useAuthContext();
  const {
    basket,
    validateBasket,
    dependantBaskets,
    loading: { validateBasketStatus },
  } = useBasketContext();
  const { dependants } = usePatientContext();
  const { logCtaClick } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();

  const [dropdownValues, setDropdownValues] =
    useState<{ id: string; name: string }[]>();
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<
    string | undefined
  >('-1');
  const [selectedDependantBasket, setSelectedDependantBasket] =
    useState<DependantBasket>();
  const [selectedDependantName, setSelectedDependantName] = useState<string>();

  const hasShopProducts = basket.shopProducts && basket.shopProducts.length > 0;
  const hasPrescriptionProducts =
    basket.prescriptionProducts && basket.prescriptionProducts.length > 0;
  const numberOfDependantBasketsWithProducts = dependantBaskets.filter(
    (basket) => basket.basket.prescriptionProducts.length > 0,
  ).length;
  const showYourBasket = hasShopProducts || hasPrescriptionProducts;
  const showDropdown =
    (showYourBasket && numberOfDependantBasketsWithProducts > 0) ||
    numberOfDependantBasketsWithProducts > 1;
  const basketSubtotal =
    selectedDropdownValue === '-1'
      ? basket.subTotal
      : selectedDependantBasket?.basket.subTotal;

  // Update dropdown values when baskets and/or dependants change
  useEffect(() => {
    const dropdownValues = dependantBaskets.map((basket) => {
      const dependant = dependants?.find(
        (dependant) => dependant.id.toString() === basket.dependantId,
      );
      const numberOfItems = basket.basket.prescriptionProducts.length;
      return {
        id: basket.dependantId,
        name: dependant
          ? `${dependant?.firstName} ${
              dependant?.lastName
            }'s basket (${numberOfItems} ${
              numberOfItems === 1 ? 'item' : 'items'
            })`
          : "Dependant's basket",
      };
    });

    if (showYourBasket) {
      const numberOfItems =
        basket.prescriptionProducts.length + basket.shopProducts.length;
      dropdownValues.unshift({
        id: '-1',
        name: `Your basket (${numberOfItems} ${
          numberOfItems === 1 ? 'item' : 'items'
        })`,
      });
    }
    setDropdownValues(dropdownValues);

    // If current basket now empty, select another one
    if (
      dependantBaskets.filter(
        (basket) => basket.dependantId === selectedDropdownValue,
      ).length === 0
    ) {
      if (showYourBasket) {
        setSelectedDropdownValue('-1');
      } else {
        setSelectedDropdownValue(
          dependantBaskets.length > 0 && dependantBaskets[0].dependantId
            ? dependantBaskets[0].dependantId
            : '-1',
        );
      }
    }
  }, [basket, dependantBaskets, dependants]);

  // Remove default selection if no 'your basket' contents
  useEffect(() => {
    if (!showYourBasket && numberOfDependantBasketsWithProducts > 0) {
      setSelectedDropdownValue(
        dependantBaskets.find(
          (basket) => basket.basket.prescriptionProducts.length > 0,
        )?.dependantId ?? '',
      );
    }
  }, []);

  // Update the associated selected basket and dependant when selected dropdown value changes
  useEffect(() => {
    setSelectedDependantBasket(
      dependantBaskets.find(
        (basket) => basket.dependantId === selectedDropdownValue,
      ),
    );
    setSelectedDependantName(
      dependants?.find(
        (dependant) => dependant.id.toString() === selectedDropdownValue,
      )?.firstName ?? '',
    );
  }, [selectedDropdownValue]);

  const handleCheckout = async () => {
    logCtaClick({
      label:
        selectedDropdownValue === '-1'
          ? t('amplitude.basket.checkoutLabel')
          : t('amplitude.basket.checkoutDependantLabel'),
      pageName: pageName?.log,
      section: t('amplitude.basket.section'),
    });
    if (authStatus) {
      const dependantId =
        selectedDropdownValue === '-1' ? undefined : selectedDropdownValue;
      const res = await validateBasket(dependantId);
      if (res) {
        const route = dependantId
          ? `${routes.ORDER.CHECKOUT}/${dependantId}`
          : basket.hasPmeds
          ? routes.ORDER.PMED
          : routes.ORDER.CHECKOUT;
        push(route);
      }
    } else {
      login({ redirectToUrl: routes.BASKET });
    }
  };

  if (
    !hasShopProducts &&
    !hasPrescriptionProducts &&
    numberOfDependantBasketsWithProducts === 0
  ) {
    return <EmptyBasket />;
  }

  return (
    <Container className={styles['basket']}>
      <Col
        xs={{ size: 12, offset: 0 }}
        md={{ size: 8, offset: 2 }}
        xl={{ size: 6, offset: 3 }}
      >
        {showDropdown && dropdownValues && (
          <Row>
            <Text size="lg" className={styles['basket__warning-msg']}>
              <CircleAlert className={styles['basket__warning-icon']} />
              {t('BasketPage.hasOtherBasketWarningMsg')}
            </Text>

            <Heading tag="h5" className={headingStyle}>
              {t('BasketPage.selectBasket')}
            </Heading>

            <DropDown
              className={styles['basket__dropdown']}
              values={dropdownValues}
              onChange={setSelectedDropdownValue}
            />
          </Row>
        )}

        {selectedDropdownValue !== '-1' &&
          selectedDependantBasket?.basket.prescriptionProducts &&
          selectedDependantBasket?.basket.prescriptionProducts.length > 0 && (
            <Row className={styles['basket__section']}>
              <Heading tag="h3" className={headingStyle}>
                {`${selectedDependantName}'s basket`}
              </Heading>
              {selectedDependantBasket.basket.prescriptionProducts.map(
                (item) => (
                  <BasketItemCard
                    key={item.id}
                    item={item}
                    type="prescription"
                    dependantId={selectedDependantBasket.dependantId}
                  />
                ),
              )}

              <Text size="lg" className={infoStyle}>
                <CircleInfo className={infoIconStyle} />
                {t('BasketPage.prescriptionSection.infoMsg')}
              </Text>
            </Row>
          )}

        {selectedDropdownValue === '-1' &&
          basket.prescriptionProducts.length > 0 && (
            <Row className={styles['basket__section']}>
              <Heading tag="h3" className={headingStyle}>
                {t('BasketPage.prescriptionSection.heading')}
              </Heading>
              {basket.prescriptionProducts.map((item) => (
                <BasketItemCard key={item.id} item={item} type="prescription" />
              ))}

              <Text size="lg" className={infoStyle}>
                <CircleInfo className={infoIconStyle} />
                {t('BasketPage.prescriptionSection.infoMsg')}
              </Text>
            </Row>
          )}

        {selectedDropdownValue === '-1' && basket.shopProducts.length > 0 && (
          <Row className={styles['basket__section']}>
            <Heading tag="h3" className={headingStyle}>
              {t('BasketPage.shopProductSection.heading')}
            </Heading>
            {basket.shopProducts.map((item) => (
              <BasketItemCard key={item.id} item={item} />
            ))}
            {basket.hasPmeds && (
              <Text size="lg" className={infoStyle}>
                <CircleInfo className={infoIconStyle} />
                {t('BasketPage.shopProductSection.infoMsg')}
              </Text>
            )}
          </Row>
        )}

        <Row className={styles['basket__price']}>
          <Heading tag="h3">{t('BasketPage.subtotal')}</Heading>
          <Heading tag="h2">£{basketSubtotal?.toFixed(2)}</Heading>
        </Row>

        <Row className={styles['basket__checkout']}>
          <Button
            label={`Checkout ${
              selectedDropdownValue === '-1'
                ? 'your'
                : `${selectedDependantName}'s`
            } basket`}
            isLoading={validateBasketStatus === 'loading'}
            onClick={() => handleCheckout()}
          />
        </Row>
      </Col>
    </Container>
  );
};
