import { AppBasket } from 'models/Basket';
import { PomForm, PomFormResponse } from 'models/PomQuestionnaires';

export const mergePomForms = (
  formsToMerge: PomFormResponse[],
  basket: AppBasket,
) => {
  return {
    ...basket,
    pomForms: formsToMerge.filter((form) => form.productIds.length >= 1),
  };
};

export const removeProductFromPomForm = (
  prevBasket: AppBasket,
  productId: string,
) => {
  const updatedForms = [...prevBasket.pomForms];
  const formIndex = updatedForms.findIndex((form) =>
    form.productIds.find((id) => id === parseInt(productId)),
  );
  if (formIndex !== -1) {
    updatedForms[formIndex].productIds = updatedForms[
      formIndex
    ].productIds.filter((id) => id !== parseInt(productId));
  }
  return { ...prevBasket, pomForms: updatedForms };
};

export const savePomFormToBasket = (
  prevBasket: AppBasket,
  conditionId: number,
  formAnswers: PomForm,
) => {
  const updatedForms = [...prevBasket.pomForms];
  const pomFormIndex = updatedForms.findIndex(
    (formResponse) => formResponse.conditionId === conditionId,
  );
  if (pomFormIndex === -1) {
    updatedForms.push({
      productIds: [],
      conditionId,
      formAnswers,
    });
  } else {
    updatedForms[pomFormIndex] = {
      ...updatedForms[pomFormIndex],
      formAnswers,
    };
  }
  return {
    ...prevBasket,
    pomForms: updatedForms,
  };
};

export const addProductToPomForm = (
  prevBasket: AppBasket,
  productId: number,
  conditionId: number,
) => {
  const updatedForms = [...prevBasket.pomForms];
  const pomFormIndex = updatedForms.findIndex(
    (formResponse) => formResponse.conditionId === conditionId,
  );

  if (pomFormIndex !== -1) {
    updatedForms[pomFormIndex] = {
      ...updatedForms[pomFormIndex],
      productIds: [
        ...new Set([...updatedForms[pomFormIndex].productIds, productId]),
      ],
    };
  }

  return {
    ...prevBasket,
    pomForms: updatedForms,
  };
};
