import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getOrdersFromGroupId } from 'api/OrderGroups';
import { getOrder } from 'api/OrderHistory';
import { useBasketContext } from 'contexts/BasketContext';
import { useDependantContext } from 'contexts/DependantContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useRetainOrderInfo } from 'hooks/useRetainOrderInfo';
import { OrderHistory } from 'models/OrderHistory';
import { Container, Col, Row } from 'components/common/grid';
import { PaymentResult } from 'components/PaymentResult';
import { LandingPageSpinner } from 'components/common/Spinner';

import styles from './OrderSuccess.module.scss';

export const OrderSuccess = () => {
  const { t } = useTranslation();
  const { getRetainedOrderInfo, clearRetainedOrderInfo } = useRetainOrderInfo();
  const { resetBasket } = useBasketContext();
  const { dependant, getDependant } = useDependantContext();
  const { logPageView } = useAmplitudeContext();
  const [orders, setOrders] = useState<OrderHistory[]>([]);
  const [fetchComplete, setFetchComplete] = useState(false);

  const getOrdersData = async () => {
    const retainedOrderInfo = getRetainedOrderInfo();
    if (retainedOrderInfo) {
      if (retainedOrderInfo.orderGroupId) {
        try {
          const orderIds = await getOrdersFromGroupId(
            retainedOrderInfo.orderGroupId,
          );
          setOrders(
            await Promise.all(orderIds.map(async (id) => await getOrder(id))),
          );
        } catch (e) {
          setOrders([]);
        }
      }
      retainedOrderInfo.dependantId &&
        (await getDependant(retainedOrderInfo.dependantId));
      resetBasket(retainedOrderInfo.dependantId);
    }
    setFetchComplete(() => true);
  };

  useEffect(() => {
    getOrdersData();
    logPageView({ pageName: t('amplitude.orderSuccess.pageName') });
    return () => clearRetainedOrderInfo();
  }, []);

  if (!fetchComplete) {
    return <LandingPageSpinner />;
  }

  return (
    <div className={styles['order-success']}>
      <Container>
        <Row>
          <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <PaymentResult.Success
              orders={orders}
              dependantName={dependant?.firstName}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
