import { Client } from 'api/utils';
import { OrderHistory } from 'models/OrderHistory';

export const getInProgress = async (): Promise<OrderHistory[]> => {
  const response = await Client.get('/orderHistory/inProgress');
  return response.data.items;
};

export const getFulfilled = async (): Promise<OrderHistory[]> => {
  const response = await Client.get('/orderHistory/fulfilled');
  return response.data.items;
};

export const getOrder = async (orderId: number): Promise<OrderHistory> => {
  const response = await Client.get(`/orderHistory/${orderId}`);
  return response.data;
};
