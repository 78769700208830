/**
 * @name generateID
 * @return {string} randomised string as `unique` id reference
 * @desc not a true crypto `uuid` but, acceptable for html id generation
 */

import { parse, isValid, isBefore, isAfter, isSameYear } from 'date-fns';
import { enGB } from 'date-fns/locale';

export const validAfterMinDate = (
  day: string,
  month: string,
  year: string,
  minDate: string,
): boolean => {
  if (year.length !== 4) {
    return false;
  } else {
    const parsedDate = parse(`${day}/${month}/${year}`, 'P', new Date(), {
      locale: enGB,
    });
    return isValid(parsedDate)
      ? isSameYear(parsedDate, new Date(minDate)) ||
          isAfter(parsedDate, new Date(minDate))
      : false;
  }
};

export const validDatePast = (
  day: string,
  month: string,
  year: string,
): boolean => {
  if (year.length !== 4) {
    return false;
  } else {
    const parsedDate = parse(`${day}/${month}/${year}`, 'P', new Date(), {
      locale: enGB,
    });
    return isValid(parsedDate) ? isBefore(parsedDate, new Date()) : false;
  }
};

export const validDateFuture = (
  day: string,
  month: string,
  year: string,
): boolean => {
  if (year.length !== 4) {
    return false;
  } else {
    const parsedDate = parse(`${day}/${month}/${year}`, 'P', new Date(), {
      locale: enGB,
    });
    return isValid(parsedDate) ? isAfter(parsedDate, new Date()) : false;
  }
};
