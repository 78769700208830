import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ExemptionPayload } from 'api/Exemption';
import { Card, CardType } from 'components/common/Card';
import { Button } from 'components/common/Button';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { FormProgressBar } from 'components/FormProgressBar';
import { InfoPanel } from 'components/InfoPanel';
import { useDependantContext } from 'contexts/DependantContext';
import { useDependantExemptionsContext } from 'contexts/DependantExemptionsContext';
import { useExemptionsContext } from 'contexts/ExemptionsContext';
import { ReactComponent as CircleInfo } from 'assets/icons/lawsat/CircleInfo.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/lawsat/Link.svg';
import { FormProgressBarProps } from 'components/FormProgressBar/FormProgressBar';
import { useConfigContext } from 'contexts/ConfigContext';

import styles from './PrescriptionExemption.module.scss';

interface PrescriptionExemptionProps {
  heading: string;
  secondaryHeading: string;
  dependantId?: string;
  yesButton: {
    route: string;
    label: string;
  };
  skipButton: {
    route: string;
    label: string;
  };
  progressBar: FormProgressBarProps;
}

export const PrescriptionExemption = ({
  heading,
  secondaryHeading,
  yesButton,
  skipButton,
  dependantId,
  progressBar,
}: PrescriptionExemptionProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    getPatientAppConfig,
    standardPrescriptionPrice,
    threeMonthPrePayment,
    twelveMonthPrePayment,
  } = useConfigContext();
  const { dependant, getDependant } = useDependantContext();
  const { saveDependantExemptionReason } = useDependantExemptionsContext();
  const { savePatientExemptionReason } = useExemptionsContext();
  const [showCTA, setShowCTA] = useState(false);

  useEffect(() => {
    if (dependantId && !dependant) {
      getDependant(dependantId);
    }
    getPatientAppConfig();
  }, []);

  const buttonClass = styles['prescription-exemption__button'];
  const ctaCard: CardType = {
    icon: LinkIcon,
    title: t('PrescriptionFlow.exemptionCert.ctaTitle'),
    description: t('PrescriptionFlow.exemptionCert.ctaText'),
    href: 'https://services.nhsbsa.nhs.uk/buy-prescription-prepayment-certificate/start',
  };

  const onClickSkipButton = () => {
    const payload: ExemptionPayload = {
      exemptionReasonId: null,
      expiryDate: null,
      shouldNotify: null,
    };
    if (dependantId) {
      saveDependantExemptionReason(dependantId, payload);
    } else {
      savePatientExemptionReason(payload);
    }
    history.push(skipButton.route);
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <div className={styles['prescription-exemption']}>
              <div
                className={styles['prescription-exemption__progress-container']}
              >
                <FormProgressBar
                  numberOfStages={progressBar.numberOfStages}
                  currentStage={progressBar.currentStage}
                />
              </div>

              {!showCTA && (
                <>
                  <Heading
                    tag="h2"
                    size="lg"
                    className={styles['prescription-exemption__title']}
                  >
                    {heading}
                  </Heading>
                  <div className={styles['prescription-exemption__button-set']}>
                    <Button
                      className={buttonClass}
                      label={yesButton.label}
                      link={{
                        to: yesButton.route,
                      }}
                    />
                    <Button
                      className={buttonClass}
                      label={t('PrescriptionFlow.exemption.noButton')}
                      onClick={() => {
                        setShowCTA(true);
                      }}
                    />
                  </div>
                  <InfoPanel
                    title={t('PrescriptionFlow.exemption.infoTitle')}
                    text={
                      <Trans i18nKey="PrescriptionFlow.exemption.infoText" />
                    }
                    icon={CircleInfo}
                  />
                </>
              )}

              {showCTA && (
                <>
                  <Heading
                    tag="h2"
                    size="lg"
                    className={styles['prescription-exemption__title-cta']}
                  >
                    {secondaryHeading}
                  </Heading>
                  <Text
                    size="lg"
                    tag="div"
                    className={styles['prescription-exemption__text']}
                  >
                    <Trans
                      i18nKey="PrescriptionFlow.exemptionCert.text"
                      values={{
                        prescriptionPrice: standardPrescriptionPrice.toFixed(2),
                        threeMonthPrice: threeMonthPrePayment.toFixed(2),
                        twelveMonthPrice: twelveMonthPrePayment.toFixed(2),
                      }}
                    />
                  </Text>
                  <Card
                    item={ctaCard}
                    className={styles['prescription-exemption__card']}
                  />
                  <div className={styles['prescription-exemption__button-set']}>
                    <Button
                      className={buttonClass}
                      label={yesButton.label}
                      link={{
                        to: yesButton.route,
                      }}
                    />
                    <Button
                      className={buttonClass}
                      label={skipButton.label}
                      onClick={onClickSkipButton}
                    />
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
