import { useState } from 'react';
import cx from 'clsx';
import { routes } from 'routes';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useBasketContext } from 'contexts/BasketContext';
import { useAuthContext } from 'contexts/AuthContext';
import { useModalContext } from 'contexts/ModalContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { Product } from 'models/Product';
import { Button } from 'components/common/Button';
import { PomConfirmLoginModal } from 'components/ConfirmLoginModal';
import { Text } from 'components/common/Text';
import { ReactComponent as CircleCheckmark } from 'assets/icons/CircleCheckmark.svg';

import styles from './AddToBasketButton.module.scss';

const buttonStyle = styles['add-to-basket-button'];

interface AddToBasketButtonProps {
  product: Product;
  basketIsAtMaxQuantity: boolean;
  onClick: () => void;
  className?: string;
}

export const AddToBasketButton = ({
  product,
  basketIsAtMaxQuantity,
  className,
  onClick,
}: AddToBasketButtonProps) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const { authStatus, login } = useAuthContext();
  const { basket } = useBasketContext();
  const { open: openModal } = useModalContext();
  const { logCtaClick } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const [successTimeout, setSuccessTimeout] = useState<NodeJS.Timeout>();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  if (!product.inStock) {
    return (
      <Button
        disabled
        variant="ui"
        appearance="flat"
        className={cx(buttonStyle, className)}
        label={t('OrderFlow.ProductDetail.outOfStock')}
      />
    );
  }

  const handleLogCtaClick = () => {
    logCtaClick({
      label: t('amplitude.products.label'),
      pageName: pageName?.log,
      section: t('amplitude.products.section'),
    });
  };

  const pomRoute = `${routes.PRODUCT.BASE}/${product.id}${routes.GENERAL_HEALTH.PT1}`;

  const addToBasketLabel = t('OrderFlow.Qty.add');
  const startConsultationLabel = t('OrderFlow.Qty.pomAdd');

  const isProductInBasket =
    basket.shopProducts.find((line) => Number(line.id) === product.id)
      ?.quantity ?? 0 >= 1;

  const isPomFormInBasket =
    basket.pomForms.findIndex(
      (form) => form.conditionId === product.productConditionId,
    ) !== -1;

  const handlePomButtonClick = () => {
    handleLogCtaClick();
    if (authStatus) {
      navigate.push(pomRoute);
    } else {
      openModal(
        <PomConfirmLoginModal
          onLogin={() =>
            login(
              { redirectToUrl: pomRoute },
              { label: 'Continue to consultation' },
            )
          }
        />,
      );
    }
  };

  const handleAddToBasketClick = () => {
    if (!basketIsAtMaxQuantity) {
      onClick?.();
      handleLogCtaClick();
      setShowSuccessMessage(true);
      if (successTimeout) {
        clearTimeout(successTimeout);
      }
      const newTimeout = setTimeout(() => setShowSuccessMessage(false), 2000);
      setSuccessTimeout(newTimeout);
    }
  };

  return (
    <div className={className}>
      {product.isPom && !isProductInBasket && !isPomFormInBasket ? (
        <Button
          testId="isPom-button"
          variant="accent"
          appearance="solid"
          className={buttonStyle}
          label={startConsultationLabel}
          onClick={handlePomButtonClick}
        />
      ) : (
        <Button
          testId="isProduct-button"
          variant={basketIsAtMaxQuantity ? 'ui' : 'accent'}
          appearance={basketIsAtMaxQuantity ? 'flat' : 'solid'}
          className={buttonStyle}
          label={addToBasketLabel}
          onClick={handleAddToBasketClick}
        />
      )}
      <div
        className={cx(
          styles['add-to-basket-button__success-message'],
          showSuccessMessage &&
            styles['add-to-basket-button__success-message--show'],
        )}
      >
        <CircleCheckmark />
        <Text size="lg">Added to basket!</Text>
      </div>
    </div>
  );
};
