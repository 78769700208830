import React from 'react';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import styles from './LandingPageHelpItem.module.scss';

interface LandingPageHelpItemProps {
  title: string;
  label: string;
  Icon: React.FC;
}

export const LandingPageHelpItem: React.FC<LandingPageHelpItemProps> = ({
  title,
  label,
  Icon,
}) => {
  return (
    <div className={styles['landing-page-help-item']}>
      <div className={styles['landing-page-help-item__icon-container']}>
        <Icon />
      </div>
      <Heading tag={'h6'} className={styles['landing-page-help-item__title']}>
        {title}
      </Heading>
      <Text className={styles['landing-page-help-item__label']}>{label}</Text>
    </div>
  );
};
