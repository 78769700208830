import { CogClient } from 'api/utils';

const url = `medicinesuggester?highlightPreTag=%3Cstrong%3E&highlightPostTag=%3C%2Fstrong%3E&search=`;

export type MedResult = {
  '@search.text': string;
  Id: string;
  Name: string | null;
};

export const medSuggest = async (querystring: string): Promise<MedResult[]> => {
  const response = await CogClient.get(`${url}${querystring}`);
  return response.data.value;
};
