/* eslint-disable sonarjs/no-duplicate-string */
import * as yup from 'yup';
import i18n from 'i18next';
import { erectileDysfunctionPomFormQuestions } from 'models/PomQuestionnaires';

export const ErectileDysfunctionPomFormSchema = (): yup.ObjectSchema => {
  const requiredError = i18n.t('common.formErrors.required');
  const booleanRequiredError = i18n.t('common.formErrors.radioRequired');
  const maxLengthError = i18n.t('common.formErrors.maxLength', { max: 500 });

  return yup.object().shape({
    [erectileDysfunctionPomFormQuestions[0].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(requiredError),
    [erectileDysfunctionPomFormQuestions[1].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(requiredError),
    [erectileDysfunctionPomFormQuestions[2].fieldName]: yup
      .string()
      .typeError()
      .nullable()
      .when(
        erectileDysfunctionPomFormQuestions[1].fieldName,
        (hasTakenEdTreatmentBefore, schema) => {
          if (hasTakenEdTreatmentBefore === 'Yes') {
            return schema
              .required(requiredError)
              .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
              .max(500, maxLengthError)
              .trim();
          }
          return schema;
        },
      ),
    [erectileDysfunctionPomFormQuestions[3].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(requiredError),
    [erectileDysfunctionPomFormQuestions[4].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(requiredError),
    [erectileDysfunctionPomFormQuestions[5].fieldName]: yup
      .string()
      .typeError()
      .nullable()
      .when(
        erectileDysfunctionPomFormQuestions[4].fieldName,
        (applicableStatementsOne, schema) => {
          if (applicableStatementsOne === 'Yes') {
            return schema
              .required(requiredError)
              .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
              .max(500, maxLengthError)
              .trim();
          }
          return schema;
        },
      ),
    [erectileDysfunctionPomFormQuestions[6].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(requiredError),
    [erectileDysfunctionPomFormQuestions[7].fieldName]: yup
      .string()
      .typeError()
      .nullable()
      .when(
        erectileDysfunctionPomFormQuestions[6].fieldName,
        (sufferFromConditions, schema) => {
          if (sufferFromConditions === 'Yes') {
            return schema
              .required(requiredError)
              .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
              .max(500, maxLengthError)
              .trim();
          }
          return schema;
        },
      ),
    [erectileDysfunctionPomFormQuestions[8].fieldName]: yup
      .boolean()
      .nullable()
      .required(i18n.t('common.formErrors.termsAndConditions'))
      .test(
        'mustBeTrue',
        i18n.t('common.formErrors.termsAndConditions'),
        (value) => value === true,
      ),
    [erectileDysfunctionPomFormQuestions[9].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(booleanRequiredError),
    [erectileDysfunctionPomFormQuestions[10].fieldName]: yup
      .string()
      .typeError()
      .nullable()
      .when(
        erectileDysfunctionPomFormQuestions[9].fieldName,
        (applicableStatementsTwo, schema) => {
          if (applicableStatementsTwo === 'Yes') {
            return schema
              .required(requiredError)
              .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
              .max(500, maxLengthError)
              .trim();
          }
          return schema;
        },
      ),
  });
};
