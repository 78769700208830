import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { useProductsContext } from 'contexts/ProductsContext';
import { useBasketContext } from 'contexts/BasketContext';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { useConfigContext } from 'contexts/ConfigContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { Container } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Image } from 'components/common/Image';
import { Text } from 'components/common/Text';
import { LandingPageSpinner } from 'components/common/Spinner';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import { Accordion } from 'components/common/Accordion';
import { ProductTileSection } from 'components/ProductTileSection';
import { QuantitySelector } from 'components/Products/QuantitySelector';
import { AddToBasketButton } from 'components/Products/AddToBasketButton';
import { RichTextViewer } from 'components/RichTextViewer';
import { isValidJSON, richTextFieldDefaultState } from 'utils/RichTextField';
import { ReactComponent as CircleInfo } from 'assets/icons/lawsat/CircleInfo.svg';
import { ReactComponent as Package } from 'assets/icons/lawsat/Package.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/lawsat/ChevronLeft.svg';

import styles from './ProductDetail.module.scss';

const infoMsgStyles = styles['product-detail__info-msg'];

interface ProductDetailProps {
  showPomBackButton?: boolean;
}

export const ProductDetail = ({ showPomBackButton }: ProductDetailProps) => {
  const { productId } = useParams<{ productId: string }>();
  const { t } = useTranslation();
  const { product, getProduct, setProduct } = useProductsContext();
  const { conditions, conditionGroups, getConditions, getConditionGroups } =
    useConditionsContext();
  const { isStandardDeliveryEnabled, isExpressDeliveryEnabled } =
    useConfigContext();
  const { basket, handleAddTreatmentToBasket } = useBasketContext();
  const { setPageName, resetPageName } = useLandingPageContext();
  const { logPageView } = useAmplitudeContext();
  useEffect(() => {
    getProduct(productId);
    !conditions && getConditions();
    !conditionGroups && getConditionGroups();

    return () => setProduct(null);
  }, [productId]);

  const pageName = {
    log: t('amplitude.products.pageName', {
      productTitle: product?.title,
    }),
  };
  useEffect(() => {
    setPageName(pageName);
    logPageView({
      pageName: pageName.log,
    });
    return () => resetPageName();
  }, [product]);

  const [quantitySelectedToAdd, setQuantitySelectedToAdd] = useState<number>(1);

  const parentCondition = conditions?.find(
    (condition) => condition.id === product?.productConditionId,
  );
  const parentConditionGroup = conditionGroups?.find(
    (conditionGroup) => conditionGroup.id === parentCondition?.conditionGroupId,
  );

  if (!product || !parentCondition || !parentConditionGroup) {
    return <LandingPageSpinner />;
  }

  const isDeliveryEnabled = !!(
    (isExpressDeliveryEnabled || isStandardDeliveryEnabled) &&
    product.inStock
  );

  const basketOrderline = basket.shopProducts.find(
    (line) => Number(line.id) === product.id,
  );

  const quantityInBasket = basketOrderline?.quantity ?? 0;

  const basketIsAtMaxQuantity =
    !!basketOrderline?.orderLimit &&
    quantityInBasket >= basketOrderline.orderLimit;

  const basketCapacity = product.orderLimit
    ? product.orderLimit - quantityInBasket
    : undefined;

  const subTotal = (product?.price * quantitySelectedToAdd).toFixed(2);

  const otherProductsToDisplay = parentCondition.products
    .filter((p) => p.id !== product.id)
    .slice(0, 6);

  const isPomFormInBasket =
    basket.pomForms.findIndex(
      (form) => form.conditionId === product.productConditionId,
    ) !== -1;

  const showQuantitySelector =
    !(product.isPom && !isPomFormInBasket) && product.inStock;

  const showPomMsg =
    product.isPom &&
    product.inStock &&
    !isPomFormInBasket &&
    basket.shopProducts.findIndex((p) => product.id.toString() === p.id) === -1;

  const showPmedMsg = product.isPMed && product.inStock;

  const atMaxAllowedToAdd =
    (basketIsAtMaxQuantity ||
      (basketCapacity &&
        quantitySelectedToAdd >= basketCapacity &&
        quantitySelectedToAdd > 1)) &&
    product.inStock;

  const showMaxMsg =
    !!product?.orderLimit &&
    ((product.orderLimit === 1 &&
      basketOrderline?.quantity &&
      basketOrderline.quantity >= product.orderLimit) ||
      (product.orderLimit !== 1 && atMaxAllowedToAdd));

  const breadcrumbs: Crumb[] = [
    { route: routes.SHOP.BASE, text: t('NavBarLabels.shop') },
    {
      route: `${routes.SHOP.CONDITIONGROUP}/${parentConditionGroup.id}`,
      text: parentConditionGroup.name,
    },
    {
      route: `${routes.SHOP.CONDITION}/${parentCondition.id}`,
      text: parentCondition.name,
    },
  ];

  return (
    <>
      {showPomBackButton ? (
        <PomBackButton parentConditionId={parentCondition.id} />
      ) : (
        <Breadcrumbs links={breadcrumbs} />
      )}
      <Container className={styles['product-detail']}>
        <div className={styles['product-detail__top-row']}>
          <div className={styles['product-detail__top-row__image-container']}>
            <Image
              imageFileName={product.imageFileName}
              className={
                styles['product-detail__top-row__image-container__image']
              }
              alt={`product-image-${product.title}`}
            />
            {product?.inStock && (
              <Text size="lg" className={infoMsgStyles}>
                <Package
                  className={styles['product-detail__error-msg__icon']}
                />
                {isDeliveryEnabled
                  ? t('OrderFlow.ProductDetail.deliveryAvailableMsg')
                  : t('OrderFlow.ProductDetail.collectionOnlyAvailableMsg')}
              </Text>
            )}
          </div>

          <div
            className={
              styles['product-detail__top-row__product-details-container']
            }
          >
            <Heading tag="h3" size="xl">
              {product?.title}
            </Heading>
            <Text size="lg" className={styles['product-detail__subtotal']}>
              {t('OrderFlow.ProductDetail.subTotal')}
            </Text>
            <Heading
              testId="subtotal"
              tag="h4"
              size="lg"
              className={styles['product-detail__price']}
            >
              £{subTotal}
            </Heading>
            <div>
              {showQuantitySelector && (
                <>
                  <Text
                    size="lg"
                    className={styles['product-detail__subtotal']}
                  >
                    {t('OrderFlow.ProductDetail.quantity')}
                  </Text>
                  <QuantitySelector
                    product={product}
                    quantitySelectedToAdd={quantitySelectedToAdd}
                    setQuantitySelectedToAdd={setQuantitySelectedToAdd}
                  />
                </>
              )}
              {showMaxMsg && (
                <Text size="lg" className={styles['product-detail__error-msg']}>
                  <CircleInfo
                    className={styles['product-detail__error-msg__icon']}
                  />
                  {t('OrderFlow.ProductDetail.maxQuantityReached')}
                </Text>
              )}
              <AddToBasketButton
                product={product}
                basketIsAtMaxQuantity={basketIsAtMaxQuantity}
                onClick={() => {
                  handleAddTreatmentToBasket(product, quantitySelectedToAdd);
                  setQuantitySelectedToAdd?.(1);
                }}
              />
              {showPomMsg && (
                <Text size="lg" className={infoMsgStyles}>
                  <CircleInfo
                    className={styles['product-detail__info-msg__icon']}
                  />
                  {t('OrderFlow.ProductDetail.pomInfoMsg')}
                </Text>
              )}
              {showPmedMsg && (
                <Text size="lg" className={infoMsgStyles}>
                  <CircleInfo
                    className={styles['product-detail__info-msg__icon']}
                  />
                  {t('OrderFlow.ProductDetail.pmedInfoMsg')}
                </Text>
              )}
            </div>
          </div>
        </div>

        {product?.description &&
          isValidJSON(product.description) &&
          product.description !== richTextFieldDefaultState && (
            <RichTextViewer
              richText={product.description}
              themeProps={{ textSize: 'md' }}
              amplitudeSectionName={t(
                'amplitude.productDetail.descriptionSection',
              )}
            />
          )}

        <div className={styles['product-detail__information-container']}>
          {product?.suitableFor && (
            <Accordion
              title={t('OrderFlow.ProductDetail.suitableFor')}
              startsExpanded
              amplitudeSectionName={t(
                'amplitude.productDetail.suitableForSection',
              )}
            >
              <Text>{product.suitableFor}</Text>
            </Accordion>
          )}
          {product?.howToUse && (
            <Accordion
              title={t('OrderFlow.ProductDetail.howToUse')}
              startsExpanded
              amplitudeSectionName={t(
                'amplitude.productDetail.howToUseSection',
              )}
            >
              <Text>{product.howToUse}</Text>
            </Accordion>
          )}
          {product?.ingredients && (
            <Accordion
              title={t('OrderFlow.ProductDetail.ingredients')}
              startsExpanded
              amplitudeSectionName={t(
                'amplitude.productDetail.ingredientsSection',
              )}
            >
              <Text>{product.ingredients}</Text>
            </Accordion>
          )}
          {product?.warnings &&
            isValidJSON(product.warnings) &&
            product.warnings !== richTextFieldDefaultState && (
              <Accordion
                title={t('OrderFlow.ProductDetail.warning')}
                startsExpanded
                amplitudeSectionName={t(
                  'amplitude.productDetail.sideEffectsSection',
                )}
              >
                <RichTextViewer
                  richText={product.warnings}
                  themeProps={{ textSize: 'md' }}
                />
              </Accordion>
            )}
        </div>

        {parentCondition && otherProductsToDisplay.length > 0 && (
          <ProductTileSection
            title={t('OrderFlow.ProductDetail.otherProductsIn', {
              condition: parentCondition.name,
            })}
            products={otherProductsToDisplay}
            classNames={{
              productList:
                styles['product-detail__tile-section-overrides__list'],
              section:
                styles['product-detail__tile-section-overrides__no-padding'],
              col: styles['product-detail__tile-section-overrides__no-padding'],
            }}
            productParentId={parentCondition.id.toString()}
            viewAllLinkRequired
            showCardsWithShadow
          />
        )}
      </Container>
    </>
  );
};

interface PomBackButtonProps {
  parentConditionId: number;
}

const PomBackButton = ({ parentConditionId }: PomBackButtonProps) => {
  const { t } = useTranslation();
  return (
    <nav aria-label="breadcrumb" className={styles['back-to-pom-results']}>
      <NavLink
        exact
        to={`${routes.SHOP.CONDITION}/${parentConditionId}${routes.POM.CONFIRMATION.BASE}`}
        className={styles['back-to-pom-results__link']}
      >
        <ChevronLeft className={styles['back-to-pom-results__link__icon']} />
        <span>{t('OrderFlow.ProductDetail.backToPomResults')}</span>
      </NavLink>
    </nav>
  );
};
