import { Client } from 'api/utils';
import { Collection } from 'models/Collection';
import { Product } from 'models/Product';

export const getProduct = async (id: string): Promise<Product> => {
  const response = await Client.get(`/Products/${id}`, {
    params: { bypassAuth: true },
  });
  return response.data;
};

export const getProductsSearch = async (
  searchString: string,
  top: number,
  skip: number,
): Promise<Collection<Product>> => {
  const reformattedSearchString = searchString.replace(' ', '%20');
  const response = await Client.get(
    `/Products?search=${reformattedSearchString}&top=${top}&skip=${skip}`,
    {
      params: { bypassAuth: true },
    },
  );
  return response.data;
};
