import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { HereToHelpSection } from 'components/Sections/Common/HereToHelpSection';
import { HeroSection } from 'components/Sections/Common/HeroSection';
import { HowItWorksSection } from 'components/Sections/Common/HowItWorksSection';
import { StartConsultationSection } from 'components/Sections/Common/StartConsultationSection';
import { PillLinkSection } from 'components/Sections/Common/PillLinkSection';
import { routes } from 'routes';

import styles from './LandingPage.module.scss';

const pageName = { log: 'shop-landing-page', display: 'Our Treatments' };

export const LandingPage = () => {
  const { t } = useTranslation();
  const { conditions, getConditions } = useConditionsContext();
  const { setPageName, resetPageName } = useLandingPageContext();
  const { logPageView } = useAmplitudeContext();

  useEffect(() => {
    setPageName(pageName);
    logPageView({ pageName: pageName.log });
    getConditions();
    return () => resetPageName();
  }, []);

  const conditionsToShow = conditions?.filter(
    (condition) =>
      !condition.name.toLowerCase().includes('general') && condition.showInShop,
  );

  const validConditionsForConsultation =
    (
      conditions?.filter(
        (condition) =>
          condition.showInShop &&
          condition.products.some(
            (product) => product.isPom && product.inStock,
          ),
      ) ?? []
    ).length > 0;

  return (
    <>
      <HeroSection heading={t('shop.landingPage.hero.heading')} />

      {conditionsToShow && conditionsToShow.length > 0 && (
        <PillLinkSection
          heading={t('LandingPage.ourTreatments.heading')}
          links={conditionsToShow.map((condition) => ({
            label: condition.name,
            route: `${routes.SHOP.CONDITION}/${condition.id}`,
          }))}
          className={
            !validConditionsForConsultation
              ? styles[
                  'landing-page__section-overrides__pill-link-section--padded'
                ]
              : styles['landing-page__section-overrides__pill-link-section']
          }
        />
      )}

      {validConditionsForConsultation && (
        <StartConsultationSection
          ctaLink={`${routes.SHOP.BASE}${routes.POM.SELECT}`}
        />
      )}

      <HowItWorksSection mode="treatments" />

      <HereToHelpSection
        className={
          styles['landing-page__section-overrides__here-to-help-section']
        }
      />
    </>
  );
};
