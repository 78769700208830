import { Client } from 'api/utils';
import { Collection } from 'models/Collection';
import { Condition } from 'models/Condition';
import { ConditionGroup } from 'models/Condition/Condition';

export const getCondition = async (id: number): Promise<Condition> => {
  const response = await Client.get(`/ProductConditions/${id}`, {
    params: { bypassAuth: true },
  });
  return response.data;
};

export const getConditions = async (): Promise<Collection<Condition>> => {
  const response = await Client.get(`/ProductConditions`, {
    params: { bypassAuth: true },
  });
  return response.data;
};

export const getConditionGroup = async (
  id: number,
): Promise<ConditionGroup> => {
  const response = await Client.get(`/ProductConditionGroups/${id}`, {
    params: { bypassAuth: true },
  });
  return response.data;
};

export const getConditionsSearch = async (
  searchString: string,
  top: number,
  skip: number,
): Promise<Collection<Condition>> => {
  const reformattedSearchString = searchString.replace(' ', '%20');
  const response = await Client.get(
    `/ProductConditions?search=${reformattedSearchString}&top=${top}&skip=${skip}`,
    {
      params: { bypassAuth: true },
    },
  );
  return response.data;
};

export const getConditionGroups = async (): Promise<
  Collection<ConditionGroup>
> => {
  const response = await Client.get('/ProductConditionGroups', {
    params: { bypassAuth: true },
  });
  return response.data;
};
