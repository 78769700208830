/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { routes } from 'routes';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { useModalContext } from 'contexts/ModalContext';
import { useAuthContext } from 'contexts/AuthContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { Heading } from 'components/common/Heading';
import { Container } from 'components/common/grid';
import { Text } from 'components/common/Text';
import { Condition } from 'models/Condition';
import { Button } from 'components/common/Button';
import { LandingPageSpinner } from 'components/common/Spinner';
import { PomConfirmLoginModal } from 'components/ConfirmLoginModal';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';
import { decodeHTMLEntities } from 'utils/transformers';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';

import styles from './PomIntermediaryPage.module.scss';

interface PomIntermediaryPageProps {
  className?: string;
}

export const PomIntermediaryPage = ({
  className,
}: PomIntermediaryPageProps) => {
  const { conditionGroupId } = useParams<{
    conditionGroupId: string;
  }>();
  const navigate = useHistory();
  const { t } = useTranslation();
  const { login, authStatus } = useAuthContext();

  const { open: openModal } = useModalContext();
  const {
    conditions,
    getConditions,
    conditionGroup,
    getConditionGroup,
    setConditionGroup,
  } = useConditionsContext();

  const { logPageView } = useAmplitudeContext();

  const conditionInfoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getConditions();
    conditionGroupId && getConditionGroup(parseInt(conditionGroupId));
    logPageView({ pageName: t('amplitude.pomIntermediaryPage.pageName') });
    setActiveCondition(null);

    return () => setConditionGroup(null);
  }, [conditionGroupId]);

  const [activeCondition, setActiveCondition] = useState<Condition | null>(
    null,
  );

  if (
    (conditionGroupId && !conditionGroup) ||
    !conditions ||
    conditions?.length <= 0
  ) {
    return <LandingPageSpinner />;
  }

  const conditionsToShow: Condition[] = (
    conditionGroupId
      ? conditions.filter((condition) =>
          conditionGroup!.conditions.some(
            (conditionGroupCondition) =>
              condition.id === conditionGroupCondition.id &&
              condition.products.some(
                (product) => product.isPom && product.inStock,
              ),
          ),
        )
      : [...conditions]
  ).filter(
    (condition) =>
      !condition.name.toLowerCase().includes('general') &&
      condition.showInShop &&
      condition.products.some((product) => product.isPom && product.inStock),
  );

  const handleProceedClick = () => {
    const proceedToUrl = `${routes.SHOP.CONDITION}/${activeCondition!.id}${
      routes.GENERAL_HEALTH.PT1
    }`;
    if (authStatus) {
      navigate.push(proceedToUrl);
    } else {
      openModal(
        <PomConfirmLoginModal
          onLogin={() =>
            login(
              {
                redirectToUrl: proceedToUrl,
              },
              { label: 'Continue to consultation' },
            )
          }
        />,
      );
    }
  };

  return (
    <div className={cx(styles['pom-intermediary'], className)}>
      <Container className={styles['pom-intermediary__container']}>
        <Heading tag="h4" className={styles['pom-intermediary__heading']}>
          {t('pomForms.intermediaryPage.heading')}
        </Heading>
        <Text size="lg" className={styles['pom-intermediary__intro']}>
          {decodeHTMLEntities(
            t('pomForms.intermediaryPage.info', {
              conditionGroup: conditionGroupId ? conditionGroup?.name : null,
            }),
          )}
        </Text>
        {conditionsToShow && conditionsToShow.length > 0 && (
          <div className={styles['pom-intermediary__pills']}>
            {conditionsToShow.map((condition) => (
              <FilterPill
                label={condition.name}
                key={condition.id}
                onClick={() => {
                  setActiveCondition(condition);
                  conditionInfoRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                }}
                isActive={condition.id === activeCondition?.id}
              />
            ))}
          </div>
        )}
        <div ref={conditionInfoRef} />
        {activeCondition && (
          <div
            className={styles['pom-intermediary__condition-info__container']}
          >
            <Heading
              tag="h4"
              className={styles['pom-intermediary__condition-info__heading']}
            >
              {activeCondition.name}
            </Heading>
            <Text size="lg">{activeCondition.tagline}</Text>
            <div
              className={styles['pom-intermediary__condition-info__buttons']}
            >
              <Button
                appearance="outline"
                label={t('pomForms.intermediaryPage.moreInfo')}
                link={{
                  to: `${routes.SHOP.CONDITION}/${activeCondition.id}`,
                }}
              />
              <Button
                label={t('pomForms.intermediaryPage.proceed')}
                onClick={handleProceedClick}
              />
            </div>
          </div>
        )}
        {conditionGroupId && (
          <NavLink
            to={`${routes.SHOP.BASE}${routes.POM.SELECT}`}
            className={cx(styles['pom-intermediary__all-conditions-link'])}
          >
            <Heading
              tag="h6"
              className={styles['pom-intermediary__all-conditions-link__label']}
            >
              {t('pomForms.intermediaryPage.seeAllConditions')}
            </Heading>
            <ChevronRight className={styles['filter-pill__icon']} />
          </NavLink>
        )}
      </Container>
    </div>
  );
};

interface PillLinkProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const FilterPill = ({ label, onClick, isActive }: PillLinkProps) => {
  return (
    <button
      className={cx(
        styles['filter-pill'],
        isActive && styles['filter-pill--active'],
      )}
      onClick={onClick}
    >
      <Heading tag="h6" className={styles['filter-pill__label']}>
        {label}
      </Heading>
      <ChevronRight className={styles['filter-pill__icon']} />
    </button>
  );
};
