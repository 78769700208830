import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { usePatientContext } from 'contexts/PatientContext';
import { Container, Row, Col } from 'components/common/grid';
import { CardType } from 'components/common/Card';
import { CardList } from 'components/CardList';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { routes } from 'routes';
import { ReactComponent as Pill } from 'assets/icons/lawsat/Pill.svg';
import { ReactComponent as Bag } from 'assets/icons/lawsat/Bag.svg';
import { ReactComponent as AccountCompletedImage } from 'assets/illustrations/process-complete.svg';
import styles from './AccountCompleted.module.scss';
import { SignUpRedirect, useSignUpRedirect } from 'hooks/useSignUpRedirect';
import { Button } from 'components/common/Button';
import { useHistory } from 'react-router';

export const AccountCompleted = () => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const { patient } = usePatientContext();
  const { getSignUpRedirect, clearSignUpRedirect } = useSignUpRedirect();
  const [contextualRedirect, setContextualRedirect] =
    useState<SignUpRedirect>();

  useEffect(() => {
    const signUpRedirect = getSignUpRedirect();
    signUpRedirect && setContextualRedirect(signUpRedirect);

    return () => clearSignUpRedirect();
  }, []);

  const ctaLinks: CardType[] = [
    {
      icon: Pill,
      title: t('signUpFlow.completed.linkList.titlePrescription'),
      description: t('signUpFlow.completed.linkList.descriptionPrescription'),
      link: routes.PRESCRIPTION.ONBOARDING.NEXT,
    },
    {
      icon: Bag,
      title: t('signUpFlow.completed.linkList.titleStore'),
      description: t('signUpFlow.completed.linkList.descriptionStore'),
      link: routes.SHOP.BASE,
    },
  ];

  return (
    <>
      <Container className={styles['account-completed']}>
        <Row>
          <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <div className={styles['account-completed__content']}>
              <AccountCompletedImage
                className={styles['account-completed__image']}
              />
              <Heading
                tag="h2"
                size="xl"
                className={styles['account-completed__title']}
              >
                {t('signUpFlow.completed.title', {
                  patient: patient?.firstName,
                })}
              </Heading>
              <Text size="lg" className={styles['account-completed__text']}>
                {t('signUpFlow.completed.intro')}
              </Text>
              {contextualRedirect && (
                <>
                  <Button
                    label={contextualRedirect.label}
                    className={styles['account-completed__contextual-button']}
                    onClick={() =>
                      navigate.push(contextualRedirect.redirectToUrl)
                    }
                  />
                  <Text size="lg" className={styles['account-completed__text']}>
                    {t('signUpFlow.completed.introPt2')}
                  </Text>
                </>
              )}
            </div>
            <CardList
              list={ctaLinks}
              className={styles['account-completed__list']}
            />
            <Text size="lg" className={styles['account-completed__info']}>
              <Trans i18nKey="signUpFlow.completed.updateText"></Trans>
            </Text>
          </Col>
        </Row>
      </Container>
    </>
  );
};
