import React from 'react';
import { BranchLoadingState } from 'components/common/BranchLoadingState';

export type BranchStatus = 'idle' | 'loading' | 'error' | 'finished' | 'empty';

interface BranchProps {
  hasError?: boolean;
  isEmpty?: boolean;
  isLoading?: boolean;
  status?: string;
  Component: () => React.ReactElement;
  EmptyComponent?: () => React.ReactElement;
  ErrorComponent?: () => React.ReactElement;
  IdleComponent?: () => React.ReactElement;
  LoadingComponent?: () => React.ReactElement;
}

// TODO: update default loading/empty/error/idle states below for your project when they are ready

export const Branch: React.FC<BranchProps> = ({
  hasError = false,
  isLoading = false,
  isEmpty = false,
  status,
  Component,
  EmptyComponent = () => <p data-testid="branch-empty-default">No Results</p>,
  ErrorComponent = () => <p data-testid="branch-error-default">Error</p>,
  IdleComponent = () => <p data-testid="branch-idle-default">Idle</p>,
  LoadingComponent = () => (
    <BranchLoadingState testId="branch-loading-default" />
  ),
}) => (
  <>
    {status === 'idle'
      ? IdleComponent()
      : status === 'error' || hasError
      ? ErrorComponent()
      : status === 'loading' || isLoading
      ? LoadingComponent()
      : status === 'empty' || isEmpty
      ? EmptyComponent()
      : Component()}
  </>
);
