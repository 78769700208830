import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { ReactComponent as SuccessImage } from 'assets/illustrations/orders.svg';
import { ReactComponent as FailImage } from 'assets/illustrations/payment-fail.svg';
import { ReactComponent as FailIcon } from 'assets/icons/lawsat/Question.svg';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { LinkPanel } from 'components/LinkPanel';
import { CheckoutOrderSummary } from 'components/CheckoutOrderSummary';
import { routes } from 'routes';
import { OrderHistory } from 'models/OrderHistory';

import styles from './PaymentResult.module.scss';

interface OrderSummaryCardProps {
  order: OrderHistory;
  heading: string;
}
const OrderSummaryCard = ({ order, heading }: OrderSummaryCardProps) => {
  if (!order.orderLines?.length || order.orderLines?.length <= 0) {
    return null;
  }
  return (
    <div className={styles['payment-result__order-summary']}>
      <Heading
        size="md"
        className={styles['payment-result__order-summary__heading']}
      >
        {heading}
      </Heading>
      <Heading
        size="md"
        className={styles['payment-result__order-summary__order-id']}
      >
        {`(#${order.orderId})`}
      </Heading>
      <CheckoutOrderSummary
        products={order?.orderLines?.map((orderline) => ({
          id: orderline.id.toString(),
          name: orderline.name,
          price: orderline.price / orderline.quantity,
          quantity: orderline.quantity,
          isPrescriptionItem: orderline.isPrescriptionItem,
        }))}
        subTotal={order.subTotal}
        deliveryCost={order.deliveryCost}
      />
    </div>
  );
};

interface SuccessProps {
  orders: OrderHistory[] | null;
  dependantName?: string;
}

const Success = ({ orders, dependantName }: SuccessProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logCtaClick } = useAmplitudeContext();

  const handleLogCtaClick = (label: string) => {
    logCtaClick({
      pageName: pageName?.log,
      label: label,
      section: t('amplitude.orderSuccess.section'),
    });
  };

  return (
    <div className={styles['payment-result']}>
      <SuccessImage />

      <Heading className={styles['payment-result__title']} tag="h2">
        {t('OrderFlow.PaymentResult.Success.title')}
      </Heading>

      <Heading tag="h4" className={styles['payment-result__description']}>
        {t('OrderFlow.PaymentResult.Success.description')}
      </Heading>

      {orders && orders.length > 0 && (
        <>
          {orders.map((order) => {
            return (
              <OrderSummaryCard
                key={order.orderId}
                order={order}
                heading={
                  dependantName
                    ? t('OrderFlow.PaymentResult.Success.namesOrder', {
                        name: dependantName,
                        type: order.containsPrescriptionItems
                          ? 'prescriptions'
                          : 'treatments',
                      })
                    : t('OrderFlow.PaymentResult.Success.yourOrder', {
                        type: order.containsPrescriptionItems
                          ? 'prescriptions'
                          : 'treatments',
                      })
                }
              />
            );
          })}

          <Heading
            size="xl"
            className={styles['payment-result__order-summary__order-total']}
          >
            {`${t('OrderFlow.PaymentResult.Success.orderTotal')}${orders
              .reduce(
                (runningTotal, order) => runningTotal + order.totalCost,
                0,
              )
              .toFixed(2)}`}
          </Heading>
        </>
      )}

      <div className={styles['payment-result__buttons']}>
        <Button
          testId="your-account-btn"
          label={t('OrderFlow.PaymentResult.Success.accountBtn')}
          link={{ to: routes.ACCOUNT.BASE }}
          onClick={() => {
            handleLogCtaClick(t('OrderFlow.PaymentResult.Success.accountBtn'));
          }}
        />
        <Button
          testId="home-btn"
          label={t('OrderFlow.PaymentResult.Success.homeBtn')}
          appearance="outline"
          link={{ to: routes.BASE }}
          onClick={() => {
            handleLogCtaClick(t('OrderFlow.PaymentResult.Success.homeBtn'));
          }}
        />
      </div>
    </div>
  );
};

const Fail = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['payment-result']}>
      <FailImage />

      <Heading className={styles['payment-result__title']} tag="h2">
        {t('OrderFlow.PaymentResult.Fail.title')}
      </Heading>

      <Heading tag="h4" className={styles['payment-result__description']}>
        {t('OrderFlow.PaymentResult.Fail.description')}
      </Heading>

      <LinkPanel
        className={styles['payment-result__link-panel']}
        icon={FailIcon}
        title={t('OrderFlow.PaymentResult.Fail.linkTitle')}
        text={t('OrderFlow.PaymentResult.Fail.linkText')}
        link={{
          to: routes.HELP.BASE,
          text: t('OrderFlow.PaymentResult.Fail.linkTitle'),
        }}
      />

      <Button
        className={styles['payment-result__button']}
        label="Done"
        testId="payment-result-button"
        link={{ to: routes.BASE }}
      />
    </div>
  );
};

export const PaymentResult = { Success, Fail };
