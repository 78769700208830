import { useState, useEffect } from 'react';
import { useAuthContext } from 'contexts/AuthContext';
import { useHistory } from 'react-router-dom';

export const useLoginRedirect = () => {
  const { localStorageIsAuthenticated, setAuthFlags, handleRedirectPromise } =
    useAuthContext();

  const navigate = useHistory();

  const [redirectLoading, setRedirectLoading] = useState(true);

  // handle redirects as a result of logging in from a non-home landing page
  const handleRedirectPromiseAsync = async () => {
    const pageState = await handleRedirectPromise();
    pageState?.redirectToUrl && navigate.push(pageState.redirectToUrl);
    setRedirectLoading(false);
  };

  useEffect(() => {
    handleRedirectPromiseAsync();
    setAuthFlags(localStorageIsAuthenticated());
  }, []);

  return { loading: redirectLoading };
};
