const SIGNUP_REDIRECT_KEY = 'signUpRedirect';

export interface SignUpRedirect {
  label: string;
  redirectToUrl: string;
}

export const useSignUpRedirect = () => {
  const setSignUpRedirect = (redirectToUrl: string, label: string) =>
    sessionStorage.setItem(
      SIGNUP_REDIRECT_KEY,
      JSON.stringify({ label: label, redirectToUrl: redirectToUrl }),
    );

  const getSignUpRedirect = () => {
    const item = sessionStorage.getItem(SIGNUP_REDIRECT_KEY);
    return item ? (JSON.parse(item) as SignUpRedirect) : undefined;
  };

  const clearSignUpRedirect = () =>
    sessionStorage.removeItem(SIGNUP_REDIRECT_KEY);

  return { setSignUpRedirect, getSignUpRedirect, clearSignUpRedirect };
};
