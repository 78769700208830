import { Dispatch, SetStateAction } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/common/IconButton';
import { ReactComponent as Add } from 'assets/icons/lawsat/Add.svg';
import { ReactComponent as Minus } from 'assets/icons/lawsat/Minus.svg';
import { Product } from 'models/Product';
import { useBasketContext } from 'contexts/BasketContext';

import styles from './QuantitySelector.module.scss';

const iconButtonStyle = styles['quantity-selector__button'];

interface QuantitySelectorProps {
  product: Product;
  quantitySelectedToAdd: number;
  setQuantitySelectedToAdd: Dispatch<SetStateAction<number>>;
}

export const QuantitySelector = ({
  product,
  quantitySelectedToAdd,
  setQuantitySelectedToAdd,
}: QuantitySelectorProps) => {
  const { t } = useTranslation();
  const { basket } = useBasketContext();
  const basketOrderline = basket.shopProducts.find(
    (line) => Number(line.id) === product.id,
  );

  const quantityInBasket = basketOrderline?.quantity ?? 0;

  const basketIsAtMaxQuantity =
    !!basketOrderline?.orderLimit &&
    quantityInBasket >= basketOrderline.orderLimit;

  const basketCapacity = product.orderLimit
    ? product.orderLimit - quantityInBasket
    : undefined;

  const isDisabled = basketIsAtMaxQuantity || !product.inStock;

  const onClickDecreaseQuantity = () => {
    setQuantitySelectedToAdd((quantity) => Math.max(quantity - 1, 1));
  };

  const onClickIncreaseQuantity = () => {
    if (
      !basketCapacity ||
      quantitySelectedToAdd < basketCapacity + quantitySelectedToAdd
    ) {
      setQuantitySelectedToAdd((quantity) => quantity + 1);
    }
  };

  return (
    <div className={cx(styles['quantity-selector'])}>
      <IconButton
        testId="decrease-button"
        icon={Minus}
        label={t('OrderFlow.Qty.removeSingle')}
        className={iconButtonStyle}
        onClick={!isDisabled ? onClickDecreaseQuantity : undefined}
        disabled={isDisabled ? true : quantitySelectedToAdd === 1}
      />
      <span
        className={styles['quantity-selector__quantity']}
        data-testid="quantity-to-add"
      >
        {quantitySelectedToAdd}
      </span>
      <IconButton
        icon={Add}
        testId="increase-button"
        label={t('OrderFlow.Qty.add')}
        className={iconButtonStyle}
        onClick={!isDisabled ? onClickIncreaseQuantity : undefined}
        disabled={
          isDisabled
            ? true
            : basketCapacity
            ? quantitySelectedToAdd >= basketCapacity
            : false
        }
      />
    </div>
  );
};
