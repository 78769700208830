import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as msal from '@azure/msal-browser';
import {
  msalConfig,
  forgotPasswordUrl,
  loginRequest,
  silentRequest,
} from 'api/utils/AzureConfig';
import { useToastContext } from 'contexts/ToastContext';
import { AxiosInterceptors } from 'components/common/AxiosInterceptors';
import { routes } from 'routes';
import { useHistory } from 'react-router';
import { Toggle, defaultToggle } from 'models/Toggle';
import { useSignUpRedirect } from 'hooks/useSignUpRedirect';

export interface PageState {
  redirectToUrl?: string;
}

export interface PostSignupRedirect {
  label: string;
}

export const clearSessionStorage = (): void => {
  window.sessionStorage.removeItem('Authenticated');
};

export interface AuthContextProps {
  setAuthStatus: Function;
  authStatus: boolean;
  authStatusToggle: Toggle<boolean | undefined>;
  handleRedirectPromise: () => Promise<PageState | undefined>;
  logout: Function;
  login: (state?: PageState, redirectAfterSignup?: PostSignupRedirect) => void;
  setAuthFlags: Function;
  clearSessionStorage: Function;
  localStorageIsAuthenticated: () => boolean;
}

export const AuthContextDefaults = {
  setAuthStatus: Function,
  authStatus: false,
  authStatusToggle: defaultToggle,
  handleRedirectPromise: async () => ({}),
  logout: Function,
  login: () => ({}),
  setAuthFlags: Function,
  clearSessionStorage: Function,
  localStorageIsAuthenticated: () => false,
};

export const AuthContext = createContext<AuthContextProps>(AuthContextDefaults);

export const useAuthContext = (): AuthContextProps => useContext(AuthContext);

interface AuthProviderProps {
  children?: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const pc = new msal.PublicClientApplication(msalConfig);
  const { setSignUpRedirect } = useSignUpRedirect();
  const [authStatus, setAuthStatus] = useState<boolean>(false);
  const [authStatusToggle, setAuthStatusToggle] =
    useState<Toggle<boolean | undefined>>(defaultToggle);

  useEffect(() => {
    setAuthStatusToggle((prevState) => ({
      current: authStatus,
      previous: prevState?.current,
    }));
  }, [authStatus]);

  const setAuthFlags = (state: boolean) => {
    setAuthStatus(state);
    window.sessionStorage.setItem('Authenticated', state.toString());
  };

  const getAuthValueFromLocalStorage = () =>
    window.sessionStorage.getItem('Authenticated');

  const localStorageIsAuthenticated = () =>
    getAuthValueFromLocalStorage() === 'true';

  const errorToast = (errorMessage: string) => {
    const isRefresh = errorMessage.includes(
      'Interaction is currently in progress',
    );
    setToast({
      status: 'error',
      title: 'Error',
      description: t('common.error.authErrorRedirectMessage'),
      isRefresh: isRefresh,
    });
  };

  const handleRedirectPromise = async () => {
    setAuthStatus(false);
    try {
      const res = await pc.handleRedirectPromise();
      if (res) {
        setAuthFlags(true);
        const state: PageState = res.state ? JSON.parse(res.state) : undefined;
        return state;
      }
      return {};
    } catch (error) {
      setAuthFlags(false);
      const msalError = error as msal.AuthError;
      msalError.errorMessage.indexOf('AADB2C90118') > -1 &&
        (await pc.loginRedirect(forgotPasswordUrl));
    }
  };

  const login = async (
    state?: PageState,
    redirectAfterSignup?: PostSignupRedirect,
  ) => {
    redirectAfterSignup?.label &&
      state?.redirectToUrl &&
      setSignUpRedirect(state.redirectToUrl, redirectAfterSignup.label);

    await handleRedirectPromise();

    const request: msal.RedirectRequest = {
      ...loginRequest,
      state: JSON.stringify(state),
    };

    await pc.loginRedirect(request).catch((error) => {
      errorToast(error.errorMessage);
    });
  };

  const logout = async () => {
    setAuthFlags(false);
    clearSessionStorage();

    try {
      silentRequest.account = pc.getAllAccounts()[0];
      const { idToken } = await pc.acquireTokenSilent(silentRequest);
      pc.logoutRedirect({
        idTokenHint: idToken,
        postLogoutRedirectUri: `${process.env.REACT_APP_URL}`,
      });
    } catch {
      history.push(routes.BASE);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        setAuthStatus,
        localStorageIsAuthenticated,
        authStatus,
        authStatusToggle,
        handleRedirectPromise,
        logout,
        login,
        setAuthFlags,
        clearSessionStorage,
      }}
    >
      <AxiosInterceptors />
      {children}
    </AuthContext.Provider>
  );
};
