/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import { Switch, useParams } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { DependantAdded } from 'containers/Dependant/DependantAdded';
import { DependantAddressContainer } from 'containers/Dependant/DependantAddressContainer';
import { DependantSelectPharmacy } from 'containers/Dependant/DependantSelectPharmacy';
import { PostCodeSearchProvider } from 'contexts/PostCodeSearchContext';
import { useTranslation } from 'react-i18next';
import { useDependantContext } from 'contexts/DependantContext';
import { LandingPageSpinner } from 'components/common/Spinner';
import { routes } from 'routes';

export const DependantAddFlow = () => {
  const { dependantId } = useParams<{ dependantId?: string }>();
  const { t } = useTranslation();
  const { dependant, getAllDependantInfo, clearDependantContext } =
    useDependantContext();

  useEffect(() => {
    if (dependantId) {
      getAllDependantInfo(dependantId);
    }
    return clearDependantContext();
  }, [dependantId]);

  if (!dependant) {
    return <LandingPageSpinner />;
  }

  const baseRoute = `${routes.DEPENDANT.ADD.BASE}${dependantId}`;
  return (
    <Switch>
      <PrivateRoute path={`${baseRoute}${routes.DEPENDANT.ADD.ADDRESS}`} exact>
        <PostCodeSearchProvider>
          <DependantAddressContainer
            heading={t('Dependant.address.title', {
              dependant: dependant?.firstName,
            })}
            submitButton={{
              label: t('Dependant.address.formLabels.submit'),
              route: `${baseRoute}${routes.DEPENDANT.ADD.PHARMACY}`,
            }}
            progressBar={{ currentStage: 2, numberOfStages: 3 }}
          />
        </PostCodeSearchProvider>
      </PrivateRoute>

      <PrivateRoute path={`${baseRoute}${routes.DEPENDANT.ADD.PHARMACY}`} exact>
        <DependantSelectPharmacy
          heading={t('Dependant.selectPharmacy.title', {
            dependantName: dependant?.firstName,
          })}
          submitButton={{
            route: `${routes.DEPENDANT.ADD.BASE}${dependantId}${routes.DEPENDANT.ADD.ADDED}`,
            label: t('Dependant.selectPharmacy.formLabels.submit'),
          }}
          progressBar={{ currentStage: 3, numberOfStages: 3 }}
        />
      </PrivateRoute>

      <PrivateRoute path={`${baseRoute}${routes.DEPENDANT.ADD.ADDED}`} exact>
        <DependantAdded />
      </PrivateRoute>
    </Switch>
  );
};
