import React from 'react';
import { ReactComponent as ShopUnavailableImage } from 'assets/illustrations/shop-unavailable.svg';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { Container } from 'components/common/grid';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';

import styles from './ShopUnavailable.module.scss';

export const ShopUnavailable = () => {
  const { t } = useTranslation();
  const { login, authStatus } = useAuthContext();
  const navigate = useHistory();

  return (
    <Container>
      <div className={styles['shop-unavailable']}>
        <ShopUnavailableImage
          className={styles['shop-unavailable__illustration']}
        />
        <Heading tag="h5" className={styles['shop-unavailable__heading']}>
          {t('shop.unavailablePage.body')}
        </Heading>
      </div>
      <div className={styles['shop-unavailable__ctas']}>
        <Button
          label={t('shop.unavailablePage.prescriptions-btn')}
          onClick={() => {
            authStatus
              ? navigate.push(routes.PRESCRIPTION.BASE)
              : login({ redirectToUrl: '/prescription' });
          }}
        />
        <Button
          label={t('shop.unavailablePage.travel-btn')}
          appearance="outline"
          onClick={() => navigate.push(routes.TRAVELVACCINATIONS.BASE)}
        />
      </div>
      <div className={styles['shop-unavailable__ctas']}>
        <Button
          className={styles['shop-unavailable__ctas__contact-us']}
          label={t('shop.unavailablePage.contact-btn')}
          appearance="ghost"
          onClick={() => navigate.push(routes.CONTACT)}
        />
      </div>
    </Container>
  );
};
