import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { App } from 'containers/App';
import 'i18n';

const container = document.getElementById('root') as HTMLElement;
const rootElement = createRoot(container);

rootElement.render(<App />);
