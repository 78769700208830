import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { InfoPanel } from 'components/InfoPanel';
import { ReactComponent as CircleInfoIcon } from 'assets/icons/lawsat/CircleInfoGreen.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/lawsat/checkmark.svg';
import styles from './OrderStatusTile.module.scss';
interface OrderStatusTileProps {
  deliveryOption?: string | null;
  orderStatus: string;
  hasPrescriptions?: boolean;
}

const getI18nRoot = (orderStatus: string) => {
  const firstLetter = orderStatus.charAt(0).toLowerCase();
  const otherLetters = orderStatus.slice(1);
  return `OrderDetails.statuses.${firstLetter}${otherLetters}`;
};

export const OrderStatusTile = ({
  deliveryOption,
  orderStatus,
  hasPrescriptions = false,
}: OrderStatusTileProps) => {
  const { t } = useTranslation();
  let title;
  let sub = '';
  const showAlert = orderStatus === 'Cancelled';
  const showTickIcon = orderStatus === 'OrderComplete';

  switch (orderStatus) {
    case 'OrderComplete': {
      title =
        deliveryOption === 'Collection'
          ? t('OrderDetails.statuses.orderComplete.collection')
          : t('OrderDetails.statuses.orderComplete.delivery');
      break;
    }
    case 'Cancelled': {
      title = t('OrderDetails.statuses.cancelled');
      break;
    }
    case 'OrderReceived': {
      title = t('OrderDetails.statuses.orderReceived.title');
      sub = hasPrescriptions
        ? t('OrderDetails.statuses.orderReceived.sub')
        : t('OrderDetails.statuses.orderReceived.subTreatmentsOnly');
      break;
    }
    default: {
      const statusRoot = getI18nRoot(orderStatus);
      title = t(`${statusRoot}.title`);
      sub = t(`${statusRoot}.sub`);
    }
  }

  return (
    <div
      className={cx(styles['order-status-tile'], {
        [styles['order-status-tile--alert']]: showAlert,
        [styles['order-status-tile--complete']]: showTickIcon,
      })}
    >
      <InfoPanel
        icon={showTickIcon ? CheckmarkIcon : CircleInfoIcon}
        title={title}
        text={sub}
      />
    </div>
  );
};
