import React, { useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { useBasketContext } from 'contexts/BasketContext';
import { usePaymentContext } from 'contexts/PaymentContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { usePatientContext } from 'contexts/PatientContext';
import { useDependantContext } from 'contexts/DependantContext';
import { useConfigContext } from 'contexts/ConfigContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useRetainOrderInfo } from 'hooks/useRetainOrderInfo';
import {
  ChosenDeliveryOption,
  DateTimeSlot,
  DeliveryAddress,
} from 'models/DeliveryOption';
import { BasketOrderline, DependantBasket } from 'models/Basket';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { Textarea } from 'components/common/Textarea';
import { Text } from 'components/common/Text';
import { CheckoutOrderCard } from 'components/CheckoutOrderCard';
import { LandingPageSpinner } from 'components/common/Spinner';
import { ReactComponent as ApplePayLogo } from 'assets/logo/ApplePay.svg';
import { ReactComponent as VisaLogo } from 'assets/logo/Visa.svg';
import { ReactComponent as GooglePayLogo } from 'assets/logo/GooglePay.svg';
import { ReactComponent as MasterCardLogo } from 'assets/logo/MasterCard.svg';
import { isFreeStandardDeliveryAvailable } from 'utils/DeliveryOption';
import { routes } from 'routes';
import dompurify from 'dompurify';

import styles from './Checkout.module.scss';

const pageName = {
  display: i18n.t('amplitude.checkout.display'),
  log: i18n.t('amplitude.checkout.log'),
};
const NOTE_MAX_LENGTH = 1000;
const sanitizer = dompurify.sanitize;

export const Checkout = () => {
  const { dependantId } = useParams<{ dependantId: string | undefined }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { saveRetainedOrderInfo } = useRetainOrderInfo();
  const {
    basket,
    dependantBaskets,
    getDeliveryOptions,
    deliveryOptions,
    clearDeliveryOptions,
    placeOrder,
  } = useBasketContext();
  const { getPatientPharmacies, getPatientPharmacy } = usePatientContext();
  const {
    getDependantPharmacies,
    getDependantPharmacy,
    getDependant,
    clearDependantContext,
  } = useDependantContext();
  const { freeDeliveryThreshold } = useConfigContext();
  const {
    getPaymentConfig,
    paymentConfig,
    statusCode,
    loading: { getPaymentConfigStatus },
  } = usePaymentContext();
  const { setPageName } = useLandingPageContext();
  const { logPageView, logCtaClick } = useAmplitudeContext();

  const formRef = useRef<HTMLFormElement>(null);

  const { control, watch, handleSubmit } = useForm({});
  const [shopErrorMessage, setShopErrorMessage] = useState<string | null>(null);
  const [prescriptionErrorMessage, setPrescriptionErrorMessage] = useState<
    string | null
  >(null);
  const [fetchComplete, setFetchComplete] = useState(false);

  const [
    selectedPrescriptionsDeliveryOption,
    setSelectedPrescriptionsDeliveryOption,
  ] = useState<ChosenDeliveryOption | undefined>();

  const [
    selectedShopProductsDeliveryOption,
    setSelectedShopProductsDeliveryOption,
  ] = useState<ChosenDeliveryOption | undefined>();

  let shopProducts: BasketOrderline[] = [];
  let prescriptionProducts: BasketOrderline[] = [];
  let dependantBasket: DependantBasket | undefined;
  let orderTotals: {
    subtotals?: {
      shop?: number;
      prescription?: number;
    };
    total: number;
  } = { total: 0 };

  if (dependantId) {
    dependantBasket = dependantBaskets.find(
      (basket) => basket.dependantId === dependantId,
    );
    if (dependantBasket) {
      prescriptionProducts = dependantBasket.basket.prescriptionProducts;
      orderTotals = {
        subtotals: { prescription: dependantBasket.basket.subTotal },
        total:
          dependantBasket.basket.subTotal +
          (selectedPrescriptionsDeliveryOption?.cost ?? 0),
      };
    }
  } else {
    shopProducts = basket.shopProducts;
    prescriptionProducts = basket.prescriptionProducts;
    orderTotals = {
      subtotals: {
        prescription: basket.subTotals.prescriptionProducts,
        shop: basket.subTotals.shopProducts,
      },
      total:
        basket.subTotals.prescriptionProducts +
        basket.subTotals.shopProducts +
        (selectedPrescriptionsDeliveryOption?.cost ?? 0) +
        (selectedShopProductsDeliveryOption?.cost ?? 0),
    };
  }

  const fetchData = async () => {
    if (dependantId) {
      await getDependant(dependantId);
      await getDependantPharmacies(dependantId);
      await getDependantPharmacy(dependantId);
    } else {
      await getPatientPharmacies();
      await getPatientPharmacy();
    }

    shopProducts.length > 0 &&
      (await getDeliveryOptions({
        shopSubtotal: orderTotals.subtotals?.shop,
      }));

    prescriptionProducts.length > 0 &&
      (await getDeliveryOptions({
        prescriptionSubtotal: orderTotals.subtotals?.prescription,
        dependantId,
      }));

    setFetchComplete(true);
  };

  useEffect(() => {
    setPageName(pageName);
    logPageView({ pageName: pageName.log });
  }, []);
  useEffect(() => {
    if (
      ((!shopProducts || shopProducts?.length <= 0) &&
        (!prescriptionProducts || prescriptionProducts?.length <= 0)) ||
      (dependantId && !dependantBasket)
    ) {
      history.push(routes.BASKET);
    } else {
      fetchData();
    }

    return () => {
      setFetchComplete(false);
      clearDependantContext();
      clearDeliveryOptions();
    };
  }, [dependantId]);

  useEffect(() => {
    if (paymentConfig !== null) {
      if (statusCode === 204) {
        history.push(routes.ORDER.SUCCESS);
      }
      if (statusCode === 200) {
        formRef.current?.submit();
      }
    }
  }, [paymentConfig, statusCode]);

  const updateSelectedDeliveryOption = (
    setter: Function,
    data: ChosenDeliveryOption | DeliveryAddress | DateTimeSlot,
    pharmacyId?: number,
  ) => {
    setter((prevState: ChosenDeliveryOption) => {
      if ('option' in data) return data; // When selecting the delivery option checkboxes
      if ('deliveryDate' in data) return { ...prevState, deliverySlot: data }; // updating chosen date for prescription delivery
      return {
        // updating selected pharmacy for treatments collection
        ...prevState,
        deliveryAddress: data,
        deliveryPharmacyId: pharmacyId,
      };
    });
  };

  const updateDeliveryOption = (
    orderType: 'shop' | 'prescription',
    data: ChosenDeliveryOption | DeliveryAddress | DateTimeSlot,
    pharmacyId?: number,
  ) => {
    const setters = {
      shop: setSelectedShopProductsDeliveryOption,
      prescription: setSelectedPrescriptionsDeliveryOption,
    };

    updateSelectedDeliveryOption(setters[orderType], data, pharmacyId);
    setShopErrorMessage(null);
    setPrescriptionErrorMessage(null);
  };

  const isValidSelection = (): boolean => {
    let isValid = true;
    if (
      prescriptionProducts.length > 0 &&
      !selectedPrescriptionsDeliveryOption
    ) {
      setPrescriptionErrorMessage(chooseDeliveryOptionError);
      isValid = false;
    }
    if (shopProducts.length > 0 && !selectedShopProductsDeliveryOption) {
      setShopErrorMessage(chooseDeliveryOptionError);
      isValid = false;
    }
    if (
      shopProducts.length > 0 &&
      selectedShopProductsDeliveryOption?.option === 'Collection' &&
      !selectedShopProductsDeliveryOption.deliveryPharmacyId
    ) {
      setShopErrorMessage(t('CheckoutPage.errors.chooseCollectionPharmacy'));
      isValid = false;
    }
    if (
      selectedPrescriptionsDeliveryOption &&
      selectedPrescriptionsDeliveryOption?.option !== 'Collection' &&
      !selectedPrescriptionsDeliveryOption?.deliverySlot?.deliveryTimeSlot
    ) {
      setPrescriptionErrorMessage(t('CheckoutPage.errors.chooseTimeSlot'));
      isValid = false;
    }
    return isValid;
  };

  const handleLogCtaClick = () => {
    if (prescriptionProducts) {
      orderTotals.total > 0
        ? logCtaClick({
            label: t('amplitude.checkout.paidLabel'),
            pageName: pageName.log,
            section: t('amplitude.checkout.sectionPrescription'),
          })
        : logCtaClick({
            label: t('amplitude.checkout.freeLabel'),
            pageName: pageName.log,
            section: t('amplitude.checkout.sectionPrescription'),
          });
    } else {
      orderTotals.total > 0
        ? logCtaClick({
            label: t('amplitude.checkout.paidLabel'),
            pageName: pageName.log,
            section: t('amplitude.checkout.sectionNonPrescription'),
          })
        : logCtaClick({
            label: t('amplitude.checkout.freeLabel'),
            pageName: pageName.log,
            section: t('amplitude.checkout.sectionNonPrescription'),
          });
    }
  };

  const onSubmit = async () => {
    handleLogCtaClick();

    if (!isValidSelection()) return;
    const note = sanitizer(watch('note'));

    const orderGroupId = await placeOrder({
      prescriptionDeliveryOption: selectedPrescriptionsDeliveryOption,
      treatmentDeliveryOption: selectedShopProductsDeliveryOption,
      note,
      dependantId,
    });

    if (orderGroupId !== 0) {
      saveRetainedOrderInfo({ orderGroupId, dependantId });
      await getPaymentConfig(orderGroupId);
    }
  };

  const showFreeDeliveryMessage = isFreeStandardDeliveryAvailable(
    deliveryOptions.shop,
    freeDeliveryThreshold ?? 0,
    orderTotals.subtotals?.shop ?? 0,
  );

  const breadcrumbs: Crumb[] = [
    { route: routes.BASKET, text: t('NavBarLabels.basket') },
    { route: routes.ORDER.CHECKOUT, text: t('NavBarLabels.checkout') },
  ];
  const chooseDeliveryOptionError = t('CheckoutPage.errors.chooseDelivery');
  const noteCharactersRemaining = NOTE_MAX_LENGTH - watch('note', '').length;

  if (!fetchComplete) {
    return <LandingPageSpinner />;
  }

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Container>
        {shopProducts.length > 0 && prescriptionProducts.length > 0 && (
          <Heading size="sm" className={styles['checkout__top-heading']}>
            {t('CheckoutPage.topText')}
          </Heading>
        )}

        <form className={styles['checkout']} onSubmit={handleSubmit(onSubmit)}>
          {prescriptionProducts.length > 0 && (
            <CheckoutOrderCard
              type="prescription"
              products={prescriptionProducts}
              subTotal={orderTotals?.subtotals?.prescription ?? 0}
              deliveryOptions={deliveryOptions.prescription}
              selectedDeliveryOption={selectedPrescriptionsDeliveryOption}
              deliveryDataHandler={updateDeliveryOption}
              errorMessage={prescriptionErrorMessage}
              dependantId={dependantId}
            />
          )}
          {shopProducts.length > 0 && (
            <CheckoutOrderCard
              type="shop"
              products={shopProducts}
              subTotal={orderTotals?.subtotals?.shop ?? 0}
              deliveryOptions={deliveryOptions.shop}
              selectedDeliveryOption={selectedShopProductsDeliveryOption}
              deliveryDataHandler={updateDeliveryOption}
              errorMessage={shopErrorMessage}
              isFreeStandardDeliveryAvailable={showFreeDeliveryMessage}
            />
          )}

          <div>
            <Controller
              name="note"
              control={control}
              defaultValue={''}
              render={(props, fieldState) => (
                <Textarea
                  label={t('CheckoutPage.noteLabel')}
                  labelClassName={styles['checkout__note__label']}
                  maxLength={NOTE_MAX_LENGTH}
                  variant={fieldState.invalid ? 'negative' : 'accent'}
                  placeholder={t('CheckoutPage.notePlaceholder')}
                  rows={3}
                  className={styles['checkout__note']}
                  {...props}
                />
              )}
            />
            <Text className={styles['checkout__note__info-msg']}>
              {t('CheckoutPage.noteCharacterRemaining', {
                remainingCharacters: noteCharactersRemaining,
              })}
            </Text>
          </div>

          <Row className={styles['checkout__footer']}>
            <Col
              xs={{ size: 12, offset: 0 }}
              md={{ size: 4, offset: 0 }}
              lg={{ size: 6, offset: 0 }}
            >
              <Heading size="xxl">
                {t('CheckoutPage.orderTotal', {
                  total: orderTotals.total.toFixed(2),
                })}
              </Heading>
            </Col>
            <Col>
              <div className={styles['checkout__footer__buttons']}>
                <Button
                  link={{ to: routes.BASKET }}
                  appearance="outline"
                  label={t('CheckoutPage.backButton')}
                  onClick={() => {
                    logCtaClick({
                      label: t('amplitude.checkout.backToBasketLabel'),
                      pageName: pageName.log,
                      section: t('amplitude.checkout.section'),
                    });
                  }}
                />
                <Button
                  label={
                    orderTotals.total > 0
                      ? t('CheckoutPage.proceedButton.payment')
                      : t('CheckoutPage.proceedButton.free')
                  }
                  type="submit"
                  isLoading={getPaymentConfigStatus === 'loading'}
                />
              </div>
              <hr />
              <div className={styles['checkout__footer__logos']}>
                <VisaLogo />
                <MasterCardLogo />
                <GooglePayLogo />
                <ApplePayLogo />
              </div>
            </Col>
          </Row>
        </form>

        {typeof paymentConfig === 'object' && (
          <form
            ref={formRef}
            name="paymentForm"
            method="post"
            action={`${process.env.REACT_APP_PAYMENT}`}
          >
            <input
              type="hidden"
              name="checkoutoption"
              value={paymentConfig?.checkoutOption}
            />
            <input
              type="hidden"
              name="currency"
              value={paymentConfig?.currency}
            />
            <input
              type="hidden"
              name="hash_algorithm"
              value={paymentConfig?.hashAlgorithm}
            />
            <input
              type="hidden"
              name="responseFailURL"
              value={`${process.env.REACT_APP_URL}${routes.ORDER.FAIL}`}
            />
            <input
              type="hidden"
              name="responseSuccessURL"
              value={`${process.env.REACT_APP_URL}${routes.ORDER.SUCCESS}`}
            />
            <input
              type="hidden"
              name="storename"
              value={paymentConfig?.storeId}
            />
            <input
              type="hidden"
              name="timezone"
              value={paymentConfig?.timeZone}
            />
            <input
              type="hidden"
              name="txntype"
              value={paymentConfig?.txnType}
            />

            <input
              type="hidden"
              name="hashExtended"
              value={paymentConfig?.hashExtended}
            />
            <input type="hidden" name="oid" value={paymentConfig?.oid} />
            <input
              type="hidden"
              name="txndatetime"
              value={paymentConfig?.txnDateTime}
            />
            <input
              type="hidden"
              name="chargetotal"
              value={paymentConfig?.chargeTotal.toFixed(2)}
            />
          </form>
        )}
      </Container>
    </>
  );
};
