import cx from 'clsx';
import { Container, Col } from 'components/common/grid';
import { RefObject } from 'react';
import { Text } from 'components/common/Text';
import { ReactComponent as RightArrow } from 'assets/icons/lawsat/ChevronRight.svg';
import { Button } from 'components/common/Button';

import styles from './ConditionTaglineSection.module.scss';
import { useTranslation } from 'react-i18next';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';

interface ConditionTaglineSectionProps {
  tagline?: string;
  showReadMore?: boolean;
  readMoreSectionRef?: RefObject<HTMLDivElement>;
  className?: string;
}

export const ConditionTaglineSection = ({
  tagline,
  showReadMore = false,
  readMoreSectionRef,
  className,
}: ConditionTaglineSectionProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logCtaClick } = useAmplitudeContext();

  const handleLogCtaClick = () => {
    logCtaClick({
      label: t('amplitude.conditionTaglineSection.label'),
      pageName: pageName?.log,
      section: t('amplitude.conditionTaglineSection.section'),
    });
  };

  if (!tagline) return null;

  return (
    <Container className={cx(styles['condition-tagline-section'], className)}>
      <Col>
        <Text size="lg">{tagline}</Text>
        {showReadMore && (
          <Button
            label="Read more"
            icon={{ element: RightArrow, alignment: 'right' }}
            appearance="ghost"
            className={styles['condition-tagline-section__read-more']}
            onClick={() => {
              handleLogCtaClick();
              readMoreSectionRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }}
          />
        )}
      </Col>
    </Container>
  );
};
