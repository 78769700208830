import React from 'react';
import {
  getInProgress,
  getFulfilled,
  getOrder as getSpecificOrder,
} from 'api/OrderHistory';
import { BranchStatus } from 'components/common/Branch/Branch';
import { OrderHistory } from 'models/OrderHistory';
import { useApiError } from 'hooks/useApiError';
import { useToastContext } from 'contexts/ToastContext';
import { useTranslation } from 'react-i18next';
export interface OrderHistoryContextProps {
  getInProgressOrders: Function;
  getFulfilledOrders: Function;
  getOrder: Function;
  inProgressStatus: BranchStatus;
  fulfilledStatus: BranchStatus;
  orderStatus: BranchStatus;
  inProgressOrders: OrderHistory[];
  fulfilledOrders: OrderHistory[];
  order: OrderHistory | null;
  children?: React.ReactNode;
}

export const OrderHistoryContextDefaults: OrderHistoryContextProps = {
  getInProgressOrders: Function,
  getFulfilledOrders: Function,
  getOrder: Function,
  inProgressStatus: 'idle',
  fulfilledStatus: 'idle',
  orderStatus: 'idle',
  inProgressOrders: [],
  fulfilledOrders: [],
  order: null,
};

export const OrderHistoryContext =
  React.createContext<OrderHistoryContextProps>(OrderHistoryContextDefaults);

export const useOrderHistoryContext = (): OrderHistoryContextProps =>
  React.useContext(OrderHistoryContext);

interface OrderHistoryProviderProps {
  children?: React.ReactNode;
}

export const OrderHistoryProvider = ({
  children,
}: OrderHistoryProviderProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const { handleApiError } = useApiError();

  const errorWithToast = () => {
    setToast({
      status: 'error',
      title: t('common.error.genericTitle'),
      description: t('common.error.genericMessage'),
    });
  };

  const [inProgressStatus, setInProgressStatus] =
    React.useState<BranchStatus>('idle');
  const [fulfilledStatus, setFulfilledStatus] =
    React.useState<BranchStatus>('idle');

  const [orderStatus, setOrderStatus] = React.useState<BranchStatus>('idle');

  const [inProgressOrders, setInProgressOrders] = React.useState<
    OrderHistory[]
  >([]);
  const [fulfilledOrders, setFulfilledOrders] = React.useState<OrderHistory[]>(
    [],
  );
  const [order, setOrder] = React.useState<OrderHistory | null>(null);

  const getInProgressOrders = async () => {
    try {
      setInProgressStatus('loading');
      const result = await getInProgress();
      setInProgressStatus(result.length > 0 ? 'finished' : 'empty');
      setInProgressOrders(result.slice(0, 5));
    } catch (error) {
      handleApiError(() => {
        setInProgressStatus('error');
        errorWithToast();
      }, error);
    }
  };

  const getFulfilledOrders = async () => {
    try {
      setFulfilledStatus('loading');
      const result = await getFulfilled();
      setFulfilledStatus(result.length > 0 ? 'finished' : 'empty');
      setFulfilledOrders(result.slice(0, 5));
    } catch (error) {
      handleApiError(() => {
        setFulfilledStatus('error');
        errorWithToast();
      }, error);
    }
  };

  const getOrder = async (orderId: number) => {
    try {
      setOrderStatus('loading');
      setOrder(null);
      const result = await getSpecificOrder(orderId);
      setOrderStatus('finished');
      setOrder(result);
    } catch (error) {
      handleApiError(() => {
        setOrderStatus('error');
        errorWithToast();
      }, error);
    }
  };

  return (
    <OrderHistoryContext.Provider
      value={{
        getInProgressOrders,
        getFulfilledOrders,
        getOrder,
        inProgressStatus,
        fulfilledStatus,
        orderStatus,
        inProgressOrders,
        fulfilledOrders,
        order,
      }}
    >
      {children}
    </OrderHistoryContext.Provider>
  );
};
