import React from 'react';
import { PrescriptionListItem } from 'components/PrescriptionListItem';
import { PrescriptionOrderItem } from 'components/PrescriptionOrderItem';
import type { PrescriptionItem } from 'models/PrescriptionItem';
import type { BasketOrderline } from 'models/Basket';
import styles from './PrescriptionList.module.scss';

interface PrescriptionListProps {
  list?: PrescriptionItem[];
  orderLines?: BasketOrderline[];
  type?: 'order' | 'list';
}

export const PrescriptionList: React.FC<PrescriptionListProps> = ({
  list,
  orderLines,
  type = 'list',
}) => {
  if (
    (!list && !orderLines) ||
    (list?.length === 0 && orderLines?.length === 0)
  ) {
    return null;
  }

  return (
    <ul className={styles['prescription-list']}>
      {type === 'list' &&
        list &&
        list.map((item) => {
          return (
            <li
              key={item.medicinalProductId}
              className={styles['prescription-list__item']}
            >
              <PrescriptionListItem item={item} />
            </li>
          );
        })}
      {type === 'order' &&
        orderLines &&
        orderLines.map((item) => {
          return (
            <li key={item.id} className={styles['prescription-list__item']}>
              <PrescriptionOrderItem item={item} />
            </li>
          );
        })}
    </ul>
  );
};
