import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { ReactComponent as MainLogo } from 'assets/logo/lawsat-logo-white.svg';
import { routes } from 'routes';
import cx from 'classnames';

import styles from './Footer.module.scss';

interface FooterProps {
  className?: string;
}

export const Footer = ({ className }: FooterProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logCtaClick } = useAmplitudeContext();

  const handleLogCtaClick = (label: string) => {
    logCtaClick({
      label: label,
      pageName: pageName?.log,
      section: t('amplitude.footer.section'),
    });
  };

  return (
    <div className={cx(styles['footer'], className)}>
      <div className={styles['footer__content']}>
        <div className={styles['footer__content__top-row']}>
          <Link
            to={routes.BASE}
            className={styles['footer__content__logo']}
            onClick={() => {
              handleLogCtaClick(t('amplitude.footer.lawsatLogoLabel'));
            }}
          >
            <MainLogo />
          </Link>
          <div className={styles['footer__content__links']}>
            <a
              href={routes.CONTACT}
              onClick={() => {
                handleLogCtaClick(t('LandingPage.footer.contactUs'));
              }}
            >
              <Heading size="sm" tag="h6">
                {t('LandingPage.footer.contactUs')}
              </Heading>
            </a>
            <a
              href={routes.HELP.BASE}
              onClick={() => {
                handleLogCtaClick(t('LandingPage.footer.helpCentre'));
              }}
            >
              <Heading size="sm" tag="h6">
                {t('LandingPage.footer.helpCentre')}
              </Heading>
            </a>
            <a
              href={routes.TERMS}
              onClick={() => {
                handleLogCtaClick(t('LandingPage.footer.terms'));
              }}
            >
              <Heading size="sm" tag="h6">
                {t('LandingPage.footer.terms')}
              </Heading>
            </a>
            <a
              href={routes.PRIVACY}
              onClick={() => {
                handleLogCtaClick(t('LandingPage.footer.privacyPolicy'));
              }}
            >
              <Heading size="sm" tag="h6">
                {t('LandingPage.footer.privacyPolicy')}
              </Heading>
            </a>
            <a
              href={routes.COOKIES}
              onClick={() => {
                handleLogCtaClick(t('LandingPage.footer.cookies'));
              }}
            >
              <Heading size="sm" tag="h6">
                {t('LandingPage.footer.cookies')}
              </Heading>
            </a>
          </div>
        </div>
        <div className={styles['footer__content__copyright']}>
          <Text size="md"> {t('LandingPage.footer.copyright')}</Text>
        </div>
      </div>
    </div>
  );
};
