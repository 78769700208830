import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigContext } from 'contexts/ConfigContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { LandingPageHelpItem } from 'components/LandingPageHelpItem';
import { ReactComponent as CartIcon } from 'assets/icons/lawsat/Cart.svg';
import { ReactComponent as SmileyIcon } from 'assets/icons/lawsat/Smiley.svg';
import { ReactComponent as WatchIcon } from 'assets/icons/lawsat/Watch.svg';
import { ReactComponent as TruckIcon } from 'assets/icons/lawsat/White-Truck.svg';
import cx from 'clsx';

import styles from './HereToHelpSection.module.scss';

interface HereToHelpSectionProps {
  className?: string;
}

export const HereToHelpSection = ({ className }: HereToHelpSectionProps) => {
  const { t } = useTranslation();
  const { freeDeliveryThreshold } = useConfigContext();
  const { pageName } = useLandingPageContext();
  const { logSectionView } = useAmplitudeContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.hereToHelpSection.section'),
      });
    }
  }, [isInViewport]);

  return (
    <div
      className={cx(styles['here-to-help-section'], className)}
      ref={elementRef}
    >
      <Container className={styles['here-to-help-section__container']}>
        <Row className={styles['here-to-help-section__title']}>
          <Heading tag={'h1'} size="xxxl">
            {t('LandingPage.hereToHelp.title')}
          </Heading>
        </Row>
        <Row className={styles['here-to-help-section__items']}>
          <Col xs={12} sm={6} lg={3}>
            <LandingPageHelpItem
              title={t('LandingPage.hereToHelp.freeDeliveryTitle')}
              label={t('LandingPage.hereToHelp.freeDeliveryContent', {
                freeDeliveryThreshold,
              })}
              Icon={TruckIcon}
            />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <LandingPageHelpItem
              title={t('LandingPage.hereToHelp.localServiceTitle')}
              label={t('LandingPage.hereToHelp.localServiceContent')}
              Icon={SmileyIcon}
            />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <LandingPageHelpItem
              title={t('LandingPage.hereToHelp.saveTimeTitle')}
              label={t('LandingPage.hereToHelp.saveTimeContent')}
              Icon={WatchIcon}
            />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <LandingPageHelpItem
              title={t('LandingPage.hereToHelp.pharmacyProductsTitle')}
              label={t('LandingPage.hereToHelp.pharmacyProductsContent')}
              Icon={CartIcon}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
