import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOrderHistoryContext } from 'contexts/OrderHistoryContext';
import { Container, Row, Col } from 'components/common/grid';
import { Branch } from 'components/common/Branch';
import { OrderDetail } from 'components/OrderDetail';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import { routes } from 'routes';

import styles from './AccountOrderStatus.module.scss';

export const AccountOrderStatus = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { getOrder, orderStatus, order } = useOrderHistoryContext();

  React.useEffect(() => {
    getOrder(id);
  }, []);

  const breadcrumbs: Crumb[] = [
    { route: routes.ACCOUNT.BASE, text: t('NavBarLabels.account') },
    {
      route: routes.ACCOUNT.ORDERS,
      text: t('Orders.landing.title'),
    },
    {
      route: `${routes.ACCOUNT.ORDERS}/order/${id}`,
      text: `#${id}`,
    },
  ];

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Container className={styles['account-order-status']}>
        <Row>
          <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <Branch
              status={orderStatus}
              Component={() => <>{order && <OrderDetail order={order} />}</>}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
