import { Client } from 'api/utils';
import { ResponseObject } from 'models/PaymentConfig';
import { routes } from 'routes';

const payload = {
  responseFailUrl: `${process.env.REACT_APP_URL}${routes.ORDER.FAIL}`,
  responseSuccessUrl: `${process.env.REACT_APP_URL}${routes.ORDER.SUCCESS}`,
};

export const fetchPaymentConfig = async (
  ID: number,
): Promise<ResponseObject> => {
  return Client.post(`/payments`, { ...payload, orderGroupId: ID });
};
