/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { CheckoutOrderSummary } from 'components/CheckoutOrderSummary';
import { DeliveryOption } from 'components/DeliveryOption';
import { BasketOrderline } from 'models/Basket';
import { ReactComponent as CircleInfo } from 'assets/icons/lawsat/CircleInfo.svg';
import { ReactComponent as CircleCheckmark } from 'assets/icons/CircleCheckmark.svg';
import {
  ChosenDeliveryOption,
  DeliveryAddress,
  DeliveryOption as DeliveryOptionModel,
  DateTimeSlot,
} from 'models/DeliveryOption';
import { useDependantContext } from 'contexts/DependantContext';

import styles from './CheckoutOrderCard.module.scss';

interface CheckoutOrderCardProps {
  type?: 'shop' | 'prescription';
  products: BasketOrderline[];
  subTotal: number;
  deliveryOptions: DeliveryOptionModel[];
  selectedDeliveryOption: ChosenDeliveryOption | undefined;
  deliveryDataHandler: (
    orderType: 'shop' | 'prescription',
    data: DeliveryOptionModel | DeliveryAddress | DateTimeSlot,
  ) => void;
  errorMessage?: string | null;
  dependantId?: string;
  isFreeStandardDeliveryAvailable?: boolean;
}

export const CheckoutOrderCard = ({
  type = 'shop',
  products,
  subTotal,
  deliveryOptions,
  selectedDeliveryOption,
  deliveryDataHandler,
  errorMessage,
  dependantId,
  isFreeStandardDeliveryAvailable = false,
}: CheckoutOrderCardProps) => {
  const { t } = useTranslation();
  const { dependant } = useDependantContext();

  return (
    <div className={styles['checkout-order-card']}>
      <Heading size="md" className={styles['checkout-order-card__title']}>
        {dependantId
          ? t('CheckoutPage.orderCardTitle.dependant', {
              name: dependant?.firstName,
            })
          : type === 'shop'
          ? t('CheckoutPage.orderCardTitle.shop')
          : t('CheckoutPage.orderCardTitle.prescription')}
      </Heading>
      <div className={styles['checkout-order-card__body']}>
        <div className={styles['checkout-order-card__summary-section']}>
          <CheckoutOrderSummary
            products={products}
            subTotal={subTotal}
            deliveryCost={selectedDeliveryOption?.cost ?? 0}
          />
          {isFreeStandardDeliveryAvailable && (
            <Text className={styles['checkout-order-card__free-delivery-msg']}>
              <CircleCheckmark />
              {t('CheckoutPage.freeDeliveryAvailable')}
            </Text>
          )}
        </div>
        <div className={styles['checkout-order-card__delivery-section']}>
          {deliveryOptions.map((deliveryOption) => (
            <DeliveryOption
              dependantId={dependantId}
              key={`${type}${deliveryOption.option}`}
              type={type}
              deliveryOption={deliveryOption}
              deliveryDataHandler={deliveryDataHandler}
              checked={selectedDeliveryOption?.option === deliveryOption.option}
              mandatory={deliveryOptions.length === 1}
            />
          ))}

          {errorMessage && (
            <Text className={styles['checkout-order-card__error-msg']}>
              <CircleInfo />
              {errorMessage}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};
