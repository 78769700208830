import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';
import { LandingPage } from './LandingPage';
import { BookingPage } from './BookingPage';
import { PricingPage } from './PricingPage';
import { VaccinationInfoPage } from './VaccinationInfoPage';

export const TravelVaccinations = () => {
  return (
    <Switch>
      <Route path={routes.TRAVELVACCINATIONS.BOOK} exact>
        <BookingPage />
      </Route>
      <Route path={routes.TRAVELVACCINATIONS.PRICING} exact>
        <PricingPage />
      </Route>
      <Route path={`${routes.TRAVELVACCINATIONS.INFO}/:vaccine`} exact>
        <VaccinationInfoPage />
      </Route>
      <Route path={routes.TRAVELVACCINATIONS.BASE}>
        <LandingPage />
      </Route>
    </Switch>
  );
};
