import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Icon } from 'components/common/Icon';
import { ReactComponent as RightArrow } from 'assets/icons/lawsat/ChevronRight.svg';

import styles from './LinkPanel.module.scss';

interface LinkPanelProps {
  className?: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  link: { to: string; text: string; newTab?: boolean };
  testId?: string;
  text: string | React.ReactElement;
  title: string;
}

export const LinkPanel: React.FC<LinkPanelProps> = ({
  className,
  icon,
  link,
  testId,
  text,
  title,
}) => {
  const targetProps = link?.newTab
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : '';

  const linkPanelClasses = cx(styles['link-panel'], {
    [`${className}`]: className,
  });

  return (
    <Link
      data-testid={testId}
      className={linkPanelClasses}
      to={link.to}
      {...targetProps}
    >
      <Icon className={styles['link-panel__main-icon']} icon={icon} size="md" />
      <Heading tag="h5" className={styles['link-panel__info-title']}>
        {title}
      </Heading>
      <Text tag="div" size="md" className={styles['link-panel__text']}>
        {text}
      </Text>
      <Icon
        className={styles['link-panel__arrow-icon']}
        icon={RightArrow}
        size="md"
      />
    </Link>
  );
};
