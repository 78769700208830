import { RefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Condition, ConditionGroup } from 'models/Condition';
import { Crumb, Breadcrumbs } from 'components/common/Breadcrumbs';
import { Col, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { ConditionTaglineSection } from 'components/Sections/Shop/ConditionTaglineSection';
import { StartConsultationCTA } from 'components/StartConsultationCTA';
import { ReactComponent as LawsatSymbol } from 'assets/logo/lawsat-symbol-with-gradient.svg';

import styles from './ConditionBanner.module.scss';

interface ConditionBannerProps {
  breadcrumbs: Crumb[];
  condition?: Condition;
  conditionGroup?: ConditionGroup;
  descriptionRef?: RefObject<HTMLDivElement>;
  startConsultationCta:
    | { showCta: false }
    | {
        showCta: true;
        startConsulationLink: string;
        showReview?: boolean;
        disableButton?: boolean;
      };
}

export const ConditionBanner = ({
  breadcrumbs,
  condition,
  conditionGroup,
  descriptionRef,
  startConsultationCta = { showCta: false },
}: ConditionBannerProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logSectionView } = useAmplitudeContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  const conditionValue = condition ?? conditionGroup;

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.conditionBanner.section'),
      });
    }
  }, [isInViewport]);

  return (
    <div className={styles['condition-banner__gradient']} ref={elementRef}>
      <Breadcrumbs links={breadcrumbs} />
      <div className={styles['condition-banner__top-section__container']}>
        <Row className={styles['condition-banner__top-section__row']}>
          <LawsatSymbol className={styles[`condition-banner__lawsat-symbol`]} />
          {startConsultationCta.showCta ? (
            <>
              <Col
                xs={{ size: 12, offset: 0 }}
                sm={{ size: 6, offset: 0 }}
                md={{ size: 7, offset: 0 }}
              >
                <Heading tag="h2" size="xxxl">
                  {conditionValue?.name}
                </Heading>
                <ConditionTaglineSection
                  tagline={conditionValue?.tagline}
                  showReadMore={!!conditionValue?.description}
                  readMoreSectionRef={descriptionRef}
                />
              </Col>

              <Col
                xs={{ size: 12, offset: 0 }}
                sm={{ size: 6, offset: 0 }}
                md={{ size: 5, offset: 0 }}
              >
                {condition ? (
                  <StartConsultationCTA
                    cta={{
                      label: startConsultationCta.showReview
                        ? t('shop.startConsultation.reviewButton')
                        : t('shop.startConsultation.startButton'),
                      link: startConsultationCta.startConsulationLink,
                      disableButton:
                        startConsultationCta.disableButton ?? false,
                    }}
                  />
                ) : (
                  <StartConsultationCTA
                    requireLogin={false}
                    cta={{
                      label: t('shop.startConsultation.startButton'),
                      link: startConsultationCta.startConsulationLink,
                      disableButton:
                        startConsultationCta.disableButton ?? false,
                    }}
                  />
                )}
              </Col>
            </>
          ) : (
            <Col>
              <Heading tag="h2" size="xxxl">
                {conditionValue?.name}
              </Heading>
              <ConditionTaglineSection
                tagline={conditionValue?.tagline}
                showReadMore={!!conditionValue?.description}
                readMoreSectionRef={descriptionRef}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};
