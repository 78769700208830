//Toast Provider uses https://fkhadra.github.io/react-toastify/introduction
import React from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { Toast } from 'components/common/Toast';
import 'react-toastify/dist/ReactToastify.css';
import 'stylesheets/vendor-override/_toast.scss';

export interface ToastContextProps {
  setToast: (toastConfig: ToastProps) => void;
  dismissToast: () => void;
  showDefaultErrorToast: () => void;
  children?: React.ReactNode;
}

export type ToastStatus = 'success' | 'info' | 'warning' | 'error';

export interface ToastProps {
  status: ToastStatus;
  title?: string;
  description?: string;
  duration?: number | boolean;
  isClosable?: boolean;
  isRefresh?: boolean;
}

export const ToastContextDefaults = {
  setToast: () => ({}),
  dismissToast: () => ({}),
  showDefaultErrorToast: () => ({}),
};

export const ToastContext =
  React.createContext<ToastContextProps>(ToastContextDefaults);

export const useToastContext = (): ToastContextProps =>
  React.useContext(ToastContext);

interface ToastProviderProps {
  children?: React.ReactNode;
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const toastId = React.useRef(undefined);
  const DEFAULT_TITLE = t('common.error.genericTitle');
  const DEFAULT_MESSAGE = '';

  const dismissToast = () => toast.dismiss(toastId.current);

  React.useEffect(() => {
    location.pathname !== '/' && dismissToast();
  }, [location]);

  const setToast = (toastConfig: ToastProps) => {
    toast[toastConfig.status](
      <Toast
        title={toastConfig?.title || DEFAULT_TITLE}
        description={toastConfig?.description || DEFAULT_MESSAGE}
        type={toastConfig.status}
        isRefresh={toastConfig.isRefresh}
      />,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        transition: Bounce,
        autoClose: false,
        pauseOnHover: true,
        hideProgressBar: false,
        draggable: false,
        closeButton: false,
        closeOnClick: toastConfig?.isClosable ?? false,
      },
    );
  };

  const showDefaultErrorToast = () => {
    const errors = {
      title: t('common.error.genericTitle'),
      message: t('common.error.genericMessage'),
    };
    setToast({
      status: 'error',
      title: errors.title,
      description: errors.message,
    });
  };

  return (
    <ToastContext.Provider
      value={{
        dismissToast,
        setToast,
        showDefaultErrorToast,
      }}
    >
      {children}
      <ToastContainer role="alert" />
    </ToastContext.Provider>
  );
};
