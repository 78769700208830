import cx from 'clsx';
import styles from './RichTextViewer.module.scss';
import textStyles from 'components/common/Text/Text.module.scss';
import headingStyles from 'components/common/Heading/Heading.module.scss';

export interface GetLexicalViewerThemeArgs {
  textSize: 'sm' | 'md' | 'lg';
}

export const getLexicalViewerTheme = ({
  textSize,
}: GetLexicalViewerThemeArgs) => ({
  placeholder: styles['rich-text-viewer__placeholder'],
  paragraph: cx(textStyles['text'], textStyles[`text-${textSize}`]),
  heading: {
    h2: cx(headingStyles['heading'], headingStyles['heading-lg']),
    h3: cx(headingStyles['heading'], headingStyles['heading-md']),
    h4: cx(headingStyles['heading'], headingStyles['heading-sm']),
  },
  list: {
    nested: {
      listitem: cx(
        styles['rich-text-viewer__nested-list-item'],
        textStyles['text'],
        textStyles[`text-${textSize}`],
      ),
    },
    ol: styles['rich-text-viewer__ol'],
    ul: styles['rich-text-viewer__ul'],
    listitem: cx(
      styles['rich-text-viewer__list-item'],
      textStyles['text'],
      textStyles[`text-${textSize}`],
    ),
  },
  text: {
    bold: cx(
      styles['rich-text-viewer--bold'],
      textStyles['text'],
      textStyles[`text-${textSize}`],
    ),
    italic: cx(
      styles['rich-text-viewer--italic'],
      textStyles['text'],
      textStyles[`text-${textSize}`],
    ),
    underline: cx(
      styles['rich-text-viewer--underline'],
      textStyles['text'],
      textStyles[`text-${textSize}`],
    ),
  },
});
