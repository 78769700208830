/* eslint-disable sonarjs/no-duplicate-string */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import {
  PsoriasisPomForm as PsoriasisPomFormType,
  psoriasisPomFormQuestions,
  PsoriasisAffectedAreas,
  defaultPsoriasisAffectedAreas,
} from 'models/PomQuestionnaires/Psoriasis';
import { useTranslation } from 'react-i18next';
import { useBasketContext } from 'contexts/BasketContext';
import { useConditionsContext } from 'contexts/ConditionsContext';
import {
  ImageSelector,
  MultiSelect,
  SingleSelect,
  TextArea,
} from '../PomForm.Components';
import { PsoriasisPomFormSchema } from 'formSchema/PomForms/PsoriasisPomFormSchema';

interface PsoriasisPomFormProps {
  onSubmit: (
    payload: PsoriasisPomFormType,
    isImageUploadRequired: boolean,
  ) => Promise<void>;
  actionButtons?: ReactNode;
}

export const PsoriasisPomForm = ({
  onSubmit,
  actionButtons,
}: PsoriasisPomFormProps) => {
  const { t } = useTranslation();
  const { basket } = useBasketContext();
  const { condition } = useConditionsContext();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const fields = psoriasisPomFormQuestions;
  const [shouldValidateImages, setShouldValidateImages] = useState(true); // assume by default that images need validating

  const formMethods = useForm({
    resolver: yupResolver(PsoriasisPomFormSchema(shouldValidateImages)),
  });
  const { handleSubmit, watch, setValue, register, reset } = formMethods;

  useEffect(() => {
    const existingForm = basket.pomForms.find(
      (form) => form.conditionId === condition?.id,
    )?.formAnswers;
    if (existingForm && existingForm.conditionId === 15) {
      reset({ ...existingForm });

      // If using Images input
      setImageFilenames(existingForm.imageNames);
      setShouldValidateImages(existingForm.imageNames?.length === 0);

      // Condition Specific Stuff
      setAffectedSkinAreas(existingForm.affectedSkinAreas);
    }
  }, [basket.shopProducts]);

  // If using Images input
  const [imageFilenames, setImageFilenames] = useState<string[]>([]);
  const imageFileInputRef = useRef<HTMLInputElement>(null);
  register(fields[12].fieldName);

  // Condition specific stuff
  const [affectedSkinAreas, setAffectedSkinAreas] =
    useState<PsoriasisAffectedAreas>(defaultPsoriasisAffectedAreas);
  const isTakingAnyMedication = watch(fields[7].fieldName);
  const hasBeenReviewedInLast12Months = watch(fields[10].fieldName);
  setValue(fields[3].fieldName, affectedSkinAreas, {
    shouldValidate: formSubmitted,
  });

  return (
    <form
      onSubmit={handleSubmit(
        (payload: PsoriasisPomFormType) => {
          setFormSubmitted(true);
          onSubmit(
            { ...payload, conditionId: 15, imageNames: imageFilenames },
            shouldValidateImages,
          );
        },
        () => setFormSubmitted(true),
      )}
    >
      <SingleSelect field={fields[0]} formMethods={formMethods} />

      <SingleSelect
        field={fields[1]}
        formMethods={formMethods}
        orientation="vertical"
        values={[
          {
            value: 'This is the first time',
            label: t('pomForms.generic.labels.howLongSuffering.firstTime'),
          },
          {
            value: 'Less than six months',
            label: t(
              'pomForms.generic.labels.howLongSuffering.lessThanSixMonths',
            ),
          },
          {
            value: 'More than six months',
            label: t(
              'pomForms.generic.labels.howLongSuffering.moreThanSixMonths',
            ),
          },
        ]}
      />

      <TextArea field={fields[2]} formMethods={formMethods} />

      <MultiSelect
        field={fields[3]}
        defaultValue={defaultPsoriasisAffectedAreas}
        formMethods={formMethods}
        checkboxes={[
          {
            label: t('pomForms.psoriasis.labels.affectedAreas.elbows'),
            value: affectedSkinAreas.Elbows,
            onChange: () =>
              setAffectedSkinAreas((prev) => ({
                ...prev,
                Elbows: !prev.Elbows,
              })),
          },
          {
            label: t('pomForms.psoriasis.labels.affectedAreas.backOfKnees'),
            value: affectedSkinAreas['Back of knees'],
            onChange: () =>
              setAffectedSkinAreas((prev) => ({
                ...prev,
                'Back of knees': !prev['Back of knees'],
              })),
          },
          {
            label: t('pomForms.psoriasis.labels.affectedAreas.hands'),
            value: affectedSkinAreas.Hands,
            onChange: () =>
              setAffectedSkinAreas((prev) => ({
                ...prev,
                Hands: !prev.Hands,
              })),
          },
          {
            label: t('pomForms.psoriasis.labels.affectedAreas.torso'),
            value: affectedSkinAreas.Torso,
            onChange: () =>
              setAffectedSkinAreas((prev) => ({
                ...prev,
                Torso: !prev.Torso,
              })),
          },
          {
            label: t('pomForms.psoriasis.labels.affectedAreas.other'),
            value: affectedSkinAreas.Other,
            onChange: () =>
              setAffectedSkinAreas((prev) => ({
                ...prev,
                Other: !prev.Other,
              })),
          },
        ]}
      />

      {affectedSkinAreas.Other && (
        <TextArea field={fields[4]} formMethods={formMethods} />
      )}

      <SingleSelect field={fields[5]} formMethods={formMethods} />

      <SingleSelect field={fields[6]} formMethods={formMethods} />

      <SingleSelect field={fields[7]} formMethods={formMethods} />

      {isTakingAnyMedication === 'Yes' && (
        <>
          <TextArea field={fields[8]} formMethods={formMethods} />

          <SingleSelect
            field={fields[9]}
            formMethods={formMethods}
            orientation="vertical"
            values={[
              {
                value: 'Daily',
                label: t('pomForms.generic.labels.howOften.daily'),
              },
              {
                value: 'A few times a week',
                label: t('pomForms.generic.labels.howOften.fewTimesAWeek'),
              },
              {
                value: 'Once a week',
                label: t('pomForms.generic.labels.howOften.onceAWeek'),
              },
              {
                value: 'Once a month',
                label: t('pomForms.generic.labels.howOften.onceAMonth'),
              },
              {
                value: 'Infrequently',
                label: t('pomForms.generic.labels.howOften.infrequently'),
              },
            ]}
          />
        </>
      )}

      <SingleSelect field={fields[10]} formMethods={formMethods} />

      {hasBeenReviewedInLast12Months === 'Yes' && (
        <TextArea field={fields[11]} formMethods={formMethods} />
      )}

      <ImageSelector
        field={fields[12]}
        formMethods={formMethods}
        imageFilenames={
          shouldValidateImages
            ? imageFilenames
            : [
                t('pomForms.generic.labels.imageUpload.count', {
                  count: imageFilenames?.length,
                }),
              ]
        }
        setImageFilenames={setImageFilenames}
        inputRef={imageFileInputRef}
        onChange={() => setShouldValidateImages(true)}
      />

      {actionButtons}
    </form>
  );
};
