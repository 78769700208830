import React from 'react';

import styles from './ValidationError.module.scss';

interface ValidationErrorProps {
  id: string;
  children?: React.ReactNode;
}

export const ValidationError = ({ id, children }: ValidationErrorProps) => {
  return (
    <span
      id={`error-${id}`}
      className={styles['validation-error']}
      data-testid={`error-${id}`}
      role="alert"
    >
      {children}
    </span>
  );
};
