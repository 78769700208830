import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';
import { getWeeks, formatDateString } from 'utils/transformers';
import { PrescriptionItem } from 'models/PrescriptionItem';
import { Button } from 'components/common/Button';
import { Icon } from 'components/common/Icon';
import { Heading } from 'components/common/Heading';
import { IconInfoSpan } from 'components/common/IconInfoSpan';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';
import { ReactComponent as Bell } from 'assets/icons/lawsat/Bell.svg';
import { ReactComponent as Clock } from 'assets/icons/lawsat/Clock.svg';
import { useDependantContext } from 'contexts/DependantContext';
import { useBasketContext } from 'contexts/BasketContext';

import { routes } from 'routes';
import styles from './PrescriptionListItem.module.scss';

interface PrescriptionListItemProps {
  item: PrescriptionItem;
}

const buttonStyles = 'prescription-list-item__button';

export const PrescriptionListItem = ({ item }: PrescriptionListItemProps) => {
  const { t } = useTranslation();
  const { dependantId } = useParams<{ dependantId: string }>();
  const { dependant } = useDependantContext();
  const {
    basket,
    dependantBaskets,
    handleAddPrescriptionProductToBasket,
    handleRemovePrescriptionProductFromBasket,
  } = useBasketContext();

  const itemIsInBasket = dependantId
    ? dependantBaskets
        .find((basket) => basket.dependantId === dependantId)
        ?.basket.prescriptionProducts.find(
          (product) => product.id === item.medicinalProductId,
        ) !== undefined
    : basket.prescriptionProducts.find(
        (product) => product.id === item.medicinalProductId,
      ) !== undefined;

  const itemRoute = dependantId
    ? `/dependant/prescription/${dependantId}/detail/${item.medicinalProductId}`
    : `${routes.PRESCRIPTION.DETAIL}/${item.medicinalProductId}`;

  return (
    <div className={styles['prescription-list-item']}>
      <Link className={styles['prescription-list-item__link']} to={itemRoute}>
        <Icon
          className={styles['prescription-list-item__arrow']}
          icon={ChevronRight}
        />

        <Heading
          size="sm"
          tag="h3"
          className={styles['prescription-list-item__title']}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: sanitize(item.medicinalProductName),
            }}
          />
        </Heading>
      </Link>

      <div className={styles['prescription-list-item__bottom-section']}>
        {item.reminderFrequencyNumberOfDays && (
          <div className={styles['prescription-list-item__info-container']}>
            <IconInfoSpan
              icon={Bell}
              text={
                getWeeks(item.reminderFrequencyNumberOfDays) <= 2
                  ? t('PrescriptionFlow.List.weekWithCount', {
                      count: getWeeks(item.reminderFrequencyNumberOfDays),
                    })
                  : t('PrescriptionFlow.List.monthWithCount', {
                      count: getWeeks(item.reminderFrequencyNumberOfDays) / 4,
                    })
              }
            />
            <IconInfoSpan
              icon={Clock}
              text={
                item.lastOrderedDateTime
                  ? t('PrescriptionFlow.List.lastOrder', {
                      time: formatDateString(item.lastOrderedDateTime),
                    })
                  : t('PrescriptionFlow.List.noOrders')
              }
            />
          </div>
        )}
        {!item.reminderFrequencyNumberOfDays && (
          <Button
            className={styles[buttonStyles]}
            label={t('PrescriptionFlow.List.reminderButton')}
            appearance="flat"
            link={{
              to: dependantId
                ? `${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.REMINDER}/${item.medicinalProductId}`
                : `${routes.PRESCRIPTION.REMINDER}/${item.medicinalProductId}`,
            }}
          />
        )}
        {itemIsInBasket ? (
          <Button
            className={styles[buttonStyles]}
            label={t(
              'PrescriptionFlow.landing.removePrescriptionItemFromBasket',
            )}
            appearance="flat"
            variant="negative"
            onClick={() =>
              handleRemovePrescriptionProductFromBasket(
                item.medicinalProductId,
                dependantId,
              )
            }
          />
        ) : (
          <Button
            className={styles[buttonStyles]}
            label={
              dependantId
                ? t(
                    'PrescriptionFlow.landing.addPrescriptionItemToDependantBasket',
                    { dependant: dependant?.firstName },
                  )
                : t('PrescriptionFlow.landing.addPrescriptionItemToBasket')
            }
            appearance="flat"
            onClick={() =>
              handleAddPrescriptionProductToBasket(item, dependantId)
            }
          />
        )}
      </div>
      <span className={styles['prescription-list-item__indicator']}></span>
    </div>
  );
};
