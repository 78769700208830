import React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { Text } from '../Text';

import styles from './Breadcrumbs.module.scss';

export interface Crumb {
  text: string;
  route: string;
}

interface BreadcrumbsProps {
  testId?: string;
  className?: string;
  links: Crumb[];
}

export const Breadcrumbs = ({ testId, className, links }: BreadcrumbsProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logCtaClick } = useAmplitudeContext();

  return (
    <nav
      aria-label="breadcrumb"
      className={cx(styles['breadcrumbs'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <ol className={styles['breadcrumbs__list']}>
        {links.map(({ text, route }) => {
          return (
            <li key={route} className={styles['breadcrumbs__list-item']}>
              <NavLink
                exact
                to={route}
                className={styles['breadcrumbs__link']}
                activeClassName={styles['breadcrumbs__link--active']}
                onClick={() => {
                  logCtaClick({
                    label: text,
                    pageName: pageName?.log,
                    section: t('amplitude.breadcrumbs.section'),
                  });
                }}
              >
                <Text size="md">{text}</Text>
              </NavLink>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
