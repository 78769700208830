import { useEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useLandingPageContext } from 'contexts/LandingPageContext/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Col, Container } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import {
  TravelVaccinationsInfoCard,
  TravelVaccinationsInfoCardProps,
} from 'components/TravelVaccinationsInfoCard';
import { Button } from 'components/common/Button';

import styles from './BookingProcessSection.module.scss';

interface BookingProcessSectionProps {
  heading: string;
  cards: TravelVaccinationsInfoCardProps[];
  cta?: { label: string; to: string };
  className?: string;
}

export const getBookingProcessCards = (
  t: TFunction<'translation', undefined>,
  pageName: 'landingPage' | 'bookingPage',
) => [
  {
    img: {
      src: '/assets/images/laptop-typing.png',
      alt: t(
        `travelVaccinations.${pageName}.bookingProcess.steps.one.imgAltText`,
      ),
    },
    heading: t(
      `travelVaccinations.${pageName}.bookingProcess.steps.one.heading`,
    ),
    bodyText: t(
      `travelVaccinations.${pageName}.bookingProcess.steps.one.bodyText`,
    ),
    stepNumber: t(
      `travelVaccinations.${pageName}.bookingProcess.steps.one.stepNumber`,
    ),
  },
  {
    img: {
      src: '/assets/images/pharmacy-purchase.png',
      alt: t(
        `travelVaccinations.${pageName}.bookingProcess.steps.two.imgAltText`,
      ),
    },
    heading: t(
      `travelVaccinations.${pageName}.bookingProcess.steps.two.heading`,
    ),
    bodyText: t(
      `travelVaccinations.${pageName}.bookingProcess.steps.two.bodyText`,
    ),
    stepNumber: t(
      `travelVaccinations.${pageName}.bookingProcess.steps.two.stepNumber`,
    ),
  },
  {
    img: {
      src: '/assets/images/friends-on-holiday.png',
      alt: t(
        `travelVaccinations.${pageName}.bookingProcess.steps.three.imgAltText`,
      ),
    },
    heading: t(
      `travelVaccinations.${pageName}.bookingProcess.steps.three.heading`,
    ),
    bodyText: t(
      `travelVaccinations.${pageName}.bookingProcess.steps.three.bodyText`,
    ),
    stepNumber: t(
      `travelVaccinations.${pageName}.bookingProcess.steps.three.stepNumber`,
    ),
  },
];

export const BookingProcessSection = ({
  heading,
  cards,
  cta,
  className,
}: BookingProcessSectionProps) => {
  const { t } = useTranslation();
  const { logCtaClick, logSectionView } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.bookingProcessSection.sectionName'),
      });
    }
  }, [isInViewport]);

  return (
    <div ref={elementRef}>
      <Container className={cx(styles['booking-process-section'], className)}>
        <Col className={styles['booking-process-section__heading-col']}>
          <Heading tag="h1">{heading}</Heading>
        </Col>
        <Col className={styles['booking-process-section__cards-col']}>
          {cards.map((card) => (
            <TravelVaccinationsInfoCard {...card} key={card.stepNumber} />
          ))}

          {cta && (
            <Button
              label={cta.label}
              link={{ to: cta.to }}
              variant="accent"
              appearance="solid"
              size="lg"
              className={styles['booking-process-section__cta__overrides']}
              onClick={() =>
                logCtaClick({
                  label: cta.label,
                  pageName: pageName?.log,
                  section: t('amplitude.bookingProcessSection.section'),
                })
              }
            />
          )}
        </Col>
      </Container>
    </div>
  );
};
