import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useApiError } from 'hooks/useApiError';
import { useToastContext } from 'contexts/ToastContext';
import { Product } from 'models/Product';
import {
  getProduct as getProductAPI,
  getProductsSearch as getProductsSearchAPI,
} from 'api/Products';
import { BranchStatus } from 'components/common/Branch/Branch';

export interface ProductsContextLoading {
  getProductStatus?: BranchStatus;
  productSearchStatus?: BranchStatus;
}

export interface ProductsContextProps {
  loading: ProductsContextLoading;
  product: Product | null;
  products: Product[];
  getProduct: (id: string) => Promise<void>;
  getProductsSearch: (
    searchString: string,
    top: number,
    skip: number,
  ) => Promise<void>;
  productSearchResultsCount: number | undefined;
  setProductSearchResultsCount: Dispatch<SetStateAction<number | undefined>>;
  setProduct: Dispatch<SetStateAction<Product | null>>;
  setProducts: Dispatch<SetStateAction<Product[]>>;
  children?: ReactNode;
}

export const ProductsContextDefaults: ProductsContextProps = {
  product: null,
  products: [],
  productSearchResultsCount: 0,
  setProductSearchResultsCount: () => undefined,
  getProductsSearch: async () => undefined,
  getProduct: async () => undefined,
  setProduct: () => ({}),
  setProducts: () => [],
  loading: {},
};

export const ProductsContext = createContext<ProductsContextProps>(
  ProductsContextDefaults,
);

export const useProductsContext = (): ProductsContextProps =>
  useContext(ProductsContext);

interface ProductsProviderProps {
  children?: ReactNode;
}

export const ProductsProvider = ({ children }: ProductsProviderProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const { handleApiError } = useApiError();

  const [loading, setLoading] = useState<ProductsContextLoading>({
    getProductStatus: 'loading',
    productSearchStatus: 'loading',
  });
  const [product, setProduct] = useState<Product | null>(null);
  const [productSearchResultsCount, setProductSearchResultsCount] =
    useState<number>();
  const [products, setProducts] = useState<Product[]>([]);

  const errors = {
    title: t('common.error.genericTitle'),
    message: t('common.error.genericMessage'),
  };

  const errorWithToast = () => {
    setToast({
      status: 'error',
      title: errors.title,
      description: errors.message,
    });
  };

  const getProduct = async (id: string) => {
    try {
      setLoading({ ...loading, getProductStatus: 'loading' });
      const result = await getProductAPI(id);
      setProduct(result);
      setLoading({ ...loading, getProductStatus: 'finished' });
    } catch (error) {
      handleApiError(() => {
        setLoading({ ...loading, getProductStatus: 'error' });
        errorWithToast();
      }, error);
    }
  };

  const getProductsSearch = async (
    searchString: string,
    top: number,
    skip: number,
  ) => {
    try {
      setLoading({ ...loading, productSearchStatus: 'loading' });
      const result = await getProductsSearchAPI(searchString, top, skip);
      setProducts(result.items);
      setProductSearchResultsCount(result.count);
      setLoading({ ...loading, productSearchStatus: 'finished' });
    } catch (error) {
      handleApiError(() => {
        setLoading({ ...loading, productSearchStatus: 'error' });
        errorWithToast();
      }, error);
    }
  };

  // memoised to keep Sonarcloud happy and prevent unnecessary (re)renders
  const value = useMemo(
    () => ({
      loading,
      product,
      products,
      productSearchResultsCount,
      setProductSearchResultsCount,
      getProduct,
      getProductsSearch,
      setProduct,
      setProducts,
    }),
    [loading, product],
  );

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};
