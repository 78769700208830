import { Client } from 'api/utils';
import {
  GeneralHealthForm,
  GeneralHealthFormApiPayload,
} from 'models/GeneralHealthQuestionnaire';

const getPayload = (form: GeneralHealthForm): GeneralHealthFormApiPayload => ({
  ...form,
  gpPracticeId:
    form.gpPracticeId === '' || form.gpPracticeId === undefined
      ? null
      : form.gpPracticeId,
  shareNotes:
    form.shareNotes === '' || form.shareNotes === undefined
      ? null
      : form.shareNotes,
  shareInformationToGpGranted: form.shareInformationToGpGranted === 'Yes',
  hasAllergies: form.hasAllergies === 'Yes',
  isRegisteredWithGp: form.isRegisteredWithGp === 'Yes',
  isTakingMedication: form.isTakingMedication === 'Yes',
  hasMedicalCondition: form.hasMedicalCondition === 'Yes',
  hasMentalHealthCondition: form.hasMentalHealthCondition === 'Yes',
});

const transformPayload = (
  payload: GeneralHealthFormApiPayload,
): GeneralHealthForm => ({
  ...payload,
  dateYY: parseInt(payload.dateOfBirth.substring(0, 4)).toString(),
  dateMM: parseInt(payload.dateOfBirth.substring(5, 7)).toString(),
  dateDD: parseInt(payload.dateOfBirth.substring(8, 10)).toString(),
  weightKg: payload.weight.toString(),
  heightM: Math.floor(payload.height / 100).toString(),
  heightCm: (payload.height % 100).toString(),
  shareInformationToGpGranted: payload.shareInformationToGpGranted
    ? 'Yes'
    : 'No',
  hasAllergies: payload.hasAllergies ? 'Yes' : 'No',
  isRegisteredWithGp: payload.isRegisteredWithGp ? 'Yes' : 'No',
  isTakingMedication: payload.isTakingMedication ? 'Yes' : 'No',
  hasMedicalCondition: payload.hasMedicalCondition ? 'Yes' : 'No',
  hasMentalHealthCondition: payload.hasMentalHealthCondition ? 'Yes' : 'No',
  shareNotes: payload.shareNotes ? payload.shareNotes : '',
  gpPracticeId: payload.gpPracticeId ? payload.gpPracticeId : undefined,
});

export const saveGeneralHealthForm = async (
  form: GeneralHealthForm,
): Promise<GeneralHealthForm> => {
  const response = await Client.post(
    `/Questionnaires/CreateGeneralHealthQuestionnaire`,
    getPayload(form),
  );
  return transformPayload(response.data);
};

export const getLatestGeneralHealthForm =
  async (): Promise<GeneralHealthForm> => {
    const response = await Client.get(`/Questionnaires/GeneralHealth/latest`);
    return transformPayload(response.data);
  };
