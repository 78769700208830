import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'components/common/grid';
import { AppBarTop } from 'components/Layout/AppBarTop';
import { Link, useHistory } from 'react-router-dom';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';

import styles from './PrescriptionSCRDetail.module.scss';

export const PrescriptionSCRDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <AppBarTop
        closeRoute={{
          onClick: () => history.goBack(),
          text: t('PrescriptionFlow.completed.back'),
        }}
      />
      <Container className={styles['prescription-scr']}>
        <Row>
          <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <Heading>Permission to View Your NHS Summary Care Record</Heading>
            <Text>
              Your Summary Care Record contains information from your health
              record such as your current prescription, allergies and any bad
              reactions to medication you have had in the past. It will also
              include your name, address, date of birth and your unique NHS
              Number to help identify you correctly.
            </Text>
            <Text>
              The pharmacist/pharmacy technician involved in providing care to
              you would like to view your Summary Care Record in order to
              provide the best possible care for you from the most up to date
              information available.
            </Text>
            <Text>
              Only the pharmacist/pharmacy technician involved in your care will
              be able to access your Summary Care Record. Whenever your record
              is accessed their details will be recorded should you wish to
              question this at any point in the future.
            </Text>
            <Text>
              You can change this permission at any point just by informing the
              pharmacy. Further information on SCR can be found at{' '}
              <Link
                to={{
                  pathname:
                    'https://digital.nhs.uk/services/summary-care-records-scr',
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                http://systems.hscic.gov.uk/scr/patients
              </Link>
            </Text>
          </Col>
        </Row>
      </Container>
    </>
  );
};
