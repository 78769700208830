import { Button } from 'components/common/Button';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';

import styles from './CTALinkCard.module.scss';

interface CTALinkCardProps {
  title: string;
  content?: string;
  button: { link: string; label: string };
}

export const CTALinkCard = ({ title, content, button }: CTALinkCardProps) => {
  return (
    <div className={styles['cta-link-card__container']}>
      <div>
        <Heading tag="h3" className={styles['cta-link-card__title']}>
          {title}
        </Heading>
        <Text className={styles['cta-link-card__content']}>{content}</Text>
      </div>
      <Button
        label={button.label}
        link={{ to: button.link }}
        className={styles['cta-link-card__button']}
      />
    </div>
  );
};
