import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { Toggle } from 'components/common/Toggle';
import { ReactComponent as Bin } from 'assets/icons/lawsat/Bin.svg';
import { useBasketContext } from 'contexts/BasketContext';
import type { BasketOrderline } from 'models/Basket';

import styles from './PrescriptionOrderItem.module.scss';

interface PrescriptionOrderItemProps {
  item: BasketOrderline;
}

export const PrescriptionOrderItem: React.FC<PrescriptionOrderItemProps> = ({
  item,
}) => {
  const { t } = useTranslation();
  const {
    handleRemovePrescriptionProductFromBasket,
    handleChangePrescriptionProductContraceptionStatus,
  } = useBasketContext();

  if (!item.isPrescriptionItem || !item.id) {
    return null;
  }

  return (
    <div className={styles['prescription-order-item']}>
      <Text className={styles['prescription-order-item__title']} size="lg">
        {item.name}
      </Text>
      {item.canBeUsedAsContraceptive && (
        <Toggle
          testId="contraceptive-toggle"
          className={styles['prescription-order-item__toggle']}
          value={item.isUsedAsContraceptive}
          label={t('PrescriptionFlow.order.toggleText')}
          onChange={() => {
            item.id &&
              handleChangePrescriptionProductContraceptionStatus(item.id);
          }}
        />
      )}
      <div className={styles['prescription-order-item__foot']}>
        <Button
          testId="remove-button"
          className={styles['prescription-order-item__button']}
          variant="ui"
          appearance="flat"
          label="Remove"
          icon={{ element: Bin }}
          onClick={() => {
            item.id && handleRemovePrescriptionProductFromBasket(item.id);
          }}
        />
        <Text className={styles['prescription-order-item__price']}>
          {item.isUsedAsContraceptive
            ? `£${item.priceWhenUsedAsContraceptive?.toFixed(2)}`
            : `£${item.price?.toFixed(2)}`}
        </Text>
      </div>
    </div>
  );
};
