import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from 'components/common/Icon';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { OrderHistory } from 'models/OrderHistory';
import { formatDateString } from 'utils/transformers';
import styles from './OrderItem.module.scss';

interface OrderItemProps {
  link: string;
  order: OrderHistory;
}

interface OrderItemHeaderProps {
  order: OrderHistory;
}

const getStatusTextKey = (order: OrderHistory): string => {
  if (!order.orderStatus) {
    return '?';
  }

  if (order.orderStatus === 'OrderComplete') {
    return order.deliveryOption === 'Collection'
      ? 'Orders.orderStatus.orderCollected'
      : 'Orders.orderStatus.orderDelivered';
  }

  const firstLetter = order.orderStatus.charAt(0).toLowerCase();
  const otherLetters = order.orderStatus.slice(1);
  return `Orders.orderStatus.${firstLetter}${otherLetters}`;
};

const OrderItemHeader: React.FC<OrderItemHeaderProps> = ({ order }) => {
  const { t } = useTranslation();
  let title;
  const date = order.orderReceivedDateTime
    ? formatDateString(order.orderReceivedDateTime)
    : '';

  if (order.containsPrescriptionItems) {
    if (order.patientIsDependant) {
      title = t('Orders.prescriptionTitleDependent', {
        name: [order.patientFirstName, order.patientLastName].join(' '),
        date,
      });
    } else {
      title = t('Orders.prescriptionTitle', {
        date,
      });
    }
  } else {
    title = t('Orders.shopOrderTitle', {
      date,
    });
  }

  return (
    <Heading size="sm" tag="h5" className={styles['order-item__heading']}>
      {title}
    </Heading>
  );
};

export const OrderItem: React.FC<OrderItemProps> = ({ link, order }) => {
  const { t } = useTranslation();

  const statusStyle =
    order.orderStatus === 'Cancelled'
      ? 'order-item__cancelled'
      : 'order-item__not-cancelled';

  return (
    <Link to={link} className={styles['order-item__link']}>
      <div className={styles['order-item']}>
        <OrderItemHeader order={order} />

        <Icon className={styles['order-item__arrow']} icon={ChevronRight} />

        {order.orderStatus && (
          <Text
            tag="label"
            size="md"
            className={styles['order-item__status-label']}
          >
            {t('Orders.status')}
            <span className={styles[statusStyle]}>
              {t(getStatusTextKey(order))}
            </span>
          </Text>
        )}

        <Text size="md" className={styles['order-item__count-and-cost']}>
          {t('Orders.itemCount', { count: order.numberOfItems })} &bull; £
          {order.totalCost.toFixed(2)}
        </Text>
      </div>
    </Link>
  );
};
