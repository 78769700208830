/* eslint-disable sonarjs/no-duplicate-string */
import React, { ReactNode, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import {
  WeightLossPomForm as WeightLossPomFormType,
  weightLossPomFormQuestions,
} from 'models/PomQuestionnaires/WeightLoss';
import { useTranslation } from 'react-i18next';
import { useBasketContext } from 'contexts/BasketContext';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { SingleCheckbox, SingleSelect, TextArea } from '../PomForm.Components';
import { WeightLossPomFormSchema } from 'formSchema/PomForms/WeightLossPomFormSchema';

interface WeightLossPomFormProps {
  onSubmit: (payload: WeightLossPomFormType) => Promise<void>;
  actionButtons?: ReactNode;
}

export const WeightLossPomForm = ({
  onSubmit,
  actionButtons,
}: WeightLossPomFormProps) => {
  const { t } = useTranslation();
  const { basket } = useBasketContext();
  const { condition } = useConditionsContext();
  const fields = weightLossPomFormQuestions;

  const formMethods = useForm({
    resolver: yupResolver(WeightLossPomFormSchema()),
  });
  const { handleSubmit, watch, reset } = formMethods;

  useEffect(() => {
    const existingForm = basket.pomForms.find(
      (form) => form.conditionId === condition?.id,
    )?.formAnswers;
    if (existingForm && existingForm.conditionId === 14) {
      reset({
        ...existingForm,
      });
    }
  }, [basket.shopProducts]);

  // Condition specific stuff
  const hasEatingDisorder = watch(fields[3].fieldName);
  const hasPsychologicalCause = watch(fields[5].fieldName);
  const previouslyTakenMedication = watch(fields[7].fieldName);
  const currentlyTakingMedication = watch(fields[9].fieldName);
  const hasKidneyLiverIssues = watch(fields[13].fieldName);

  return (
    <form
      onSubmit={handleSubmit((payload: WeightLossPomFormType) =>
        onSubmit({ ...payload, conditionId: 14 }),
      )}
    >
      <TextArea field={fields[0]} formMethods={formMethods} />

      <SingleSelect
        field={fields[1]}
        helperText={t('pomForms.weightLoss.diabetesMedications', {
          returnObjects: true,
        })}
        formMethods={formMethods}
      />

      <SingleSelect field={fields[2]} formMethods={formMethods} />

      <SingleSelect field={fields[3]} formMethods={formMethods} />

      {hasEatingDisorder === 'Yes' && (
        <TextArea field={fields[4]} formMethods={formMethods} />
      )}

      <SingleSelect field={fields[5]} formMethods={formMethods} />

      {hasPsychologicalCause === 'Yes' && (
        <TextArea field={fields[6]} formMethods={formMethods} />
      )}

      <SingleSelect field={fields[7]} formMethods={formMethods} />

      {previouslyTakenMedication === 'Yes' && (
        <TextArea field={fields[8]} formMethods={formMethods} />
      )}

      <SingleSelect field={fields[9]} formMethods={formMethods} />

      {currentlyTakingMedication === 'Yes' && (
        <TextArea field={fields[10]} formMethods={formMethods} />
      )}

      <TextArea field={fields[11]} formMethods={formMethods} />

      <SingleSelect field={fields[12]} formMethods={formMethods} />

      <SingleSelect field={fields[13]} formMethods={formMethods} />

      {hasKidneyLiverIssues === 'Yes' && (
        <TextArea field={fields[14]} formMethods={formMethods} />
      )}

      <SingleCheckbox
        field={fields[15]}
        checkbox={{ label: t('pomForms.weightLoss.terms.confirm') }}
        formMethods={formMethods}
        helperText={t('pomForms.weightLoss.terms.terms', {
          returnObjects: true,
        })}
      />

      {actionButtons}
    </form>
  );
};
