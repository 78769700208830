import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { DependantAdd } from 'containers/Dependant/DependantAdd';
import { DependantContactDetails } from 'containers/Dependant/DependantContactDetails';
import { DependantPrescription } from './DependantPrescription';
import { DependantAddFlow } from './DependantAddFlow';
import { DependantAccount } from './DependantAccount';
import { routes } from 'routes';

export const Dependant = () => {
  return (
    <Switch>
      {/* Add Dependant */}

      <PrivateRoute path={routes.DEPENDANT.ADD.BASE} exact>
        <DependantAdd />
      </PrivateRoute>

      <PrivateRoute path={routes.DEPENDANT.ADD.CONTACT_DETAILS} exact>
        <DependantContactDetails />
      </PrivateRoute>

      <PrivateRoute path={`${routes.DEPENDANT.ADD.BASE}:dependantId`}>
        <DependantAddFlow />
      </PrivateRoute>

      {/* Prescriptions  */}

      <PrivateRoute path={`${routes.DEPENDANT.PRESCRIPTION.BASE}:dependantId`}>
        <DependantPrescription />
      </PrivateRoute>

      {/* Account  */}

      <PrivateRoute path={`${routes.DEPENDANT.ACCOUNT.BASE}:dependantId`}>
        <DependantAccount />
      </PrivateRoute>
    </Switch>
  );
};
