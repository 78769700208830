import { AppBasket, BasketOrderline } from 'models/Basket';
import { Product } from 'models/Product';
import {
  getIndexOfProductInBasket,
  getOrderlineFromShopProduct,
} from './HelperFunctions';

export const addTreatmentToBasket = (
  prevBasket: AppBasket,
  product: Product,
  quantity: number = 1,
) => {
  const updatedShopProducts = [...prevBasket.shopProducts];
  const itemIndex = getIndexOfProductInBasket(
    product.id.toString(),
    updatedShopProducts,
  );

  if (itemIndex === -1) {
    updatedShopProducts.push(getOrderlineFromShopProduct(product, quantity));
  } else {
    const existingProduct = updatedShopProducts[itemIndex];
    existingProduct.quantity += quantity;

    const { orderLimit } = existingProduct;
    if (orderLimit && existingProduct.quantity > orderLimit) {
      existingProduct.quantity = orderLimit;
    }
  }
  return { ...prevBasket, shopProducts: updatedShopProducts };
};

export const updateTreatmentQuantityInBasket = (
  prevBasket: AppBasket,
  productId: string,
  method: string,
  handleRemoveTreatmentFromBasket: (productId: string) => void,
) => {
  const updatedShopProducts = [...prevBasket.shopProducts];
  const itemIndex = getIndexOfProductInBasket(productId, updatedShopProducts);
  if (itemIndex !== -1) {
    const currentItem = updatedShopProducts[itemIndex];
    if (
      !(
        method === 'increase' && currentItem.quantity === currentItem.orderLimit
      )
    ) {
      currentItem.quantity += method === 'increase' ? 1 : -1;
      if (currentItem.quantity === 0) {
        handleRemoveTreatmentFromBasket(productId);
      }
    }
  }

  return { ...prevBasket, shopProducts: updatedShopProducts };
};

export const removeTreatmentFromBasket = (
  prevBasket: AppBasket,
  productId: string,
) => {
  const prevShopProducts = [...prevBasket.shopProducts];
  const updatedShopProducts = prevShopProducts.filter(
    (shopProduct) => shopProduct.id !== productId,
  );
  return { ...prevBasket, shopProducts: updatedShopProducts };
};

export const mergeTreatmentBaskets = (
  prevBasket: AppBasket,
  productsToMerge: BasketOrderline[],
) => {
  const updatedShopProducts = [...prevBasket.shopProducts];
  productsToMerge.forEach((productToMerge) => {
    const itemIndex = getIndexOfProductInBasket(
      productToMerge.id,
      updatedShopProducts,
    );
    if (itemIndex === -1) {
      updatedShopProducts.push(productToMerge);
    } else {
      const updatedProduct = {
        ...updatedShopProducts[itemIndex],
        quantity: Math.min(
          updatedShopProducts[itemIndex].orderLimit || Infinity,
          updatedShopProducts[itemIndex].quantity + productToMerge.quantity,
        ),
        pMedQuestionComplete:
          updatedShopProducts[itemIndex].pMedQuestionComplete ||
          productToMerge.pMedQuestionComplete,
      };

      updatedShopProducts[itemIndex] = updatedProduct;
    }
  });
  return { ...prevBasket, shopProducts: updatedShopProducts };
};
