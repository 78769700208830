import React from 'react';

export interface ScrollContextProps {
  scrollPosition: number;
  isScrolling: boolean;
  children?: React.ReactNode;
}

export const ScrollContextDefaults: ScrollContextProps = {
  scrollPosition: 0,
  isScrolling: false,
};

export const ScrollContext = React.createContext<ScrollContextProps>(
  ScrollContextDefaults,
);

let currentPosition = 0;
let ticking = false;
let scrollingEndsTimeout: number | undefined;

export const useScrollContext = (): ScrollContextProps =>
  React.useContext(ScrollContext);

interface ScrollProviderProps {
  children?: React.ReactNode;
}

export const ScrollProvider = ({ children }: ScrollProviderProps) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [isScrolling, setIsScrolling] = React.useState(false);

  const onScroll = () => {
    refreshIsScrolling();
    refreshScrollPosition();
  };

  const refreshScrollPosition = () => {
    currentPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        setScrollPosition(currentPosition);
        ticking = false;
      });

      ticking = true;
    }
  };

  const refreshIsScrolling = () => {
    window.clearTimeout(scrollingEndsTimeout);
    setIsScrolling(true);

    scrollingEndsTimeout = window.setTimeout(() => {
      setIsScrolling(false);
    }, 66);
  };

  React.useEffect(() => {
    refreshScrollPosition();
    window.addEventListener('scroll', onScroll);
  });

  React.useEffect(() => {
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <ScrollContext.Provider value={{ scrollPosition, isScrolling }}>
      {children}
    </ScrollContext.Provider>
  );
};
