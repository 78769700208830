import i18n from 'i18next';
import { DeliveryOption } from 'models/DeliveryOption';

export const formatDeliveryOptionTitle = (option: string) => {
  switch (option) {
    case 'FreeDelivery':
      return i18n.t('CheckoutPage.deliveryOption.freeDelivery');
    case 'ExpressDelivery':
      return i18n.t('CheckoutPage.deliveryOption.expressDelivery');
    default:
      return i18n.t('CheckoutPage.deliveryOption.standardDelivery');
  }
};

export const getEstimatedDeliveryTimeInfo = (option: string) => {
  switch (option) {
    case 'FreeDelivery':
      return i18n.t(
        'CheckoutPage.deliveryOption.estimatedWaitTimeFreeDelivery',
      );
    case 'ExpressDelivery':
      return i18n.t(
        'CheckoutPage.deliveryOption.estimatedWaitTimeExpressDelivery',
      );
    default:
      return i18n.t(
        'CheckoutPage.deliveryOption.estimatedWaitTimeStandardDelivery',
      );
  }
};

export const isFreeStandardDeliveryAvailable = (
  deliveryOptions: DeliveryOption[],
  freeDeliveryThreshold: number,
  orderTotal: number,
) => {
  const standardDeliveryOption = deliveryOptions.find(
    (deliveryOption) => deliveryOption.option === 'StandardDelivery',
  );
  return (
    standardDeliveryOption &&
    freeDeliveryThreshold !== undefined &&
    orderTotal >= freeDeliveryThreshold
  );
};
