import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Pill } from 'assets/icons/lawsat/Pill-Outline.svg';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { GP } from 'models/GP';
import styles from './GPCard.module.scss';

interface GPCardProps {
  gp: GP;
  buttonText: string;
  link: string;
}

export const GPCard: React.FC<GPCardProps> = ({ gp, buttonText, link }) => {
  const gpAddress = [gp.address1, gp.address2, gp.address3, gp.postcode]
    .filter((line) => !!line)
    .join(', ');

  return (
    <div className={styles['gp-card']}>
      <Pill className={styles['gp-card__icon']} />
      <Heading tag={'h3'} size={'sm'}>
        {gp.name}
      </Heading>
      <Text>{gpAddress}</Text>
      <Link to={link} className={styles['gp-card__link']}>
        {buttonText}
      </Link>
    </div>
  );
};
