import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { PrescriptionItem } from 'models/PrescriptionItem';
import type { Product } from 'models/Product';
import type {
  AppBasket,
  BasketOrderline,
  DependantBasket,
  LocalStorageBasket,
  PlaceOrderPayload,
} from 'models/Basket';
import { PMedQuestionnaire, PmedFormResponse } from 'models/PMedQuestionnaire';
import {
  validateBasket as validateBasketApi,
  createBasket as createBasketApi,
  saveBasket as saveBasketApi,
  recallBasket as recallBasketApi,
  getDeliveryOptions as getDeliveryOptionsApi,
  placeOrder as placeOrderApi,
  deleteBasket as deleteBasketApi,
  uploadPomFormImages as uploadPomFormImagesApi,
} from 'api/Basket';
import { useToastContext } from 'contexts/ToastContext';
import { useApiError } from 'hooks/useApiError';
import { useHistory } from 'react-router';
import { useAuthContext } from 'contexts/AuthContext';
import { DeliveryOption } from 'models/DeliveryOption';
import { usePatientContext } from 'contexts/PatientContext';
import { useConfigContext } from 'contexts/ConfigContext';
import {
  getBasketOrderlineSubtotal,
  getDependantBasketIndex,
  addTreatmentToBasket,
  mergeTreatmentBaskets,
  removeTreatmentFromBasket,
  updateTreatmentQuantityInBasket,
  savePmedFormToBasket,
  removePmedFormFromBasket,
  mergePmedForms,
  addPrescriptionProductToDependantBasket,
  addPrescriptionProductToBasket,
  removePrescriptionProductFromDependantBasket,
  removePrescriptionProductFromBasket,
  toggleDependantExemptionStatus,
  toggleExemptionStatus,
  changeDependantProductContraceptionStatus,
  changeProductContraceptionStatus,
  mergeDependantPrescriptionBaskets,
  mergePrescriptionBaskets,
  getBasketForSaving,
  updateDerivedBasketState,
  removeBasketFromLocalStorage,
  saveBasketToLocalStorage,
  mergePomForms,
  removeProductFromPomForm,
  savePomFormToBasket,
  addProductToPomForm,
} from './HelperFunctions';
import { BasketContextProps } from '.';
import { BasketContextDefaults, BasketContextLoading } from './Types';
import { routes } from 'routes';
import { PomForm, PomFormResponse } from 'models/PomQuestionnaires';
import { useTranslation } from 'react-i18next';

const BASKET_TIMEOUT_MINUTES = 1000 * 60 * 60; // ms*s*m => 60 minutes
export const LOCAL_STORAGE_BASKET_KEY = 'basket';

export const BasketContext = createContext<BasketContextProps>(
  BasketContextDefaults,
);

export const useBasketContext = (): BasketContextProps =>
  useContext(BasketContext);

interface BasketProviderProps {
  children?: ReactNode;
}

export const BasketProvider = ({ children }: BasketProviderProps) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const { standardPrescriptionPrice } = useConfigContext();
  const { patient, patientToggle, getDependants, dependants } =
    usePatientContext();
  const { authStatus, authStatusToggle } = useAuthContext();
  const { isShopEnabled, shopEnabledToggle } = useConfigContext();
  const { handleApiError } = useApiError();
  const { showDefaultErrorToast, setToast } = useToastContext();
  const [loading, setLoading] = useState<BasketContextLoading>({
    validateBasketStatus: 'idle',
  });
  const [basket, setBasket] = useState<AppBasket>(BasketContextDefaults.basket);
  const [dependantBaskets, setDependantBaskets] = useState<DependantBasket[]>(
    [],
  );
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [shopDeliveryOptions, setShopDeliveryOptions] = useState<
    DeliveryOption[]
  >([]);
  const [prescriptionDeliveryOptions, setPrescriptionDeliveryOptions] =
    useState<DeliveryOption[]>([]);

  const stringifiedDependantProducts = JSON.stringify(
    dependantBaskets.map((basket) => basket.basket.prescriptionProducts),
  );
  const stringifiedBasket = JSON.stringify(basket);

  // storage / recall logic
  useEffect(() => {
    !patient && !authStatus && getBasketFromLocalStorage();
  }, []);

  useEffect(() => {
    if (authStatusToggle.previous && !authStatusToggle.current) {
      resetAllBaskets();
    }
  }, [authStatusToggle]);

  useEffect(() => {
    if (!patientToggle.previous && patientToggle.current) {
      clearTimeout(timer);
      recallBasketFromBackend();
      removeBasketFromLocalStorage();
      getDependants();
    }
  }, [patientToggle]);

  useEffect(() => {
    if (shopEnabledToggle.previous && !shopEnabledToggle.current) {
      setBasket((basket) => ({
        ...basket,
        shopProducts: [],
      }));
    }
  }, [shopEnabledToggle]);

  useEffect(() => {
    if (dependants && dependants?.length > 0) {
      dependants.forEach((dependant) => {
        dependant.id && recallBasketFromBackend(dependant.id.toString());
      });
    }
  }, [dependants]);

  useEffect(() => {
    updateBasket();
  }, [stringifiedBasket]);

  useEffect(() => {
    dependantBaskets.length > 0 && updateDependantBaskets();
  }, [stringifiedDependantProducts]);

  const startTimer = () => {
    setTimer(
      setTimeout(() => {
        resetAllBaskets();
        navigate.push(routes.BASE);
      }, BASKET_TIMEOUT_MINUTES),
    );
  };

  const restartTimer = () => {
    clearTimeout(timer);
    startTimer();
  };

  const refreshBasketTimeout = () => {
    !authStatus && updateBasket();
  };

  const resetAllBaskets = () => {
    removeBasketFromLocalStorage();
    setBasket(BasketContextDefaults.basket);
    setDependantBaskets([]);
    updateBasket();
  };

  const resetBasket = (dependantId: string | undefined = undefined) => {
    if (dependantId) {
      setDependantBaskets((prevBaskets) => {
        const basketIndex = getDependantBasketIndex(dependantId, prevBaskets);
        return prevBaskets.filter((_, index) => index !== basketIndex);
      });
    } else {
      setBasket(BasketContextDefaults.basket);
    }
  };

  const getBasketFromLocalStorage = () => {
    const storedBasket = localStorage.getItem(LOCAL_STORAGE_BASKET_KEY);
    if (storedBasket) {
      const parsedBasket: LocalStorageBasket = JSON.parse(storedBasket);
      const now = new Date();
      if (parsedBasket.expiresAt && now.getTime() > parsedBasket.expiresAt) {
        resetAllBaskets();
      } else {
        setBasket({
          ...BasketContextDefaults.basket,
          shopProducts: isShopEnabled ? parsedBasket.shopProducts : [],
          prescriptionProducts: parsedBasket.prescriptionProducts,
          pmedForms: parsedBasket.pmedForms,
        });
        updateBasket();
      }
    }
  };

  const saveBasketToBackend = async (
    basketForSaving: LocalStorageBasket,
    dependantId: string | undefined = undefined,
  ) => {
    try {
      const { status } = await recallBasketApi(dependantId);
      if (status === 200) {
        await saveBasketApi(basketForSaving, dependantId);
      } else {
        await createBasketApi(basketForSaving, dependantId);
      }
    } catch (error) {
      handleApiError(() => {
        showDefaultErrorToast();
      }, error);
    }
  };

  const recallBasketFromBackend = async (
    dependantId: string | undefined = undefined,
  ) => {
    try {
      const { status, data } = await recallBasketApi(dependantId);
      if (status === 200) {
        const recalledBasket: LocalStorageBasket = JSON.parse(data.contents);
        if (dependantId) {
          handleMergePrescriptionProductBaskets(
            recalledBasket.prescriptionProducts,
            dependantId,
          );
        } else {
          isShopEnabled &&
            handleMergeTreatmentBaskets(recalledBasket.shopProducts);
          isShopEnabled && handleMergePmedForms(recalledBasket.pmedForms);
          isShopEnabled && handleMergePomForms(recalledBasket.pomForms);
          handleMergePrescriptionProductBaskets(
            recalledBasket.prescriptionProducts,
          );
        }
      }
    } catch (error) {
      handleApiError(() => {
        showDefaultErrorToast();
      }, error);
    }
  };

  // core basket logic

  const updateBasket = async () => {
    if (authStatus && patient) {
      await saveBasketToBackend(
        getBasketForSaving({
          basket,
          expiresAt: null,
        }),
      );
    } else {
      saveBasketToLocalStorage(
        getBasketForSaving({
          basket,
          expiresAt: new Date().getTime() + BASKET_TIMEOUT_MINUTES,
        }),
      );
      restartTimer();
    }
    setBasket((basket) => updateDerivedBasketState(basket));
  };

  const updateDependantBaskets = async () => {
    dependantBaskets.forEach((dependantBasket) => {
      const basketForSaving: LocalStorageBasket = {
        prescriptionProducts: dependantBasket.basket.prescriptionProducts,
        pmedForms: [],
        pomForms: [],
        shopProducts: [],
        expiresAt: null,
      };

      if (basketForSaving.prescriptionProducts.length > 0) {
        saveBasketToBackend(basketForSaving, dependantBasket.dependantId);
        setDependantBaskets((prevDependantBaskets) => {
          const updatedDependantBaskets = [...prevDependantBaskets];
          const dependantBasketIndex = updatedDependantBaskets?.findIndex(
            (basket) => basket.dependantId === dependantBasket.dependantId,
          );
          if (dependantBasketIndex !== -1) {
            updatedDependantBaskets[dependantBasketIndex].basket = {
              ...updatedDependantBaskets[dependantBasketIndex].basket,
              subTotal: getBasketOrderlineSubtotal(
                updatedDependantBaskets[dependantBasketIndex].basket
                  .prescriptionProducts,
              ),
            };
          }
          return updatedDependantBaskets;
        });
      } else if (
        !basketForSaving.prescriptionProducts ||
        basketForSaving.prescriptionProducts.length === 0
      ) {
        deleteBasketApi(dependantBasket.dependantId);
        setDependantBaskets((prevDependantBaskets) => {
          const updatedDependantBaskets = [...prevDependantBaskets];
          const dependantBasketIndex = updatedDependantBaskets?.findIndex(
            (basket) => basket.dependantId === dependantBasket.dependantId,
          );
          if (dependantBasketIndex !== -1) {
            updatedDependantBaskets.splice(dependantBasketIndex, 1);
          }
          return updatedDependantBaskets;
        });
      }
    });
  };

  // Shop Product functions

  const handleAddTreatmentToBasket = (product: Product, quantity?: number) => {
    setBasket((prevBasket) => {
      const updatedBasket = product.isPom
        ? addProductToPomForm(
            prevBasket,
            product.id,
            product.productConditionId,
          )
        : prevBasket;
      return addTreatmentToBasket(updatedBasket, product, quantity);
    });
  };

  const handleRemoveTreatmentFromBasket = (productId: string) => {
    setBasket((prevBasket) => removeTreatmentFromBasket(prevBasket, productId));
    handleRemovePmedFormFromBasket(productId);
    handleRemoveProductFromPomForm(productId);
  };

  const handleUpdateTreatmentQuantityInBasket = (
    productId: string,
    method: 'increase' | 'decrease',
  ) => {
    setBasket((prevBasket) =>
      updateTreatmentQuantityInBasket(
        prevBasket,
        productId,
        method,
        handleRemoveTreatmentFromBasket,
      ),
    );
  };

  const handleMergeTreatmentBaskets = (productsToMerge: BasketOrderline[]) => {
    setBasket((prevBasket) =>
      mergeTreatmentBaskets(prevBasket, productsToMerge),
    );
  };

  // Pmed form functions

  const handleSavePmedFormToBasket = (
    productId: number,
    formAnswers: PMedQuestionnaire,
  ) => {
    setBasket((prevBasket) =>
      savePmedFormToBasket(prevBasket, productId, formAnswers),
    );
  };

  const handleRemovePmedFormFromBasket = (productId: string) => {
    setBasket((prevBasket) => removePmedFormFromBasket(prevBasket, productId));
  };

  const handleMergePmedForms = (formsToMerge: PmedFormResponse[]) => {
    setBasket((basket) => mergePmedForms(formsToMerge, basket));
  };

  // Pom form functions

  const handleSavePomFormToBasket = (
    conditionId: number,
    formAnswers: PomForm,
  ) => {
    setBasket((prevBasket) =>
      savePomFormToBasket(prevBasket, conditionId, formAnswers),
    );
  };

  const handleRemoveProductFromPomForm = (productId: string) => {
    setBasket((prevBasket) => removeProductFromPomForm(prevBasket, productId));
  };

  const handleMergePomForms = (formsToMerge: PomFormResponse[]) => {
    setBasket((basket) => mergePomForms(formsToMerge, basket));
  };

  const handleUploadPomFormImages = async (
    imagesToUpload: File[],
    conditionId: string | number,
  ) => {
    try {
      setLoading({ ...loading, pomImageUploadStatus: 'loading' });
      const res = await uploadPomFormImagesApi(imagesToUpload, conditionId);
      setLoading({ ...loading, pomImageUploadStatus: 'finished' });
      return { guids: res.map((image) => image.imageName), success: true };
    } catch (error) {
      setLoading({ ...loading, pomImageUploadStatus: 'error' });
      handleApiError(() => {
        setToast({
          status: 'error',
          title: t('pomForms.generic.labels.imageUpload.error'),
        });
      }, error);
    }
    return { guids: [], success: false };
  };

  // Prescription Product Functions

  const handleAddPrescriptionProductToBasket = (
    prescriptionItem: PrescriptionItem,
    dependantId: string | undefined = undefined,
  ) => {
    dependantId
      ? setDependantBaskets((prevDependantBaskets) =>
          addPrescriptionProductToDependantBasket(
            prevDependantBaskets,
            dependantId,
            prescriptionItem,
          ),
        )
      : setBasket((prevBasket) =>
          addPrescriptionProductToBasket(prevBasket, prescriptionItem),
        );
  };

  const handleRemovePrescriptionProductFromBasket = (
    prescriptionItemId: string,
    dependantId: string | undefined = undefined,
  ) => {
    dependantId
      ? setDependantBaskets((prevDependantBaskets) =>
          removePrescriptionProductFromDependantBasket(
            prevDependantBaskets,
            dependantId,
            prescriptionItemId,
          ),
        )
      : setBasket((prevBasket) =>
          removePrescriptionProductFromBasket(prevBasket, prescriptionItemId),
        );
  };

  const handleToggleExemptionStatus = (
    hasExemption: boolean,
    dependantId: string | undefined = undefined,
  ) => {
    dependantId
      ? setDependantBaskets((prevDependantBaskets) =>
          toggleDependantExemptionStatus(
            prevDependantBaskets,
            dependantId,
            hasExemption,
            standardPrescriptionPrice,
          ),
        )
      : setBasket((prevBasket) =>
          toggleExemptionStatus(
            prevBasket,
            hasExemption,
            standardPrescriptionPrice,
          ),
        );
  };

  const handleChangePrescriptionProductContraceptionStatus = (
    prescriptionItemId: string,
    dependantId: string | undefined = undefined,
  ) => {
    dependantId
      ? setDependantBaskets((prevDependantBaskets) =>
          changeDependantProductContraceptionStatus(
            prevDependantBaskets,
            dependantId,
            prescriptionItemId,
          ),
        )
      : setBasket((prevBasket) =>
          changeProductContraceptionStatus(prevBasket, prescriptionItemId),
        );
  };

  const handleMergePrescriptionProductBaskets = (
    productsToMerge: BasketOrderline[],
    dependantId: string | undefined = undefined,
  ) => {
    dependantId
      ? setDependantBaskets((prevDependantBaskets) =>
          mergeDependantPrescriptionBaskets(
            prevDependantBaskets,
            dependantId,
            productsToMerge,
          ),
        )
      : setBasket((basket) =>
          mergePrescriptionBaskets(basket, productsToMerge),
        );
  };

  // Order functions

  const getDeliveryOptions = async ({
    shopSubtotal,
    prescriptionSubtotal,
    dependantId,
  }: {
    shopSubtotal?: number;
    prescriptionSubtotal?: number;
    dependantId?: string;
  }) => {
    try {
      if (shopSubtotal !== undefined) {
        const shopOptionsResult = await getDeliveryOptionsApi({
          containsPrescriptionItems: false,
          subTotal: shopSubtotal,
        });
        setShopDeliveryOptions(shopOptionsResult);
      }
      if (prescriptionSubtotal !== undefined) {
        const prescriptionOptionsResult = await getDeliveryOptionsApi({
          containsPrescriptionItems: true,
          subTotal: prescriptionSubtotal,
          dependantId,
        });
        setPrescriptionDeliveryOptions(prescriptionOptionsResult);
      }
    } catch (error) {
      handleApiError(() => {
        showDefaultErrorToast();
      }, error);
    }
  };

  const clearDeliveryOptions = () => {
    setShopDeliveryOptions([]);
    setPrescriptionDeliveryOptions([]);
  };

  const validateBasket = async (dependantId?: string) => {
    try {
      setLoading({ ...loading, validateBasketStatus: 'loading' });
      let basketToValidate: AppBasket = { ...BasketContextDefaults.basket };
      if (dependantId) {
        const dependantBasketIndex = getDependantBasketIndex(
          dependantId,
          dependantBaskets,
        );
        if (dependantBasketIndex !== -1) {
          basketToValidate.prescriptionProducts =
            dependantBaskets[dependantBasketIndex].basket.prescriptionProducts;
          basketToValidate.subTotal =
            dependantBaskets[dependantBasketIndex].basket.subTotal;
          basketToValidate.hasPrescriptions = true;
        } else {
          return false;
        }
      } else {
        basketToValidate = { ...basket };
      }
      await validateBasketApi({ basket: basketToValidate, dependantId });
      setLoading({ ...loading, validateBasketStatus: 'finished' });
      return true;
    } catch (error) {
      handleApiError(() => {
        setLoading({ ...loading, validateBasketStatus: 'error' });
        showDefaultErrorToast();
      }, error);
      return false;
    }
  };

  const placeOrder = async ({
    prescriptionDeliveryOption: chosenPrescriptionDeliveryOption,
    treatmentDeliveryOption: chosenShopDeliveryOption,
    note,
    dependantId,
  }: Partial<PlaceOrderPayload>) => {
    if (!chosenPrescriptionDeliveryOption && !chosenShopDeliveryOption) {
      throw new Error('No delivery method selected!');
    }
    try {
      let basketForOrder: AppBasket;
      if (dependantId) {
        const dependantBasket = dependantBaskets.find(
          (basket) => basket.dependantId.toString() === dependantId,
        );
        if (dependantBasket) {
          basketForOrder = {
            ...BasketContextDefaults.basket,
            prescriptionProducts: dependantBasket.basket.prescriptionProducts,
            subTotal: dependantBasket.basket.subTotal,
            hasPrescriptions: true,
          };
        } else {
          throw new Error('Dependant basket could not be found');
        }
      } else {
        basketForOrder = basket;
      }

      return await placeOrderApi({
        basket: basketForOrder,
        prescriptionDeliveryOption: chosenPrescriptionDeliveryOption ?? null,
        treatmentDeliveryOption: chosenShopDeliveryOption ?? null,
        deliveryPharmacyId: chosenShopDeliveryOption?.deliveryPharmacyId,
        dependantId,
        note,
      });
    } catch (error) {
      handleApiError(() => {
        showDefaultErrorToast();
      }, error);
      return 0;
    }
  };

  const value: BasketContextProps = useMemo(
    () => ({
      basket,
      dependantBaskets,
      deliveryOptions: {
        shop: shopDeliveryOptions,
        prescription: prescriptionDeliveryOptions,
      },
      loading,
      refreshBasketTimeout,
      handleAddTreatmentToBasket,
      handleSavePmedFormToBasket,
      handleSavePomFormToBasket,
      handleUploadPomFormImages,
      handleAddPrescriptionProductToBasket,
      handleRemoveTreatmentFromBasket,
      handleRemovePrescriptionProductFromBasket,
      handleUpdateTreatmentQuantityInBasket,
      resetAllBaskets,
      handleChangePrescriptionProductContraceptionStatus,
      handleToggleExemptionStatus,
      validateBasket,
      getDeliveryOptions,
      clearDeliveryOptions,
      placeOrder,
      getBasketFromLocalStorage,
      resetBasket,
    }),
    [
      basket,
      dependantBaskets,
      shopDeliveryOptions,
      prescriptionDeliveryOptions,
      loading,
    ],
  );

  return (
    <BasketContext.Provider value={value}>{children}</BasketContext.Provider>
  );
};
