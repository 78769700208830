import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import { Exemption } from 'models/Exemption';
import { useExemptionsContext } from 'contexts/ExemptionsContext';
import { useModalContext } from 'contexts/ModalContext';
import { useDependantContext } from 'contexts/DependantContext';
import { useDependantExemptionsContext } from 'contexts/DependantExemptionsContext';
import { useToastContext } from 'contexts/ToastContext';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { Branch } from 'components/common/Branch';
import { Button } from 'components/common/Button';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { FormProgressBar } from 'components/FormProgressBar';
import { RemoveExemptionReasonModal } from 'components/RemoveExemptionReasonModal';
import { listValues, SelectList } from 'components/common/SelectList';
import { FormProgressBarProps } from 'components/FormProgressBar/FormProgressBar';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import { routes } from 'routes';

import styles from './PrescriptionExemptionList.module.scss';

interface PrescriptionExemptionListProps {
  dependantId?: string;
  heading: string;
  exemptionExpireRoute: string;
  exemptionNoExpireRoute: string;
  progressBar?: FormProgressBarProps;
  showRemove?: boolean;
}

export const PrescriptionExemptionList = ({
  dependantId,
  heading,
  progressBar,
  exemptionExpireRoute,
  exemptionNoExpireRoute,
  showRemove,
}: PrescriptionExemptionListProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast } = useToastContext();
  const {
    getExemptionList,
    exemptionList,
    savePatientExemptionReason,
    deletePatientExemptionReason,
    setExemptionSelected: downselectExemption,
    exemptionDetails,
  } = useExemptionsContext();
  const {
    saveDependantExemptionReason,
    deleteDependantExemptionReason,
    setDependantExemptionSelected,
    dependantExemptionDetails,
  } = useDependantExemptionsContext();
  const { dependant } = useDependantContext();
  const { open } = useModalContext();
  const { pathname } = useLocation();

  useEffect(() => {
    getExemptionList();
  }, []);

  const schema = yup.object().shape({
    exemptionSelected: yup
      .string()
      .required(t('Account.contactDetails.formErrors.requiredAddress')),
  });

  const { control, handleSubmit, getValues, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const formatList = (list: Exemption[]): listValues[] => {
    return list.map((item) => {
      return {
        value: item.id.toString(),
        label: item.description,
        prefix: item.code,
      };
    });
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('PrescriptionFlow.exemptionReason.formErrors.toastNoReason'),
    });
  };

  const onSubmit = () => {
    const selected = parseInt(getValues('exemptionSelected'));
    const selectedExp = exemptionList.find((item) => item.id === selected);

    if (selectedExp?.canExpire) {
      if (dependantId) {
        setDependantExemptionSelected(selectedExp);
      } else {
        downselectExemption(selectedExp);
      }
      history.push(exemptionExpireRoute);
    } else {
      submitForm(selected);
    }
  };

  const submitForm = (selected: number) => {
    const payload = {
      exemptionReasonId: selected,
      expiryDate: null,
      shouldNotify: null,
    };
    if (dependantId) {
      saveDependantExemptionReason(dependantId, payload);
    } else {
      savePatientExemptionReason(payload);
    }
    history.push(exemptionNoExpireRoute);
  };

  const onRemove = dependantId
    ? () => deleteDependantExemptionReason(dependantId)
    : () => deletePatientExemptionReason();

  const savedExemptionId = dependantId
    ? dependantExemptionDetails?.exemptionReasonId
    : exemptionDetails?.exemptionReasonId;

  const exemptionSelected = exemptionList.find(
    (exemption) => +exemption.id === +watch('exemptionSelected'),
  );
  const isSaveButtonDisabled =
    !exemptionSelected || // nothing selected
    exemptionSelected.id === savedExemptionId;

  const showRemoveButton =
    showRemove &&
    ((!dependantId && exemptionDetails) ||
      (dependantId && dependantExemptionDetails)); // don't show if there isn't a saved exemption already

  const breadcrumbs: Crumb[] = [
    { route: routes.ACCOUNT.BASE, text: t('NavBarLabels.account') },
    { route: routes.ACCOUNT.EDIT.EXEMPTION, text: t(heading) },
  ];

  return (
    <Container>
      {(pathname.match(routes.ACCOUNT.EDIT.EXEMPTION) ||
        pathname.match(routes.DEPENDANT.ACCOUNT.EDIT.EXEMPTION)) && (
        <Breadcrumbs links={breadcrumbs} />
      )}
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <div className={styles['prescription-exemption']}>
            {progressBar && (
              <div
                className={styles['prescription-exemption__progress-container']}
              >
                <FormProgressBar
                  numberOfStages={progressBar.numberOfStages}
                  currentStage={progressBar.currentStage}
                />
              </div>
            )}
            <Heading
              tag="h2"
              size="lg"
              className={styles['prescription-exemption__title']}
            >
              {heading}
            </Heading>
            <Text size="lg" id="labelForList">
              {dependantId
                ? t('PrescriptionFlow.exemptionReason.textWithDependant', {
                    dependantName: dependant?.firstName,
                  })
                : t('PrescriptionFlow.exemptionReason.text')}
            </Text>
            <Branch
              Component={() => (
                <form
                  data-testid="prescription-exemption-form"
                  className={styles['prescription-exemption__form']}
                  onSubmit={handleSubmit(onSubmit, invalid)}
                >
                  <Controller
                    control={control}
                    defaultValue={savedExemptionId?.toString() || ''}
                    name="exemptionSelected"
                    isRequired
                    render={(props) => (
                      <>
                        <SelectList
                          labelBy="labelForList"
                          className={styles['prescription-exemption__list']}
                          id="exemptionSelected"
                          listValues={formatList(exemptionList)}
                          {...props}
                        />
                      </>
                    )}
                  />
                  <AppBarBottom
                    fullWidth
                    align={showRemoveButton ? 'center' : 'right'}
                  >
                    <Button
                      width="full"
                      testId="submit-button"
                      type="submit"
                      appearance="solid"
                      disabled={isSaveButtonDisabled}
                      label={
                        exemptionSelected?.canExpire
                          ? t('PrescriptionFlow.exemptionReason.submitButton')
                          : t('Account.exemption.saveExemption')
                      }
                      isLoading={false}
                    />
                    {showRemoveButton && (
                      <Button
                        className={
                          styles['prescription-exemption__remove-button']
                        }
                        width="full"
                        testId="remove-button"
                        appearance="solid"
                        variant={'negative'}
                        label={t('Account.exemption.removeExemption')}
                        isLoading={false}
                        onClick={() =>
                          open(
                            <RemoveExemptionReasonModal
                              onRemove={onRemove}
                              dependantName={
                                dependantId && dependant?.firstName
                              }
                            />,
                          )
                        }
                      />
                    )}
                  </AppBarBottom>
                </form>
              )}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
