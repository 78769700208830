import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Card, CardType } from 'components/common/Card';
import { GetInTouch } from 'components/GetInTouch';
import { usePatientContext } from 'contexts/PatientContext';
import { routes } from 'routes';
import styles from './HelpCentreLanding.module.scss';

export const HelpCentreLanding = () => {
  const { t } = useTranslation();
  const { patient, patientPharmacy, getPatientPharmacy } = usePatientContext();

  useEffect(() => {
    if (patient) {
      getPatientPharmacy();
    }
  }, [patient]);

  const helpItems: CardType[] = [
    {
      title: t('Account.help.articles.orderNotArrived.title'),
      link: `${routes.HELP.BASE}/order-not-arrived`,
    },
    {
      title: t('Account.help.articles.orderNotInStore.title'),
      link: `${routes.HELP.BASE}/order-not-in-store`,
    },
    {
      title: t('Account.help.articles.receivingOrder.title'),
      link: `${routes.HELP.BASE}/receiving-order`,
    },
    {
      title: t('Account.help.articles.deliveryOptions.title'),
      link: `${routes.HELP.BASE}/delivery-options`,
    },
    {
      title: t('Account.help.articles.deliveryNotAvailable.title'),
      link: `${routes.HELP.BASE}/delivery-not-available`,
    },
    {
      title: t('Account.help.articles.exemptionNotify.title'),
      link: `${routes.HELP.BASE}/exemption-notify`,
    },
    {
      title: t('Account.help.articles.orderForDependant.title'),
      link: `${routes.HELP.BASE}/order-for-dependant`,
    },
  ];

  if (!patientPharmacy) {
    helpItems.push({
      title: t('Account.help.articles.contactUs'),
      link: `${routes.CONTACT}`,
    });
  }

  return (
    <>
      <Container className={styles['help-centre-landing']}>
        <Row>
          <Col>
            <Heading
              tag="h2"
              size="xl"
              className={styles['help-centre-landing__title']}
            >
              {t('Account.help.title')}
            </Heading>
            <Heading
              tag="h6"
              size="xs"
              className={styles['help-centre-landing__subtitle']}
            >
              {t('Account.help.articlesTitle')}
            </Heading>
            <div className={styles['help-centre-landing__card-list']}>
              {helpItems.map((item) => (
                <Card
                  key={item.title}
                  item={item}
                  className={styles['help-centre-landing__card--with-icon']}
                />
              ))}
            </div>

            {patientPharmacy && (
              <>
                <Heading
                  tag="h6"
                  size="xs"
                  className={styles['help-centre-landing__subtitle']}
                >
                  {t('Account.help.getInTouch')}
                </Heading>
                <GetInTouch pharmacy={patientPharmacy} />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
