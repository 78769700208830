import React from 'react';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useDependantContext } from 'contexts/DependantContext';
import { usePrescriptionContext } from 'contexts/PrescriptionContext';
import { useToastContext } from 'contexts/ToastContext';
import { AppBarTop } from 'components/Layout/AppBarTop';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { Container, Row, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { SelectList, listValues } from 'components/common/SelectList';

import { routes } from 'routes';
import styles from './PrescriptionReminder.module.scss';

export const PrescriptionReminder = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setToast, dismissToast } = useToastContext();
  const {
    prescriptionDetail,
    getPatientPrescriptionDetail,
    updatePatientPrescriptionReminder,
  } = usePrescriptionContext();
  const {
    dependant,
    dependantPrescription,
    getDependantPrescription,
    updateDependantPrescriptionReminder,
  } = useDependantContext();
  const { dependantId } = useParams<{ dependantId: string }>();

  const loadData = async () => {
    if (dependantId) {
      (!dependantPrescription ||
        dependantPrescription.medicinalProductId !== id) &&
        (await getDependantPrescription(dependantId, id));
    } else {
      (!prescriptionDetail || prescriptionDetail.medicinalProductId !== id) &&
        (await getPatientPrescriptionDetail(id));
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const monthsString = 'PrescriptionFlow.reminder.monthWithCount';
  const frequencies: listValues[] = [
    {
      value: '7',
      label: t('PrescriptionFlow.reminder.weekWithCount', {
        count: 1,
      }),
    },
    {
      value: '14',
      label: t('PrescriptionFlow.reminder.weekWithCount', {
        count: 2,
      }),
    },
    {
      value: '28',
      label: t(monthsString, {
        count: 1,
      }),
    },
    {
      value: '56',
      label: t(monthsString, {
        count: 2,
      }),
    },
    {
      value: '84',
      label: t(monthsString, {
        count: 3,
      }),
    },
    {
      value: '168',
      label: t(monthsString, {
        count: 6,
      }),
    },
  ];

  const schema = yup.object().shape({
    reminderSelected: yup.string().required(),
  });

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
    dismissToast();
    if (dependantId) {
      await updateDependantPrescriptionReminder(
        dependantId,
        id,
        parseInt(getValues('reminderSelected')),
      );
    } else {
      await updatePatientPrescriptionReminder(
        id,
        parseInt(getValues('reminderSelected')),
      );
    }
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('PrescriptionFlow.reminder.error'),
    });
  };

  return (
    <>
      <AppBarTop
        closeRoute={{
          to: dependantId
            ? `${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}`
            : routes.PRESCRIPTION.BASE,
          text: t('PrescriptionFlow.completed.back'),
        }}
      />
      <Container>
        <Row>
          <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <Heading tag="h3" size="lg">
              {t('PrescriptionFlow.reminder.title')}
            </Heading>
            {(prescriptionDetail || dependantPrescription) && (
              <Text className={styles['prescription-reminder__intro']}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      dependantId
                        ? t('PrescriptionFlow.reminder.introWithDependant', {
                            item: dependantPrescription?.medicinalProductName,
                            dependantName: dependant?.firstName,
                          })
                        : t('PrescriptionFlow.reminder.intro', {
                            item: prescriptionDetail?.medicinalProductName,
                          }),
                    ),
                  }}
                />
              </Text>
            )}
            <form
              data-testid="account-address-form"
              onSubmit={handleSubmit(onSubmit, invalid)}
            >
              <Controller
                control={control}
                defaultValue=""
                name="reminderSelected"
                isRequired
                render={(props) => (
                  <>
                    <SelectList
                      className={styles['prescription-reminder__list']}
                      id="reminderSelected"
                      listValues={frequencies}
                      {...props}
                    />
                  </>
                )}
              />
              <AppBarBottom fullWidth>
                <Button
                  testId="submit-button"
                  type="submit"
                  width="full"
                  label={t('PrescriptionFlow.reminder.submitButton')}
                />
                <Text className={styles['prescription-reminder__footer-txt']}>
                  {t('PrescriptionFlow.reminder.submitContext')}
                </Text>
              </AppBarBottom>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
