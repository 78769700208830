import { useEffect } from 'react';
import { useBasketContext } from 'contexts/BasketContext';
import { EmptyBasket } from 'components/Basket/EmptyBasket';
import { Basket as FullBasket } from 'components/Basket/Basket';
import { useLocation } from 'react-router-dom';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useConfigContext } from 'contexts/ConfigContext';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import { routes } from 'routes';
import { useTranslation } from 'react-i18next';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import i18n from 'i18n';

const pageName = {
  display: i18n.t('amplitude.basket.display'),
  log: i18n.t('amplitude.basket.log'),
};

export const Basket = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { refreshBasketTimeout } = useBasketContext();
  const { getPatientAppConfig } = useConfigContext();

  useEffect(() => {
    refreshBasketTimeout();
    getPatientAppConfig();
  }, [location]);

  const { basket, dependantBaskets } = useBasketContext();
  const { setPageName, resetPageName } = useLandingPageContext();
  const { logPageView } = useAmplitudeContext();

  useEffect(() => {
    setPageName(pageName);
    logPageView({ pageName: pageName.log });
    return () => resetPageName();
  }, []);

  const breadcrumbs: Crumb[] = [
    { route: routes.SHOP.BASE, text: t('NavBarLabels.shop') },
    { route: routes.BASKET, text: t('NavBarLabels.basket') },
  ];

  const isEmptyBasket =
    (!basket.shopProducts || basket.shopProducts.length === 0) &&
    (!basket.prescriptionProducts ||
      basket.prescriptionProducts.length === 0) &&
    (!dependantBaskets || dependantBaskets.length === 0);
  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      {isEmptyBasket ? <EmptyBasket /> : <FullBasket />}
    </>
  );
};
