import { Client } from 'api/utils';
import {
  BackendBasket,
  GetDeliveryOptionsPayload,
  LocalStorageBasket,
  PlaceOrderPayload,
  ValidateBasketPayload,
} from 'models/Basket';
import { DeliveryOption } from 'models/DeliveryOption';
import { getBasketPayload } from './BasketTransformers';

interface JSONBasket {
  contents: string;
}

export const createBasket = async (
  basketForSaving: LocalStorageBasket,
  dependantId?: string,
): Promise<void> => {
  return dependantId
    ? await Client.post(`/Basket/dependant/${dependantId}`, {
        contents: JSON.stringify(basketForSaving),
      })
    : await Client.post(`/Basket/create`, {
        contents: JSON.stringify(basketForSaving),
      });
};

export const saveBasket = async (
  basketForSaving: LocalStorageBasket,
  dependantId?: string,
): Promise<void> => {
  return dependantId
    ? await Client.put(`/Basket/dependant/${dependantId}`, {
        contents: JSON.stringify(basketForSaving),
      })
    : await Client.put(`/Basket/update`, {
        contents: JSON.stringify(basketForSaving),
      });
};

export const recallBasket = async (dependantId?: string) => {
  return dependantId
    ? await Client.get<JSONBasket>(`/Basket/dependant/${dependantId}`)
    : await Client.get<JSONBasket>(`/Basket`);
};

export const deleteBasket = async (dependantId?: string) => {
  return dependantId
    ? await Client.delete(`/Basket/dependant/${dependantId}`)
    : await Client.delete(`/Basket/delete`);
};

export const uploadPomFormImages = async (
  imageFiles: File[],
  conditionId: string | number,
): Promise<{ imageName: string }[]> => {
  const formData = new FormData();
  imageFiles?.map((file, index) => {
    formData.append(`imageFile${index}`, file);
  });
  const response = await Client.put(
    `questionnaires/${conditionId}/UploadPomQuestionnaireImages`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
  return response.data;
};

export const getDeliveryOptions = async ({
  containsPrescriptionItems,
  subTotal,
  dependantId = undefined,
}: GetDeliveryOptionsPayload): Promise<DeliveryOption[]> => {
  const response = await Client.post('/Basket/deliveryOptions', {
    containsPrescriptionItems,
    subTotal,
    dependantPatientId: dependantId ?? null,
  });
  return response.data;
};

export const validateBasket = async ({
  basket,
  dependantId = undefined,
}: ValidateBasketPayload): Promise<void> => {
  const basketForValidation: BackendBasket = getBasketPayload({ basket });
  return await Client.post(`/Basket/validate`, {
    basket: basketForValidation,
    dependantId: dependantId ?? null,
  });
};

export const placeOrder = async ({
  basket,
  prescriptionDeliveryOption,
  treatmentDeliveryOption,
  deliveryPharmacyId,
  note,
  dependantId = undefined,
}: PlaceOrderPayload): Promise<number> => {
  const res = await Client.post(`/Basket/placeOrder`, {
    dependantId: dependantId ?? null,
    basket: getBasketPayload({
      basket,
    }),
    treatmentDeliveryOption: {
      option: treatmentDeliveryOption?.option,
    },
    prescriptionDeliveryOption: {
      option: prescriptionDeliveryOption?.option,
      deliveryDate: prescriptionDeliveryOption?.deliverySlot?.deliveryDate,
      deliveryTimeSlot:
        prescriptionDeliveryOption?.deliverySlot?.deliveryTimeSlot,
    },
    deliveryPharmacyId,
    note,
  });
  return res.data.orderGroupId;
};
