import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/common/IconButton';
import { ReactComponent as Add } from 'assets/icons/lawsat/Add.svg';
import { ReactComponent as Minus } from 'assets/icons/lawsat/Minus.svg';
import { useBasketContext } from 'contexts/BasketContext';

import styles from './BasketQuantitySelector.module.scss';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';

interface BasketQuantitySelectorProps {
  productId: string;
  productName: string;
  qty: number;
  maxQuantity?: number;
  className?: string;
}

export const BasketQuantitySelector = ({
  productId,
  productName,
  qty,
  maxQuantity,
  className,
}: BasketQuantitySelectorProps) => {
  const { t } = useTranslation();
  const { handleUpdateTreatmentQuantityInBasket } = useBasketContext();
  const { logCtaClick } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();

  const handleLogCtaClick = (label: string) => {
    logCtaClick({
      pageName: pageName?.log,
      label: label,
      section: productName,
    });
  };

  return (
    <div className={cx(styles['quantity-selector'], className)}>
      <IconButton
        testId="decrease-button"
        icon={Minus}
        label={t('OrderFlow.Qty.removeSingle')}
        className={styles['quantity-selector__button']}
        onClick={() => {
          handleLogCtaClick(t('amplitude.basket.decreaseQuantityLabel'));
          handleUpdateTreatmentQuantityInBasket(productId, 'decrease');
        }}
        disabled={qty === 1}
      />
      <span className={styles['quantity-selector__quantity']}>{qty}</span>
      <IconButton
        icon={Add}
        testId="increase-button"
        label={t('OrderFlow.Qty.add')}
        className={styles['quantity-selector__button']}
        onClick={() => {
          handleLogCtaClick(t('amplitude.basket.increaseQuantityLabel'));
          handleUpdateTreatmentQuantityInBasket(productId, 'increase');
        }}
        disabled={maxQuantity ? qty >= maxQuantity : false}
      />
    </div>
  );
};
