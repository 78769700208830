import { ReactElement, useEffect, useState } from 'react';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Heading } from 'components/common/Heading';
import { ReactComponent as ChevronUp } from 'assets/icons/lawsat/ChevronUp.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/lawsat/ChevronDown.svg';

import styles from './Accordion.module.scss';

interface AccordionProps {
  title: string;
  startsExpanded?: boolean;
  children: ReactElement;
  amplitudeSectionName?: string | null;
}

export const Accordion = ({
  title,
  children,
  startsExpanded = false,
  amplitudeSectionName = null,
}: AccordionProps) => {
  const { elementRef, isInViewport } = useIsInViewport({});
  const { logSectionView } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const [isExpanded, setIsExpanded] = useState(startsExpanded);

  useEffect(() => {
    amplitudeSectionName &&
      logSectionView({
        pageName: pageName?.log,
        sectionName: amplitudeSectionName,
      });
  }, [isInViewport]);

  return (
    <div className={styles['accordion__container']} ref={elementRef}>
      <div className={styles['accordion__title']}>
        <button
          onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
          aria-expanded={isExpanded}
          className={styles['accordion__button']}
        >
          <Heading tag="h5" size="sm">
            {title}
          </Heading>
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </button>
      </div>
      <hr className={styles['accordion__divider']} />
      <section
        hidden={!isExpanded}
        className={
          isExpanded
            ? styles['accordion__content']
            : styles['accordion__content-closed']
        }
      >
        {children}
      </section>
    </div>
  );
};
