import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { HelpArticle } from 'components/HelpArticle';
import { articleList } from './helpArticlesList';
import { useConfigContext } from 'contexts/ConfigContext';
import styles from './HelpCentreArticles.module.scss';

export const HelpCentreArticles = () => {
  const { articleId } = useParams<{ articleId: string }>();
  const { t } = useTranslation();
  const { freeDeliveryThreshold } = useConfigContext();

  useEffect(() => {
    if (articleId) {
      scrollToArticle(articleId);
    }
  }, []);

  const scrollToArticle = (id: string) => {
    const articleElement = document.getElementById(id);
    articleElement?.scrollIntoView(true);
    const scrolledY = window.scrollY;
    if (scrolledY) {
      window.scroll({ left: 0, top: scrolledY - 64, behavior: 'smooth' });
    }
  };

  if (articleList[3].id !== 'delivery-options') {
    articleList.splice(3, 0, {
      id: 'delivery-options',
      title: 'Account.help.articles.deliveryOptions.title',
      paragraphs: [
        { paragraph: 'Account.help.articles.deliveryOptions.p1' },
        {
          paragraph: 'Account.help.articles.deliveryOptions.p2',
          options: {
            freeDeliveryThreshold: `£${freeDeliveryThreshold?.toFixed(2)}`,
          },
        },
        { paragraph: 'Account.help.articles.deliveryOptions.p3' },
      ],
      link: {
        url: 'Account.help.articles.deliveryOptions.a1.url',
        label: 'Account.help.articles.deliveryOptions.a1.label',
      },
    });
  }

  return (
    <Container className={styles['help-articles']}>
      <Row>
        <Col>
          <Heading
            tag="h3"
            size="lg"
            className={styles['help-articles__title']}
          >
            {t('Account.help.FAQ')}
          </Heading>

          {articleList.map((article) => {
            return <HelpArticle article={article} key={article.id} />;
          })}
        </Col>
      </Row>
    </Container>
  );
};
