/* eslint-disable sonarjs/no-duplicate-string */
import * as yup from 'yup';
import i18n from 'i18next';
import { weightLossPomFormQuestions } from 'models/PomQuestionnaires';

export const WeightLossPomFormSchema = (): yup.ObjectSchema => {
  const requiredError = i18n.t('common.formErrors.required');
  const booleanRequiredError = i18n.t('common.formErrors.radioRequired');
  const maxLengthError = i18n.t('common.formErrors.maxLength', { max: 500 });

  return yup.object().shape({
    [weightLossPomFormQuestions[0].fieldName]: yup
      .string()
      .required(requiredError)
      .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
      .max(500, maxLengthError)
      .trim(),
    [weightLossPomFormQuestions[1].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(requiredError),
    [weightLossPomFormQuestions[2].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(requiredError),
    [weightLossPomFormQuestions[3].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(requiredError),
    [weightLossPomFormQuestions[4].fieldName]: yup
      .string()
      .typeError()
      .nullable()
      .when(
        weightLossPomFormQuestions[3].fieldName,
        (hasEatingDisorder, schema) => {
          if (hasEatingDisorder === 'Yes') {
            return schema
              .required(requiredError)
              .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
              .max(500, maxLengthError)
              .trim();
          }
          return schema;
        },
      ),
    [weightLossPomFormQuestions[5].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(booleanRequiredError),
    [weightLossPomFormQuestions[6].fieldName]: yup
      .string()
      .typeError()
      .nullable()
      .when(
        weightLossPomFormQuestions[5].fieldName,
        (hasPsychologicalCause, schema) => {
          if (hasPsychologicalCause === 'Yes') {
            return schema
              .required(requiredError)
              .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
              .max(500, maxLengthError)
              .trim();
          }
          return schema;
        },
      ),
    [weightLossPomFormQuestions[7].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(booleanRequiredError),
    [weightLossPomFormQuestions[8].fieldName]: yup
      .string()
      .typeError()
      .nullable()
      .when(
        weightLossPomFormQuestions[7].fieldName,
        (previouslyTakenMedication, schema) => {
          if (previouslyTakenMedication === 'Yes') {
            return schema
              .required(requiredError)
              .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
              .max(500, maxLengthError)
              .trim();
          }
          return schema;
        },
      ),
    [weightLossPomFormQuestions[9].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(booleanRequiredError),
    [weightLossPomFormQuestions[10].fieldName]: yup
      .string()
      .typeError()
      .nullable()
      .when(
        weightLossPomFormQuestions[9].fieldName,
        (currentlyTakenMedication, schema) => {
          if (currentlyTakenMedication === 'Yes') {
            return schema
              .required(requiredError)
              .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
              .max(500, maxLengthError)
              .trim();
          }
          return schema;
        },
      ),
    [weightLossPomFormQuestions[11].fieldName]: yup
      .string()
      .required(requiredError)
      .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
      .max(500, maxLengthError)
      .trim(),
    [weightLossPomFormQuestions[12].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(requiredError),
    [weightLossPomFormQuestions[13].fieldName]: yup
      .string()
      .oneOf(['Yes', 'No'], booleanRequiredError)
      .required(requiredError),
    [weightLossPomFormQuestions[14].fieldName]: yup
      .string()
      .typeError()
      .nullable()
      .when(
        weightLossPomFormQuestions[13].fieldName,
        (hasKidneyLiverIssues, schema) => {
          if (hasKidneyLiverIssues === 'Yes') {
            return schema
              .required(requiredError)
              .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
              .max(500, maxLengthError)
              .trim();
          }
          return schema;
        },
      ),
    [weightLossPomFormQuestions[15].fieldName]: yup
      .boolean()
      .nullable()
      .required(i18n.t('common.formErrors.termsAndConditions'))
      .test(
        'mustBeTrue',
        i18n.t('common.formErrors.termsAndConditions'),
        (value) => value === true,
      ),
  });
};
