import { BranchStatus } from 'components/common/Branch';
import { AppBasket, DependantBasket, PlaceOrderPayload } from 'models/Basket';
import { DeliveryOption } from 'models/DeliveryOption';
import { PMedQuestionnaire } from 'models/PMedQuestionnaire';
import { PomForm } from 'models/PomQuestionnaires';
import { PrescriptionItem } from 'models/PrescriptionItem';
import { Product } from 'models/Product';
import { ReactNode } from 'react';

export interface BasketContextLoading {
  validateBasketStatus?: BranchStatus;
  pomImageUploadStatus?: BranchStatus;
}

export interface BasketContextProps {
  basket: AppBasket;
  dependantBaskets: DependantBasket[];
  getBasketFromLocalStorage: () => void;
  refreshBasketTimeout: () => void;
  handleAddTreatmentToBasket: (product: Product, quantity?: number) => void;
  handleSavePmedFormToBasket: (
    productId: number,
    formAnswers: PMedQuestionnaire,
  ) => void;
  handleSavePomFormToBasket: (
    conditionId: number,
    formAnswers: PomForm,
  ) => void;
  handleUploadPomFormImages: (
    imagesToUpload: File[],
    conditionId: string | number,
  ) => Promise<{ guids: string[]; success: boolean }>;
  handleAddPrescriptionProductToBasket: (
    prescriptionItem: PrescriptionItem,
    dependantId?: string,
  ) => void;
  handleRemovePrescriptionProductFromBasket: (
    prescriptionItemId: string,
    dependantId?: string,
  ) => void;
  handleRemoveTreatmentFromBasket: (productId: string) => void;
  handleUpdateTreatmentQuantityInBasket: (
    productId: string,
    method: 'increase' | 'decrease',
  ) => void;
  handleChangePrescriptionProductContraceptionStatus: (
    prescriptionItemId: string,
    dependantId?: string,
  ) => void;
  handleToggleExemptionStatus: (
    hasExemption: boolean,
    dependantId?: string | undefined,
  ) => void;
  validateBasket: (dependantId?: string) => Promise<boolean>;
  deliveryOptions: {
    shop: DeliveryOption[];
    prescription: DeliveryOption[];
  };
  getDeliveryOptions: ({
    shopSubtotal,
    prescriptionSubtotal,
    dependantId,
  }: {
    shopSubtotal?: number;
    prescriptionSubtotal?: number;
    dependantId?: string;
  }) => void;
  clearDeliveryOptions: () => void;
  placeOrder: ({
    prescriptionDeliveryOption,
    treatmentDeliveryOption,
    note,
    dependantId,
  }: Partial<PlaceOrderPayload>) => Promise<number>;
  resetAllBaskets: () => void;
  resetBasket: (dependantId?: string | undefined) => void;
  loading: BasketContextLoading;
  children?: ReactNode;
}

export const BasketContextDefaults: BasketContextProps = {
  basket: {
    shopProducts: [],
    prescriptionProducts: [],
    subTotal: 0,
    subTotals: {
      shopProducts: 0,
      prescriptionProducts: 0,
    },
    hasPmeds: false,
    hasPrescriptions: false,
    allPmedFormsComplete: false,
    pmedForms: [],
    pomForms: [],
  },
  dependantBaskets: [],
  getBasketFromLocalStorage: () => ({}),
  refreshBasketTimeout: () => ({}),
  handleAddTreatmentToBasket: () => ({}),
  handleSavePmedFormToBasket: () => ({}),
  handleSavePomFormToBasket: () => ({}),
  handleUploadPomFormImages: async () => ({ guids: [''], success: true }),
  handleAddPrescriptionProductToBasket: () => ({}),
  handleRemoveTreatmentFromBasket: () => ({}),
  handleRemovePrescriptionProductFromBasket: () => ({}),
  handleUpdateTreatmentQuantityInBasket: () => ({}),
  handleChangePrescriptionProductContraceptionStatus: () => ({}),
  handleToggleExemptionStatus: () => ({}),
  validateBasket: async () => false,
  deliveryOptions: {
    shop: [],
    prescription: [],
  },
  getDeliveryOptions: () => undefined,
  clearDeliveryOptions: () => ({}),
  placeOrder: async () => 1,
  resetAllBaskets: () => ({}),
  resetBasket: () => ({}),
  loading: {},
};

export const defaultDependantBasket: DependantBasket = {
  dependantId: '',
  basket: { prescriptionProducts: [], subTotal: 0 },
};
