import { PrivateRoute } from 'components/common/PrivateRoute';
import { GeneralHealthForm } from 'containers/GeneralHealthForm';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { useHistory } from 'react-router';
import { LandingPageSpinner } from 'components/common/Spinner';
import { Route, Switch, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ConditionPage } from './ConditionPage';
import { PomForm } from 'containers/POM/PomForm';
import { useTranslation } from 'react-i18next';
import { PomConfirmationConditionFlow } from 'containers/POM/PomConfirmationConditionFlow';
import { routes } from 'routes';
import { ProductDetail } from 'pages/Product/ProductDetail';

export const Condition = () => {
  const { conditionId } = useParams<{
    conditionId: string;
  }>();
  const navigate = useHistory();
  const { t } = useTranslation();

  const { getCondition, condition, setCondition } = useConditionsContext();

  useEffect(() => {
    if (conditionId) {
      getCondition(parseInt(conditionId));
    }
    return () => {
      setCondition(null);
    };
  }, [conditionId]);

  if (!condition) {
    return <LandingPageSpinner />;
  }

  if (condition && !condition.showInShop) {
    navigate.push(routes.SHOP.BASE);
    return null;
  }

  return (
    <Switch>
      <Route path={`${routes.SHOP.CONDITION}/:conditionId`} exact>
        <ConditionPage />
      </Route>

      <PrivateRoute
        path={`${routes.SHOP.CONDITION}/:conditionId${routes.GENERAL_HEALTH.BASE}`}
      >
        <GeneralHealthForm />
      </PrivateRoute>

      <PrivateRoute
        path={`${routes.SHOP.CONDITION}/:conditionId${routes.POM.BASE}`}
        exact
      >
        <PomForm
          backButton={{
            label: t('pomForms.back'),
            route: `${routes.SHOP.CONDITION}/${condition.id}${routes.GENERAL_HEALTH.PT2}`,
          }}
          nextButton={{
            label: t('pomForms.submitCondition'),
            route: `${routes.SHOP.CONDITION}/${condition.id}${routes.POM.CONFIRMATION.BASE}`,
          }}
        />
      </PrivateRoute>
      <PrivateRoute
        path={`${routes.SHOP.CONDITION}/:conditionId${routes.POM.CONFIRMATION.BASE}`}
        exact
      >
        <PomConfirmationConditionFlow />
      </PrivateRoute>
      <PrivateRoute
        path={`${routes.SHOP.CONDITION}/:conditionId${routes.POM.CONFIRMATION.PRODUCT}/:productId`}
        exact
      >
        <ProductDetail showPomBackButton />
      </PrivateRoute>
    </Switch>
  );
};
