import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useDependantContext } from 'contexts/DependantContext';
import { usePostCodeSearchContext } from 'contexts/PostCodeSearchContext';
import { useToastContext } from 'contexts/ToastContext';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { PostCodeSearch } from 'components/PostCodeSearch';
import { SelectList } from 'components/common/SelectList';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { ReactComponent as AddressImage } from 'assets/illustrations/address.svg';
import { useHistory } from 'react-router-dom';

import styles from './DependantAddressSearch.module.scss';

interface DependantAddressSearchProps {
  submitButton: {
    route: string;
    label: string;
  };
  setAddressPageState: React.Dispatch<
    React.SetStateAction<'search' | 'manual'>
  >;
}

export const DependantAddressSearch: React.FC<DependantAddressSearchProps> = ({
  submitButton,
  setAddressPageState,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast, dismissToast } = useToastContext();
  const {
    dependant,
    saveDependantAddress,
    setDependantAddress,
    loading,
    setLoading,
  } = useDependantContext();
  const { addresses } = usePostCodeSearchContext();

  const schema = yup.object().shape({
    addressSelected: yup
      .string()
      .required(t('Dependant.address.formErrors.requiredAddress')),
  });

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
    dismissToast();
    const addressPayload = addresses?.find(
      (address) => address.value === getValues('addressSelected'),
    );
    setLoading(true);
    addressPayload?.address
      ? await saveDependantAddress(dependant?.id, addressPayload.address)
      : invalid();
    history.push(submitButton.route);
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('Dependant.address.formErrors.toastNoAddressTitle'),
      description: t('Dependant.address.formErrors.toastNoAddressDescription'),
    });
    setLoading(false);
  };

  return (
    <div className={styles['dependant-address-search']}>
      <PostCodeSearch />
      {!addresses && (
        <div className={styles['dependant-address-search__content']}>
          <AddressImage className={styles['dependant-address-search__image']} />
          <Text size="lg" className={styles['dependant-address-search__text']}>
            {t('Dependant.address.intro')}
          </Text>
          <Button
            appearance="ghost"
            testId="switch-button"
            label={t('Dependant.address.manualSwitch')}
            onClick={() => {
              setAddressPageState('manual');
            }}
          />
        </div>
      )}
      {addresses && (
        <form
          data-testid="dependant-address-form"
          className={styles['dependant-contact-details__form']}
          onSubmit={handleSubmit(onSubmit, invalid)}
        >
          <Controller
            control={control}
            defaultValue=""
            name="addressSelected"
            isRequired
            render={(props) => (
              <>
                <SelectList
                  id="addressSelected"
                  listValues={addresses}
                  {...props}
                />
              </>
            )}
          />
          <AppBarBottom fullWidth>
            <Button
              width="full"
              className={styles['dependant-address-search__submit-button']}
              testId="submit-button"
              type="submit"
              appearance="solid"
              label={submitButton.label}
              isLoading={loading === 'loading'}
            ></Button>
            <Button
              testId="switch-button-edit"
              width="full"
              className={styles['dependant-address-search__manual-button']}
              appearance="ghost"
              label={t('Dependant.address.edit')}
              onClick={() => {
                const newAddress = addresses?.find(
                  (address) => address.value === getValues('addressSelected'),
                );
                setDependantAddress(newAddress?.address);
                setAddressPageState('manual');
              }}
            ></Button>
          </AppBarBottom>
        </form>
      )}
    </div>
  );
};
