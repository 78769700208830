import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { AutoComplete } from 'components/common/AutoComplete';
import { Button } from 'components/common/Button';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { FormProgressBar } from 'components/FormProgressBar';
import { SelectList } from 'components/common/SelectList';
import { useDependantContext } from 'contexts/DependantContext';
import { useSuggestContext } from 'contexts/SuggestContext';
import { useToastContext } from 'contexts/ToastContext';
import { usePatientContext } from 'contexts/PatientContext';
import { ReactComponent as GPImage } from 'assets/illustrations/gp.svg';
import { FormProgressBarProps } from 'components/FormProgressBar/FormProgressBar';
import { convertGPList } from 'utils/transformers';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import { routes } from 'routes';

import styles from './SelectGPLanding.module.scss';

interface SelectGPLandingProps {
  heading: string;
  submitButton: {
    route: string;
    label: string;
  };
  dependantId?: string;
  progressBar?: FormProgressBarProps;
}

export const SelectGPLanding = ({
  dependantId,
  submitButton,
  progressBar,
  heading,
}: SelectGPLandingProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast } = useToastContext();
  const { savePatientGp } = usePatientContext();
  const { saveDependantGP, dependant } = useDependantContext();
  const { getGPlist, gpList, setGPList } = useSuggestContext();
  const { pathname } = useLocation();

  const schema = yup.object().shape({
    selectedGP: yup.string().required(),
  });

  useEffect(() => {
    getGPlist();
  }, []);

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('PrescriptionFlow.gp.formErrors.toastNoReason'),
    });
  };

  const onSubmit = async () => {
    if (dependantId) {
      await saveDependantGP(dependantId, getValues('selectedGP'));
      history.push(submitButton.route);
    } else {
      await savePatientGp(getValues('selectedGP'));
      history.push(submitButton.route);
    }
  };

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const breadcrumbs: Crumb[] = dependantId
    ? [
        { route: routes.ACCOUNT.BASE, text: t('NavBarLabels.account') },
        {
          route: `${routes.DEPENDANT.ACCOUNT.BASE}${dependantId}`,
          text: t('Account.dependantLanding.title', {
            dependantName: dependant?.firstName,
          }),
        },
        {
          route: `${routes.DEPENDANT.ACCOUNT.BASE}${dependantId}${routes.DEPENDANT.ACCOUNT.EDIT.SELECT_GP}`,
          text: t('Account.dependantLanding.addGP'),
        },
      ]
    : [
        { route: routes.ACCOUNT.BASE, text: t('NavBarLabels.account') },
        {
          route: routes.ACCOUNT.EDIT.SELECT_GP,
          text: t('Account.landing.addGP'),
        },
      ];

  return (
    <Container className={styles['select-gp-landing']}>
      {(pathname.match(routes.ACCOUNT.EDIT.SELECT_GP) ||
        pathname.match(routes.DEPENDANT.ACCOUNT.EDIT.SELECT_GP)) && (
        <Breadcrumbs links={breadcrumbs} />
      )}
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          {progressBar && (
            <div className={styles['select-gp-landing__progress-container']}>
              <FormProgressBar
                numberOfStages={progressBar.numberOfStages}
                currentStage={progressBar.currentStage}
              />
            </div>
          )}
          <Heading
            tag="h2"
            size="lg"
            className={styles['select-gp-landing__title']}
          >
            {heading}
          </Heading>

          <AutoComplete
            getResults={(str: string) => {
              getGPlist(str);
            }}
            clearResults={() => {
              setGPList([]);
            }}
            placeholder={t('PrescriptionFlow.gp.autocompletePlaceholder')}
            label={t('PrescriptionFlow.gp.autocompleteLabel')}
          />
          <div className={styles['select-gp-landing__nhs-link']}>
            <span>
              {t('PrescriptionFlow.gp.nhsFromLabel')}
              <a href={'https://www.nhs.uk'}>
                {t('PrescriptionFlow.gp.nhsWeb')}
              </a>
            </span>
          </div>
          {gpList.length === 0 && (
            <div className={styles['select-gp-landing__content']}>
              <GPImage className={styles['select-gp-landing__image']} />
            </div>
          )}
          <form
            data-testid="gp-form"
            onSubmit={handleSubmit(onSubmit, invalid)}
          >
            {gpList.length > 0 && (
              <Controller
                control={control}
                defaultValue=""
                name="selectedGP"
                isRequired
                render={(props) => (
                  <>
                    <SelectList
                      id="selectedGP"
                      listValues={convertGPList(gpList)}
                      {...props}
                    />
                  </>
                )}
              />
            )}
            <AppBarBottom fullWidth>
              <Button
                width="full"
                className={styles['prescription-exemption__submit-button']}
                testId="submit-button"
                type="submit"
                appearance="solid"
                label={submitButton.label}
                isLoading={false}
              />
            </AppBarBottom>
          </form>
        </Col>
      </Row>
    </Container>
  );
};
