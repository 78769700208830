import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BookingProcessSection,
  getBookingProcessCards,
} from 'components/Sections/TravelVaccinations/BookingProcessSection';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { TravelVaccinationsSection } from 'components/Sections/Home/TravelVaccinationsSection';

import styles from './BookingPage.module.scss';

const pageName = {
  log: 'travel-vax-booking-page',
  display: 'Travel Vaccinations',
};

export const BookingWidget = () => {
  const { logSectionView } = useAmplitudeContext();

  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName.log,
        sectionName: 'booking-widget',
      });
    }
  }, [isInViewport]);

  return (
    <div ref={elementRef} className={styles['booking-page__widget__container']}>
      <iframe
        title="Travel Vaccinations Booking Widget"
        width="100%"
        height="805px"
        src="https://book.appointedd.com/app/645a684db932120f85083678"
      />
    </div>
  );
};

export const BookingPage = () => {
  const { t } = useTranslation();
  const { logPageView } = useAmplitudeContext();
  const { setPageName, resetPageName } = useLandingPageContext();

  useEffect(() => {
    logPageView({ pageName: pageName.log });
    setPageName(pageName);
    return () => resetPageName();
  }, []);

  return (
    <>
      <TravelVaccinationsSection />

      <BookingWidget />

      <BookingProcessSection
        heading={t('travelVaccinations.bookingPage.bookingProcess.heading')}
        cards={getBookingProcessCards(t, 'bookingPage')}
      />
    </>
  );
};
