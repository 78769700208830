import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useCookiesContext } from 'contexts/CookiesContext';
import { Button } from 'components/common/Button';
import { Heading } from 'components/common/Heading';
import { routes } from 'routes';
import { ReactComponent as Close } from 'assets/icons/lawsat/Close.svg';
import styles from './CookiesPopup.module.scss';

export const CookiesPopup = () => {
  const { hasApprovedCookies, approveCookies } = useCookiesContext();
  const location = useLocation();
  const { t } = useTranslation();

  const onClose = () => {
    approveCookies();
  };

  const isVisible =
    hasApprovedCookies === false && location.pathname !== routes.COOKIES;

  return isVisible ? (
    <div className={styles['cookies-popup']}>
      <div className={styles['cookies-popup__container']}>
        <Close
          data-testid={'close-button'}
          className={styles['cookies-popup__close']}
          onClick={onClose}
        />
        <div>
          <Heading>{t('Cookies.popup.title')}</Heading>
          <Button
            testId={'cookies-button'}
            variant={'accent'}
            appearance={'ghost'}
            link={{ to: routes.COOKIES }}
            label={t('Cookies.popup.content')}
          />
        </div>
      </div>
    </div>
  ) : null;
};
