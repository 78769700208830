import { Switch } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { AccountLanding } from 'containers/Account/AccountLanding';
import { AccountOrderStatus } from 'containers/Account/AccountOrderStatus';
import { OrdersLanding } from 'containers/Order/OrdersLanding';
import { AccountEdit } from './AccountEdit';
import { useEffect, useState } from 'react';
import { usePatientContext } from 'contexts/PatientContext';
import { useExemptionsContext } from 'contexts/ExemptionsContext';
import { LandingPageSpinner } from 'components/common/Spinner';
import { routes } from 'routes';

export const Account = () => {
  const [fetchComplete, setFetchComplete] = useState(false);

  const { getAllPatientInfo } = usePatientContext();

  const { getExemptionDetails } = useExemptionsContext();

  const fetchData = async () => {
    await getAllPatientInfo();
    await getExemptionDetails();
    setFetchComplete(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!fetchComplete) {
    return <LandingPageSpinner />;
  }

  return (
    <Switch>
      {/* Landing Page */}

      <PrivateRoute path={routes.ACCOUNT.BASE} exact>
        <AccountLanding />
      </PrivateRoute>

      {/* Historical Orders */}

      <PrivateRoute path={routes.ACCOUNT.ORDERS} exact>
        <OrdersLanding />
      </PrivateRoute>

      <PrivateRoute path={`${routes.ACCOUNT.ORDERS}/order/:id`}>
        <AccountOrderStatus />
      </PrivateRoute>

      {/* Edit Details */}

      <PrivateRoute path={routes.ACCOUNT.EDIT.BASE}>
        <AccountEdit />
      </PrivateRoute>

      {/* Dependant Account */}
    </Switch>
  );
};
