import React from 'react';
import amplitude, { AmplitudeClient } from 'amplitude-js';

interface LogCtaClickPayload {
  label: string;
  pageName?: string;
  section?: string;
}

interface LogPageViewPayload {
  pageName: string;
}

interface LogSectionViewPayload {
  pageName?: string;
  sectionName: string;
}

export interface AmplitudeContextProps {
  logEvent: (text: string) => void;
  logCtaClick: (payload: LogCtaClickPayload) => void;
  logPageView: (payload: LogPageViewPayload) => void;
  logSectionView: (payload: LogSectionViewPayload) => void;
}

export const AmplitudeContextDefaults = {
  logEvent: () => ({}),
  logCtaClick: () => ({}),
  logPageView: () => ({}),
  logSectionView: () => ({}),
};

export const AmplitudeContext = React.createContext<AmplitudeContextProps>(
  AmplitudeContextDefaults,
);

const apikey = process.env.REACT_APP_AMPLITUDE_API_KEY;

export const useAmplitudeContext = (): AmplitudeContextProps =>
  React.useContext(AmplitudeContext);

interface AmplitudeProviderProps {
  children?: React.ReactNode;
}

export const AmplitudeProvider = ({ children }: AmplitudeProviderProps) => {
  let client: AmplitudeClient | undefined = undefined;

  const amplitudeSetup = async () => {
    if (!apikey) {
      console.warn(
        'Please provide an Amplitude API key to enable event tracking!',
      );
    } else {
      client = amplitude.getInstance();
      client.init(apikey);
    }
  };

  React.useEffect(() => {
    amplitudeSetup();
  }, []);

  const logEvent = (text: string) => {
    if (client) {
      client.logEvent(text);
    } else {
      console.warn(
        'No valid Amplitude instance can be found, please check the configuration and try again',
      );
    }
  };

  const logEventWithPayload = (name: string, payload: object) => {
    if (client) {
      client.logEvent(name, payload);
    } else {
      console.warn(
        'No valid Amplitude instance can be found, please check the configuration and try again',
      );
    }
  };

  const logCtaClick = (payload: LogCtaClickPayload) => {
    logEventWithPayload('CTA Click', payload);
  };

  const logPageView = (payload: LogPageViewPayload) => {
    logEventWithPayload('Page View', payload);
  };

  const logSectionView = (payload: LogSectionViewPayload) => {
    logEventWithPayload('Section View', payload);
  };

  return (
    <AmplitudeContext.Provider
      value={{
        logEvent,
        logCtaClick,
        logPageView,
        logSectionView,
      }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
};
