import { DeliveryAddress } from 'models/DeliveryOption';
import { Address } from 'models/Address';
import { Pharmacy } from 'models/Pharmacy';

export const formatAddress = (address: Address | DeliveryAddress): string =>
  Object.values(address)
    .filter((value) => !!value)
    .join(', ');

export const formatPharmacyAddress = (pharmacy: Pharmacy): string => {
  const addressFields = [
    pharmacy.addressLine1,
    pharmacy.addressLine2,
    pharmacy.addressLine3,
    pharmacy.addressTownCity,
    pharmacy.addressCounty,
    pharmacy.addressPostCode,
  ];

  return addressFields.filter((value) => !!value).join(', ');
};
