import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';

import strings from './en.json';
import styles from '../VaccinationDescriptions.module.scss';

export const RabiesDescription = () => {
  return (
    <div>
      <Heading tag="h3">{strings.About.title}</Heading>
      <Text>{strings.About.description}</Text>
      <br />
      <Heading tag="h3">{strings.CommonSymptoms.title}</Heading>
      <Text>{strings.CommonSymptoms.descriptionI}</Text>
      <ul>
        {strings.CommonSymptoms.symptomsListI.map((symptom) => (
          <li className={styles['vaccination__list']} key={symptom}>
            {symptom}
          </li>
        ))}
      </ul>
      <br />
      <Text>{strings.CommonSymptoms.descriptionII}</Text>
      <ul>
        {strings.CommonSymptoms.symptomsListII.map((symptom) => (
          <li className={styles['vaccination__list']} key={symptom}>
            {symptom}
          </li>
        ))}
      </ul>
      <br />
      <Heading tag="h3">{strings.Prevention.title}</Heading>
      <Text>{strings.Prevention.description}</Text>
      <ul>
        {strings.Prevention.preventionList.map((prevention) => (
          <li className={styles['vaccination__list']} key={prevention}>
            {prevention}
          </li>
        ))}
      </ul>
      <br />
      <Heading tag="h3">{strings.Vaccination.title}</Heading>
      <Text>{strings.Vaccination.description}</Text>
      <br />
      <Heading tag="h3">{strings.VaccinationLength.title}</Heading>
      <Text>{strings.VaccinationLength.description}</Text>
    </div>
  );
};
