import { useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { Crumb } from 'components/common/Breadcrumbs';
import { ProductTileSection } from 'components/ProductTileSection';
import { ConditionDescriptionSection } from 'components/Sections/Shop/ConditionDescriptionSection';
import { ConditionBanner } from 'components/Sections/Shop/ConditionBanner/ConditionBanner';
import { PillLinkSection } from 'components/Sections/Common/PillLinkSection';
import { routes } from 'routes';

import styles from './ConditionGroupPage.module.scss';

export const ConditionGroupPage = () => {
  const { conditionGroupId } = useParams<{ conditionGroupId: string }>();
  const { t } = useTranslation();
  const {
    getConditionGroup,
    conditionGroup,
    setConditionGroup,
    getCondition,
    condition: generalCondition,
    setCondition,
    getConditions,
    conditions,
  } = useConditionsContext();
  const navigate = useHistory();
  const { setPageName, resetPageName } = useLandingPageContext();
  const { logPageView } = useAmplitudeContext();

  const descriptionRef = useRef<HTMLDivElement>(null);

  const pageName = {
    log: t('amplitude.conditionGroupPage.pageName', {
      conditionGroupName: conditionGroup?.name,
    }),
    display: `${conditionGroup?.name}`,
  };

  useEffect(() => {
    getConditionGroup(parseInt(conditionGroupId));
    getConditions();
    return () => {
      setConditionGroup(null);
      setCondition(null);
    };
  }, [conditionGroupId]);

  useEffect(() => {
    if (conditionGroup?.name) {
      logPageView({ pageName: pageName.log });
      setPageName(pageName);
    }

    const generalConditionIndex = conditionGroup?.conditions.findIndex(
      (condition) =>
        condition.name.toLowerCase().includes('general') &&
        condition.showInShop,
    );

    if (generalConditionIndex && generalConditionIndex !== -1) {
      const generalConditionId = conditionGroup?.conditions.splice(
        generalConditionIndex,
        1,
      )[0].id;
      if (generalConditionId) {
        getCondition(generalConditionId);
      }
    }

    return () => resetPageName();
  }, [conditionGroup]);

  if (!conditionGroup) {
    return null;
  }

  if (conditionGroup && !conditionGroup.showInShop) {
    navigate.push(routes.SHOP.BASE);
    return null;
  }

  const conditionsToShow = conditionGroup?.conditions.filter(
    (condition) => condition.showInShop,
  );

  const hasConditionsWithPoms =
    conditions?.some((condition) =>
      conditionGroup.conditions.some(
        (groupCondition) =>
          groupCondition.id === condition.id &&
          condition.showInShop &&
          condition.products.some(
            (product) => product.isPom && product.inStock,
          ),
      ),
    ) ?? false;

  const breadcrumbs: Crumb[] = [
    { route: routes.SHOP.BASE, text: t('NavBarLabels.shop') },
    {
      route: `${routes.SHOP.CONDITIONGROUP}/${conditionGroup.id}`,
      text: conditionGroup.name,
    },
  ];

  return (
    <div className={styles[`condition-group-page__container`]}>
      <ConditionBanner
        breadcrumbs={breadcrumbs}
        startConsultationCta={{
          showReview: false,
          showCta: hasConditionsWithPoms,
          startConsulationLink: `${routes.SHOP.CONDITIONGROUP}/${conditionGroup.id}${routes.POM.SELECT}`,
        }}
        conditionGroup={conditionGroup}
        descriptionRef={descriptionRef}
      />

      {conditionsToShow && conditionsToShow.length > 0 && (
        <PillLinkSection
          heading={`Our ${conditionGroup.name.toLocaleLowerCase()} treatments`}
          links={conditionsToShow.map((condition) => ({
            label: condition.name,
            route: `${routes.SHOP.CONDITION}/${condition.id}`,
          }))}
        />
      )}

      {generalCondition && generalCondition.products.length > 0 && (
        <ProductTileSection
          products={generalCondition.products}
          classNames={{
            productList: styles['condition-group-page__product-list-overrides'],
          }}
          showCardsWithShadow
        />
      )}

      {conditionGroup.description && (
        <>
          <div ref={descriptionRef} />
          <ConditionDescriptionSection
            description={conditionGroup.description}
          />
        </>
      )}
    </div>
  );
};
