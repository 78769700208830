import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import { useToastContext } from 'contexts/ToastContext';
import { PMedTile } from 'components/PMedTile';
import { Col, Container, Row } from 'components/common/grid';
import { AppBarTop } from 'components/Layout/AppBarTop';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { useBasketContext } from 'contexts/BasketContext';
import styles from './OrderPMed.module.scss';

export const OrderPMed = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast } = useToastContext();
  const { basket, handleRemoveTreatmentFromBasket } = useBasketContext();

  useEffect(() => {
    if (!basket.hasPmeds) {
      if (
        basket.shopProducts.length === 0 &&
        basket.prescriptionProducts.length === 0
      ) {
        history.push(routes.BASKET);
      } else {
        history.push(routes.ORDER.CHECKOUT);
      }
    }
  }, [basket]);

  return (
    <div className={styles['order-p-med']}>
      <Container>
        <AppBarTop
          backRoute={{
            onClick: () => history.goBack(),
            text: t('common.goBack'),
          }}
        />
        <Row>
          <Col
            sm="12"
            md={{ size: '8', offset: '2' }}
            lg={{ size: '6', offset: '3' }}
          >
            <div className={styles['order-p-med__header']}>
              <Heading tag="h3">{t('OrderFlow.OrderPMed.title')}</Heading>
              <Text size="lg">
                {basket.shopProducts.length > 1
                  ? t('OrderFlow.OrderPMed.subTitlePlural')
                  : t('OrderFlow.OrderPMed.subTitle')}
              </Text>
            </div>
            {basket.shopProducts.map((line) => {
              if (line.isPMed) {
                return (
                  <PMedTile
                    key={line.id}
                    orderLine={line}
                    onDelete={() =>
                      line.id && handleRemoveTreatmentFromBasket(line.id)
                    }
                    onOpenForm={() => history.push(`/order/pmed/${line.id}`)}
                  />
                );
              }
            })}
            <AppBarBottom fullWidth>
              <Button
                width="full"
                appearance="solid"
                label={`${t(
                  'OrderFlow.related.continue',
                )} -  £${basket.subTotal.toFixed(2)}`}
                testId="continue-button"
                onClick={() =>
                  !basket.allPmedFormsComplete &&
                  setToast({
                    status: 'error',
                    title: t('OrderFlow.OrderPMed.errorToastTitle'),
                    description: t('OrderFlow.OrderPMed.errorToastDescription'),
                  })
                }
                link={
                  basket.allPmedFormsComplete
                    ? {
                        to: routes.ORDER.CHECKOUT,
                      }
                    : undefined
                }
              />
            </AppBarBottom>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
