import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';

import strings from './en.json';
import styles from '../VaccinationDescriptions.module.scss';

export const HepatitisADescription = () => {
  return (
    <div>
      <Heading tag="h3">{strings.About.title}</Heading>
      <Text>{strings.About.descriptionI}</Text>
      <br />
      <Text className={styles['vaccination__subtitle']}>
        {strings.About.causesOfInfectionSubtitle}
      </Text>
      <ul>
        {strings.About.causesOfInfectionList.map((cause) => (
          <li className={styles['vaccination__list']} key={cause}>
            {cause}
          </li>
        ))}
      </ul>
      <br />
      <Text>{strings.About.descriptionII}</Text>
      <br />
      <Heading tag="h3">{strings.CommonSymptoms.title}</Heading>
      <ul>
        {strings.CommonSymptoms.symptomsList.map((symptom) => (
          <li className={styles['vaccination__list']} key={symptom}>
            {symptom}
          </li>
        ))}
      </ul>
      <br />
      <Text>{strings.CommonSymptoms.descriptionI}</Text>
      <br />
      <Heading tag="h3">{strings.Prevention.title}</Heading>
      <Text>{strings.Prevention.descriptionI}</Text>
      <ul>
        <li className={styles['vaccination__list']}>
          {strings.Prevention.preventionIText}&nbsp;
          <a href={strings.Prevention.preventionIHref}>
            {strings.Prevention.preventionIHrefText}
          </a>
        </li>
        <li className={styles['vaccination__list']}>
          {strings.Prevention.preventionIIText}&nbsp;
          <a href={strings.Prevention.preventionIIHref}>
            {strings.Prevention.preventionIIHrefText}
          </a>
        </li>
        <li className={styles['vaccination__list']}>
          {strings.Prevention.preventionIIIText}
        </li>
      </ul>
      <br />
      <Heading tag="h3">{strings.Vaccination.title}</Heading>
      <Text>{strings.Vaccination.descriptionI}</Text>
    </div>
  );
};
