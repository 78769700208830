/* eslint-disable sonarjs/no-duplicate-string */
import * as yup from 'yup';
import i18n from 'i18next';
import { eczemaPomFormQuestions } from 'models/PomQuestionnaires';

export const EczemaPomFormSchema = (
  shouldValidateImages: boolean,
): yup.ObjectSchema => {
  const requiredError = i18n.t('common.formErrors.required');
  const booleanRequiredError = i18n.t('common.formErrors.radioRequired');
  const multiSelectRequiredError = i18n.t(
    'common.formErrors.multiSelectRequired',
  );
  const maxLengthError = i18n.t('common.formErrors.maxLength', { max: 500 });

  return yup.object().shape(
    {
      [eczemaPomFormQuestions[0].fieldName]: yup
        .string()
        .oneOf(['Yes', 'No'], booleanRequiredError)
        .required(booleanRequiredError),
      [eczemaPomFormQuestions[1].fieldName]: yup
        .string()
        .oneOf(
          [
            'This is the first time',
            'Less than six months',
            'More than six months',
          ],
          booleanRequiredError,
        )
        .required(requiredError),
      [eczemaPomFormQuestions[2].fieldName]: yup
        .string()
        .required(requiredError)
        .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
        .max(500, maxLengthError)
        .trim(),
      [eczemaPomFormQuestions[3].fieldName]: yup
        .object()
        .shape({
          Elbows: yup.boolean(),
          'Back of knees': yup.boolean(),
          Hands: yup.boolean(),
          Torso: yup.boolean(),
          Other: yup.boolean(),
        })
        .test('atLeastOneTrue', multiSelectRequiredError, (value) => {
          return (
            value?.Elbows ||
            value?.['Back of knees'] ||
            value?.Hands ||
            value?.Torso ||
            value?.Other
          );
        })
        .required(multiSelectRequiredError),
      [eczemaPomFormQuestions[4].fieldName]: yup
        .string()
        .typeError()
        .nullable()
        .when('affectedSkinAreas', (affectedSkinAreas, schema) => {
          if (affectedSkinAreas.other) {
            return schema
              .required(requiredError)
              .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
              .max(500, maxLengthError)
              .trim();
          }
          return schema;
        }),
      [eczemaPomFormQuestions[5].fieldName]: yup
        .string()
        .oneOf(['Yes', 'No'], booleanRequiredError)
        .required(booleanRequiredError),
      [eczemaPomFormQuestions[6].fieldName]: yup
        .string()
        .oneOf(['Yes', 'No'], booleanRequiredError)
        .required(booleanRequiredError),
      [eczemaPomFormQuestions[7].fieldName]: yup
        .string()
        .oneOf(['Yes', 'No'], booleanRequiredError)
        .required(booleanRequiredError),
      [eczemaPomFormQuestions[8].fieldName]: yup
        .string()
        .typeError()
        .nullable()
        .when(
          eczemaPomFormQuestions[7].fieldName,
          (isTakingAnyMedication, schema) => {
            if (isTakingAnyMedication === 'Yes') {
              return schema
                .required(requiredError)
                .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
                .max(500, maxLengthError)
                .trim();
            }
            return schema;
          },
        ),
      [eczemaPomFormQuestions[9].fieldName]: yup
        .string()
        .typeError()
        .nullable()
        .when(
          eczemaPomFormQuestions[7].fieldName,
          (isTakingAnyMedication, schema) => {
            if (isTakingAnyMedication === 'Yes') {
              return schema
                .required(requiredError)
                .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
                .max(500, maxLengthError)
                .trim();
            }
            return schema;
          },
        ),
      [eczemaPomFormQuestions[10].fieldName]: yup
        .string()
        .oneOf(['Yes', 'No'], booleanRequiredError)
        .required(booleanRequiredError),
      [eczemaPomFormQuestions[11].fieldName]: yup
        .string()
        .typeError()
        .nullable()
        .when(
          'hasBeenReviewedInLast12Months',
          (hasBeenReviewedInLast12Months, schema) => {
            if (hasBeenReviewedInLast12Months === 'Yes') {
              return schema
                .required(requiredError)
                .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
                .max(500, maxLengthError)
                .trim();
            }
            return schema;
          },
        ),
      [eczemaPomFormQuestions[12].fieldName]: shouldValidateImages
        ? yup
            .array()
            .required(i18n.t('common.formErrors.tooFewImages'))
            .min(1, i18n.t('common.formErrors.tooFewImages'))
            .max(2, i18n.t('common.formErrors.tooManyImages', { max: 2 }))
            .of(
              yup
                .mixed()
                .test('notBiggerThan5mb', 'File size exceeds 5MB', (file) =>
                  file ? file.size / Math.pow(1024, 2) <= 5 : true,
                ),
            )
        : yup.array(),
    },
    [['imageFiles', 'imageFiles']],
  );
};
