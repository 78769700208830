import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { Text } from 'components/common/Text';
import { Container } from 'components/common/grid';
import { ReactComponent as QuestionAnswer } from 'assets/icons/lawsat/QuestionAnswer.svg';
import { ReactComponent as Cart } from 'assets/icons/lawsat/Cart.svg';
import { ReactComponent as UserCheckmark } from 'assets/icons/lawsat/UserCheckmark.svg';
import cx from 'clsx';

import styles from './StartConsultationSection.module.scss';

const infoMsgStyle = 'start-consultation-section__info-msg';
const infoMsgIconStyle = 'start-consultation-section__info-msg__icon';

interface StartConsultationSectionProps {
  ctaLink: string;
  className?: string;
}

export const StartConsultationSection = ({
  className,
  ctaLink,
}: StartConsultationSectionProps) => {
  const { t } = useTranslation();
  const navigate = useHistory();

  const { logCtaClick, logSectionView } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.startConsultationSection.section'),
      });
    }
  }, [isInViewport]);

  const handleClick = () => {
    logCtaClick({
      label: t('amplitude.startConsultationSection.label'),
      pageName: pageName?.log,
      section: t('amplitude.startConsultationSection.section'),
    });
    navigate.push(ctaLink);
  };

  return (
    <div
      className={cx(styles['start-consultation-section'], className)}
      ref={elementRef}
    >
      <Container className={styles['start-consultation-section__container']}>
        <Heading
          tag="h2"
          className={styles['start-consultation-section__heading']}
        >
          {t('shop.startConsultation.heading')}
        </Heading>
        <div className={styles['start-consultation-section__content-row']}>
          <div className={styles['start-consultation-section__text-col']}>
            <Heading tag="h4">
              {t('shop.startConsultation.sectionTextPt1')}
            </Heading>
            <Heading tag="h4">
              {t('shop.startConsultation.sectionTextPt2')}
            </Heading>
          </div>
          <div className={styles['start-consultation-section__cta-col']}>
            <div className={styles['start-consultation-section__info-steps']}>
              <Text size="lg" className={styles[infoMsgStyle]}>
                <QuestionAnswer className={styles[infoMsgIconStyle]} />
                {t('shop.startConsultation.stepOne')}
              </Text>
              <Text size="lg" className={styles[infoMsgStyle]}>
                <Cart className={styles[infoMsgIconStyle]} />
                {t('shop.startConsultation.stepTwo')}
              </Text>
              <Text size="lg" className={styles[infoMsgStyle]}>
                <UserCheckmark className={styles[infoMsgIconStyle]} />
                {t('shop.startConsultation.stepThree')}
              </Text>
            </div>
            <Button
              label={t('shop.startConsultation.startButton')}
              onClick={handleClick}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};
