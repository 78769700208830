import React from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';
import { useToastContext } from 'contexts/ToastContext';
import { useDependantContext } from 'contexts/DependantContext';
import { InputText } from 'components/common/InputText';
import { Button } from 'components/common/Button';
import { AppBarBottom } from 'components/Layout/AppBarBottom';

import styles from './DependantAddress.module.scss';

interface DependantAddressProps {
  submitButton: {
    route: string;
    label: string;
  };
  setAddressPageState: React.Dispatch<
    React.SetStateAction<'search' | 'manual'>
  >;
}

export const DependantAddress = ({
  submitButton,
  setAddressPageState,
}: DependantAddressProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setToast, dismissToast } = useToastContext();
  const { saveDependantAddress, loading, dependant, dependantAddress } =
    useDependantContext();

  React.useEffect(() => {
    if (dependantAddress) {
      setValue('line1', dependantAddress.line1 ?? '');
      setValue('line2', dependantAddress.line2 ?? '');
      setValue('line3', dependantAddress.line3 ?? '');
      setValue('townCity', dependantAddress.townCity ?? '');
      setValue('postCode', dependantAddress.postCode ?? '');
    }
  }, []);

  const errorMax = t('Dependant.address.formErrors.errorMax');
  const errorMin = t('Dependant.address.formErrors.errorMin');

  const sharedRules = yup
    .string()
    .matches(/^.{3,}$/, {
      message: errorMin,
      excludeEmptyString: true,
    })
    .max(250, errorMax);

  const schema = yup.object().shape({
    line1: yup
      .string()
      .max(250, errorMax)
      .required(t('Dependant.address.formErrors.requiredAddress')),
    line2: sharedRules,
    line3: sharedRules,
    townCity: sharedRules.required(
      t('Dependant.address.formErrors.requiredTownCity'),
    ),
    postCode: yup
      .string()
      .required(t('Dependant.address.formErrors.requiredPostCode'))
      .matches(
        /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i,
        t('Dependant.address.formErrors.invalidPostCode'),
      ),
  });

  const { control, handleSubmit, getValues, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
    dismissToast();
    const formValues = getValues();
    await saveDependantAddress(dependant?.id, formValues);
    history.push(submitButton.route);
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('Dependant.address.formErrors.addressValidationErrorsTitle'),
      description: t(
        'Dependant.address.formErrors.addressValidationErrorsDescription',
      ),
    });
  };
  return (
    <>
      <form
        data-testid="dependant-address-form"
        className={styles['dependant-address__form']}
        onSubmit={handleSubmit(onSubmit, invalid)}
      >
        <Controller
          control={control}
          defaultValue=""
          name="line1"
          isRequired
          render={(props, fieldState) => (
            <>
              <InputText
                testId="addressFormLine1"
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.line1?.message}
                label={t('Dependant.address.formLabels.line1')}
                {...props}
              />
            </>
          )}
        />
        <Controller
          control={control}
          defaultValue=""
          name="line2"
          isRequired
          render={(props, fieldState) => (
            <>
              <InputText
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.line2?.message}
                label={t('Dependant.address.formLabels.line2')}
                {...props}
              />
            </>
          )}
        />
        <Controller
          control={control}
          defaultValue=""
          name="line3"
          isRequired
          render={(props, fieldState) => (
            <>
              <InputText
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.line3?.message}
                label={t('Dependant.address.formLabels.line3')}
                {...props}
              />
            </>
          )}
        />
        <Controller
          control={control}
          defaultValue=""
          name="townCity"
          isRequired
          render={(props, fieldState) => (
            <>
              <InputText
                testId="addressFormTownCity"
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.townCity?.message}
                label={t('Dependant.address.formLabels.townCity')}
                {...props}
              />
            </>
          )}
        />
        <Controller
          control={control}
          defaultValue=""
          name="postCode"
          isRequired
          render={(props, fieldState) => (
            <>
              <InputText
                testId="addressFormPostCode"
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.postCode?.message}
                label={t('Dependant.address.formLabels.postCode')}
                {...props}
              />
            </>
          )}
        />
        <AppBarBottom fullWidth>
          <Button
            width="full"
            className={styles['dependant-address__submit-button']}
            testId="submit-button"
            type="submit"
            appearance="solid"
            label={submitButton.label}
            isLoading={loading === 'loading'}
          ></Button>
          <Button
            testId="switch-button-search"
            width="full"
            className={styles['dependant-address__continue-button']}
            appearance="ghost"
            label={t('Dependant.address.searchSwitch')}
            isLoading={loading === 'loading'}
            onClick={() => {
              setAddressPageState('search');
            }}
          ></Button>
        </AppBarBottom>
      </form>
    </>
  );
};
