import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Heading } from 'components/common/Heading';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { routes } from 'routes';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useIsInViewport } from 'hooks/useIsInView';
import translations from 'translations/en.json';

import styles from './PriceSection.module.scss';

const priceGroups =
  translations.travelVaccinations.pricingPage.priceList.groups;

const sectionName = 'price-list';

interface PricingGroupProps {
  group: {
    heading: string;
    items: {
      name: string;
      price: string;
    }[];
  };
  className?: string;
}

const PricingGroup = ({ group, className }: PricingGroupProps) => {
  return (
    <div
      key={group.heading}
      className={clsx(styles['price-section__content__group'], className)}
    >
      <Heading className={styles['price-section__content__group__heading']}>
        {group.heading}
      </Heading>
      <div
        key={group.heading}
        className={styles['price-section__content__group__items__container']}
      >
        <ul className={styles['price-section__content__group__items']}>
          {group.items.map((item) => (
            <li
              key={item.name}
              className={styles['price-section__content__group__items__item']}
            >
              <div
                className={
                  styles[
                    'price-section__content__group__items__item__container'
                  ]
                }
              >
                <Text tag="span" size="lg">
                  {item.name}
                </Text>
                <Text tag="span" size="lg">
                  {item.price}
                </Text>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const PriceSection = () => {
  const { t } = useTranslation();
  const { logCtaClick, logSectionView } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName,
      });
    }
  }, [isInViewport]);

  return (
    <div ref={elementRef} className={styles['price-section']}>
      <Heading className={styles['price-section__heading']}>
        {t('travelVaccinations.pricingPage.priceList.heading')}
      </Heading>
      <div className={styles['price-section__content']}>
        {priceGroups.map((group, index) => (
          <PricingGroup
            key={group.heading}
            group={group}
            className={styles[`price-section__content__group--${index}`]}
          />
        ))}
      </div>
      <div className={styles['price-section__cta']}>
        <Button
          label={t('travelVaccinations.bookCtaLabel')}
          link={{
            to: routes.TRAVELVACCINATIONS.BOOK,
          }}
          onClick={() =>
            logCtaClick({
              label: t('travelVaccinations.bookCtaLabel'),
              pageName: pageName?.log,
              section: sectionName,
            })
          }
        />
      </div>
    </div>
  );
};
