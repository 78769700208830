/* eslint-disable */
/* based on  https://usehooks.com/useDebounce/ with minor changes */
import React from 'react';

export function useDebounce(value: any, delay: number): any {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
