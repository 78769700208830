import * as yup from 'yup';
import { validAfterMinDate, validDatePast } from 'utils/validation';
import i18n from 'i18next';

export const accountPatientDetailsSchema = (
  includeNhsNumber = false,
): yup.ObjectSchema => {
  const errorStrings = {
    min: `${i18n.t('Account.contactDetails.formErrors.min')}`,
    max: `${i18n.t('Account.contactDetails.formErrors.max')}`,
    date: `${i18n.t('Account.contactDetails.formErrors.date')}`,
    required: `${i18n.t('Account.contactDetails.formErrors.required')}`,
  };

  const sharedRules = yup
    .string()
    .matches(/^[A-Za-z].*$/, {
      message: i18n.t('Account.contactDetails.formErrors.firstCharacter'),
      excludeEmptyString: true,
    })
    .max(50, errorStrings.max);

  return yup.object().shape({
    firstName: sharedRules.required(
      `${errorStrings.required} ${i18n
        .t('Account.contactDetails.formLabels.firstName')
        .toLowerCase()}`,
    ),
    lastName: sharedRules.required(
      `${errorStrings.required}
         ${i18n.t('Account.contactDetails.formLabels.lastName').toLowerCase()}`,
    ),
    contactNumber: yup
      .string()
      .required(
        `${errorStrings.required}
        ${i18n
          .t('Account.contactDetails.formLabels.contactNumber')
          .toLowerCase()}`,
      )
      .max(13, errorStrings.max)
      .matches(
        /^[0-9]+$/,
        i18n.t('Account.contactDetails.formErrors.numbersOnly'),
      ),
    dateOfBirth: yup
      .string()
      .required('date required')
      .test('dateOfBirth', errorStrings.date, function () {
        return validAfterMinDate(
          this.parent.dateDD,
          this.parent.dateMM,
          this.parent.dateYY,
          '1900',
        );
      })
      .test('dateOfBirth', errorStrings.date, function () {
        return validDatePast(
          this.parent.dateDD,
          this.parent.dateMM,
          this.parent.dateYY,
        );
      }),
    addressLine1: yup
      .string()
      .max(250, errorStrings.max)
      .required(i18n.t('Account.contactDetails.formErrors.requiredAddress')),
    addressLine2: yup.string().max(50, errorStrings.max),
    addressLine3: yup.string().max(50, errorStrings.max),
    addressTownCity: yup
      .string()
      .max(50, errorStrings.max)
      .required(i18n.t('Account.contactDetails.formErrors.requiredTownCity')),
    addressPostCode: yup
      .string()
      .required(i18n.t('Account.contactDetails.formErrors.requiredPostCode'))
      .matches(
        /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i,
        i18n.t('Account.contactDetails.formErrors.postCode'),
      ),
    nhsNumber: yup
      .string()
      .test(
        'nhsNumber',
        i18n.t('Account.contactDetails.formErrors.nhs'),
        function () {
          if (!includeNhsNumber || this.parent.nhsNumber.length === 0) {
            return true;
          } else {
            return includeNhsNumber && this.parent.nhsNumber.length === 10;
          }
        },
      ),
  });
};
