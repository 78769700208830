import { Redirect, Switch, useParams } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { PrescriptionAdd } from 'containers/Prescription/PrescriptionAdd';
import { PrescriptionCompleted } from 'containers/Prescription/PrescriptionCompleted';
import { PrescriptionLanding } from 'containers/Prescription/PrescriptionLanding';
import { PrescriptionReminder } from 'containers/Prescription/PrescriptionReminder';
import { PrescriptionDetail } from 'containers/Prescription/PrescriptionDetail';
import { SuggestProvider } from 'contexts/SuggestContext';
import { LandingPageSpinner } from 'components/common/Spinner';
import { useDependantPrescriptionOnboardingComplete } from 'hooks/useOnboardingComplete/useOnboardingComplete';
import { routes } from 'routes';

export const DependantPrescription = () => {
  const { dependantId } = useParams<{ dependantId: string }>();
  const DependantPrescriptionOnboardingResult =
    useDependantPrescriptionOnboardingComplete(dependantId);

  if (!DependantPrescriptionOnboardingResult?.checksComplete) {
    return <LandingPageSpinner />;
  }

  const baseRoute = `${routes.DEPENDANT.PRESCRIPTION.BASE}:dependantId`;
  return (
    <Switch>
      {/* Bounce to onboarding if not complete */}
      {!DependantPrescriptionOnboardingResult?.onboardingComplete && (
        <Redirect
          to={`${routes.DEPENDANT.PRESCRIPTION.ONBOARDING.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.ONBOARDING.NEXT}`}
        />
      )}

      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.PRESCRIPTION.COMPLETED}`}
      >
        <PrescriptionCompleted isDependant={true} />
      </PrivateRoute>

      <PrivateRoute path={`${baseRoute}${routes.DEPENDANT.PRESCRIPTION.ADD}`}>
        <SuggestProvider>
          <PrescriptionAdd />
        </SuggestProvider>
      </PrivateRoute>
      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.PRESCRIPTION.DETAIL}/:id`}
      >
        <PrescriptionDetail isDependant={true} />
      </PrivateRoute>
      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.PRESCRIPTION.REMINDER}/:id`}
      >
        <PrescriptionReminder />
      </PrivateRoute>
      <PrivateRoute path={baseRoute} exact>
        <PrescriptionLanding />
      </PrivateRoute>
    </Switch>
  );
};
