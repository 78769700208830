/* eslint-disable sonarjs/no-duplicate-string */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import {
  RosaceaPomForm as RosaceaPomFormType,
  rosaceaPomFormQuestions,
} from 'models/PomQuestionnaires/Rosacea';
import { useTranslation } from 'react-i18next';
import { useBasketContext } from 'contexts/BasketContext';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { ImageSelector, SingleSelect, TextArea } from '../PomForm.Components';
import { RosaceaPomFormSchema } from 'formSchema/PomForms/RosaceaPomFormSchema';

interface RosaceaPomFormProps {
  onSubmit: (
    payload: RosaceaPomFormType,
    isImageUploadRequired: boolean,
  ) => Promise<void>;
  actionButtons?: ReactNode;
}

export const RosaceaPomForm = ({
  onSubmit,
  actionButtons,
}: RosaceaPomFormProps) => {
  const { t } = useTranslation();
  const { basket } = useBasketContext();
  const { condition } = useConditionsContext();
  const fields = rosaceaPomFormQuestions;
  const [shouldValidateImages, setShouldValidateImages] = useState(true); // assume by default that images need validating

  const formMethods = useForm({
    resolver: yupResolver(RosaceaPomFormSchema(shouldValidateImages)),
  });
  const { handleSubmit, watch, register, reset } = formMethods;

  useEffect(() => {
    const existingForm = basket.pomForms.find(
      (form) => form.conditionId === condition?.id,
    )?.formAnswers;
    if (existingForm && existingForm.conditionId === 3) {
      reset({ ...existingForm });

      // If using Images input
      setImageFilenames(existingForm.imageNames);
      setShouldValidateImages(existingForm.imageNames?.length === 0);
    }
  }, [basket.shopProducts]);

  // If using Images input
  const [imageFilenames, setImageFilenames] = useState<string[]>([]);
  const imageFileInputRef = useRef<HTMLInputElement>(null);
  register(fields[6].fieldName);

  // Condition specific stuff

  const hasOtherSkinCondition = watch(fields[3].fieldName);

  return (
    <form
      onSubmit={handleSubmit((payload: RosaceaPomFormType) => {
        onSubmit(
          { ...payload, conditionId: 3, imageNames: imageFilenames },
          shouldValidateImages,
        );
      })}
    >
      <SingleSelect field={fields[0]} formMethods={formMethods} />

      <SingleSelect
        field={fields[1]}
        formMethods={formMethods}
        orientation="vertical"
        values={[
          {
            value: 'This is the first time',
            label: t('pomForms.generic.labels.howLongSuffering.firstTime'),
          },
          {
            value: 'Less than six months',
            label: t(
              'pomForms.generic.labels.howLongSuffering.lessThanSixMonths',
            ),
          },
          {
            value: 'More than six months',
            label: t(
              'pomForms.generic.labels.howLongSuffering.moreThanSixMonths',
            ),
          },
        ]}
      />

      <TextArea field={fields[2]} formMethods={formMethods} />

      <SingleSelect field={fields[3]} formMethods={formMethods} />

      {hasOtherSkinCondition === 'Yes' && (
        <TextArea field={fields[4]} formMethods={formMethods} />
      )}

      <SingleSelect field={fields[5]} formMethods={formMethods} />

      <ImageSelector
        field={fields[6]}
        formMethods={formMethods}
        imageFilenames={
          shouldValidateImages
            ? imageFilenames
            : [
                t('pomForms.generic.labels.imageUpload.count', {
                  count: imageFilenames?.length,
                }),
              ]
        }
        setImageFilenames={setImageFilenames}
        inputRef={imageFileInputRef}
        onChange={() => setShouldValidateImages(true)}
      />

      {actionButtons}
    </form>
  );
};
