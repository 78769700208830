import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';

import styles from './PrivacyPolicy.module.scss';

interface ArticleProps {
  title?: string;
  textArray: string[];
}

interface ArticleListProps {
  textArray: string[];
}

const articleStyle = 'privacy-policy__article';
const titleStyles = 'privacy-policy__title';

const Article = ({ title, textArray }: ArticleProps) => {
  return (
    <>
      {title && <Heading className={styles[titleStyles]}>{title}</Heading>}
      {textArray.map((p, index) => {
        return (
          <Text className={styles['privacy-policy__article']} key={index}>
            {p}
          </Text>
        );
      })}
    </>
  );
};

const ArticleList = ({ textArray }: ArticleListProps) => {
  return (
    <ul className={styles[articleStyle]}>
      {textArray.map((i, index) => {
        return (
          <li className={styles['privacy-policy__article-list']} key={index}>
            <Text>{i}</Text>
          </li>
        );
      })}
    </ul>
  );
};

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <Container className={styles['privacy-policy']}>
      <Row>
        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 8, offset: 2 }}>
          <Heading className={styles[titleStyles]}>
            {t('PrivacyPolicy.title')}
          </Heading>
          <Article
            textArray={t('PrivacyPolicy.paragraphs', {
              returnObjects: true,
            })}
          />
          <Article
            title={t('PrivacyPolicy.InfoRecorded.title')}
            textArray={t('PrivacyPolicy.InfoRecorded.paragraphs', {
              returnObjects: true,
            })}
          />
          <ArticleList
            textArray={t('PrivacyPolicy.InfoRecorded.list', {
              returnObjects: true,
            })}
          />
          <Article
            title={t('PrivacyPolicy.ProcessingInfo.title')}
            textArray={t('PrivacyPolicy.ProcessingInfo.paragraphs1', {
              returnObjects: true,
            })}
          />
          <ArticleList
            textArray={t('PrivacyPolicy.ProcessingInfo.list', {
              returnObjects: true,
            })}
          />
          <Article
            textArray={t('PrivacyPolicy.ProcessingInfo.paragraphs2', {
              returnObjects: true,
            })}
          />
          <Article
            title={t('PrivacyPolicy.YourRights.title')}
            textArray={t('PrivacyPolicy.YourRights.paragraphs', {
              returnObjects: true,
            })}
          />
          <Article
            title={t('PrivacyPolicy.RightToView.title')}
            textArray={t('PrivacyPolicy.RightToView.paragraphs', {
              returnObjects: true,
            })}
          />
          <Heading className={styles[articleStyle]} size="md">
            {t('PrivacyPolicy.RightToView.dataOfficer')}
          </Heading>
          <Article
            title={t('PrivacyPolicy.WebsitePrivacy.title')}
            textArray={t('PrivacyPolicy.WebsitePrivacy.paragraphs1', {
              returnObjects: true,
            })}
          />
          <ArticleList
            textArray={t('PrivacyPolicy.WebsitePrivacy.list1', {
              returnObjects: true,
            })}
          />

          <Article
            textArray={t('PrivacyPolicy.WebsitePrivacy.paragraphs2', {
              returnObjects: true,
            })}
          />
          <Article
            textArray={t('PrivacyPolicy.WebsitePrivacy.community', {
              returnObjects: true,
            })}
          />
          <ArticleList
            textArray={t('PrivacyPolicy.WebsitePrivacy.list2', {
              returnObjects: true,
            })}
          />

          <Article
            textArray={t('PrivacyPolicy.WebsitePrivacy.paragraphs3', {
              returnObjects: true,
            })}
          />
          <ArticleList
            textArray={t('PrivacyPolicy.WebsitePrivacy.list3', {
              returnObjects: true,
            })}
          />
          <Article
            textArray={t('PrivacyPolicy.WebsitePrivacy.paragraphs4', {
              returnObjects: true,
            })}
          />
          <Article
            title={t('PrivacyPolicy.OnlineConsultations.title')}
            textArray={t('PrivacyPolicy.OnlineConsultations.paragraphs1', {
              returnObjects: true,
            })}
          />
          <ArticleList
            textArray={t('PrivacyPolicy.OnlineConsultations.list1', {
              returnObjects: true,
            })}
          />
          <Article
            textArray={t('PrivacyPolicy.OnlineConsultations.paragraphs2', {
              returnObjects: true,
            })}
          />
          <ArticleList
            textArray={t('PrivacyPolicy.OnlineConsultations.lists2', {
              returnObjects: true,
            })}
          />
        </Col>
      </Row>
    </Container>
  );
};
