import { HelpArticle } from 'models/HelpArticle/HelpArticle';

export const articleList: HelpArticle[] = [
  {
    id: 'order-not-arrived',
    title: 'Account.help.articles.orderNotArrived.title',
    paragraphs: [
      { paragraph: 'Account.help.articles.orderNotArrived.p1' },
      { paragraph: 'Account.help.articles.orderNotArrived.p2' },
    ],
  },
  {
    id: 'order-not-in-store',
    title: 'Account.help.articles.orderNotInStore.title',
    paragraphs: [
      { paragraph: 'Account.help.articles.orderNotInStore.p1' },
      { paragraph: 'Account.help.articles.orderNotInStore.p2' },
    ],
  },
  {
    id: 'receiving-order',
    title: 'Account.help.articles.receivingOrder.title',
    paragraphs: [
      { paragraph: 'Account.help.articles.receivingOrder.p1' },
      { paragraph: 'Account.help.articles.receivingOrder.p2' },
      { paragraph: 'Account.help.articles.receivingOrder.p3' },
      { paragraph: 'Account.help.articles.receivingOrder.p4' },
    ],
  },
  {
    id: 'delivery-not-available',
    title: 'Account.help.articles.deliveryNotAvailable.title',
    paragraphs: [
      { paragraph: 'Account.help.articles.deliveryNotAvailable.p1' },
      { paragraph: 'Account.help.articles.deliveryNotAvailable.p2' },
    ],
  },
  {
    id: 'exemption-notify',
    title: 'Account.help.articles.exemptionNotify.title',
    paragraphs: [{ paragraph: 'Account.help.articles.exemptionNotify.p1' }],
  },
  {
    id: 'order-for-dependant',
    title: 'Account.help.articles.orderForDependant.title',
    paragraphs: [
      { paragraph: 'Account.help.articles.orderForDependant.p1' },
      { paragraph: 'Account.help.articles.orderForDependant.p2' },
    ],
  },
];
