import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'contexts/ModalContext';
import { Button } from 'components/common/Button';
import { Text } from 'components/common/Text';
import { ReactComponent as CircleInfo } from 'assets/icons/lawsat/CircleInfo.svg';
import styles from './PomConfirmLoginModal.module.scss';

interface PomConfirmLoginModalProps {
  onLogin: () => void;
}

export const PomConfirmLoginModal = ({
  onLogin,
}: PomConfirmLoginModalProps) => {
  const { close } = useModalContext();
  const { t } = useTranslation();

  return (
    <div className={styles['pom-confirm-login-modal']}>
      <Text size="lg" className={styles['pom-confirm-login-modal__info-msg']}>
        <CircleInfo
          className={styles['pom-confirm-login-modal__info-msg__icon']}
        />
        {t('OrderFlow.pomLoginModal.message')}
      </Text>
      <Button
        className={styles['pom-confirm-login-modal__remove-button']}
        width={'full'}
        variant={'accent'}
        label={t('OrderFlow.pomLoginModal.loginButton')}
        onClick={() => {
          onLogin();
          close();
        }}
      />
      <Button
        width={'full'}
        variant={'negative'}
        label={t('OrderFlow.pomLoginModal.backButton')}
        onClick={() => close()}
      />
    </div>
  );
};
