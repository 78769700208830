import { PrescriptionItem } from 'models/PrescriptionItem';
import {
  getIndexOfProductInBasket,
  getDependantBasketIndex,
  getOrderlineFromPrescriptionItem,
} from './HelperFunctions';
import { AppBasket, BasketOrderline, DependantBasket } from 'models/Basket';
import { defaultDependantBasket } from 'contexts/BasketContext/Types';

export const changeProductContraceptionStatus = (
  prevBasket: AppBasket,
  prescriptionItemId: string,
) => {
  const updatedPrescriptionProducts = [...prevBasket.prescriptionProducts];
  const itemIndex = getIndexOfProductInBasket(
    prescriptionItemId,
    updatedPrescriptionProducts,
  );
  if (itemIndex !== -1) {
    updatedPrescriptionProducts[itemIndex].isUsedAsContraceptive =
      !updatedPrescriptionProducts[itemIndex].isUsedAsContraceptive;
  }
  return {
    ...prevBasket,
    prescriptionProducts: updatedPrescriptionProducts,
  };
};

export const changeDependantProductContraceptionStatus = (
  prevDependantBaskets: DependantBasket[],
  dependantId: string,
  prescriptionItemId: string,
) => {
  const updatedDependantBaskets = [...prevDependantBaskets];
  const dependantBasketIndex = getDependantBasketIndex(
    dependantId,
    updatedDependantBaskets,
  );
  if (dependantBasketIndex !== -1) {
    const itemIndex = getIndexOfProductInBasket(
      prescriptionItemId,
      updatedDependantBaskets[dependantBasketIndex].basket.prescriptionProducts,
    );
    if (itemIndex !== -1) {
      updatedDependantBaskets[dependantBasketIndex].basket.prescriptionProducts[
        itemIndex
      ].isUsedAsContraceptive =
        !updatedDependantBaskets[dependantBasketIndex].basket
          .prescriptionProducts[itemIndex].isUsedAsContraceptive;
    }
  }
  return updatedDependantBaskets;
};

export const toggleExemptionStatus = (
  prevBasket: AppBasket,
  hasExemption: boolean,
  prescriptionPrice: number,
) => {
  const prevPrescriptionProducts = [...prevBasket.prescriptionProducts];
  const updatedPrescriptionProducts = prevPrescriptionProducts.map(
    (prescriptionProduct) => ({
      ...prescriptionProduct,
      price: hasExemption ? 0 : prescriptionPrice,
    }),
  );
  return {
    ...prevBasket,
    prescriptionProducts: updatedPrescriptionProducts,
  };
};

export const toggleDependantExemptionStatus = (
  prevDependantBaskets: DependantBasket[],
  dependantId: string,
  hasExemption: boolean,
  prescriptionPrice: number,
) => {
  const updatedDependantBaskets = [...prevDependantBaskets];
  const dependantBasketIndex = getDependantBasketIndex(
    dependantId,
    updatedDependantBaskets,
  );
  if (dependantBasketIndex !== -1) {
    updatedDependantBaskets[dependantBasketIndex].basket.prescriptionProducts =
      updatedDependantBaskets[
        dependantBasketIndex
      ].basket.prescriptionProducts.map((product) => ({
        ...product,
        price: hasExemption ? 0 : prescriptionPrice,
      }));
  }
  return updatedDependantBaskets;
};

export const removePrescriptionProductFromBasket = (
  prevBasket: AppBasket,
  prescriptionItemId: string,
) => {
  const prevPrescriptionProducts = [...prevBasket.prescriptionProducts];
  const updatedPrescriptionProducts = prevPrescriptionProducts.filter(
    (prescriptionProduct) => prescriptionProduct.id !== prescriptionItemId,
  );
  return {
    ...prevBasket,
    prescriptionProducts: updatedPrescriptionProducts,
  };
};

export const removePrescriptionProductFromDependantBasket = (
  prevDependantBaskets: DependantBasket[],
  dependantId: string,
  prescriptionItemId: string,
) => {
  const updatedDependantBaskets = [...prevDependantBaskets];
  const dependantBasketIndex = getDependantBasketIndex(
    dependantId,
    updatedDependantBaskets,
  );
  if (dependantBasketIndex !== -1) {
    updatedDependantBaskets[dependantBasketIndex].basket.prescriptionProducts =
      prevDependantBaskets[
        dependantBasketIndex
      ].basket.prescriptionProducts.filter(
        (prescriptionProduct) => prescriptionProduct.id !== prescriptionItemId,
      );
  }
  return updatedDependantBaskets;
};

export const addPrescriptionProductToBasket = (
  prevBasket: AppBasket,
  prescriptionItem: PrescriptionItem,
) => {
  const updatedPrescriptionProducts = [...prevBasket.prescriptionProducts];
  const itemIndex = getIndexOfProductInBasket(
    prescriptionItem.medicinalProductId,
    updatedPrescriptionProducts,
  );
  if (itemIndex === -1) {
    updatedPrescriptionProducts.push(
      getOrderlineFromPrescriptionItem(prescriptionItem),
    );
  }
  return {
    ...prevBasket,
    prescriptionProducts: updatedPrescriptionProducts,
  };
};
export const addPrescriptionProductToDependantBasket = (
  prevDependantBaskets: DependantBasket[],
  dependantId: string,
  prescriptionItem: PrescriptionItem,
) => {
  const newOrderline = getOrderlineFromPrescriptionItem(prescriptionItem);
  const updatedDependantBaskets = [...prevDependantBaskets];
  const dependantBasketIndex = getDependantBasketIndex(
    dependantId,
    updatedDependantBaskets,
  );
  if (dependantBasketIndex === -1) {
    updatedDependantBaskets.push({
      dependantId,
      basket: {
        ...defaultDependantBasket.basket,
        prescriptionProducts: [newOrderline],
      },
    });
  } else {
    const itemIndex = getIndexOfProductInBasket(
      prescriptionItem.medicinalProductId,
      updatedDependantBaskets[dependantBasketIndex].basket.prescriptionProducts,
    );
    if (itemIndex === -1) {
      updatedDependantBaskets[
        dependantBasketIndex
      ].basket.prescriptionProducts.push(newOrderline);
      return updatedDependantBaskets;
    }
  }
  return updatedDependantBaskets;
};

export const mergePrescriptionBaskets = (
  basket: AppBasket,
  productsToMerge: BasketOrderline[],
) => {
  return {
    ...basket,
    prescriptionProducts: productsToMerge,
  };
};

export const mergeDependantPrescriptionBaskets = (
  prevDependantBaskets: DependantBasket[],
  dependantId: string,
  productsToMerge: BasketOrderline[],
) => {
  const updatedDependantBaskets = [...prevDependantBaskets];
  const dependantBasketIndex = getDependantBasketIndex(
    dependantId,
    updatedDependantBaskets,
  );
  if (dependantBasketIndex !== -1) {
    updatedDependantBaskets[dependantBasketIndex].basket.prescriptionProducts =
      productsToMerge;
  } else {
    updatedDependantBaskets.push({
      dependantId,
      basket: {
        ...defaultDependantBasket.basket,
        prescriptionProducts: productsToMerge,
      },
    });
  }
  return updatedDependantBaskets;
};
