import React, { ChangeEvent, useState, KeyboardEvent } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import { IconButton } from 'components/common/IconButton';
import { ReactComponent as SearchIcon } from 'assets/icons/lawsat/Search.svg';
import { useTranslation } from 'react-i18next';

import styles from './SearchBar.module.scss';

interface SearchBarProps {
  onSearch?: () => void;
}

export const SearchBar = ({ onSearch }: SearchBarProps) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const [searchString, setSearchString] = useState('');
  const [error, setError] = useState(false);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setSearchString(e.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleError = () => {
    setError(true);
    setTimeout(() => setError(false), 1000);
  };

  const handleSubmit = () => {
    if (searchString.trim() === '') {
      handleError();
    } else {
      onSearch && onSearch();
      navigate.push(
        `${routes.SEARCH_RESULTS}/${searchString.trim().replace(' ', '%20')}/1`,
      );
      setTimeout(() => setSearchString(''), 250);
    }
  };

  return (
    <div
      className={cx(
        styles['search-bar__container'],
        error && styles['search-bar__container--error'],
      )}
    >
      <input
        type="text"
        className={styles['search-bar__input']}
        placeholder={t('NavBarLabels.searchPlaceholder')}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        value={searchString}
      />
      <IconButton
        className={cx(
          styles['search-bar__button'],
          error && styles['search-bar__button--error'],
        )}
        icon={SearchIcon}
        label="Search"
        onClick={handleSubmit}
      />
    </div>
  );
};
