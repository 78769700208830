import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'contexts/ModalContext';
import { Button } from 'components/common/Button';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import styles from './RemoveExemptionReasonModal.module.scss';

interface RemoveExemptionReasonModalProps {
  onRemove: () => void;
  dependantName?: string;
}

export const RemoveExemptionReasonModal: React.FC<
  RemoveExemptionReasonModalProps
> = ({ onRemove, dependantName }) => {
  const { close } = useModalContext();
  const { t } = useTranslation();

  return (
    <div className={styles['remove-exemption-reason-modal']}>
      <Heading size="lg" tag="h3">
        {t('Account.exemption.removeExemptionModal.title')}
      </Heading>
      <Text className={styles['remove-exemption-reason-modal__content']}>
        {dependantName
          ? t('Account.exemption.removeExemptionModal.contentWithDependant', {
              dependantName: dependantName,
            })
          : t('Account.exemption.removeExemptionModal.content')}
      </Text>
      <Button
        testId={'remove-exemption-button'}
        className={styles['remove-exemption-reason-modal__remove-button']}
        width={'full'}
        variant={'negative'}
        label={t('Account.exemption.removeExemptionModal.removeButton')}
        onClick={() => {
          onRemove();
          close();
        }}
      />
      <Button
        testId={'cancel-button'}
        width={'full'}
        variant={'ui'}
        appearance={'flat'}
        label={t('Account.exemption.removeExemptionModal.cancelButton')}
        onClick={() => close()}
      />
    </div>
  );
};
