/* eslint-disable sonarjs/no-duplicate-string */
import * as yup from 'yup';
import i18n from 'i18next';
import { acnePomFormQuestions } from 'models/PomQuestionnaires';

export const AcnePomFormSchema = (
  shouldValidateImages: boolean,
): yup.ObjectSchema => {
  const requiredError = i18n.t('common.formErrors.required');
  const booleanRequiredError = i18n.t('common.formErrors.radioRequired');
  const multiSelectRequiredError = i18n.t(
    'common.formErrors.multiSelectRequired',
  );
  const maxLengthError = i18n.t('common.formErrors.maxLength', { max: 500 });

  return yup.object().shape(
    {
      [acnePomFormQuestions[0].fieldName]: yup
        .string()
        .oneOf(['Yes', 'No'], booleanRequiredError)
        .required(booleanRequiredError),
      [acnePomFormQuestions[1].fieldName]: yup
        .string()
        .oneOf(
          [
            'This is the first time',
            'Less than six months',
            'More than six months',
          ],
          booleanRequiredError,
        )
        .required(requiredError),
      [acnePomFormQuestions[2].fieldName]: yup
        .object()
        .shape({
          Whiteheads: yup.boolean(),
          Blackheads: yup.boolean(),
          'Inflamed spots on surface of skin': yup.boolean(),
          'Inflamed spots underneath surface of skin': yup.boolean(),
        })
        .test('atLeastOneTrue', multiSelectRequiredError, (value) => {
          return (
            value?.Whiteheads ||
            value?.Blackheads ||
            value?.['Inflamed spots on surface of skin'] ||
            value?.['Inflamed spots underneath surface of skin']
          );
        })
        .required(multiSelectRequiredError),
      [acnePomFormQuestions[3].fieldName]: yup
        .object()
        .shape({
          Face: yup.boolean(),
          Back: yup.boolean(),
          Chest: yup.boolean(),
          Other: yup.boolean(),
        })
        .test('atLeastOneTrue', multiSelectRequiredError, (value) => {
          return value?.Face || value?.Back || value?.Chest || value?.Other;
        })
        .required(multiSelectRequiredError),
      [acnePomFormQuestions[4].fieldName]: yup
        .string()
        .typeError()
        .nullable()
        .when(
          acnePomFormQuestions[3].fieldName,
          (affectedSkinAreas, schema) => {
            if (affectedSkinAreas.other) {
              return schema
                .required(requiredError)
                .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
                .max(500, maxLengthError)
                .trim();
            }
            return schema;
          },
        ),
      [acnePomFormQuestions[5].fieldName]: yup
        .string()
        .oneOf(['Yes', 'No'], booleanRequiredError)
        .required(booleanRequiredError),
      [acnePomFormQuestions[6].fieldName]: yup
        .string()
        .typeError()
        .nullable()
        .when(
          acnePomFormQuestions[5].fieldName,
          (isTakingAnyMedication, schema) => {
            if (isTakingAnyMedication === 'Yes') {
              return schema
                .required(requiredError)
                .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
                .max(500, maxLengthError)
                .trim();
            }
            return schema;
          },
        ),
      [acnePomFormQuestions[7].fieldName]: yup
        .string()
        .oneOf(['Yes', 'No'], booleanRequiredError)
        .required(booleanRequiredError),
      [acnePomFormQuestions[8].fieldName]: yup
        .string()
        .typeError()
        .nullable()
        .when(
          acnePomFormQuestions[7].fieldName,
          (hasBeenReviewedInLast12Months, schema) => {
            if (hasBeenReviewedInLast12Months === 'Yes') {
              return schema
                .required(requiredError)
                .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
                .max(500, maxLengthError)
                .trim();
            }
            return schema;
          },
        ),
      [acnePomFormQuestions[9].fieldName]: shouldValidateImages
        ? yup
            .array()
            .required(i18n.t('common.formErrors.tooFewImages'))
            .min(1, i18n.t('common.formErrors.tooFewImages'))
            .max(2, i18n.t('common.formErrors.tooManyImages', { max: 2 }))
            .of(
              yup
                .mixed()
                .test('notBiggerThan5mb', 'File size exceeds 5MB', (file) =>
                  file ? file.size / Math.pow(1024, 2) <= 5 : true,
                ),
            )
        : yup.array(),
      [acnePomFormQuestions[10].fieldName]: yup
        .boolean()
        .nullable()
        .required(i18n.t('common.formErrors.termsAndConditions'))
        .test(
          'mustBeTrue',
          i18n.t('common.formErrors.termsAndConditions'),
          (value) => value === true,
        ),
      imageNames: yup.array().of(yup.string()),
    },
    [['imageFiles', 'imageFiles']],
  );
};
