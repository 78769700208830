import React, { useEffect, useState } from 'react';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { useTranslation } from 'react-i18next';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { ReactComponent as Address } from 'assets/illustrations/landing-address.svg';
import { ReactComponent as Orders } from 'assets/illustrations/landing-orders.svg';
import { ReactComponent as Gp } from 'assets/illustrations/landing-gp.svg';
import { ReactComponent as PillBottle } from 'assets/illustrations/prescription-add.svg';
import cx from 'clsx';

import styles from './HowItWorksSection.module.scss';

const treatmentSteps = [
  {
    step: 1,
    label: 'LandingPage.howItWorks.treatments.stepOneLabel',
    image: <Orders />,
  },
  {
    step: 2,
    label: 'LandingPage.howItWorks.treatments.stepTwoLabel',
    image: <Gp />,
  },
  {
    step: 3,
    label: 'LandingPage.howItWorks.treatments.stepThreeLabel',
    image: <Address />,
  },
];

const prescriptionSteps = [
  {
    step: 1,
    label: 'LandingPage.howItWorks.prescriptions.stepOneLabel',
    image: <PillBottle />,
  },
  {
    step: 2,
    label: 'LandingPage.howItWorks.prescriptions.stepTwoLabel',
    image: <Gp />,
  },
  {
    step: 3,
    label: 'LandingPage.howItWorks.prescriptions.stepThreeLabel',
    image: <Address />,
  },
];

interface HowItWorksSectionProps {
  mode?: 'treatments' | 'prescriptions' | 'both';
}

export const HowItWorksSection = ({
  mode = 'treatments',
}: HowItWorksSectionProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logSectionView } = useAmplitudeContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.howItWorksSection.section'),
      });
    }
  }, [isInViewport]);

  const [activeMode, setActiveMode] = useState<'treatments' | 'prescriptions'>(
    mode === 'both' ? 'treatments' : mode,
  );

  const handleModeChange = () => {
    setActiveMode((prev) =>
      prev === 'prescriptions' ? 'treatments' : 'prescriptions',
    );
  };

  const steps =
    activeMode === 'treatments' ? treatmentSteps : prescriptionSteps;

  return (
    <div className={styles['how-it-works-section__container']} ref={elementRef}>
      <Heading
        tag="h1"
        size="xxxl"
        className={styles['how-it-works-section__heading']}
      >
        {t('LandingPage.howItWorks.title')}
      </Heading>

      {mode === 'both' && (
        <div className={styles['how-it-works-section__toggle__container']}>
          <Button
            label={t('LandingPage.howItWorks.treatmentsButton')}
            appearance={activeMode === 'treatments' ? 'solid' : 'ghost'}
            className={cx(
              styles['how-it-works-section__toggle__button'],
              activeMode === 'treatments' &&
                styles['how-it-works-section__toggle__button--active'],
            )}
            disabled={activeMode === 'treatments'}
            onClick={activeMode !== 'treatments' ? handleModeChange : undefined}
          />
          <Button
            label={t('LandingPage.howItWorks.prescriptionsButton')}
            appearance={activeMode === 'prescriptions' ? 'solid' : 'ghost'}
            className={cx(
              styles['how-it-works-section__toggle__button'],
              activeMode === 'prescriptions' &&
                styles['how-it-works-section__toggle__button--active'],
            )}
            disabled={activeMode === 'prescriptions'}
            onClick={
              activeMode !== 'prescriptions' ? handleModeChange : undefined
            }
          />
        </div>
      )}

      <div className={styles['how-it-works-section__steps']}>
        {steps.map((step) => (
          <div className={styles['how-it-works-section__step']} key={step.step}>
            {step.image}
            <div className={styles['how-it-works-section__step__footer']}>
              <div
                className={styles['how-it-works-section__step__footer__number']}
              >
                <Heading size="xs" tag="h6">
                  {step.step}
                </Heading>
              </div>
              <Heading
                size="sm"
                tag="h6"
                className={styles['how-it-works-section__step__footer__copy']}
              >
                {t(step.label)}
              </Heading>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
