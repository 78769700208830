/* eslint-disable sonarjs/no-duplicate-string */
import React, { ReactNode, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import {
  ErectileDysfunctionPomForm as ErectileDysfunctionPomFormType,
  erectileDysfunctionPomFormQuestions,
} from 'models/PomQuestionnaires/ErectileDysfunction';
import { useTranslation } from 'react-i18next';
import { useBasketContext } from 'contexts/BasketContext';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { SingleCheckbox, SingleSelect, TextArea } from '../PomForm.Components';
import { ErectileDysfunctionPomFormSchema } from 'formSchema/PomForms/ErectileDysfunctionPomFormSchema';

interface ErectileDysfunctionPomFormProps {
  onSubmit: (payload: ErectileDysfunctionPomFormType) => Promise<void>;
  actionButtons?: ReactNode;
}

export const ErectileDysfunctionPomForm = ({
  onSubmit,
  actionButtons,
}: ErectileDysfunctionPomFormProps) => {
  const { t } = useTranslation();
  const { basket } = useBasketContext();
  const { condition } = useConditionsContext();
  const fields = erectileDysfunctionPomFormQuestions;

  const formMethods = useForm({
    resolver: yupResolver(ErectileDysfunctionPomFormSchema()),
  });
  const { handleSubmit, watch, reset } = formMethods;

  useEffect(() => {
    const existingForm = basket.pomForms.find(
      (form) => form.conditionId === condition?.id,
    )?.formAnswers;
    if (existingForm && existingForm.conditionId === 4) {
      reset({
        ...existingForm,
      });
    }
  }, [basket.shopProducts]);

  // Condition specific stuff
  const hasTakenEdTreatmentBefore = watch(fields[1].fieldName);
  const applicableStatementsOne = watch(fields[4].fieldName);
  const sufferFromConditions = watch(fields[6].fieldName);
  const applicableStatementsTwo = watch(fields[9].fieldName);

  return (
    <form
      onSubmit={handleSubmit(
        (payload: ErectileDysfunctionPomFormType) => {
          onSubmit({ ...payload, conditionId: 4 });
        },
        (errors) => console.error(errors),
      )}
    >
      <SingleSelect field={fields[0]} formMethods={formMethods} />

      <SingleSelect field={fields[1]} formMethods={formMethods} />

      {hasTakenEdTreatmentBefore === 'Yes' && (
        <TextArea field={fields[2]} formMethods={formMethods} />
      )}

      <SingleSelect field={fields[3]} formMethods={formMethods} />

      <SingleSelect
        field={fields[4]}
        formMethods={formMethods}
        helperText={t('pomForms.erectileDysfunction.statements', {
          returnObjects: true,
        })}
      />

      {applicableStatementsOne === 'Yes' && (
        <TextArea field={fields[5]} formMethods={formMethods} />
      )}

      <SingleSelect
        field={fields[6]}
        formMethods={formMethods}
        helperText={t('pomForms.erectileDysfunction.conditions', {
          returnObjects: true,
        })}
      />

      {sufferFromConditions === 'Yes' && (
        <TextArea field={fields[7]} formMethods={formMethods} />
      )}

      <SingleCheckbox
        field={fields[8]}
        checkbox={{ label: t('pomForms.erectileDysfunction.terms.confirm') }}
        formMethods={formMethods}
        helperText={t('pomForms.erectileDysfunction.terms.terms', {
          returnObjects: true,
        })}
      />

      <SingleSelect
        field={fields[9]}
        formMethods={formMethods}
        helperText={t('pomForms.erectileDysfunction.applicables', {
          returnObjects: true,
        })}
      />

      {applicableStatementsTwo === 'Yes' && (
        <TextArea field={fields[10]} formMethods={formMethods} />
      )}

      {actionButtons}
    </form>
  );
};
