import React from 'react';
import cx from 'classnames';
import { ProductTile } from 'components/Products/ProductTile';
import { ProductSummary } from 'models/Product/Product';
import styles from './ProductList.module.scss';

interface ProductListProps {
  className?: string;
  testId?: string;
  products: ProductSummary[];
  baseUrl: string;
  showCardsWithShadow?: boolean;
}

export const ProductList = ({
  className,
  testId,
  products,
  baseUrl,
  showCardsWithShadow,
}: ProductListProps) => {
  return (
    <>
      {products.length > 0 && (
        <section
          data-testid={testId}
          className={cx(styles['product-list'], {
            [`${className}`]: className,
          })}
        >
          <ul className={styles['product-list__list']}>
            {products.map((product) => {
              return (
                <li
                  key={product.id}
                  className={styles['product-list__list-item']}
                >
                  <ProductTile
                    product={product}
                    productLinkBaseUrl={baseUrl}
                    withShadow={showCardsWithShadow}
                  />
                </li>
              );
            })}
          </ul>
        </section>
      )}
    </>
  );
};
