import { AppBasket, BackendBasket } from 'models/Basket';
import { getPomFormAnswersPayload } from 'models/PomQuestionnaires/PomPayloadTransformer';

interface BasketPayload {
  basket: AppBasket;
}

export const getBasketPayload = ({
  basket: { subTotal, shopProducts, prescriptionProducts, pmedForms, pomForms },
}: BasketPayload): BackendBasket => ({
  subTotal: Number(subTotal.toFixed(2)),
  items: [
    ...shopProducts.map(({ id, name, quantity, isUsedAsContraceptive }) => ({
      productId: Number(id),
      name,
      quantity,
      isUsedAsContraceptive,
    })),
    ...prescriptionProducts.map(({ id, name, isUsedAsContraceptive }) => ({
      medicinalProductId: id,
      name,
      quantity: 0, // backend doesn't care for quantities on prescription products
      isUsedAsContraceptive,
    })),
  ],
  pmedForms,
  pomForms: pomForms
    .filter((pomForm) => pomForm.productIds.length > 0)
    .map((pomForm) => ({
      conditionId: pomForm.conditionId,
      productIds: pomForm.productIds,
      formResponse: {
        responses: getPomFormAnswersPayload(
          pomForm.conditionId,
          pomForm.formAnswers,
        ),
        ...('imageNames' in pomForm.formAnswers &&
        pomForm.formAnswers.imageNames.length > 0
          ? {
              images: pomForm.formAnswers.imageNames,
            }
          : { images: [] }),
      },
    })),
});
