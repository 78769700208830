import React from 'react';

export interface CookiesContextProps {
  hasApprovedCookies: boolean | null;
  approveCookies: () => void;
  children?: React.ReactNode;
}

export const CookiesContextDefaults: CookiesContextProps = {
  hasApprovedCookies: null,
  approveCookies: () => null,
};

export const CookiesContext = React.createContext<CookiesContextProps>(
  CookiesContextDefaults,
);

export const useCookiesContext = (): CookiesContextProps =>
  React.useContext(CookiesContext);

interface CookiesProviderProps {
  children?: React.ReactNode;
}

export const CookiesProvider = ({ children }: CookiesProviderProps) => {
  const [hasApprovedCookies, setHasApprovedCookies] = React.useState<
    boolean | null
  >(null);

  const storageKey = 'hasApprovedCookies';

  React.useEffect(() => {
    setHasApprovedCookies(localStorage.getItem(storageKey) === 'true');
  }, []);

  const approveCookies = () => {
    setHasApprovedCookies(true);
    localStorage.setItem(storageKey, 'true');
  };

  return (
    <CookiesContext.Provider value={{ hasApprovedCookies, approveCookies }}>
      {children}
    </CookiesContext.Provider>
  );
};
