import { Switch, useParams } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { PrescriptionExemption } from 'containers/Prescription/PrescriptionExemption';
import { PrescriptionExemptionExpire } from 'containers/Prescription/PrescriptionExemptionExpire';
import { PrescriptionExemptionList } from 'containers/Prescription/PrescriptionExemptionList';
import { PrescriptionNextStep } from 'containers/Prescription/PrescriptionNextStep';
import { SelectGPLanding } from 'containers/Account/SelectGPLanding';
import { useDependantExemptionsContext } from 'contexts/DependantExemptionsContext';
import { SuggestProvider } from 'contexts/SuggestContext';
import { useDependantContext } from 'contexts/DependantContext';
import { useTranslation } from 'react-i18next';
import { useDependantPrescriptionOnboardingComplete } from 'hooks/useOnboardingComplete/useOnboardingComplete';
import { LandingPageSpinner } from 'components/common/Spinner';
import { routes } from 'routes';

export const DependantPrescriptionOnboarding = () => {
  const { dependantId } = useParams<{ dependantId: string }>();
  const { t } = useTranslation();
  const { dependant } = useDependantContext();
  const { dependantExemptionDetails } = useDependantExemptionsContext();
  const DependantPrescriptionOnboardingResult =
    useDependantPrescriptionOnboardingComplete(dependantId);

  if (!DependantPrescriptionOnboardingResult?.checksComplete) {
    return <LandingPageSpinner />;
  }

  const baseRoute = `${routes.DEPENDANT.PRESCRIPTION.ONBOARDING.BASE}:dependantId`;
  return (
    <Switch>
      {/* Onboarding Step 0 */}
      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.PRESCRIPTION.ONBOARDING.NEXT}`}
        exact
      >
        <PrescriptionNextStep />
      </PrivateRoute>

      {/* Onboarding Step 1 */}
      <PrivateRoute path={`${baseRoute}${routes.SELECT_GP}`} exact>
        <SuggestProvider>
          <SelectGPLanding
            dependantId={dependantId}
            heading={t('PrescriptionFlow.gp.titleWithDependant', {
              dependantName: dependant?.firstName,
            })}
            submitButton={{
              route: `${routes.DEPENDANT.PRESCRIPTION.ONBOARDING.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.EXEMPTION}`,
              label: t('PrescriptionFlow.gp.submitButton'),
            }}
            progressBar={{ numberOfStages: 2, currentStage: 1 }}
          />
        </SuggestProvider>
      </PrivateRoute>

      {/* Onboarding Step 2 */}
      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.PRESCRIPTION.EXEMPTION}`}
        exact
      >
        <PrescriptionExemption
          dependantId={dependantId}
          heading={t('PrescriptionFlow.exemption.titleWithDependant', {
            dependantName: dependant?.firstName,
          })}
          secondaryHeading={t(
            'PrescriptionFlow.exemptionCert.titleWithDependant',
            {
              dependantName: dependant?.firstName,
            },
          )}
          progressBar={{ numberOfStages: 2, currentStage: 2 }}
          skipButton={{
            route: `${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.COMPLETED}`,
            label: t('PrescriptionFlow.exemptionCert.noButton'),
          }}
          yesButton={{
            label: t('PrescriptionFlow.exemptionCert.yesButtonWithDependant'),
            route: `${routes.DEPENDANT.PRESCRIPTION.ONBOARDING.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.EXEMPTION_SELECT}`,
          }}
        />
      </PrivateRoute>

      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.PRESCRIPTION.EXEMPTION_SELECT}`}
        exact
      >
        <PrescriptionExemptionList
          dependantId={dependantId}
          heading={
            dependantExemptionDetails?.exemptionReasonId
              ? t('Account.exemption.titleWithDependant')
              : t('PrescriptionFlow.exemptionReason.titleWithDependant', {
                  dependantName: dependant?.firstName,
                })
          }
          progressBar={{ currentStage: 2, numberOfStages: 2 }}
          exemptionExpireRoute={`${routes.DEPENDANT.PRESCRIPTION.ONBOARDING.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.EXEMPTION_EXP}`}
          exemptionNoExpireRoute={`${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.COMPLETED}`}
        />
      </PrivateRoute>

      <PrivateRoute
        path={`${baseRoute}${routes.DEPENDANT.PRESCRIPTION.EXEMPTION_EXP}`}
        exact
      >
        <PrescriptionExemptionExpire
          dependantId={dependantId}
          heading={t('PrescriptionFlow.exemptionExpiry.titleWithDependant', {
            dependantName: dependant?.firstName,
          })}
          selectExemptionRoute={`${routes.DEPENDANT.PRESCRIPTION.ONBOARDING.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.EXEMPTION_SELECT}`}
          submitButton={{
            label: t('PrescriptionFlow.exemptionReason.submitButton'),
            route: `${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}${routes.DEPENDANT.PRESCRIPTION.COMPLETED}`,
          }}
          progressBar={{ numberOfStages: 2, currentStage: 2 }}
        />
      </PrivateRoute>
    </Switch>
  );
};
