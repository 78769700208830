import React from 'react';
import classNames from 'classnames';
interface ColAttributes {
  size: boolean | number | string;
  order?: string | number;
  offset: string | number;
}

export type supportedTags = 'div' | 'section' | 'ul' | 'ol';
export type colAttrTypes = boolean | number | string | ColAttributes;
export type colSizeTypes = boolean | number | string | undefined;
export interface ColProps {
  tag?: supportedTags;
  xs?: colAttrTypes;
  sm?: colAttrTypes;
  md?: colAttrTypes;
  lg?: colAttrTypes;
  xl?: colAttrTypes;
  className?: string;
  testId?: string;
  children?: React.ReactNode;
}

export const Col = ({
  tag = 'div',
  xs,
  sm,
  md,
  lg,
  xl,
  className,
  children,
  testId,
  ...attributes
}: ColProps) => {
  const widths: string[] = ['xs', 'sm', 'md', 'lg', 'xl'];
  const colProps = [xs, sm, md, lg, xl];
  const colClasses: string[] = [];
  const Component = tag;

  const getColumnSizeClass = (
    isXs: boolean,
    colWidth: string,
    colSize: colSizeTypes,
  ) => {
    if (colSize === true || colSize === '') {
      return isXs ? 'col' : `col-${colWidth}`;
    } else if (colSize === 'auto') {
      return isXs ? 'col-auto' : `col-${colWidth}-auto`;
    }

    return isXs ? `col-${colSize}` : `col-${colWidth}-${colSize}`;
  };

  widths.forEach((colWidth: string, i) => {
    const column = colProps[i];

    if (!column && column !== '') {
      if (i === 0) {
        colClasses.push('col');
      }
      return;
    }
    //if is smallest size
    const isXs = !i;

    if (typeof column === 'object' && column !== null) {
      const colSize = isXs ? '-' : `-${colWidth}-`;

      const colClass = getColumnSizeClass(isXs, colWidth, column.size);

      colClasses.push(
        classNames({
          [colClass]: column.size || column.size === '',
          [`order${colSize}${column.order}`]:
            column.order || column.order === 0,
          [`offset${colSize}${column.offset}`]:
            column.offset || column.offset === 0,
        }),
      );
    } else {
      const colClass = getColumnSizeClass(isXs, colWidth, column);
      colClasses.push(colClass);
    }
  });

  const classes = classNames(className, colClasses);

  return (
    <Component
      {...attributes}
      className={classes}
      data-testid={testId ? testId : null}
    >
      {children}
    </Component>
  );
};
