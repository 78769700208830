import React from 'react';
import cx from 'classnames';
import { Card, CardType } from 'components/common/Card';
import styles from './CardList.module.scss';

interface CardListProps {
  testId?: string;
  list: CardType[];
  className?: string;
}

export const CardList: React.FC<CardListProps> = ({
  list,
  className,
  testId,
}) => {
  return (
    <ul className={cx(styles['card-list'], className)} data-testid={testId}>
      {list.map((item, index) => {
        return (
          <li key={index}>
            <Card
              item={item}
              className={cx(styles['card-list__card'], {
                [styles['card-list__card--has-icons']]: item.icon,
              })}
            />
          </li>
        );
      })}
    </ul>
  );
};
