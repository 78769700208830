import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { ProductSummary } from 'models/Product';
import { Container, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { ProductList } from 'components/Products/ProductList';
import { Text } from 'components/common/Text';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';
import { decodeHTMLEntities } from 'utils/transformers';
import { routes } from 'routes';
import cx from 'clsx';

import styles from './ProductTileSection.module.scss';

interface ProductTileSectionProps {
  title?: string;
  bodyText?: string;
  products: ProductSummary[];
  classNames?: {
    section?: string;
    productList?: string;
    col?: string;
  };
  testId?: string;
  viewAllLinkRequired?: boolean;
  showCardsWithShadow?: boolean;
  productParentId?: string;
}

export const ProductTileSection = ({
  title,
  bodyText,
  products,
  classNames,
  testId,
  viewAllLinkRequired,
  productParentId,
  showCardsWithShadow,
}: ProductTileSectionProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logSectionView } = useAmplitudeContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.productTileSection.section'),
      });
    }
  }, [isInViewport]);

  return (
    <div ref={elementRef}>
      <Container
        testId={testId}
        className={cx(styles['product-tile-section'], classNames?.section)}
      >
        <Col className={classNames?.col}>
          {title && (
            <Heading
              tag="h3"
              size="md"
              className={styles['product-tile-section__title']}
            >
              {decodeHTMLEntities(title)}
            </Heading>
          )}
          {bodyText && (
            <Text
              size="lg"
              className={styles['product-tile-section__body-text']}
            >
              {bodyText}
            </Text>
          )}
          <ProductList
            testId="product-list"
            products={products}
            baseUrl="/product"
            className={classNames?.productList}
            showCardsWithShadow={showCardsWithShadow}
          />
          {viewAllLinkRequired && (
            <Link
              to={{
                pathname: `${routes.SHOP.CONDITION}/${productParentId}`,
              }}
              className={styles['product-tile-section']}
            >
              <p className={styles['product-tile-section__view-more']}>
                {t('OrderFlow.ProductDetail.viewAll')}
                <ChevronRight
                  className={styles['product-tile-section__view-more__icon']}
                />
              </p>
            </Link>
          )}
        </Col>
      </Container>
    </div>
  );
};
