import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToastContext } from 'contexts/ToastContext';
import { useApiError } from 'hooks/useApiError';
import { PaymentConfig as PaymentConfigType } from 'models/PaymentConfig';
import { BranchStatus } from 'components/common/Branch/Branch';
import { fetchPaymentConfig } from 'api/Payment';

export interface PaymentContextLoading {
  getPaymentConfigStatus?: BranchStatus;
}

export interface PaymentContextProps {
  getPaymentConfig: Function;
  paymentConfig: PaymentConfigType | string | null;
  loading: PaymentContextLoading;
  statusCode: number | null;
  children?: React.ReactNode;
}

export const PaymentContextDefaults: PaymentContextProps = {
  getPaymentConfig: Function,
  paymentConfig: null,
  loading: {},
  statusCode: null,
};

export const PaymentContext = React.createContext<PaymentContextProps>(
  PaymentContextDefaults,
);

export const usePaymentContext = (): PaymentContextProps =>
  React.useContext(PaymentContext);

interface PaymentProviderProps {
  children?: React.ReactNode;
}

export const PaymentProvider = ({ children }: PaymentProviderProps) => {
  const { handleApiError } = useApiError();
  const { setToast } = useToastContext();
  const { t } = useTranslation();

  const [paymentConfig, setPaymentConfig] = React.useState<
    PaymentConfigType | string | null
  >(null);
  const [loading, setLoading] = React.useState<PaymentContextLoading>({
    getPaymentConfigStatus: 'idle',
  });
  const [statusCode, setStatusCode] = React.useState<number | null>(null);

  const errors = {
    title: t('common.error.genericTitle'),
    message: t('common.error.genericMessage'),
  };

  const errorWithToast = () => {
    setToast({
      status: 'error',
      title: errors.title,
      description: errors.message,
    });
    setLoading({
      getPaymentConfigStatus: 'error',
    });
  };

  const getPaymentConfig = async (orderId: number) => {
    try {
      setLoading({
        getPaymentConfigStatus: 'loading',
      });
      const result = await fetchPaymentConfig(orderId);
      setPaymentConfig(result.data);
      setStatusCode(result.status);
      setLoading({
        getPaymentConfigStatus: 'finished',
      });
    } catch (error) {
      handleApiError(() => {
        errorWithToast();
      }, error);
    }
  };
  return (
    <PaymentContext.Provider
      value={{ getPaymentConfig, paymentConfig, loading, statusCode }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
