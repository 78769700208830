import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApiError } from 'hooks/useApiError';
import { useToastContext } from 'contexts/ToastContext';
import { Condition } from 'models/Condition';
import {
  getConditions as getConditionsAPI,
  getCondition as getConditionAPI,
  getConditionGroup as getConditionGroupAPI,
  getConditionGroups as getConditionGroupsAPI,
  getConditionsSearch as getConditionSearchAPI,
} from 'api/Conditions';
import { BranchStatus } from 'components/common/Branch/Branch';
import { ConditionGroup } from 'models/Condition/Condition';

export interface ConditionsContextLoading {
  getConditionStatus?: BranchStatus;
  getConditionsStatus?: BranchStatus;
  getConditionGroupStatus?: BranchStatus;
  getConditionGroupsStatus?: BranchStatus;
  conditionSearchStatus?: BranchStatus;
}

export interface ConditionsContextProps {
  condition: Condition | null;
  conditions: Condition[] | null;
  getCondition: (conditionId: number) => void;
  getConditions: () => void;
  getConditionsSearch: (
    searchString: string,
    top: number,
    skip: number,
  ) => void;
  setCondition: React.Dispatch<React.SetStateAction<Condition | null>>;
  setConditions: React.Dispatch<React.SetStateAction<Condition[] | null>>;
  conditionGroup?: ConditionGroup | null;
  conditionGroups: ConditionGroup[] | null;
  getConditionGroup: (conditionGroupId: number) => void;
  getConditionGroups: () => void;
  setConditionGroup: React.Dispatch<
    React.SetStateAction<ConditionGroup | null | undefined>
  >;
  loading: ConditionsContextLoading;
  children?: React.ReactNode;
}

export const ConditionsContextDefaults: ConditionsContextProps = {
  condition: null,
  conditions: [],
  getCondition: () => ({}),
  getConditions: () => ({}),
  getConditionsSearch: () => ({}),
  setCondition: () => ({}),
  setConditions: () => ({}),
  conditionGroup: null,
  conditionGroups: [],
  getConditionGroup: () => ({}),
  getConditionGroups: () => ({}),
  setConditionGroup: () => ({}),
  loading: {},
};

export const ConditionsContext = React.createContext<ConditionsContextProps>(
  ConditionsContextDefaults,
);

export const useConditionsContext = (): ConditionsContextProps =>
  React.useContext(ConditionsContext);

interface ConditionsProviderProps {
  children?: React.ReactNode;
}

export const ConditionsProvider = ({ children }: ConditionsProviderProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const { handleApiError } = useApiError();

  const [condition, setCondition] = React.useState<Condition | null>(null);
  const [conditions, setConditions] = React.useState<Condition[] | null>(null);
  const [conditionGroup, setConditionGroup] =
    React.useState<ConditionGroup | null>();
  const [conditionGroups, setConditionGroups] = React.useState<
    ConditionGroup[] | null
  >(null);
  const [loading, setLoading] = React.useState<ConditionsContextLoading>({
    getConditionStatus: 'loading',
    getConditionsStatus: 'loading',
    getConditionGroupStatus: 'loading',
    getConditionGroupsStatus: 'loading',
    conditionSearchStatus: 'loading',
  });

  const errors = {
    title: t('common.error.genericTitle'),
    message: t('common.error.genericMessage'),
  };

  const errorWithToast = () => {
    setToast({
      status: 'error',
      title: errors.title,
      description: errors.message,
    });
  };

  const getCondition = async (conditionId: number): Promise<void> => {
    try {
      setLoading({ ...loading, getConditionStatus: 'loading' });
      const result = await getConditionAPI(conditionId);
      setCondition(result);
      setLoading({
        ...loading,
        getConditionStatus: 'finished',
      });
    } catch (error) {
      handleApiError(() => {
        setLoading({ ...loading, getConditionStatus: 'error' });
        errorWithToast();
      }, error);
    }
  };

  const getConditions = async (): Promise<void> => {
    try {
      setLoading({ ...loading, getConditionsStatus: 'loading' });
      const result = await getConditionsAPI();
      setConditions(result.items);
      setLoading({ ...loading, getConditionsStatus: 'finished' });
    } catch (error) {
      handleApiError(() => {
        setLoading({ ...loading, getConditionsStatus: 'error' });
        errorWithToast();
      }, error);
    }
  };

  const getConditionsSearch = async (
    searchString: string,
    top: number,
    skip: number,
  ): Promise<void> => {
    try {
      setLoading({ ...loading, conditionSearchStatus: 'loading' });
      const result = await getConditionSearchAPI(searchString, top, skip);
      setConditions(result.items);
      setLoading({ ...loading, conditionSearchStatus: 'finished' });
    } catch (error) {
      handleApiError(() => {
        setLoading({ ...loading, conditionSearchStatus: 'error' });
        errorWithToast();
      }, error);
    }
  };

  const getConditionGroup = async (conditionGroupId: number): Promise<void> => {
    try {
      setLoading({ ...loading, getConditionGroupStatus: 'loading' });
      const result = await getConditionGroupAPI(conditionGroupId);
      setConditionGroup(result);
      setLoading({
        ...loading,
        getConditionGroupStatus: 'finished',
      });
    } catch (error) {
      handleApiError(() => {
        setLoading({ ...loading, getConditionGroupStatus: 'error' });
        errorWithToast();
      }, error);
    }
  };

  const getConditionGroups = async (): Promise<void> => {
    try {
      setLoading({ ...loading, getConditionGroupsStatus: 'loading' });
      const result = await getConditionGroupsAPI();
      setConditionGroups(result.items);
      setLoading({ ...loading, getConditionGroupsStatus: 'finished' });
    } catch (error) {
      handleApiError(() => {
        setLoading({ ...loading, getConditionGroupsStatus: 'error' });
        errorWithToast();
      }, error);
    }
  };

  const value = {
    condition,
    conditions,
    getCondition,
    getConditions,
    getConditionsSearch,
    setCondition,
    setConditions,
    conditionGroup,
    conditionGroups,
    getConditionGroup,
    getConditionGroups,
    setConditionGroup,
    loading,
  };

  return (
    <ConditionsContext.Provider value={value}>
      {children}
    </ConditionsContext.Provider>
  );
};
