import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDependantContext } from 'contexts/DependantContext';
import { Container } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { routes } from 'routes';
import { ReactComponent as GP } from 'assets/illustrations/gp.svg';
import { usePatientContext } from 'contexts/PatientContext';
import styles from './PrescriptionNextStep.module.scss';

export const PrescriptionNextStep = () => {
  const { dependantId } = useParams<{ dependantId: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const { dependant, getDependant } = useDependantContext();
  const { patientPharmacy, patientGp, patientSCRAccepted } =
    usePatientContext();

  useEffect(() => {
    if (!dependantId && patientPharmacy && patientGp && patientSCRAccepted) {
      history.push(routes.PRESCRIPTION.BASE);
    }
  }, [dependantId, patientPharmacy, patientGp, patientSCRAccepted]);

  useEffect(() => {
    if (dependantId && dependantId !== 'undefined') {
      getDependant(dependantId);
    }
  }, [dependantId]);

  return (
    <Container>
      <div className={styles['prescription-next-steps']}>
        <GP className={styles['prescription-next-steps__illustration']} />
        <Heading
          tag="h3"
          className={styles['prescription-next-steps__heading']}
        >
          {dependantId
            ? t('PrescriptionFlow.next.dependantTitle', {
                name: dependant?.firstName,
              })
            : t('PrescriptionFlow.next.title')}
        </Heading>
      </div>
      <div className={styles['prescription-next-steps__ctas']}>
        <Button
          label={t('PrescriptionFlow.next.cta')}
          onClick={() => {
            history.push(
              dependantId
                ? `${routes.DEPENDANT.PRESCRIPTION.ONBOARDING.BASE}${dependantId}${routes.SELECT_GP}`
                : routes.PRESCRIPTION.ONBOARDING.REVIEW_CONTACT,
            );
          }}
        />
      </div>
    </Container>
  );
};
