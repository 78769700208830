import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { routes } from 'routes';
import { useDependantContext } from 'contexts/DependantContext';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { Icon } from 'components/common/Icon';
import { ReactComponent as DependantImage } from 'assets/illustrations/dependant.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';
import { ReactComponent as SwitchIcon } from 'assets/icons/lawsat/Switch.svg';
import styles from './DependantAdded.module.scss';

export const DependantAdded = () => {
  const { t } = useTranslation();
  const { dependant, getDependant } = useDependantContext();
  const { dependantId } = useParams<{ dependantId: string }>();

  React.useEffect(() => {
    if (!dependant) {
      getDependant(dependantId);
    }
  }, []);

  const dependantFullname = `${dependant?.firstName} ${dependant?.lastName}`;
  const dependantBaseUrl = `${routes.DEPENDANT.PRESCRIPTION.BASE}${dependant?.id}`;

  return (
    <Container className={styles['patient-dependant-added']}>
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <div className={styles['patient-dependant-added__content']}>
            <DependantImage
              className={styles['patient-dependant-added__image']}
            />
            <Heading
              tag="h2"
              size="xl"
              className={styles['patient-dependant-added__title']}
            >
              {t('Dependant.added.title')}
            </Heading>
            <Text
              size="lg"
              tag="p"
              className={styles['patient-dependant-completed__text']}
            >
              {t('Dependant.added.text', {
                dependant: dependant?.firstName,
              })}
            </Text>
            <div className={styles['patient-dependant-added__link-container']}>
              <Link
                className={styles['patient-dependant-added__link']}
                to={`${dependantBaseUrl}${routes.DEPENDANT.PRESCRIPTION.ONBOARDING.NEXT}`}
              >
                <div
                  className={styles['patient-dependant-added__link-content']}
                >
                  <Icon icon={SwitchIcon} />
                  <Heading
                    size="sm"
                    tag="h3"
                    className={styles['patient-dependant-added__link-text']}
                  >
                    {t('Dependant.added.switch', {
                      dependant: dependantFullname,
                    })}
                  </Heading>
                  <Icon
                    className={styles['patient-dependant-added__arrow']}
                    icon={ChevronRight}
                  />
                </div>
              </Link>
            </div>
            <Button
              className={styles['patient-dependant-added__button']}
              label={t('Dependant.added.later')}
              width="grow"
              variant="accent"
              appearance="solid"
              link={{
                to: routes.ACCOUNT.BASE,
              }}
            />
            <Text
              size="sm"
              tag="div"
              className={styles['patient-dependant-added__info']}
            >
              {t('Dependant.added.info', {
                dependant: dependant?.firstName,
              })}
            </Text>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
