import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { Redirect, useParams } from 'react-router-dom';

import { HepatitisADescription } from './VaccinationDescriptions/HepatitisADescription/HepatitisADescription';
import { MalariaDescription } from './VaccinationDescriptions/MalariaDescription/MalariaDescription';
import { TyphoidDescription } from './VaccinationDescriptions/TyphoidDescription/TyphoidDescription';
import { YellowFeverDescription } from './VaccinationDescriptions/YellowFeverDescription/YellowFeverDescription';
import { JapaneseEncephalitisDescription } from './VaccinationDescriptions/JapaneseEncephalitisDescription/JapaneseEncephalitisDescription';
import { RabiesDescription } from './VaccinationDescriptions/RabiesDescription/RabiesDescription';
import { TickBorneEncephalitisDescription } from './VaccinationDescriptions/TickBorneEncephalitisDescription/TickBorneEncephalitisDescription';
import { CholeraDescription } from './VaccinationDescriptions/CholeraDescription/CholeraDescription';
import { Container } from 'components/common/grid/Container';
import { CTALinkCard } from 'components/CTALinkCard/CTALinkCard';
import { BannerSection } from 'components/Sections/Common/BannerSection';
import { Crumb, Breadcrumbs } from 'components/common/Breadcrumbs';
import { Row } from 'components/common/grid/Row';
import { Col } from 'components/common/grid/Col';

import styles from './VaccinationInfoPage.module.scss';

export const formatVaccineName = (vaccine: string) => {
  switch (vaccine) {
    case 'hepatitis-a':
      return 'Hepatitis A';
    case 'malaria':
      return 'Malaria';
    case 'typhoid':
      return 'Typhoid';
    case 'yellow-fever':
      return 'Yellow Fever';
    case 'japanese-encephalitis':
      return 'Japanese encephalitis';
    case 'rabies':
      return 'Rabies';
    case 'tick-borne-encephalitis':
      return 'Tick-borne encephalitis';
    case 'cholera':
      return 'Cholera';
    default:
      return 'Travel vaccinations';
  }
};

export const getVaccineInfo = (vaccine: string) => {
  switch (vaccine) {
    case 'hepatitis-a':
      return <HepatitisADescription />;
    case 'malaria':
      return <MalariaDescription />;
    case 'typhoid':
      return <TyphoidDescription />;
    case 'yellow-fever':
      return <YellowFeverDescription />;
    case 'japanese-encephalitis':
      return <JapaneseEncephalitisDescription />;
    case 'rabies':
      return <RabiesDescription />;
    case 'tick-borne-encephalitis':
      return <TickBorneEncephalitisDescription />;
    case 'cholera':
      return <CholeraDescription />;
    default:
      return <Redirect to={routes.TRAVELVACCINATIONS.BASE} />;
  }
};

export const VaccinationInfoPage = () => {
  const { vaccine = '' } = useParams<{ vaccine: string }>();
  const { t } = useTranslation();

  const headingSuffix = ' - Everything you need to know';
  const formattedVaccine = formatVaccineName(vaccine);

  const breadcrumbs: Crumb[] = [
    {
      route: routes.TRAVELVACCINATIONS.BASE,
      text: t('travelVaccinations.travelBreadcrumb'),
    },
    {
      route: `${routes.TRAVELVACCINATIONS.INFO}/${vaccine}`,
      text: formattedVaccine,
    },
  ];

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <BannerSection
        heading={formattedVaccine.concat(headingSuffix)}
        variant="condensed"
        colProps={{
          textCol: { xl: { size: 12, offset: 0 } },
        }}
      />
      <Container className={styles['vaccination-info__container']}>
        <Row>
          <Col
            xs={{ size: 12, offset: 0, order: 1 }}
            lg={{ size: 8, offset: 0, order: 0 }}
          >
            {getVaccineInfo(vaccine)}
            <br />
            <CTALinkCard
              title={t('travelVaccinations.readyToBook')}
              button={{
                link: routes.TRAVELVACCINATIONS.BOOK,
                label: t('travelVaccinations.bookCtaLabel'),
              }}
            />
          </Col>
          <Col
            xs={{ size: 12, offset: 0, order: 0 }}
            lg={{ size: 4, offset: 0, order: 1 }}
            className={styles['vaccination-info__cta-one']}
          >
            <CTALinkCard
              title={t('travelVaccinations.readyToBook')}
              button={{
                link: routes.TRAVELVACCINATIONS.BOOK,
                label: t('travelVaccinations.bookCtaLabel'),
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
