import { AppBasket, LocalStorageBasket } from 'models/Basket';
import { getBasketOrderlineSubtotal } from './HelperFunctions';
import { LOCAL_STORAGE_BASKET_KEY } from '../BasketContext';

export const updateDerivedBasketState = (basket: AppBasket): AppBasket => {
  return {
    ...basket,
    subTotal:
      getBasketOrderlineSubtotal(basket.shopProducts) +
      getBasketOrderlineSubtotal(basket.prescriptionProducts),
    subTotals: {
      shopProducts: getBasketOrderlineSubtotal(basket.shopProducts),
      prescriptionProducts: getBasketOrderlineSubtotal(
        basket.prescriptionProducts,
      ),
    },
    hasPmeds: basket.shopProducts.filter((item) => item.isPMed).length > 0,
    hasPrescriptions: basket.prescriptionProducts.length > 0,
    allPmedFormsComplete: basket.shopProducts
      .filter((item) => item.isPMed)
      .reduce(
        (runningCheck: boolean, item) =>
          runningCheck ? item.pMedQuestionComplete ?? false : runningCheck,
        true,
      ),
  };
};

export const getBasketForSaving = ({
  basket,
  expiresAt,
}: {
  basket: AppBasket;
  expiresAt: number | null;
}): LocalStorageBasket => ({
  shopProducts: basket.shopProducts,
  prescriptionProducts: basket.prescriptionProducts,
  pmedForms: basket.pmedForms,
  pomForms: basket.pomForms,
  expiresAt,
});

export const saveBasketToLocalStorage = (
  basketForSaving: LocalStorageBasket,
) => {
  localStorage.setItem(
    LOCAL_STORAGE_BASKET_KEY,
    JSON.stringify(basketForSaving),
  );
};

export const removeBasketFromLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_BASKET_KEY);
};
