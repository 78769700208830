import cx from 'classnames';
import { OrderLineSummary } from 'components/OrderLineSummary';
import { Heading } from 'components/common/Heading';
import { BasketOrderline } from 'models/Basket';
import { useTranslation } from 'react-i18next';

import styles from './CheckoutOrderSummary.module.scss';

interface CheckoutOrderSummaryProps {
  products: BasketOrderline[];
  subTotal: number;
  deliveryCost: number;
}

export const CheckoutOrderSummary = ({
  products,
  subTotal,
  deliveryCost,
}: CheckoutOrderSummaryProps) => {
  const { t } = useTranslation();
  const isPrescriptionSummary = products[0].isPrescriptionItem;

  return (
    <div className={styles['order-summary']}>
      <div
        className={cx(
          styles['order-summary__product-list-headings'],
          isPrescriptionSummary &&
            styles['order-summary__product-list-headings--prescription-only'],
        )}
      >
        <Heading
          size="sm"
          className={styles['order-summary__product-list-headings__col1']}
        >
          {t('CheckoutPage.summaryHeadings.item')}
        </Heading>
        {!isPrescriptionSummary && (
          <Heading
            size="sm"
            className={styles['order-summary__product-list-headings__col2']}
          >
            {t('CheckoutPage.summaryHeadings.quantity')}
          </Heading>
        )}
        <Heading
          size="sm"
          className={styles['order-summary__product-list-headings__col3']}
        >
          {t('CheckoutPage.summaryHeadings.price')}
        </Heading>
      </div>

      <ul className={styles['order-summary__product-list']}>
        {products.map((product) => (
          <li
            key={product.id}
            className={styles['order-summary__product-list-item']}
          >
            <OrderLineSummary item={product} />
          </li>
        ))}
      </ul>

      <div
        className={cx(
          styles['order-summary__subtotal-line'],
          isPrescriptionSummary &&
            styles['order-summary__subtotal-line--prescription-only'],
        )}
      >
        <Heading
          size="sm"
          className={cx(styles['order-summary__subtotal-line__name'], {
            [`${styles['order-summary__subtotal-line__name--short']}`]:
              isPrescriptionSummary,
          })}
        >
          {t('CheckoutPage.subtotal')}
        </Heading>
        <Heading
          size="sm"
          className={styles['order-summary__subtotal-line__value']}
        >
          £{subTotal?.toFixed(2)}
        </Heading>
      </div>
      <div
        className={cx(
          styles['order-summary__subtotal-line'],
          isPrescriptionSummary &&
            styles['order-summary__subtotal-line--prescription-only'],
          styles['mb-400'],
        )}
      >
        <Heading
          size="sm"
          className={cx(styles['order-summary__subtotal-line__name'], {
            [`${styles['order-summary__subtotal-line__name--short']}`]:
              isPrescriptionSummary,
          })}
        >
          {t('CheckoutPage.includeDelivery')}
        </Heading>
        <Heading
          size="sm"
          className={styles['order-summary__subtotal-line__value']}
        >
          £{(subTotal + deliveryCost).toFixed(2)}
        </Heading>
      </div>
    </div>
  );
};
