import React from 'react';
import cx from 'classnames';
import {
  useRadioState,
  Radio,
  RadioGroup as ReakitRadioGroup,
} from 'reakit/Radio';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Label } from 'components/common/Label';
import { Icon } from 'components/common/Icon';
import { ReactComponent as Checkmark } from 'assets/icons/lawsat/checkmark.svg';
import styles from './SelectList.module.scss';

export type listValues = {
  value: string;
  label: string;
  title?: string;
  prefix?: string;
};

interface SelectListProps {
  id: string;
  value?: string;
  listValues?: listValues[];
  onChange?: (value: string) => void;
  className?: string;
  labelBy?: string;
}

export const SelectListRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  SelectListProps
> = ({ id, listValues, onChange, value, className, labelBy }, ref) => {
  const radio = useRadioState({
    state: value,
    orientation: 'vertical',
    wrap: true,
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className={cx(styles['select-list'], { [`${className}`]: className })}>
      <ReakitRadioGroup
        {...radio}
        className={styles['select-list__list']}
        baseId={id}
        aria-labelledby={labelBy}
      >
        {listValues &&
          listValues.map((item, index) => {
            return (
              <Label
                className={cx(styles['select-list__label'], {
                  [styles['select-list__label--has-prefix']]: item.prefix,
                })}
                key={index}
              >
                <Radio
                  {...radio}
                  className={styles['select-list__radio']}
                  value={item.value}
                  ref={ref}
                  data-testid={`${id}-${index}`}
                  id={`${id}-${index}`}
                  name={`${id}-${index}`}
                  onChange={handleOnChange}
                />
                <Icon
                  className={styles['select-list__tick']}
                  icon={Checkmark}
                />
                {item.prefix && (
                  <span className={styles['select-list__prefix']}>
                    {item.prefix}
                  </span>
                )}
                {item.title && (
                  <Heading
                    size="sm"
                    tag="h4"
                    className={styles['select-list__title']}
                  >
                    {item.title}
                  </Heading>
                )}
                <Text
                  className={styles['select-list__text']}
                  size={item.title ? 'md' : 'lg'}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.label }}></span>
                </Text>
                <span className={styles['select-list__faux-background']}></span>
              </Label>
            );
          })}
      </ReakitRadioGroup>
    </div>
  );
};

export const SelectList = React.forwardRef(SelectListRef);
