import * as yup from 'yup';
import i18n from 'i18next';

export const pMedQuestionnaireSchema = (): yup.ObjectSchema => {
  const requiredError = i18n.t('common.formErrors.required');
  const radioRequiredError = i18n.t('common.formErrors.radioRequired');
  const maxLengthError = i18n.t('common.formErrors.maxLength', { max: 1000 });

  return yup.object().shape({
    whoWillUseIt: yup
      .string()
      .nullable()
      .oneOf(['Myself', 'SomeoneElse'], radioRequiredError)
      .required(requiredError),
    isUserPregnant: yup.boolean().nullable().required(radioRequiredError),
    isUserBreastFeeding: yup.boolean().nullable().required(radioRequiredError),
    userAgeInYears: yup
      .number()
      .required(requiredError)
      .typeError(i18n.t('common.formErrors.number'))
      .integer(i18n.t('common.formErrors.integer'))
      .min(0, i18n.t('common.formErrors.minValue', { min: 0 }))
      .max(150, i18n.t('common.formErrors.maxValue', { max: 150 })),
    conditionsSymptomsUsedFor: yup
      .string()
      .required(requiredError)
      .min(1, i18n.t('common.formErrors.minLength', { min: 1 }))
      .max(1000, maxLengthError)
      .trim(),
    userSymptomsDuration: yup
      .string()
      .nullable()
      .oneOf(
        ['LessThanAWeek', 'OneToThreeWeeks', 'OverThreeWeeks'],
        radioRequiredError,
      )
      .required(requiredError),
    userExistingConditions: yup.string().max(1000, maxLengthError).trim(),
    userOtherMedications: yup.string().max(1000, maxLengthError).trim(),
    additionalComments: yup.string().max(1000, maxLengthError).trim(),
    termsConditionsConfirm: yup
      .boolean()
      .nullable()
      .required(i18n.t('common.formErrors.termsAndConditions')),
  });
};
