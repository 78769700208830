import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Heading } from 'components/common/Heading';
import { Container } from 'components/common/grid';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';

import styles from './PillLinkSection.module.scss';

interface PillLink {
  label: string;
  route: string;
}

interface PillLinkSectionProps {
  heading: string;
  links: PillLink[];
  className?: string;
}

export const PillLinkSection = ({
  heading,
  links,
  className,
}: PillLinkSectionProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logSectionView } = useAmplitudeContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.pillLinkSection.sectionName'),
      });
    }
  }, [isInViewport]);

  return (
    <div
      className={cx(styles['pill-link-section'], className)}
      ref={elementRef}
    >
      <Container className={styles['pill-link-section__container']}>
        <Heading tag="h2" className={styles['pill-link-section__heading']}>
          {heading}
        </Heading>
        <div className={styles['pill-link-section__links']}>
          {links.map((link) => (
            <PillLink link={link} key={link.label} />
          ))}
        </div>
      </Container>
    </div>
  );
};

interface PillLinkProps {
  link: PillLink;
}

const PillLink = ({ link }: PillLinkProps) => {
  const { t } = useTranslation();
  const { pageName } = useLandingPageContext();
  const { logCtaClick } = useAmplitudeContext();

  return (
    <Link
      to={link.route}
      className={styles['pill-link']}
      onClick={() =>
        logCtaClick({
          label: link.label,
          pageName: pageName?.log,
          section: t('amplitude.pillLinkSection.section'),
        })
      }
    >
      <Heading tag="h6" className={styles['pill-link__label']}>
        {link.label}
      </Heading>
      <ChevronRight className={styles['pill-link__icon']} />
    </Link>
  );
};
