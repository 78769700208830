import axios from 'axios';
import { useHistory } from 'react-router';
import { routes } from 'routes';

interface UseApiErrorInterface {
  handleApiError: (handle: Function, error?: unknown) => void;
}

export const useApiError = (): UseApiErrorInterface => {
  const navigate = useHistory();
  const handleApiError = (handle: Function, error?: unknown) => {
    if (axios.isAxiosError(error)) {
      error.response?.data?.detail === 'Patient does not exist' // this means the user has signed up but not completed onboarding
        ? navigate.push(routes.SIGN_UP.BASE)
        : error.response?.status !== 401 && handle();
    }
  };
  return { handleApiError };
};
