import React from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import { MedResult } from 'api/MedSuggest';
import { AppBarBottom } from 'components/Layout/AppBarBottom';
import { AppBarTop } from 'components/Layout/AppBarTop';
import { AutoComplete } from 'components/common/AutoComplete';
import { Button } from 'components/common/Button';
import { Col, Container, Row } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { IconInfoSpan } from 'components/common/IconInfoSpan';
import { listValues, SelectList } from 'components/common/SelectList';
import { useModalContext } from 'contexts/ModalContext';
import { useDependantContext } from 'contexts/DependantContext';
import { usePrescriptionContext } from 'contexts/PrescriptionContext';
import { useSuggestContext } from 'contexts/SuggestContext';
import { useToastContext } from 'contexts/ToastContext';
import { usePatientContext } from 'contexts/PatientContext';
import { ReactComponent as Phone } from 'assets/icons/lawsat/Phone.svg';
import { ReactComponent as AddPrescriptionImage } from 'assets/illustrations/prescription-add.svg';

import { routes } from 'routes';
import styles from './PrescriptionAdd.module.scss';

export const convertList = (originalList: MedResult[]): listValues[] => {
  return originalList.map((item) => {
    return {
      value: item.Id,
      label: item['@search.text'],
    };
  });
};

export const PrescriptionAdd = () => {
  const { t } = useTranslation();
  const { getMedlist, medList, setMedList } = useSuggestContext();
  const { setToast } = useToastContext();
  const { addPatientPrescription, getPatientPrescriptions } =
    usePrescriptionContext();
  const {
    getDependantPrescriptions,
    addDependantPrescription,
    dependantPharmacy,
    getDependantPharmacy,
  } = useDependantContext();
  const { patientPharmacy, getPatientPharmacy } = usePatientContext();
  const { open, close } = useModalContext();
  const { dependantId } = useParams<{ dependantId: string }>();

  const schema = yup.object().shape({
    selectedMed: yup.string().required(),
  });

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('PrescriptionFlow.add.errorTitle'),
    });
  };

  const onSubmit = async () => {
    if (dependantId) {
      await addDependantPrescription(dependantId, getValues('selectedMed'));
      await getDependantPrescriptions(dependantId);
    } else {
      await addPatientPrescription(getValues('selectedMed'));
      await getPatientPrescriptions();
    }
  };

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    getMedlist();
    if (dependantId) {
      getDependantPharmacy(dependantId);
    } else {
      getPatientPharmacy();
    }
  }, []);

  const pharmacy = dependantId ? dependantPharmacy : patientPharmacy;

  return (
    <>
      <AppBarTop
        closeRoute={{
          to: dependantId
            ? `${routes.DEPENDANT.PRESCRIPTION.BASE}${dependantId}`
            : routes.PRESCRIPTION.BASE,
          text: t('PrescriptionFlow.completed.back'),
        }}
        title={t('PrescriptionFlow.add.title')}
      />
      <Container className={styles['prescription-add']}>
        <Row>
          <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <Heading
              tag="h2"
              size="xl"
              className={styles['prescription-add__title']}
            >
              {t('PrescriptionFlow.add.title')}
            </Heading>
            <AutoComplete
              getResults={(str: string) => {
                getMedlist(str);
              }}
              clearResults={() => {
                setMedList([]);
              }}
              placeholder={t('PrescriptionFlow.add.placeholder')}
              label={t('PrescriptionFlow.add.placeholder')}
            />
            {medList.length === 0 && (
              <div
                className={styles['prescription-add__content']}
                data-testid="empty-image"
              >
                <AddPrescriptionImage
                  className={styles['prescription-add__image']}
                />
              </div>
            )}
            <form
              data-testid="gp-form"
              onSubmit={handleSubmit(onSubmit, invalid)}
            >
              {medList.length > 0 && (
                <Controller
                  control={control}
                  defaultValue=""
                  name="selectedMed"
                  isRequired
                  render={(props) => (
                    <>
                      <SelectList
                        id="selectedMed"
                        listValues={convertList(medList)}
                        {...props}
                      />
                    </>
                  )}
                />
              )}
              <AppBarBottom fullWidth>
                <Button
                  width="full"
                  className={styles['prescription-add__submit-button']}
                  testId="submit-button"
                  type="submit"
                  appearance="solid"
                  label={t('PrescriptionFlow.add.submit')}
                  size="sm"
                />
                <Button
                  width="full"
                  className={styles['prescription-add__help-button']}
                  appearance="ghost"
                  label={
                    dependantId
                      ? t('PrescriptionFlow.add.helpWithDependant')
                      : t('PrescriptionFlow.add.help')
                  }
                  size="sm"
                  onClick={() => {
                    open(
                      <>
                        <Heading tag="h3" size="lg">
                          {t('PrescriptionFlow.add.modalTitle')}
                        </Heading>
                        <Text
                          size="lg"
                          className={styles['prescription-add__modal-text']}
                        >
                          {t('PrescriptionFlow.add.modalText')}
                        </Text>
                        {pharmacy && (
                          <>
                            <Heading
                              tag="h4"
                              size="sm"
                              className={
                                styles['prescription-add__modal-title']
                              }
                            >
                              {`${t('PrescriptionFlow.add.modalPrefix')} ${
                                pharmacy.name
                              }`}
                            </Heading>
                            {pharmacy.phoneNumber && (
                              <IconInfoSpan
                                size="lg"
                                icon={Phone}
                                text={pharmacy.phoneNumber?.toString()}
                              />
                            )}
                          </>
                        )}
                        <Button
                          className={styles['prescription-add__modal-button']}
                          label={t('PrescriptionFlow.add.modalClose')}
                          onClick={() => {
                            close();
                          }}
                        />
                      </>,
                    );
                  }}
                />
              </AppBarBottom>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
