/* eslint-disable sonarjs/no-duplicate-string */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { AcnePomFormSchema } from 'formSchema/PomForms/AcnePomFormSchema';
import {
  AcneAffectedAreas,
  AcneTypesOfSymptoms,
  AcnePomForm as AcnePomFormType,
} from 'models/PomQuestionnaires/Acne';
import { useTranslation } from 'react-i18next';
import { useBasketContext } from 'contexts/BasketContext';
import { useConditionsContext } from 'contexts/ConditionsContext';
import {
  acnePomFormQuestions,
  defaultAcneAffectedAreas,
  defaultAcneTypesOfSymptoms,
} from 'models/PomQuestionnaires/Acne/AcnePomForm';
import {
  ImageSelector,
  MultiSelect,
  SingleCheckbox,
  SingleSelect,
  TextArea,
} from '../PomForm.Components';

interface AcnePomFormProps {
  onSubmit: (
    payload: AcnePomFormType,
    isImageUploadRequired: boolean,
  ) => Promise<void>;
  actionButtons?: ReactNode;
}

export const AcnePomForm = ({ onSubmit, actionButtons }: AcnePomFormProps) => {
  const { t } = useTranslation();
  const { basket } = useBasketContext();
  const { condition } = useConditionsContext();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [shouldValidateImages, setShouldValidateImages] = useState(true); // assume by default that images need validating
  const fields = acnePomFormQuestions;

  const formMethods = useForm({
    resolver: yupResolver(AcnePomFormSchema(shouldValidateImages)),
  });
  const { handleSubmit, watch, setValue, register, reset } = formMethods;

  useEffect(() => {
    const existingForm = basket.pomForms.find(
      (form) => form.conditionId === condition?.id,
    )?.formAnswers;
    if (existingForm && existingForm.conditionId === 1) {
      reset({ ...existingForm });

      // If using Images input
      setImageFilenames(existingForm.imageNames);
      setShouldValidateImages(existingForm.imageNames?.length === 0);

      // Condition Specific Stuff
      setUserAcneSymptoms(existingForm.userAcneSymptoms);
      setAffectedSkinAreas(existingForm.affectedSkinAreas);
    }
  }, [basket.shopProducts]);

  // If using Images input
  const [imageFilenames, setImageFilenames] = useState<string[]>([]);
  const imageFileInputRef = useRef<HTMLInputElement>(null);
  register(fields[9].fieldName);

  // Condition specific stuff
  const [userAcneSymptoms, setUserAcneSymptoms] = useState<AcneTypesOfSymptoms>(
    defaultAcneTypesOfSymptoms,
  );
  const [affectedSkinAreas, setAffectedSkinAreas] = useState<AcneAffectedAreas>(
    defaultAcneAffectedAreas,
  );
  const isTakingAnyMedication = watch(fields[5].fieldName);
  const hasBeenReviewedInLast12Months = watch(fields[7].fieldName);
  setValue(fields[3].fieldName, affectedSkinAreas, {
    shouldValidate: formSubmitted,
  });
  setValue(fields[2].fieldName, userAcneSymptoms, {
    shouldValidate: formSubmitted,
  });

  return (
    <form
      onSubmit={handleSubmit(
        (payload: AcnePomFormType) => {
          setFormSubmitted(true);
          onSubmit(
            { ...payload, conditionId: 1, imageNames: imageFilenames },
            shouldValidateImages,
          );
        },
        () => setFormSubmitted(true),
      )}
    >
      <SingleSelect field={fields[0]} formMethods={formMethods} />

      <SingleSelect
        field={fields[1]}
        formMethods={formMethods}
        orientation="vertical"
        values={[
          {
            value: 'This is the first time',
            label: t('pomForms.generic.labels.howLongSuffering.firstTime'),
          },
          {
            value: 'Less than six months',
            label: t(
              'pomForms.generic.labels.howLongSuffering.lessThanSixMonths',
            ),
          },
          {
            value: 'More than six months',
            label: t(
              'pomForms.generic.labels.howLongSuffering.moreThanSixMonths',
            ),
          },
        ]}
      />

      <MultiSelect
        field={fields[2]}
        defaultValue={defaultAcneTypesOfSymptoms}
        formMethods={formMethods}
        checkboxes={[
          {
            label: t('pomForms.acne.labels.typesOfSymptoms.whiteheads'),
            value: userAcneSymptoms.Whiteheads,
            onChange: () =>
              setUserAcneSymptoms((prev) => ({
                ...prev,
                Whiteheads: !prev.Whiteheads,
              })),
          },
          {
            label: t('pomForms.acne.labels.typesOfSymptoms.blackheads'),
            value: userAcneSymptoms.Blackheads,
            onChange: () =>
              setUserAcneSymptoms((prev) => ({
                ...prev,
                Blackheads: !prev.Blackheads,
              })),
          },
          {
            label: t('pomForms.acne.labels.typesOfSymptoms.spotsSurface'),
            value: userAcneSymptoms['Inflamed spots on surface of skin'],
            onChange: () =>
              setUserAcneSymptoms((prev) => ({
                ...prev,
                ['Inflamed spots on surface of skin']:
                  !prev['Inflamed spots on surface of skin'],
              })),
          },
          {
            label: t('pomForms.acne.labels.typesOfSymptoms.spotsUnderneath'),
            value:
              userAcneSymptoms['Inflamed spots underneath surface of skin'],
            onChange: () =>
              setUserAcneSymptoms((prev) => ({
                ...prev,
                ['Inflamed spots underneath surface of skin']:
                  !prev['Inflamed spots underneath surface of skin'],
              })),
          },
        ]}
      />

      <MultiSelect
        field={fields[3]}
        defaultValue={defaultAcneAffectedAreas}
        formMethods={formMethods}
        checkboxes={[
          {
            label: t('pomForms.acne.labels.affectedAreas.face'),
            value: affectedSkinAreas.Face,
            onChange: () =>
              setAffectedSkinAreas((prev) => ({
                ...prev,
                Face: !prev.Face,
              })),
          },
          {
            label: t('pomForms.acne.labels.affectedAreas.back'),
            value: affectedSkinAreas.Back,
            onChange: () =>
              setAffectedSkinAreas((prev) => ({
                ...prev,
                Back: !prev.Back,
              })),
          },
          {
            label: t('pomForms.acne.labels.affectedAreas.chest'),
            value: affectedSkinAreas.Chest,
            onChange: () =>
              setAffectedSkinAreas((prev) => ({
                ...prev,
                Chest: !prev.Chest,
              })),
          },
          {
            label: t('pomForms.acne.labels.affectedAreas.other'),
            value: affectedSkinAreas.Other,
            onChange: () =>
              setAffectedSkinAreas((prev) => ({
                ...prev,
                Other: !prev.Other,
              })),
          },
        ]}
      />

      {affectedSkinAreas.Other && (
        <TextArea field={fields[4]} formMethods={formMethods} />
      )}

      <SingleSelect field={fields[5]} formMethods={formMethods} />

      {isTakingAnyMedication === 'Yes' && (
        <TextArea field={fields[6]} formMethods={formMethods} />
      )}

      <SingleSelect field={fields[7]} formMethods={formMethods} />

      {hasBeenReviewedInLast12Months === 'Yes' && (
        <TextArea field={fields[8]} formMethods={formMethods} />
      )}

      <ImageSelector
        field={fields[9]}
        formMethods={formMethods}
        imageFilenames={
          shouldValidateImages
            ? imageFilenames
            : [
                t('pomForms.generic.labels.imageUpload.count', {
                  count: imageFilenames?.length,
                }),
              ]
        }
        setImageFilenames={setImageFilenames}
        inputRef={imageFileInputRef}
        onChange={() => setShouldValidateImages(true)}
      />

      <SingleCheckbox
        field={fields[10]}
        checkbox={{ label: t('pomForms.acne.labels.terms.confirm') }}
        formMethods={formMethods}
        helperText={t('pomForms.acne.labels.terms.terms')}
      />

      {actionButtons}
    </form>
  );
};
