import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOrderHistoryContext } from 'contexts/OrderHistoryContext';
import { Container, Row, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { OrderItem } from 'components/OrderItem';
import { Branch } from 'components/common/Branch';
import { BranchStatus } from 'components/common/Branch/Branch';
import { OrderHistory } from 'models/OrderHistory';
import { ReactComponent as OrdersImage } from 'assets/illustrations/orders.svg';
import { routes } from 'routes';
import { Breadcrumbs, Crumb } from 'components/common/Breadcrumbs';
import styles from './OrdersLanding.module.scss';

interface OrdersListProps {
  orders: OrderHistory[];
  titleKey: string;
}

const mergeStatuses = (statuses: BranchStatus[]): BranchStatus => {
  if (statuses.includes('error')) {
    return 'error';
  }
  if (statuses.includes('loading')) {
    return 'loading';
  }
  if (statuses.includes('idle')) {
    return 'idle';
  }
  if (statuses.every((status) => status === 'empty')) {
    return 'empty';
  }
  return 'finished';
};

export const OrdersLanding = () => {
  const { t } = useTranslation();

  const {
    getInProgressOrders,
    getFulfilledOrders,
    inProgressStatus,
    fulfilledStatus,
    inProgressOrders,
    fulfilledOrders,
  } = useOrderHistoryContext();

  const status = mergeStatuses([fulfilledStatus, inProgressStatus]);

  React.useEffect(() => {
    getInProgressOrders();
    getFulfilledOrders();
  }, []);

  const OrdersList = ({ orders, titleKey }: OrdersListProps) => {
    return (
      <div className={styles['orders-landing__orders-list']}>
        <Heading
          tag="h6"
          size="xs"
          className={styles['orders-landing__orders-heading']}
        >
          {t(titleKey)}
        </Heading>
        {orders.map((order) => (
          <OrderItem
            order={order}
            link={`${routes.ACCOUNT.ORDERS}/order/${order.orderId}`}
            key={order.orderId}
          />
        ))}
      </div>
    );
  };

  const breadcrumbs: Crumb[] = [
    { route: routes.ACCOUNT.BASE, text: t('NavBarLabels.account') },
    {
      route: routes.ACCOUNT.ORDERS,
      text: t('Orders.landing.title'),
    },
  ];

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Container className={styles['orders-landing']}>
        <Row>
          <Col>
            <Heading
              tag="h2"
              size="xl"
              className={styles['orders-landing__heading']}
            >
              {t('Orders.landing.title')}
            </Heading>

            <Branch
              status={status}
              EmptyComponent={() => (
                <div className={styles['orders-landing__no-orders']}>
                  <OrdersImage />
                  <Heading tag="h4" size="md">
                    {t('Orders.landing.notOrderedTitle')}
                  </Heading>
                  <Text size="lg">{t('Orders.landing.notOrdered')}</Text>
                </div>
              )}
              Component={() => (
                <>
                  {inProgressStatus === 'finished' && (
                    <OrdersList
                      orders={inProgressOrders}
                      titleKey="Orders.landing.activeOrders"
                    />
                  )}

                  {fulfilledStatus === 'finished' && (
                    <OrdersList
                      orders={fulfilledOrders}
                      titleKey="Orders.landing.recentOrders"
                    />
                  )}
                </>
              )}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
