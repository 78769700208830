import { ExtClient } from 'api/utils';

const url = 'https://api.getAddress.io/find';
const queryString = 'api-key=mdv4VGg9gU6Wo30JsYoJ1A30756&format=true';

export type Address = {
  formatted_address: string[];
  thoroughfare: string;
  building_name: string;
  sub_building_name: string;
  sub_building_number: string;
  building_number: string;
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  locality: string;
  town_or_city: string;
  county: string;
  district: string;
  country: string;
};

export type postCodeReturn = {
  postcode: string;
  latitude: number;
  longitude: number;
  addresses: string[][];
};

export const searchPostCode = async (
  postcode: string,
): Promise<postCodeReturn> => {
  const response = await ExtClient.get(`${url}/${postcode}?${queryString}`);
  return response.data;
};
