import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'components/common/Button';
import { Heading } from 'components/common/Heading';
import { Label } from 'components/common/Label';
import { RadioGroup } from 'components/common/RadioGroup';
import { InputText } from 'components/common/InputText';
import { Toggle } from 'components/common/Toggle';
import { useSuggestContext } from 'contexts/SuggestContext';
import { AutoComplete } from 'components/common/AutoComplete';
import { SelectList } from 'components/common/SelectList';
import {
  convertGPList,
  convertHeightFromImperial,
  convertHeightFromMetric,
  convertHeightToImperial,
  convertWeightFromImperial,
  convertWeightToImperial,
} from 'utils/transformers';
import { generalHealthQuestionnairePt1 } from 'formSchema/GeneralHealthQuestionnaire';
import {
  GeneralHealthFormPt1 as GeneralHealthFormPt1Type,
  Units,
} from 'models/GeneralHealthQuestionnaire';
import { Product } from 'models/Product';
import { useHistory } from 'react-router-dom';
import { ValidationError } from 'components/common/ValidationError';
import { Text } from 'components/common/Text';
import { Image } from 'components/common/Image';
import { Condition } from 'models/Condition';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';

import styles from '../GeneralHealthForm.module.scss';

const secondaryLabelStyles = 'general-health-form__secondary-label';
const halfWidthInputStyles = 'general-health-form__input--half-width';
const utilityStyles = {
  mb0: 'utility-styles__mb-0',
  mbSm: 'utility-styles__mb-sm',
  mbMd: 'utility-styles__mb-md',
};

interface GeneralHealthFormPt1Props {
  nextButton: { label: string; route: string };
  backButton: { label: string; route: string };
  formDataHandler: (data: GeneralHealthFormPt1Type) => void;
  product?: Product | null;
  condition?: Condition | null;
  progressBar?: JSX.Element;
  formData?: GeneralHealthFormPt1Type;
}

export const GeneralHealthFormPt1 = ({
  nextButton,
  backButton,
  product,
  condition,
  formDataHandler,
  formData,
  progressBar,
}: GeneralHealthFormPt1Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getGPlist, gpList, setGPList, loading } = useSuggestContext();
  const { logPageView } = useAmplitudeContext();
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState,
    errors,
    reset,
  } = useForm({
    resolver: yupResolver(generalHealthQuestionnairePt1()),
  });

  const [heightUnits, setHeightUnits] = useState<Units>(
    formData?.heightUnits ?? 'metric',
  );
  const [weightUnits, setWeightUnits] = useState<Units>(
    formData?.weightUnits ?? 'metric',
  );

  const isRegisteredWithGp: string = watch('isRegisteredWithGp');
  const gpPracticeId: string = watch('gpPracticeId');
  const dateChanged: { dateDD: string; dateMM: string; dateYY: string } = watch(
    ['dateDD', 'dateMM', 'dateYY'],
  );
  const heightChanged: {
    heightFt: string;
    heightIn: string;
    heightM: string;
    heightCm: string;
  } = watch(['heightFt', 'heightIn', 'heightM', 'heightCm']);
  const weightChanged: {
    weightSt: string;
    weightLb: string;
    weightKg: string;
  } = watch(['weightSt', 'weightLb', 'weightKg']);
  const [showAutoComplete, setShowAutoComplete] = useState(
    !(gpPracticeId && gpPracticeId.length > 0),
  );

  useEffect(() => {
    if (product) {
      logPageView({
        pageName: t('amplitude.generalHealthPt1.productPageName', {
          productTitle: product?.title,
        }),
      });
    } else if (condition) {
      logPageView({
        pageName: t('amplitude.generalHealthPt1.conditionPageName', {
          conditionName: condition?.name,
        }),
      });
    }
  }, []);

  useEffect(() => {
    if (formData) {
      getGPlist(formData.gpPracticeId);
      reset({ ...formData });
    }
  }, [formData]);

  useEffect(() => {
    const dateValues = getValues(['dateDD', 'dateMM', 'dateYY']);
    if (dateValues.dateDD && dateValues.dateMM && dateValues.dateYY) {
      const year = dateValues.dateYY as string;
      const month = dateValues.dateMM as string;
      const day = dateValues.dateDD as string;

      setValue(
        'dateOfBirth',
        `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`,
        {
          shouldDirty: true,
          shouldValidate: formState.isSubmitted,
        },
      );
    }
  }, [dateChanged]);

  useEffect(() => {
    const heightValues = getValues([
      'heightFt',
      'heightIn',
      'heightM',
      'heightCm',
    ]);
    const feet = Number(heightValues.heightFt);
    const inches = Number(heightValues.heightIn);
    const metres = Number(heightValues.heightM);
    const cm = Number(heightValues.heightCm);

    if (heightUnits === 'imperial') {
      setValue('height', convertHeightFromImperial(feet, inches), {
        shouldDirty: true,
        shouldValidate: formState.isSubmitted,
      });
    } else {
      setValue('height', convertHeightFromMetric(metres, cm), {
        shouldDirty: true,
        shouldValidate: formState.isSubmitted,
      });
    }
  }, [heightChanged]);

  useEffect(() => {
    const weightValues = getValues(['weightSt', 'weightLb', 'weightKg']);
    const stones = Number(weightValues.weightSt);
    const pounds = Number(weightValues.weightLb);
    const kilograms = Number(weightValues.weightKg);

    if (weightUnits === 'imperial') {
      setValue('weight', convertWeightFromImperial(stones, pounds), {
        shouldDirty: true,
        shouldValidate: formState.isSubmitted,
      });
    } else {
      setValue('weight', kilograms, {
        shouldDirty: true,
        shouldValidate: formState.isSubmitted,
      });
    }
  }, [weightChanged]);

  useEffect(() => {
    if (weightUnits === 'imperial') {
      setValue(
        'weightSt',
        convertWeightToImperial(parseInt(weightChanged.weightKg)).stones,
        {
          shouldDirty: true,
          shouldValidate: formState.isSubmitted,
        },
      );
      setValue(
        'weightLb',
        convertWeightToImperial(parseInt(weightChanged.weightKg)).pounds,
        {
          shouldDirty: true,
          shouldValidate: formState.isSubmitted,
        },
      );
    } else {
      setValue(
        'weightKg',
        convertWeightFromImperial(
          parseInt(weightChanged.weightSt),
          parseInt(weightChanged.weightLb),
        ).toFixed(0),
        {
          shouldDirty: true,
          shouldValidate: formState.isSubmitted,
        },
      );
    }
  }, [weightUnits]);

  useEffect(() => {
    if (heightUnits === 'imperial') {
      setValue(
        'heightFt',
        convertHeightToImperial(
          parseInt(heightChanged.heightM),
          parseInt(heightChanged.heightCm),
        ).feet,
        {
          shouldDirty: true,
          shouldValidate: formState.isSubmitted,
        },
      );
      setValue(
        'heightIn',
        convertHeightToImperial(
          parseInt(heightChanged.heightM),
          parseInt(heightChanged.heightCm),
        ).inches,
        {
          shouldDirty: true,
          shouldValidate: formState.isSubmitted,
        },
      );
    } else {
      setValue(
        'heightM',
        Math.floor(
          convertHeightFromImperial(
            parseInt(heightChanged.heightFt),
            parseInt(heightChanged.heightIn),
          ) / 100,
        ).toFixed(0),
        {
          shouldDirty: true,
          shouldValidate: formState.isSubmitted,
        },
      );
      setValue(
        'heightCm',
        (
          convertHeightFromImperial(
            parseInt(heightChanged.heightFt),
            parseInt(heightChanged.heightIn),
          ) % 100
        ).toFixed(0),
        {
          shouldDirty: true,
          shouldValidate: formState.isSubmitted,
        },
      );
    }
  }, [heightUnits]);

  useEffect(() => {
    if (gpPracticeId) {
      setShowAutoComplete(false);
      getGPlist(gpPracticeId);
      setValue('gpPracticeId', gpPracticeId, {
        shouldDirty: true,
        shouldValidate: formState.isSubmitted,
      });
    } else {
      getGPlist();
    }
  }, [gpPracticeId]);

  useEffect(() => {
    if (isRegisteredWithGp === 'No') {
      setShowAutoComplete(true);
      reset({
        ...getValues(),
        gpPracticeId: '',
        shareInformationToGpGranted: 'No',
      });
    }
  }, [isRegisteredWithGp]);

  const handleChangeGp = () => {
    setShowAutoComplete(true);
    reset({ ...getValues(), gpPracticeId: '' });
    setGPList([]);
  };

  const onSubmit = (payload: GeneralHealthFormPt1Type) => {
    const formValues = {
      ...payload,
      isRegisteredWithGp,
      gpPracticeId,
      heightUnits,
      weightUnits,
    };
    formDataHandler(formValues);
    history.push(nextButton.route);
  };

  return (
    <>
      <div className={styles['general-health-form__progress-container']}>
        {progressBar}
      </div>

      {product && !condition && (
        <>
          <Text size="md">
            {t('OrderFlow.generalHealthQuestionnaire.introPt1Treatment')}
          </Text>
          <Text size="md" className={styles['general-health-form__intro-pt-2']}>
            {t('OrderFlow.generalHealthQuestionnaire.introPt2')}
          </Text>
          <div
            className={styles['general-health-form__product-info-container']}
          >
            <Image
              imageFileName={product.imageFileName}
              alt={`product-image-${product.title}`}
              className={styles['general-health-form__product-image']}
            />
            <Heading tag="h3">{product.title}</Heading>
          </div>
        </>
      )}

      {!product && condition && (
        <>
          <Text size="md">
            {t('OrderFlow.generalHealthQuestionnaire.introPt1Condition')}
          </Text>
          <Text size="md" className={styles['general-health-form__intro-pt-2']}>
            {t('OrderFlow.generalHealthQuestionnaire.introPt2')}
          </Text>
        </>
      )}

      <Heading size="md" className={styles['general-health-form__title']}>
        {t('OrderFlow.generalHealthQuestionnaire.titlePt1')}
      </Heading>
      <Controller
        name="isRegisteredWithGp"
        control={control}
        defaultValue=""
        render={(props) => (
          <>
            <Label
              id="isRegisteredWithGpLabel"
              size="lg"
              error={!!errors.isRegisteredWithGp?.message}
            >
              {t(
                'OrderFlow.generalHealthQuestionnaire.formLabels.registeredWithGP.question',
              )}
            </Label>
            {errors.isRegisteredWithGp?.message && (
              <ValidationError id="isRegisteredWithGpValidationError">
                {errors.isRegisteredWithGp.message}
              </ValidationError>
            )}
            <RadioGroup
              id="isRegisteredWithGp"
              labelBy="isRegisteredWithGpLabel"
              values={[
                {
                  value: 'Yes',
                  label: t(
                    'OrderFlow.generalHealthQuestionnaire.formLabels.commonOptions.yes',
                  ),
                },
                {
                  value: 'No',
                  label: t(
                    'OrderFlow.generalHealthQuestionnaire.formLabels.commonOptions.no',
                  ),
                },
              ]}
              className={cx(styles[halfWidthInputStyles], {
                [`${styles[utilityStyles.mb0]}`]: isRegisteredWithGp === 'Yes',
              })}
              {...props}
            />
          </>
        )}
      />
      {isRegisteredWithGp === 'Yes' && (
        <div className={styles[utilityStyles.mbMd]}>
          {showAutoComplete && (
            <>
              <Label
                id="selectGPLabel"
                size="lg"
                error={!!errors.gpPracticeId?.message}
                className={styles[secondaryLabelStyles]}
              >
                {t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.registeredWithGP.moreDetails',
                )}
              </Label>

              <AutoComplete
                getResults={(str: string) => {
                  getGPlist(str);
                }}
                clearResults={() => {
                  setGPList([]);
                }}
                placeholder={t('PrescriptionFlow.gp.autocompletePlaceholder')}
                label={t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.registeredWithGP.moreDetails',
                )}
                className={cx(
                  styles['general-health-form__autocomplete-overrides'],
                )}
                loading={loading}
              />
            </>
          )}

          {gpList.length > 0 && (
            <Controller
              name="gpPracticeId"
              control={control}
              defaultValue=""
              render={(props) => (
                <SelectList
                  id="gpPracticeId"
                  labelBy="selectGPLabel"
                  listValues={convertGPList(gpList)}
                  className={cx(
                    styles[utilityStyles.mbMd],
                    styles['general-health-form__select-gp-list'],
                  )}
                  {...props}
                  onChange={(e) => setValue('gpPracticeId', e)}
                  value={gpPracticeId ? gpPracticeId.toString() : ''}
                />
              )}
            />
          )}

          {!showAutoComplete && (
            <div className={styles['general-health-form__change-gp-button']}>
              <Button
                label="Change GP?"
                onClick={handleChangeGp}
                appearance="minimal"
                size="sm"
              />
            </div>
          )}

          <Controller
            name="shareInformationToGpGranted"
            control={control}
            defaultValue=""
            render={(props) => (
              <>
                <Label
                  id="shareInformationToGpGrantedLabel"
                  size="lg"
                  error={!!errors.shareInformationToGpGranted?.message}
                >
                  {t(
                    'OrderFlow.generalHealthQuestionnaire.formLabels.shareInformationToGpGranted.question',
                  )}
                </Label>
                {errors.shareInformationToGpGranted?.message && (
                  <ValidationError id="shareInformationToGpGrantedValidationError">
                    {errors.shareInformationToGpGranted.message}
                  </ValidationError>
                )}
                <RadioGroup
                  id="shareInformationToGpGranted"
                  labelBy="shareInformationToGpGrantedLabel"
                  values={[
                    {
                      value: 'Yes',
                      label: t(
                        'OrderFlow.generalHealthQuestionnaire.formLabels.commonOptions.yes',
                      ),
                    },
                    {
                      value: 'No',
                      label: t(
                        'OrderFlow.generalHealthQuestionnaire.formLabels.commonOptions.no',
                      ),
                    },
                  ]}
                  className={styles[halfWidthInputStyles]}
                  {...props}
                />
              </>
            )}
          />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="gender"
          control={control}
          defaultValue=""
          render={(props) => (
            <>
              <Label
                id="genderLabel"
                size="lg"
                error={!!errors.gender?.message}
              >
                {t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.gender.question',
                )}
              </Label>
              {errors.gender?.message && (
                <ValidationError id="genderValidationError">
                  {errors.gender.message}
                </ValidationError>
              )}
              <RadioGroup
                id="gender"
                labelBy="genderLabel"
                values={[
                  {
                    value: 'Male',
                    label: t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.gender.options.male',
                    ),
                  },
                  {
                    value: 'Female',
                    label: t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.gender.options.female',
                    ),
                  },
                ]}
                className={styles[halfWidthInputStyles]}
                {...props}
              />
            </>
          )}
        />

        <Controller
          name="dateOfBirth"
          control={control}
          defaultValue=""
          render={(props) => (
            <>
              <Label
                id="dateOfBirthLabel"
                size="lg"
                error={!!errors.dateOfBirth?.message}
              >
                {t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.DOB.question',
                )}
              </Label>
              {errors.dateOfBirth?.message && (
                <ValidationError id="dateOfBirthValidationError">
                  {errors.dateOfBirth.message}
                </ValidationError>
              )}
              <input
                type="hidden"
                {...props}
                aria-labelledby="dateOfBirthLabel"
              />
            </>
          )}
        />

        <div
          className={cx(
            styles['general-health-form__date-fields'],
            styles[halfWidthInputStyles],
          )}
        >
          <Controller
            name="dateDD"
            control={control}
            defaultValue=""
            render={(props) => (
              <InputText
                label={t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.DOB.options.days',
                )}
                placeholder="DD"
                type="number"
                labelClassName={styles[secondaryLabelStyles]}
                {...props}
              />
            )}
          />
          <Controller
            name="dateMM"
            control={control}
            defaultValue=""
            render={(props) => (
              <InputText
                label={t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.DOB.options.months',
                )}
                placeholder="MM"
                type="number"
                labelClassName={styles[secondaryLabelStyles]}
                {...props}
              />
            )}
          />
          <Controller
            name="dateYY"
            control={control}
            defaultValue=""
            render={(props) => (
              <InputText
                label={t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.DOB.options.years',
                )}
                placeholder="YYYY"
                type="number"
                labelClassName={styles[secondaryLabelStyles]}
                {...props}
              />
            )}
          />
        </div>

        <Controller
          name="height"
          control={control}
          defaultValue={0}
          render={(props) => (
            <>
              <Label
                id="heightLabel"
                size="lg"
                error={!!errors.height?.message}
              >
                {t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.height.question',
                )}
              </Label>
              {errors.height?.message && (
                <ValidationError id="heightValidationError">
                  {errors.height.message}
                </ValidationError>
              )}
              <input type="hidden" {...props} aria-labelledby="heightLabel" />
            </>
          )}
        />

        <div
          className={cx(
            styles['general-health-form__height-fields'],
            styles[halfWidthInputStyles],
          )}
        >
          {heightUnits === 'imperial' && (
            <>
              <Controller
                name="heightFt"
                control={control}
                defaultValue=""
                render={(props) => (
                  <InputText
                    label={t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.height.options.feet',
                    )}
                    placeholder="Ft."
                    type="number"
                    className={styles[utilityStyles.mb0]}
                    labelClassName={styles[secondaryLabelStyles]}
                    {...props}
                  />
                )}
              />
              <Controller
                name="heightIn"
                control={control}
                defaultValue=""
                render={(props) => (
                  <InputText
                    label={t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.height.options.inches',
                    )}
                    placeholder="In."
                    type="number"
                    className={styles[utilityStyles.mb0]}
                    labelClassName={styles[secondaryLabelStyles]}
                    {...props}
                  />
                )}
              />
            </>
          )}

          {heightUnits === 'metric' && (
            <>
              <Controller
                name="heightM"
                control={control}
                defaultValue=""
                render={(props) => (
                  <InputText
                    label={t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.height.options.metres',
                    )}
                    placeholder="m."
                    type="number"
                    className={styles[utilityStyles.mb0]}
                    labelClassName={styles[secondaryLabelStyles]}
                    {...props}
                  />
                )}
              />
              <Controller
                name="heightCm"
                control={control}
                defaultValue=""
                render={(props) => (
                  <InputText
                    label={t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.height.options.centimetres',
                    )}
                    placeholder="cm."
                    type="number"
                    className={styles[utilityStyles.mb0]}
                    labelClassName={styles[secondaryLabelStyles]}
                    {...props}
                  />
                )}
              />
            </>
          )}
        </div>

        <Toggle
          label={
            heightUnits === 'imperial'
              ? t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.height.toggle.metric',
                )
              : t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.height.toggle.imperial',
                )
          }
          value={heightUnits === 'metric'}
          className={styles[utilityStyles.mbMd]}
          onChange={() =>
            setHeightUnits((prevUnit) =>
              prevUnit === 'imperial' ? 'metric' : 'imperial',
            )
          }
        />

        <Controller
          name="weight"
          control={control}
          defaultValue={0}
          render={(props) => (
            <>
              <Label
                id="weightLabel"
                size="lg"
                error={!!errors.weight?.message}
              >
                {t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.weight.question',
                )}
              </Label>
              {errors.weight?.message && (
                <ValidationError id="weightValidationError">
                  {errors.weight.message}
                </ValidationError>
              )}
              <input type="hidden" {...props} aria-labelledby="weightLabel" />
            </>
          )}
        />

        <div
          className={cx(
            styles[halfWidthInputStyles],
            styles['general-health-form__weight-fields'],
          )}
        >
          {weightUnits === 'imperial' && (
            <>
              <Controller
                name="weightSt"
                control={control}
                defaultValue=""
                render={(props) => (
                  <InputText
                    label={t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.weight.options.stones',
                    )}
                    placeholder="St."
                    type="number"
                    className={styles[utilityStyles.mb0]}
                    labelClassName={styles[secondaryLabelStyles]}
                    {...props}
                  />
                )}
              />
              <Controller
                name="weightLb"
                control={control}
                defaultValue=""
                render={(props) => (
                  <InputText
                    label={t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.weight.options.pounds',
                    )}
                    placeholder="Lbs."
                    type="number"
                    className={styles[utilityStyles.mb0]}
                    labelClassName={styles[secondaryLabelStyles]}
                    {...props}
                  />
                )}
              />
            </>
          )}

          {weightUnits === 'metric' && (
            <>
              <Controller
                name="weightKg"
                control={control}
                defaultValue=""
                render={(props) => (
                  <InputText
                    label={t(
                      'OrderFlow.generalHealthQuestionnaire.formLabels.weight.options.kilograms',
                    )}
                    placeholder="kgs."
                    type="number"
                    className={styles[utilityStyles.mb0]}
                    labelClassName={styles[secondaryLabelStyles]}
                    {...props}
                  />
                )}
              />
            </>
          )}
        </div>

        <Toggle
          label={
            weightUnits === 'imperial'
              ? t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.weight.toggle.metric',
                )
              : t(
                  'OrderFlow.generalHealthQuestionnaire.formLabels.weight.toggle.imperial',
                )
          }
          value={weightUnits === 'metric'}
          className={styles[utilityStyles.mbMd]}
          onChange={() =>
            setWeightUnits((prevUnit) =>
              prevUnit === 'imperial' ? 'metric' : 'imperial',
            )
          }
        />

        <div className={styles['general-health-form__button-container']}>
          <Button
            appearance="outline"
            label={backButton.label}
            onClick={() => history.push(backButton.route)}
            className={styles['general-health-form__button']}
          />
          <Button
            label={nextButton.label}
            type="submit"
            className={styles['general-health-form__button']}
          />
        </div>
      </form>
    </>
  );
};
