import React, { useEffect, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { GeneralHealthFormPt1 } from 'containers/GeneralHealthForm/GeneralHealthFormPt1';
import { GeneralHealthFormPt2 } from 'containers/GeneralHealthForm/GeneralHealthFormPt2';
import { SuggestProvider } from 'contexts/SuggestContext/SuggestContext';
import { useProductsContext } from 'contexts/ProductsContext';
import { LandingPageSpinner } from 'components/common/Spinner';
import {
  GeneralHealthForm as GeneralHealthFormType,
  GeneralHealthFormPt1 as GeneralHealthFormPt1Type,
  GeneralHealthFormPt2 as GeneralHealthFormPt2Type,
} from 'models/GeneralHealthQuestionnaire';
import { Col, Container, Row } from 'components/common/grid';
import { FormProgressBar } from 'components/FormProgressBar';
import { usePatientContext } from 'contexts/PatientContext';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { useTranslation } from 'react-i18next';

import { routes } from 'routes';
import styles from './GeneralHealthForm.module.scss';

export const GeneralHealthForm = () => {
  const { conditionId, productId } = useParams<{
    conditionId?: string;
    productId?: string;
  }>();
  const { t } = useTranslation();
  const [fetchComplete, setFetchComplete] = useState(false);
  const { product } = useProductsContext();
  const { condition } = useConditionsContext();
  const {
    savePatientGeneralHealthForm,
    getPatientGeneralHealthForm,
    patientGeneralHealthForm,
    patient,
    getPatientGp,
  } = usePatientContext();
  const [pt1FormData, setPt1FormData] = useState<
    GeneralHealthFormPt1Type | undefined
  >();

  useEffect(() => {
    const getData = async () => {
      await getPatientGeneralHealthForm();
      await getPatientGp();
      setFetchComplete(true);
    };
    getData();
  }, []);

  if (
    (productId && !product) ||
    (conditionId && !condition) ||
    !fetchComplete
  ) {
    return <LandingPageSpinner />;
  }

  const handleSubmitPt1 = (pt1FormDataUpdate: GeneralHealthFormPt1Type) => {
    setPt1FormData((existingData) => ({
      ...existingData,
      ...pt1FormDataUpdate,
    }));
  };

  const handleSubmitPt2 = (pt2FormData: GeneralHealthFormPt2Type) => {
    const formToStore = {
      ...(pt1FormData ?? startingData),
      ...pt2FormData,
    } as GeneralHealthFormType;
    savePatientGeneralHealthForm(formToStore);
  };

  const routePrefix = productId
    ? routes.PRODUCT.BASE
    : conditionId
    ? routes.SHOP.CONDITION
    : '/';
  const routeId = productId ? product?.id : conditionId ? condition?.id : '';
  const baseRoute = `${routePrefix}/${routeId}`;

  const startingData: GeneralHealthFormType = {
    ...(patientGeneralHealthForm as GeneralHealthFormType),
    dateYY:
      (patient?.dateOfBirth
        ? parseInt(patient.dateOfBirth.substring(0, 4)).toString()
        : patientGeneralHealthForm?.dateYY) ?? '',
    dateMM:
      (patient?.dateOfBirth
        ? parseInt(patient.dateOfBirth.substring(5, 7)).toString()
        : patientGeneralHealthForm?.dateMM) ?? '',
    dateDD:
      (patient?.dateOfBirth
        ? parseInt(patient.dateOfBirth.substring(8, 10)).toString()
        : patientGeneralHealthForm?.dateDD) ?? '',
  };

  const part1NextButton = {
    label: t('OrderFlow.generalHealthQuestionnaire.buttons.proceed'),
    route: `${baseRoute}${routes.GENERAL_HEALTH.PT2}`,
  };
  const part1BackButton = {
    label: productId
      ? t('OrderFlow.generalHealthQuestionnaire.buttons.backToTreatment')
      : conditionId
      ? t('OrderFlow.generalHealthQuestionnaire.buttons.backToCondition')
      : '',
    route: `${baseRoute}`,
  };
  const part2NextButton = {
    label: t('OrderFlow.generalHealthQuestionnaire.buttons.proceed'),
    route: `${baseRoute}${routes.POM.BASE}`,
  };
  const part2BackButton = {
    label: t('OrderFlow.generalHealthQuestionnaire.buttons.backPt2'),
    route: `${baseRoute}${routes.GENERAL_HEALTH.PT1}`,
  };

  return (
    <Container className={styles['general-health-form']}>
      <Row>
        <Col>
          <Switch>
            <Route path={`${baseRoute}${routes.GENERAL_HEALTH.PT1}`} exact>
              <SuggestProvider>
                <GeneralHealthFormPt1
                  nextButton={part1NextButton}
                  backButton={part1BackButton}
                  product={product}
                  condition={condition}
                  formDataHandler={handleSubmitPt1}
                  progressBar={
                    <FormProgressBar currentStage={1} numberOfStages={4} />
                  }
                  formData={startingData}
                />
              </SuggestProvider>
            </Route>

            <Route path={`${baseRoute}${routes.GENERAL_HEALTH.PT2}`} exact>
              <GeneralHealthFormPt2
                nextButton={part2NextButton}
                backButton={part2BackButton}
                product={product}
                condition={condition}
                formDataHandler={handleSubmitPt2}
                progressBar={
                  <FormProgressBar currentStage={2} numberOfStages={4} />
                }
                formData={patientGeneralHealthForm as GeneralHealthFormPt2Type}
              />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};
