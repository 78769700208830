import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useAuthContext } from 'contexts/AuthContext';
import { IconButton } from 'components/common/IconButton';
import { BasketIcon } from 'components/Basket/BasketIcon';
import { ReactComponent as Close } from 'assets/icons/lawsat/Close.svg';
import { ReactComponent as Menu } from 'assets/icons/lawsat/Menu.svg';
import { ReactComponent as MainLogo } from 'assets/logo/lawsat-logo.svg';
import { ReactComponent as SymbolLogo } from 'assets/logo/lawsat-symbol.svg';
import { ReactComponent as TextLogo } from 'assets/logo/lawsat-text.svg';
import { ReactComponent as BustIcon } from 'assets/icons/lawsat/User.svg';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { Heading } from 'components/common/Heading';
import { DesktopMenuItems } from 'components/Layout/DesktopMenuItems';
import { MobileMenuItems } from '../MobileMenuItems';
import { SearchBar } from 'components/Layout/SearchBar';
import { ReactComponent as SearchIcon } from 'assets/icons/lawsat/Search.svg';
import { routes } from 'routes';

import styles from './NavBar.module.scss';

export const isRouteActive = (
  pathname: string,
  searchString: string,
  exact = false,
) => {
  return exact ? pathname === searchString : pathname.startsWith(searchString);
};

const activeLinkStyle = styles['navbar__icons--active'];

export const NavBar = () => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const { pathname } = useLocation();
  const { login, authStatus } = useAuthContext();
  const { pageName } = useLandingPageContext();
  const { logCtaClick } = useAmplitudeContext();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const handleClickMobileSearchIcon = () => {
    setShowMobileSearch((prev) => !prev);
  };

  const handleCloseMobileMenu = () => {
    setShowMobileMenu(false);
  };

  const handleLogCtaClick = (label: string) => {
    logCtaClick({
      label: label,
      pageName: pageName?.log,
      section: t('amplitude.navbar.section'),
    });
  };

  useEffect(() => {
    setShowMobileMenu(false);
  }, [pathname]);

  return (
    <div className={styles['navbar']}>
      <div className={styles['navbar__desktop']}>
        <div className={styles['navbar__desktop__top-row']}>
          <Link to={routes.BASE}>
            <MainLogo
              className={styles['navbar__logo']}
              data-testid={'navbar-desktop-lawsat-logo'}
              onClick={() => {
                handleLogCtaClick(t('amplitude.navbar.lawsatLogoLabel'));
              }}
            />
          </Link>
          <div className={styles['navbar__icons']}>
            <SearchBar />
            <IconButton
              icon={BustIcon}
              testId={`desktop-account-button`}
              label={t('NavBarLabels.account')}
              onClick={() => {
                authStatus
                  ? navigate.push(routes.ACCOUNT.BASE)
                  : login({ redirectToUrl: routes.ACCOUNT.BASE });
              }}
              className={cx(
                isRouteActive(pathname, routes.ACCOUNT.BASE) && activeLinkStyle,
              )}
            />

            <BasketIcon
              type="icon"
              className={cx(
                isRouteActive(pathname, routes.BASKET) && activeLinkStyle,
              )}
            />
          </div>
        </div>

        <DesktopMenuItems />
      </div>

      <div className={styles['navbar__mobile']}>
        <div className={styles['navbar__mobile__top-row']}>
          <IconButton
            label={t('NavBarLabels.openMenu')}
            icon={Menu}
            aria-hidden={'false'}
            className={cx(styles['navbar__menu-button'])}
            aria-haspopup={'true'}
            aria-controls="lawsat-main-menu"
            aria-expanded={showMobileMenu ? 'true' : 'false'}
            onClick={() => {
              setShowMobileMenu(true);
            }}
          />

          <Link
            to={routes.BASE}
            onClick={() => {
              handleLogCtaClick(t('amplitude.navbar.section'));
            }}
            className={cx(styles['navbar__symbol-logo'])}
          >
            <SymbolLogo />
          </Link>

          {pageName?.display ? (
            <Heading
              size="sm"
              tag="h1"
              className={styles['navbar__mobile-title-logo']}
            >
              {pageName.display}
            </Heading>
          ) : (
            <TextLogo className={styles['navbar__mobile-title-logo']} />
          )}

          <IconButton
            icon={SearchIcon}
            label="Search"
            onClick={handleClickMobileSearchIcon}
          />

          <BasketIcon
            className={cx(
              isRouteActive(pathname, routes.BASKET) && activeLinkStyle,
            )}
          />

          <div
            className={cx(styles['navbar__menu'], {
              [styles['navbar__menu--open']]: showMobileMenu,
            })}
          >
            <IconButton
              className={styles['navbar__menu-close']}
              size="md"
              label={t('NavBarLabels.closeMenu')}
              icon={Close}
              onClick={() => handleCloseMobileMenu()}
            />

            <div>
              <MobileMenuItems
                isMenuOpen={showMobileMenu}
                closeMenu={handleCloseMobileMenu}
              />
            </div>
            <MainLogo className={styles['navbar__main-logo']} />
          </div>
        </div>
        <div
          className={cx(
            styles['navbar__mobile__search-bar__container'],
            showMobileSearch &&
              styles['navbar__mobile__search-bar__container--show'],
          )}
        >
          <SearchBar
            onSearch={() => {
              setShowMobileSearch(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};
