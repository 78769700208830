/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Label } from 'components/common/Label';
import { ValidationError } from 'components/common/ValidationError';
import { RadioGroup } from 'components/common/RadioGroup';
import { Controller, UseFormMethods } from 'react-hook-form';
import { BackendPomFormQuestion } from 'models/PomQuestionnaires';
import { RadioValue } from 'components/common/RadioGroup/RadioGroup';
import { CheckBox } from 'components/common/CheckBox';
import { CheckBoxProps } from 'components/common/CheckBox/CheckBox';
import { Textarea } from 'components/common/Textarea';
import i18n from 'i18n';
import { FileUpload } from 'components/FileUpload';
import { useTranslation } from 'react-i18next';

import styles from './PomForm.module.scss';

const halfWidthInputStyle = styles['pomform__input--half-width'];
const checkboxStyle = styles['pomform__input__checkbox'];
const textAreaStyles = styles['pomform__input__textarea'];

const booleanRadioValues = [
  {
    value: 'Yes',
    label: i18n.t('common.yes'),
  },
  {
    value: 'No',
    label: i18n.t('common.no'),
  },
];

interface SingleSelectProps {
  field: BackendPomFormQuestion;
  formMethods: UseFormMethods<Record<string, any>>;
  values?: RadioValue[];
  orientation?: 'horizontal' | 'vertical';
  helperText?: string | string[];
}

export const SingleSelect = ({
  field,
  formMethods,
  values = booleanRadioValues,
  orientation = 'horizontal',
  helperText,
}: SingleSelectProps) => {
  return (
    <Controller
      name={field.fieldName}
      control={formMethods.control}
      defaultValue={''}
      render={(props) => (
        <>
          <Label
            id={`${field.fieldName}Label`}
            error={!!formMethods.errors[field.fieldName]?.message}
            size="lg"
          >
            {field.question}
          </Label>
          <HelperText helperText={helperText} />
          {formMethods.errors[field.fieldName]?.message && (
            <ValidationError id={`${field.fieldName}Error`}>
              {formMethods.errors[field.fieldName].message}
            </ValidationError>
          )}
          <RadioGroup
            id={`${field.fieldName}RadioGroup`}
            labelBy={`${field.fieldName}RadioGroupLabel`}
            className={halfWidthInputStyle}
            values={values}
            orientation={orientation}
            {...props}
          />
        </>
      )}
    />
  );
};

interface MultiSelectProps {
  field: BackendPomFormQuestion;
  formMethods: UseFormMethods<Record<string, any>>;
  defaultValue: any;
  checkboxes: CheckBoxProps[];
}

export const MultiSelect = ({
  field,
  formMethods,
  defaultValue,
  checkboxes,
}: MultiSelectProps) => {
  return (
    <Controller
      name={field.fieldName}
      defaultValue={defaultValue}
      control={formMethods.control}
      render={() => (
        <div className={halfWidthInputStyle}>
          <Label
            id={`${field.fieldName}Label`}
            error={!!formMethods.errors.userAcneSymptoms?.message}
            size="lg"
          >
            {field.question}
          </Label>
          {formMethods.errors[field.fieldName]?.message && (
            <ValidationError id={`${field.fieldName}Error`}>
              {formMethods.errors[field.fieldName].message}
            </ValidationError>
          )}
          {checkboxes.map((checkbox) => (
            <CheckBox
              key={checkbox.label}
              className={checkboxStyle}
              label={checkbox.label}
              value={checkbox.value}
              onChange={checkbox.onChange}
            />
          ))}
        </div>
      )}
    />
  );
};

interface SingleCheckboxProps {
  field: BackendPomFormQuestion;
  formMethods: UseFormMethods<Record<string, any>>;
  checkbox: CheckBoxProps;
  helperText?: string | string[];
  defaultValue?: boolean;
}

export const SingleCheckbox = ({
  field,
  formMethods,
  defaultValue = false,
  checkbox,
  helperText,
}: SingleCheckboxProps) => {
  return (
    <Controller
      name={field.fieldName}
      control={formMethods.control}
      defaultValue={defaultValue}
      render={(props) => (
        <>
          <Label
            id={`${field.fieldName}Label`}
            error={!!formMethods.errors[field.fieldName]?.message}
            size="lg"
          >
            {field.question}
          </Label>
          <HelperText helperText={helperText} />
          {formMethods.errors[field.fieldName]?.message && (
            <ValidationError id={`${field.fieldName}Error`}>
              {formMethods.errors[field.fieldName].message}
            </ValidationError>
          )}
          <CheckBox
            className={checkboxStyle}
            size="sm"
            variant="accent"
            label={checkbox.label}
            {...props}
          />
        </>
      )}
    />
  );
};

interface TextAreaProps {
  field: BackendPomFormQuestion;
  formMethods: UseFormMethods<Record<string, any>>;
  placeHolder?: string;
}

export const TextArea = ({
  field,
  formMethods,
  placeHolder,
}: TextAreaProps) => {
  return (
    <Controller
      name={field.fieldName}
      control={formMethods.control}
      defaultValue={''}
      render={(props, fieldState) => (
        <Textarea
          label={field.question}
          className={textAreaStyles}
          placeholder={placeHolder}
          validationError={formMethods.errors[field.fieldName]?.message}
          variant={fieldState.invalid ? 'negative' : 'accent'}
          {...props}
        />
      )}
    />
  );
};

interface ImageSelectorProps {
  field: BackendPomFormQuestion;
  formMethods: UseFormMethods<Record<string, any>>;
  imageFilenames: string[];
  setImageFilenames: React.Dispatch<React.SetStateAction<string[]>>;
  inputRef: React.RefObject<HTMLInputElement>;
  onChange?: () => void;
}

export const ImageSelector = ({
  field,
  formMethods,
  imageFilenames,
  setImageFilenames,
  inputRef,
  onChange: externalOnchange,
}: ImageSelectorProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={field.fieldName}
      control={formMethods.control}
      defaultValue={[]}
      render={({ onChange: formHandlerOnChange }) => {
        const imagesError =
          formMethods.errors[field.fieldName] &&
          (formMethods.errors[field.fieldName].message ||
            (formMethods.errors[field.fieldName].length > 0 &&
              formMethods.errors[field.fieldName][0].message));
        const internalOnChange = (value: File[] | null | undefined) => {
          externalOnchange?.();
          formHandlerOnChange(value);
        };
        return (
          <FileUpload
            className={styles['pomform__input__file-upload']}
            inputLabel={field.question}
            accept=".jpg, .jpeg"
            generalHelperText={t(
              'pomForms.generic.labels.imageUpload.helperText',
            )}
            uploadFormatHelperText={t(
              'pomForms.generic.labels.imageUpload.format',
            )}
            validationError={imagesError}
            fileNames={imageFilenames}
            setFileNames={setImageFilenames}
            ref={inputRef}
            onChange={internalOnChange}
            allowMultiSelect
          />
        );
      }}
    />
  );
};
interface HelperTextProps {
  helperText?: string | string[];
}
const HelperText = ({ helperText }: HelperTextProps) => {
  if (!helperText) return null;
  return (
    <>
      {Array.isArray(helperText) ? (
        <ul className={styles['pomform__input__terms-helper__list']}>
          {helperText.map((term) => (
            <li
              key={term}
              className={
                styles['pomform__input__terms-helper__list__list-item']
              }
            >
              {term}
            </li>
          ))}
        </ul>
      ) : (
        <span className={styles['pomform__input__terms-helper']}>
          {helperText}
        </span>
      )}
    </>
  );
};
