import React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'contexts/ModalContext';
import { useScrollContext } from 'contexts/ScrollContext';
import { Container, Row, Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { IconButton } from 'components/common/IconButton';
import { ReactComponent as BackArrow } from 'assets/icons/lawsat/ArrowLeft.svg';
import { ReactComponent as Close } from 'assets/icons/lawsat/Close.svg';

import styles from './AppBarTop.module.scss';

export type BackRoute = {
  to?: string;
  text: string;
  onClick?: () => void;
};
export interface AppBarTopProps {
  title?: string;
  backRoute?: BackRoute;
  closeRoute?: BackRoute;
  className?: string;
  scrollPos?: number;
  isCoreFlow?: boolean;
  confirmClose?: {
    text: string;
    CTA: string;
    route: string;
  };
}

export const AppBarTop: React.FC<AppBarTopProps> = ({
  title,
  backRoute,
  closeRoute,
  className,
  confirmClose,
  isCoreFlow = true,
  scrollPos = 100,
}) => {
  const { t } = useTranslation();
  const { scrollPosition } = useScrollContext();
  const { open, close } = useModalContext();
  const history = useHistory();

  return (
    <div
      className={cx(styles['app-bar-top'], {
        [`${className}`]: className,
        [styles['app-bar-top--scrolled']]: scrollPosition > scrollPos,
      })}
    >
      <Container>
        <Row>
          <Col
            xs="12"
            md={isCoreFlow ? { size: 8, offset: 2 } : '12'}
            lg={isCoreFlow ? { size: 6, offset: 3 } : '12'}
          >
            <div className={styles['app-bar-top__inner']}>
              {backRoute && (
                <IconButton
                  testId={'back-button'}
                  className={styles['app-bar-top__link']}
                  icon={BackArrow}
                  link={backRoute.to ? { to: backRoute.to } : undefined}
                  onClick={backRoute.onClick}
                  label={backRoute.text}
                />
              )}
              {title && (
                <Heading size="sm" className={styles['app-bar-top__title']}>
                  {title}
                </Heading>
              )}
              {closeRoute && (
                <IconButton
                  testId={'close-button'}
                  className={styles['app-bar-top__close']}
                  icon={Close}
                  link={closeRoute.to ? { to: closeRoute.to } : undefined}
                  onClick={closeRoute.onClick}
                  label={closeRoute.text}
                />
              )}
              {confirmClose && (
                <IconButton
                  className={styles['app-bar-top__close']}
                  testId="close-button"
                  icon={Close}
                  label="close"
                  onClick={() => {
                    open(
                      <>
                        <Heading
                          tag="h3"
                          size="lg"
                          className={styles['app-bar-top__modal-title']}
                        >
                          {confirmClose.text}
                        </Heading>
                        <Button
                          className={styles['app-bar-top__modal-button']}
                          testId="confirm-button"
                          label={confirmClose.CTA}
                          onClick={() => {
                            close();
                            history.push(confirmClose.route);
                          }}
                        />
                        <Button
                          className={styles['app-bar-top__modal-button']}
                          variant="ui"
                          appearance="flat"
                          label={t('common.appBarTop.modalClose')}
                          onClick={() => {
                            close();
                          }}
                        />
                      </>,
                    );
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
