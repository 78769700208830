import React from 'react';
import { BasketOrderline } from 'models/Basket';
import { Heading } from 'components/common/Heading';
import { ReactComponent as Bin } from 'assets/icons/lawsat/Bin.svg';
import { Toggle } from 'components/common/Toggle';
import { ProductImage } from 'components/Products/ProductImage';
import { Container, Col, Row } from 'components/common/grid';
import { Text } from 'components/common/Text';
import { useBasketContext } from 'contexts/BasketContext';
import { BasketQuantitySelector } from 'components/Basket/BasketQuantitySelector';
import { ReactComponent as CircleAlert } from 'assets/icons/lawsat/CircleAlert.svg';
import { useTranslation } from 'react-i18next';

import styles from './BasketItemCard.module.scss';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';

interface BasketItemCardProps {
  item: BasketOrderline;
  type?: 'shop' | 'prescription';
  dependantId?: string;
}

export const BasketItemCard = ({
  item,
  type = 'shop',
  dependantId,
}: BasketItemCardProps) => {
  const { t } = useTranslation();
  const {
    handleRemovePrescriptionProductFromBasket,
    handleRemoveTreatmentFromBasket,
    handleChangePrescriptionProductContraceptionStatus,
  } = useBasketContext();
  const { logCtaClick } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();

  const removeFromBasket = () => {
    type === 'shop'
      ? handleRemoveTreatmentFromBasket(item.id)
      : handleRemovePrescriptionProductFromBasket(item.id, dependantId);
  };

  const handleLogCtaClick = (label: string) => {
    logCtaClick({
      pageName: pageName?.log,
      label: label,
      section: item.name,
    });
  };

  const heading = (
    <Heading size="sm" className={styles['basket-item-card__name']}>
      {item.name}
    </Heading>
  );

  return (
    <>
      <Container className={styles['basket-item-card']}>
        <div className={styles['basket-item-card__inner']}>
          <Row className={styles['basket-item-card__top-row']}>
            {type === 'shop' && (
              <Col
                xs={4}
                sm={2}
                className={styles['basket-item-card__image__col']}
              >
                <Link
                  to={`${routes.PRODUCT.BASE}/${item.id}`}
                  data-testid="product-image-link"
                >
                  <ProductImage
                    imageFileName={item.imageFileName}
                    className={styles['basket-item-card__image']}
                  />
                </Link>
              </Col>
            )}
            <Col
              xs={type === 'shop' ? 8 : 12}
              sm={type === 'shop' ? 7 : 12}
              className={styles['basket-item-card__details']}
            >
              {type === 'shop' ? (
                <Link
                  to={`${routes.PRODUCT.BASE}/${item.id}`}
                  className={styles['basket-item-card__name__link']}
                  data-testid="product-heading-link"
                >
                  {heading}
                </Link>
              ) : (
                <>{heading}</>
              )}
              <Heading size="xs" className={styles['basket-item-card__price']}>
                £
                {item.isUsedAsContraceptive
                  ? item.priceWhenUsedAsContraceptive?.toFixed(2)
                  : item.price.toFixed(2)}
              </Heading>
            </Col>
            {type === 'shop' && (
              <Col
                xs={3}
                className={styles['basket-item-card__qty-selector--desktop']}
              >
                <BasketQuantitySelector
                  productId={item.id}
                  productName={item.name}
                  qty={item.quantity}
                  maxQuantity={item.orderLimit}
                />
              </Col>
            )}
          </Row>

          {type === 'shop' && (
            <Row>
              <Col
                xs={{ size: 8, offset: 4 }}
                className={styles['basket-item-card__qty-selector--mobile']}
              >
                <BasketQuantitySelector
                  productId={item.id}
                  productName={item.name}
                  qty={item.quantity}
                  maxQuantity={item.orderLimit}
                />
              </Col>
            </Row>
          )}

          <Row className={styles['basket-item-card__button-row']}>
            <Col
              xs={{ size: 8, offset: type === 'shop' ? 4 : 0 }}
              sm={{ size: 2, offset: 0 }}
            >
              <button
                className={styles['basket-item-card__delete-button']}
                onClick={() => {
                  handleLogCtaClick(t('amplitude.basket.removeItemLabel'));
                  removeFromBasket();
                }}
              >
                <Bin />
                <Text
                  className={styles['basket-item-card__delete-button__text']}
                >
                  {t('BasketPage.deleteBtn')}
                </Text>
              </button>
            </Col>

            {type === 'prescription' && item.canBeUsedAsContraceptive && (
              <Col xs={{ size: 8, offset: 0 }} sm={{ size: 9, offset: 0 }}>
                <Toggle
                  label={t('BasketPage.itemCardToggleLabel')}
                  value={item.isUsedAsContraceptive}
                  onChange={() => {
                    handleLogCtaClick(
                      t('amplitude.basket.contraceptiveToggleLabel'),
                    );
                    handleChangePrescriptionProductContraceptionStatus(
                      item.id,
                      dependantId,
                    );
                  }}
                />
              </Col>
            )}
          </Row>
        </div>
      </Container>

      {type === 'shop' &&
        item.orderLimit &&
        item.quantity >= item.orderLimit && (
          <Text size="lg" className={styles['basket-item-card__warning-msg']}>
            <CircleAlert className={styles['basket-item-card__warning-icon']} />
            {t('BasketPage.itemCardWarningMsg')}
          </Text>
        )}
    </>
  );
};
