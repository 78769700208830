import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRadioState, Radio, RadioGroup } from 'reakit/Radio';
import { Pharmacy } from 'models/Pharmacy';
import { Label } from 'components/common/Label';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Icon } from 'components/common/Icon';
import { ReactComponent as Checkmark } from 'assets/icons/lawsat/checkmark.svg';
import { ReactComponent as Clear } from 'assets/icons/lawsat/Clear.svg';

import styles from './PharmacyList.module.scss';
interface PharmacyListProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  labelBy?: string;
  onChange?: (value: string) => void;
  testId?: string;
  value?: string;
  values: Pharmacy[];
}

const PharmacyListRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  PharmacyListProps
> = (
  { className, id, disabled = false, labelBy, onChange, testId, value, values },
  ref,
) => {
  const radio = useRadioState({ state: value, wrap: true });

  React.useEffect(() => {
    radio.setState(value);
  }, [value]);

  const { t } = useTranslation();
  const classes = cx(`${styles['input__pharmacy-group']}`, {
    [`${styles['disabled']}`]: disabled,
    [`${className}`]: className,
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };
  const optionClass = styles['input__delivery-option'];
  return (
    <RadioGroup
      {...radio}
      data-testid={testId}
      className={classes}
      aria-labelledby={labelBy}
      baseId={id}
    >
      {values.map(
        (
          {
            id,
            name,
            deliveryAvailable,
            collectionAvailable,
            addressLine1,
            addressLine2,
            addressLine3,
            addressTownCity,
            addressPostCode,
          },

          i,
        ) => (
          <Label
            testId={testId ? `${testId}-label` : undefined}
            className={cx(styles['input__pharmacy-label'])}
            key={i}
          >
            <Radio
              {...radio}
              className={styles['input__pharmacy']}
              disabled={disabled}
              data-testid={testId ? `${testId}-radio` : undefined}
              onChange={handleOnChange}
              value={id.toString()}
              ref={ref}
              id={`${id}-${i}`}
              name={`${id}-${i}`}
            />
            <span
              data-testid={testId ? `${testId}-circle` : undefined}
              className={styles['input__pharmacy-circle']}
            />
            <div className={styles['input__pharmacy-text']}>
              <Heading
                tag="h5"
                size="sm"
                className={styles['input__pharmacy-name']}
              >
                {name}
              </Heading>
              <div className={styles['input__delivery-options']}>
                {deliveryAvailable ? (
                  <Label tag="span" size="md" className={optionClass}>
                    <Icon icon={Checkmark} className={styles['input__check']} />
                    <span>{t('Account.selectPharmacy.deliveryAvailable')}</span>
                  </Label>
                ) : (
                  <Label tag="span" size="md" className={optionClass}>
                    <Icon icon={Clear} className={styles['input__clear']} />
                    {t('Account.selectPharmacy.deliveryNotAvailable')}
                  </Label>
                )}
                {collectionAvailable && (
                  <Label tag="span" size="md" className={optionClass}>
                    <Icon icon={Checkmark} className={styles['input__check']} />
                    {t('Account.selectPharmacy.collect')}
                  </Label>
                )}
              </div>

              <Text size="md" className={styles['input__address']}>
                {addressLine1 && `${addressLine1}, `}
                {addressLine2 && `${addressLine2}, `}
                {addressLine3 && `${addressLine3}, `}
                {addressTownCity && `${addressTownCity}, `}
                {addressPostCode && `${addressPostCode}`}
              </Text>
            </div>
            <span className={styles['input__faux-background']}></span>
          </Label>
        ),
      )}
    </RadioGroup>
  );
};

export const PharmacyList = React.forwardRef(PharmacyListRef);
