import React from 'react';
import { Spinner } from 'components/common/Spinner';
import styles from './BranchLoadingState.module.scss';

interface BranchLoadingStateProps {
  testId?: string;
}

export const BranchLoadingState: React.FC<BranchLoadingStateProps> = ({
  testId,
}) => {
  return (
    <div data-testid={testId} className={styles['branch-loading-state']}>
      <Spinner />
    </div>
  );
};
