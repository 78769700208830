import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAmplitudeContext } from 'contexts/AmplitudeContext';
import { useLandingPageContext } from 'contexts/LandingPageContext';
import { useIsInViewport } from 'hooks/useIsInView';
import { Col } from 'components/common/grid';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { ReactComponent as LinkToIcon } from 'assets/icons/LinkTo.svg';

import styles from './AdviceSection.module.scss';

export const AdviceSection = () => {
  const { t } = useTranslation();

  const { logSectionView, logCtaClick } = useAmplitudeContext();
  const { pageName } = useLandingPageContext();
  const { elementRef, isInViewport } = useIsInViewport({});

  useEffect(() => {
    if (isInViewport) {
      logSectionView({
        pageName: pageName?.log,
        sectionName: t('amplitude.adviceSection.sectionName'),
      });
    }
  }, [isInViewport]);

  return (
    <div className={styles['advice-section']} ref={elementRef}>
      <div className={styles['advice-section__row']}>
        <Col className={styles['advice-section__col']}>
          <Heading tag="h2">
            {t('travelVaccinations.landingPage.advice.heading')}
          </Heading>
          <Heading tag="h6">
            {t('travelVaccinations.landingPage.advice.bodyTextOne')}
          </Heading>
          <Heading tag="h6">
            {t('travelVaccinations.landingPage.advice.bodyTextTwo')}
          </Heading>
          <Button
            label={t('travelVaccinations.landingPage.advice.ctaLabel')}
            link={{
              to: 'https://www.gov.uk/foreign-travel-advice',
              rel: 'noopener noreferrer',
              target: '_blank',
            }}
            variant="accent"
            icon={{ element: LinkToIcon, alignment: 'right' }}
            appearance="minimal"
            width="grow"
            className={styles['advice-section__cta-overrides']}
            onClick={() =>
              logCtaClick({
                label: t('travelVaccinations.landingPage.advice.ctaLabel'),
                pageName: pageName?.log,
                section: t('amplitude.adviceSection.section'),
              })
            }
          />
        </Col>
      </div>
    </div>
  );
};
