import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';

import styles from '../VaccinationDescriptions.module.scss';
import strings from './en.json';

export const CholeraDescription = () => {
  return (
    <div>
      <Heading tag="h3">{strings.About.title}</Heading>
      <Text>{strings.About.descriptionI}</Text>
      <Text>{strings.About.descriptionII}</Text>
      <br />
      <Heading tag="h3">{strings.CommonSymptoms.title}</Heading>
      <Text>{strings.CommonSymptoms.descriptionI}</Text>
      <ul>
        {strings.CommonSymptoms.symptomsListI.map((symptom) => (
          <li className={styles['vaccination__list']} key={symptom}>
            {symptom}
          </li>
        ))}
      </ul>
      <Text>{strings.CommonSymptoms.descriptionII}</Text>
      <br />
      <Heading tag="h3">{strings.Prevention.title}</Heading>
      <Text>{strings.Prevention.descriptionI}</Text>
      <ul>
        {strings.Prevention.preventionList.map((prevention) => (
          <li className={styles['vaccination__list']} key={prevention}>
            {prevention}
          </li>
        ))}
      </ul>
      <br />
      <Text>{strings.Prevention.descriptionII}</Text>
      <br />
      <Heading tag="h3">{strings.Vaccination.title}</Heading>
      <Text>{strings.Vaccination.description}</Text>
      <ul>
        {strings.Vaccination.atRiskList.map((group) => (
          <li className={styles['vaccination__list']} key={group}>
            {group}
          </li>
        ))}
      </ul>
      <br />
      <Heading tag="h3">{strings.VaccinationLength.title}</Heading>
      <Text>{strings.VaccinationLength.description}</Text>
    </div>
  );
};
